<template>
  <AgTabs test-id="test">
    <template #TabHeading>
      <v-tab value="one">
        <ag-icon test-id="flightIcon" name="flightIcon"></ag-icon>
        <span>Flights</span>
      </v-tab>
      <v-tab value="two">
        <ag-icon test-id="hotelIcon" name="hotelIcon"></ag-icon>
        <span>Hotels</span></v-tab
      >
      <v-tab value="three">
        <ag-icon test-id="umrah" name="umrah"></ag-icon>
        <span>Tours</span></v-tab
      >
    </template>
    <template #TabDetail>
      <v-window-item
        value="one"
        transition="fade"
        reverse-transition="fade"
        :disabled="true"
      >
        <flight-bookings :is-dashboard-view="isDashboardView" />
      </v-window-item>
      <v-window-item
        value="two"
        transition="fade"
        reverse-transition="fade"
        :disabled="true"
      >
        <hotel-bookings />
      </v-window-item>
      <v-window-item
        value="three"
        transition="fade"
        reverse-transition="fade"
        :disabled="true"
      >
        <tour-bookings />
      </v-window-item>
    </template>
  </AgTabs>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FlightBookings from "@/modules/MyBookings/components/FlightBookings.vue";
import HotelBookings from "@/modules/MyBookings/components/HotelBookings.vue";
import TourBookings from "@/modules/MyBookings/components/GroupTourBookings.vue";

export default defineComponent({
  name: "MyBookingsTable",
  props: {
    isDashboardView: {
      type: Boolean,
      default: false,
    },
  },
  components: { HotelBookings, FlightBookings, TourBookings },
});
</script>
