import { MutationTree } from "vuex";
import { INewsState } from "@/ag-portal-common/interfaces/news.interface";

const mutations: MutationTree<INewsState> = {
  setLoadingState(state, isLoading) {
    state.isNewsLoading = isLoading;
  },
  setNews(state, newsData) {
    state.news = newsData;
  },
};

export default mutations;
