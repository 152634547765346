<template>
  <AgDiv class="not_found_wrap">
    <img src="@/assets/Ag401.svg" class="imgStyle" />
    <AgHeading
      title="You Are Not Authorized To Perform This Action"
      variant="h3"
      style="text-align: center"
    ></AgHeading
  ></AgDiv>
</template>
<script>
export default {
  name: "fourZeroOne",
};
</script>

<style scoped>
.imgStyle {
  width: 100%;
  background-size: contain;
  height: 500px;
  margin: auto;
}
</style>
