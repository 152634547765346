<template>
  <AGAppContainer class="default-page-color" fullWidth>
    <a-g-full-width-page>
      <template v-slot:content-area>
        <AGContainer class="login_wrapper">
          <AGPanel>
            <AGLogo :url="urlLogo" is-small />
            <h5>Reset Your <span>Password?</span></h5>
            <p>Enter your new password below</p>
            <form @submit.prevent="onSubmit">
              <AGTextField
                v-model="resetPasswordPayload.newPassword"
                :testId="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'reset-password')"
                :value="resetPasswordPayload.newPassword"
                name="newPassword"
                label="Password"
                :error="errors['newPassword']"
                :appendIcon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              />

              <AGTextField
                v-model="resetPasswordPayload.confirmPassword"
                :testId="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'reset-password')"
                :value="resetPasswordPayload.confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                :error="errors['confirmPassword']"
                :appendIcon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              />
              <div class="forgot_card">
                <router-link
                  :to="PATH.ROOT"
                  :testId="genTestId(ELEMENT_TYPES.LINK, 'back-to-login')"
                >
                  <AGButton
                    variant="link"
                    :testId="genTestId(ELEMENT_TYPES.BUTTON, 'back-to-login')"
                    >Back to Login
                  </AGButton>
                </router-link>
              </div>

              <div class="d-grid">
                <AGButton
                  type="submit"
                  :testId="genTestId(ELEMENT_TYPES.BUTTON, 'login-submit')"
                  :is-loading="isLoading"
                  variant="primary"
                  >Reset Password
                </AGButton>
              </div>
            </form>
          </AGPanel>
        </AGContainer>
      </template>
    </a-g-full-width-page>
  </AGAppContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PATH } from "@/ag-portal-common/constants/path";
import {
  genTestId,
  yupValidationErrorAsSchema,
  parseLocalStorageData,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { ResetPasswordDto } from "@/modules/Auth/dtos/auth.dto";
import { validateResetPasswordSchema } from "@/modules/Auth/validations/resetPassword.validation";
import queryString from "query-string";
import { ValidationError } from "yup";
import { IOrganizationPreference } from "@/ag-portal-common/interfaces/organization.interface";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";

export default defineComponent({
  name: "ResetPasswordView",
  computed: {
    PATH() {
      return PATH;
    },
    urlLogo() {
      const organizationPreference: IOrganizationPreference | null =
        parseLocalStorageData(STORAGE_KEYS.PREFERENCES);
      return organizationPreference?.logo;
    },
  },
  data(): {
    isLoading: boolean;
    showPassword: boolean;
    ELEMENT_TYPES: IObject;
    resetPasswordPayload: IObject;
    errors: IObject;
  } {
    return {
      isLoading: false,
      showPassword: false,
      ELEMENT_TYPES,
      resetPasswordPayload: { newPassword: "", confirmPassword: "" },
      errors: {},
    };
  },

  methods: {
    genTestId,
    async onSubmit() {
      try {
        this.errors = {};
        await validateResetPasswordSchema.validate(this.resetPasswordPayload, {
          abortEarly: false,
        });
        this.isLoading = true;
        let parsedQuery = queryString.parse(window.location.search) as IObject;
        let payload: ResetPasswordDto = {
          new_password: this.resetPasswordPayload.newPassword,
          token: parsedQuery?.token || "",
        };
        this.$store.dispatch("resetPassword", {
          body: payload,
          sucessCallback: this.onPasswordResetSuccess,
          failureCallback: this.disableIsLoading,
        });
      } catch (ex) {
        if (ex instanceof ValidationError) {
          this.errors = yupValidationErrorAsSchema(ex);
        }
      }
    },
    onPasswordResetSuccess() {
      this.disableIsLoading();
      this.$router.push(PATH.ROOT);
    },
    disableIsLoading() {
      this.isLoading = false;
    },
  },
});
</script>
<style>
header {
  line-height: 1.5;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

.error-container {
  color: red;
  margin: 10px 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}

.wrapper {
  padding-left: 24px !important;
}

.login_box {
  margin: auto !important;
}
</style>
