<template>
  <my-bookings-filters-view
    v-show="showFilters"
    @fetchBookings="onUpdateFilter"
  />

  <ag-card>
    <ag-loader v-if="$store.getters.isFetchingFlightBookings" />
    <template v-else-if="showTable">
      <ag-table
        :headers="headers"
        :page="fetchBookingParams.page"
        :items="$store.getters.flightBookings.bookings"
        :total-page-count="totalPageCount"
        :has-pagination="hasPagination"
        :items-per-page="itemsPerPage"
        @onPageChange="onPageChange"
        :has-search="false"
      >
        <template #col-bookingId="{ item }">
          <router-link
            :data-test-id="
              genTestId(ELEMENT_TYPES.LINK, `flight-booking-${item.raw.id}`)
            "
            :to="
              formatStringToRoutePath(PATH.FLIGHTS_BOOKING_DETAILS, {
                id: item.raw.id,
              })
            "
            >{{ item.raw.bookingId }}
          </router-link>
        </template>

        <template #col-travelers="{ item }">
          <AGButtonDropDown
            class="table_dropdown_wrap"
            text="Test"
            :items="item.raw.travelers"
          >
            <template #label>
              <i>{{ item.raw.travelers[0] }}</i>
            </template>
            <AGDropDownButtonItem
              v-for="(item, index) in item.raw.travelers"
              :key="index"
              :name="item"
            />
          </AGButtonDropDown>
        </template>

        <template #col-sector="{ item }">
          <ag-div class="sector_status color_chip green"
            >{{ item.raw.sector }}
          </ag-div>
        </template>

        <template #col-agent="{ item }">
          <ag-div>{{ item.raw.agent }}</ag-div>
        </template>

        <template #col-bookingLogs="{ item }" v-if="isOperationsOrSuperUser">
          <AGButton
            :is-loading="selectedId === item.raw.bookingId"
            @click="onViewBookingLogs(item.raw.bookingId)"
          >
            <div class="d-flex align-items-center">View Bookings Logs</div>
          </AGButton>
        </template>

        <template #col-issuanceLogs="{ item }" v-if="isOperationsOrSuperUser">
          <router-link
            :data-test-id="
              genTestId(ELEMENT_TYPES.LINK, `issuance-logs-${item.raw.pnr}`)
            "
            :to="
              formatStringToRoutePath(PATH.ISSUANCE_LOGS, {
                pnr: item.raw.pnr,
              })
            "
            target="_blank"
          >
            <AGButton>
              <div class="d-flex align-items-center">View Issuance Logs</div>
            </AGButton>
          </router-link>
        </template>

        <template #col-bookingTime="{ item }">
          <div>{{ formattedDate(item.raw.bookingTime) }}</div>
          <div class="text-color-green">
            {{ formattedTime(item.raw.bookingTime) }}
          </div>
        </template>

        <template #col-travelDate="{ item }">
          <div>{{ formattedDate(item.raw.travelDate) }}</div>
        </template>

        <template #col-status="{ item }">
          <div class="d-flex align-items-center">
            <ag-div class="flight_table_status color_chip green"
              >{{ item.raw.status }}
            </ag-div>

            <ag-div
              v-if="
                item.raw.confirmedBookingExpiresAt === null &&
                item.raw.status !== isIssued
              "
            >
              PNR Expiry will be available soon
            </ag-div>
            <ag-div
              v-else-if="
                isDateExpired(
                  getConfirmOrInitiateDate(
                    item.raw.status,
                    item.raw.initiatedBookingExpiresAt,
                    item.raw.confirmedBookingExpiresAt
                  )
                ) && item.raw.status !== isIssued
              "
              class="color-red"
            >
              Expired
            </ag-div>
            <ag-div v-else>
              <ag-div v-if="item.raw.status !== isIssued">
                Expires in <br />
                <AgDuration
                  :to="
                    getConfirmOrInitiateDate(
                      item.raw.status,
                      item.raw.initiatedBookingExpiresAt,
                      item.raw.confirmedBookingExpiresAt
                    )
                  "
                />
              </ag-div>
            </ag-div>
          </div>
        </template>

        <template #col-brandTicket="{ item }">
          <AGButton
            variant="link"
            :testId="
              genTestId(
                ELEMENT_TYPES.BUTTON,
                `flight-booking-brand-ticket-${item.raw.id}`
              )
            "
            @click="downloadBrandTicket(item.raw.bookingId)"
            :disabled="disableBrandTicket(item.raw.status)"
            :is-loading="
              $store.getters.isDownloadingBrandTicket &&
              item.raw.bookingId === activeBookingIdForBrandTicket
            "
          >
            <strong>
              <v-icon class="font-size-24" icon="mdi-download"></v-icon>
            </strong>
          </AGButton>
        </template>

        <template #col-airlineTicket="{ item }">
          <AGButton
            :testId="
              genTestId(
                ELEMENT_TYPES.BUTTON,
                `flight-booking-airline-ticket-${item.raw.id}`
              )
            "
            variant="link"
            v-if="
              showDownloadAirlineTicketButton(
                item.raw.status,
                item.raw.supplier
              )
            "
            @click="onClickAirlineTicket(item.raw)"
          >
            <strong>
              <v-icon class="font-size-24" icon="mdi-send"></v-icon>
            </strong>
          </AGButton>

          <ag-div class="text-center" v-else>N/A</ag-div>
        </template>
      </ag-table>
      <AgDiv test-id="" class="text-center">
        <AGButton
          test-id=""
          variant="primary"
          type="button"
          v-show="isDashboardView"
          @click="handleViewMore"
          >View More
        </AGButton>
      </AgDiv>
    </template>

    <ag-not-found v-else :heading="NOTIFICATION_MESSAGES.NO_RESULTS_FOUND" />
    <airline-ticket-modal
      :show-modal="showAirlineTicketModal"
      @onSendAirlineTicket="onSendAirlineTicket"
      @closeModal="showAirlineTicketModal = false"
    />
  </ag-card>

  <ag-modal :is-open="isOpen" @close="closeModal" modal-width="50%">
    <template #body>
      <pre>{{ stringifyApiResponse }}</pre>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import {
  formatStringToRoutePath,
  genTestId,
  getFormattedDateTime,
  isDateExpired,
} from "@/ag-portal-common/utils/helpers";
import {
  FORMAT_DD_MMM_YYYY,
  FORMAT_HH_SS,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { ALLOWED_SUPPLIERS } from "@/ag-portal-common/constants/allowedSuppliers";
import { BOOKING_STATUS } from "@/ag-portal-common/enums/BOOKING_STATUS";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { FetchBookingsParamsRequestModel } from "@/modules/MyBookings/models/fetchBookingsParams.request";
import MyBookingsFiltersView from "@/modules/MyBookings/components/MyBookingsFilters.vue";
import AirlineTicketModal from "@/modules/MyBookings/components/AirlineTicketModal.vue";
import { IFlightBookings } from "@/interfaces/flightBookings.interface";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import AgDuration from "@/ag-portal-common/components/AgDuration.vue";
import { SendAirlineTicketBodyRequest } from "@/modules/MyBookings/models/sendAirlineTicketBody.request";
import { BOOKING_TYPES } from "@/ag-portal-common/enums/BOOKING_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { PATH } from "@/ag-portal-common/constants/path";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import MyBookingsService from "@/modules/MyBookings/services/myBookings.service";

export default defineComponent({
  name: "FlightBookings",
  components: { AgDuration, AirlineTicketModal, MyBookingsFiltersView },
  props: {
    isDashboardView: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    const isNewFetchData = false;
    this.fetchBookings(isNewFetchData, this.setItemsPerPage);
  },
  computed: {
    isIssued() {
      return BOOKING_STATUS.ISSUED.toUpperCase();
    },
    showFilters() {
      return this.isDashboardView === false;
    },
    showTable() {
      return (
        !this.$store.getters.isFetchingFlightBookings &&
        this.$store.getters.flightBookings.bookings.length
      );
    },
    hasPagination() {
      return this.isDashboardView ? false : true;
    },
    PATH() {
      return PATH;
    },
    stringifyApiResponse() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      return this.apiResponse ? JSON.stringify(this.apiResponse, null, 2) : "";
    },
    totalPageCount() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      let itemsPerPage = this.itemsPerPage;
      let totalCount = this.$store.getters.flightBookings.count;

      return Math.ceil(totalCount / itemsPerPage);
    },
  },
  created() {
    let user = this.user() as IUserV2;
    let userRole = user?.role_unique_id;

    if (
      userRole === USER_ROLES.OPERATIONS ||
      userRole === USER_ROLES.AG_SUPER_USER
    ) {
      this.headers.push(
        {
          title: "Booking Logs",
          value: "bookingLogs",
          key: "bookingLogs",
          sortable: false,
        },
        {
          title: "Issuance Logs",
          value: "issuanceLogs",
          key: "issuanceLogs",
          sortable: false,
        }
      );
    }
  },
  setup() {
    const user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2 | null;
    const isOperationsOrSuperUser =
      user()?.role_unique_id === USER_ROLES.OPERATIONS ||
      user()?.role_unique_id === USER_ROLES.AG_SUPER_USER;

    return {
      user,
      isOperationsOrSuperUser,
    };
  },
  methods: {
    formatStringToRoutePath,
    genTestId,
    isDateExpired,
    closeModal() {
      this.isOpen = false;
    },
    async onViewBookingLogs(id: string) {
      this.selectedId = id;
      this.isOpen = false;
      this.apiResponse = null;
      this.isBookingLogLoading = true;
      const myBookingsService = new MyBookingsService();
      const response = await myBookingsService.fetchBookingLogs(id);
      this.apiResponse = response;
      if (response) {
        this.isBookingLogLoading = false;
        this.isOpen = true;
        this.selectedId = "";
      }
    },

    onPageChange(num: number) {
      let nextPage = this.$store.getters.flightBookings.next;
      let prevPage = this.$store.getters.flightBookings.previous;
      let currentPage: number = this.fetchBookingParams.page || 1;
      if ((num > currentPage && nextPage) || (currentPage > num && prevPage)) {
        this.fetchBookingParams.page = num;
        const isNewFetchData = true;
        this.fetchBookings(isNewFetchData);
      }
    },
    setItemsPerPage() {
      let bookingsLength = this.$store.getters.flightBookings.bookings.length;
      this.itemsPerPage = bookingsLength;
    },
    onUpdateFilter(filters: FetchBookingsParamsRequestModel) {
      this.fetchBookingParams = {
        ...filters,
        booking_type: this.fetchBookingParams.booking_type,
        page: 1,
      };
      const isNewFetchData = true;
      this.fetchBookings(isNewFetchData);
    },
    getConfirmOrInitiateDate(
      status: BOOKING_STATUS,
      initiatedBookingExpiresAt: Date,
      confirmedBookingExpiresAt: null | Date
    ) {
      if (status === BOOKING_STATUS.CONFIRMED.toUpperCase())
        return confirmedBookingExpiresAt || "Invalid Date";
      return initiatedBookingExpiresAt;
    },
    onSendAirlineTicket(emails: string[]) {
      this.$store.dispatch("sendAirlineTicket", {
        payload: {
          pnr: this.activeBooking?.pnr,
          airline_provider: this.activeBooking?.supplier,
          emails,
        } as SendAirlineTicketBodyRequest,
        callback: () => (this.showAirlineTicketModal = false),
      });
    },
    downloadBrandTicket(bookingId: string) {
      this.activeBookingIdForBrandTicket = bookingId;
      this.$store.dispatch("downloadBrandTicket", bookingId);
    },
    onClickAirlineTicket(booking: IFlightBookings) {
      this.activeBooking = booking;
      this.showAirlineTicketModal = true;
    },
    fetchBookings(isFetchNewData = false, callBack?: () => void) {
      this.$store.dispatch("fetchFlightBookings", {
        payload: this.fetchBookingParams,
        isFetchNewData,
        callBack,
      });
    },
    formattedDate(date: Date) {
      return getFormattedDateTime(date, FORMAT_DD_MMM_YYYY);
    },
    formattedTime(date: Date) {
      return getFormattedDateTime(date, FORMAT_HH_SS);
    },
    showDownloadAirlineTicketButton(status: string, supplier: string) {
      const isAllowedSupplier = ALLOWED_SUPPLIERS.includes(supplier);
      return (
        status === BOOKING_STATUS.ISSUED.toUpperCase() && isAllowedSupplier
      );
    },
    disableBrandTicket(status: BOOKING_STATUS) {
      return status === BOOKING_STATUS.CANCELED.toUpperCase();
    },
    handleViewMore() {
      this.$router.push(PATH.MY_BOOKINGS);
    },
  },
  data(): {
    apiResponse: any;
    ELEMENT_TYPES: IObject;
    activeBooking: IFlightBookings | null;
    showAirlineTicketModal: boolean;
    itemsPerPage: number;
    selectedId: string;
    headers: Array<{
      title: string;
      value: string;
      key: string;
      sortable: boolean;
    }>;
    fetchBookingParams: FetchBookingsParamsRequestModel;
    NOTIFICATION_MESSAGES: IObject;
    activeBookingIdForBrandTicket: string;
    isBookingLogLoading: boolean;
    isOpen: boolean;
  } {
    return {
      NOTIFICATION_MESSAGES,
      apiResponse: null,
      ELEMENT_TYPES,
      isOpen: false,
      itemsPerPage: 0,
      selectedId: "",
      isBookingLogLoading: false,
      fetchBookingParams: {
        page: 1,
        start: null,
        end: null,
        search: "",
        booking_type: BOOKING_TYPES.FLIGHT,
      },
      activeBooking: null,
      activeBookingIdForBrandTicket: "",
      showAirlineTicketModal: false,
      headers: [
        {
          title: "Booking ID",
          value: "bookingId",
          key: "bookingId",
          sortable: false,
        },
        {
          title: "Travelers",
          value: "travelers",
          key: "travelers",
          sortable: false,
        },
        {
          title: "Sector",
          value: "sector",
          key: "sector",
          sortable: false,
        },
        {
          title: "Booking Time",
          value: "bookingTime",
          key: "bookingTime",
          sortable: false,
        },
        {
          title: "PNR",
          value: "pnr",
          key: "pnr",
          sortable: false,
        },
        {
          title: "Supplier",
          value: "supplier",
          key: "supplier",
          sortable: false,
        },
        {
          title: "Travel Date",
          value: "travelDate",
          key: "travelDate",
          sortable: false,
        },
        {
          title: "Status",
          value: "status",
          key: "status",
          sortable: false,
        },
        {
          title: "Agent Details",
          value: "agent",
          key: "agent",
          sortable: false,
        },

        {
          title: "Brand Ticket",
          value: "brandTicket",
          key: "brandTicket",
          sortable: false,
        },
        {
          title: "Airline Ticket",
          value: "airlineTicket",
          key: "airlineTicket",
          sortable: false,
        },
      ],
    };
  },
});
</script>
