<template>
  <div>
    <RevenueChart />
    <AgCard>
      <MyBookingsTable :isDashboardView="true" />
    </AgCard>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import StorageService from "@/ag-portal-common/services/storage.service";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";
import { differenceInMinutes } from "date-fns";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import RevenueChart from "./RevenueChart.vue";
import MyBookingsTable from "@/modules/MyBookings/components/MyBookingsTable.vue";

export default defineComponent({
  name: "AgentDashboardView",
  components: { RevenueChart, MyBookingsTable },
});
</script>
