<template>
  <ag-card>
    <ag-heading title="Create New Payment" variant="h3" />

    <ag-row>
      <ag-column xs="12" sm="12" md="6">
        <ag-div>
          <ag-heading title="Select Payment Method" variant="label" />
          <ag-radio
            v-model="method"
            :testId="
              genTestId(ELEMENT_TYPES.RADIO_BUTTON, 'create-payment-method')
            "
          >
            <ag-card v-show="!isSectorDubai">
              <ag-radio-item
                :testId="
                  genTestId(ELEMENT_TYPES.RADIO_BUTTON, 'create-payment-IBFT')
                "
                :value="PAYMENT_METHODS_TYPES.IBFT"
                name="method"
                label="IBFT - Bank Transfer"
              ></ag-radio-item>
              <AgTable
                v-if="bankDetails?.bank_account_no !== null && isBank"
                :headers="bankTable"
                :items="[bankDetails]"
                :has-pagination="false"
                :items-per-page="1"
                :has-search="false"
              >
                <template #col-bank_name="{ item }">
                  <ag-div>{{ item.raw.bank_name }}</ag-div>
                </template>
                <template #col-bank_iban="{ item }">
                  <ag-div>{{ item.raw.bank_iban }}</ag-div>
                </template>
                <template #col-bank_title="{ item }">
                  <ag-div>{{ item.raw.bank_title }}</ag-div>
                </template>
                <template #col-bank_account_no="{ item }">
                  <ag-div>{{ item.raw.bank_account_no }}</ag-div>
                </template>
              </AgTable>
              <ag-div class="margin_top_20" v-if="isBank">
                <ag-heading
                  v-if="bankDetails?.bank_account_no !== null"
                  :title="`Note: ${noteForAmount}`"
                  variant="b"
                />
              </ag-div>
              <AgNotFound
                v-if="bankDetails?.bank_account_no === null && isBank"
                test-id=""
                heading="Please contact billing team to have your IBFT details updated"
                description=""
              />
            </ag-card>

            <ag-card v-show="!isSectorDubai">
              <ag-radio-item
                :testId="
                  genTestId(
                    ELEMENT_TYPES.RADIO_BUTTON,
                    'create-payment-ONE_BILL'
                  )
                "
                :value="PAYMENT_METHODS_TYPES.ONE_BILL"
                name="method"
                label="One Bill"
              ></ag-radio-item>
            </ag-card>

            <ag-card>
              <ag-radio-item
                :testId="
                  genTestId(
                    ELEMENT_TYPES.RADIO_BUTTON,
                    'create-payment-CREDIT_CARD'
                  )
                "
                :value="PAYMENT_METHODS_TYPES.CREDIT_CARD"
                name="method"
                label="Debit/Credit Card"
              ></ag-radio-item>
            </ag-card>
          </ag-radio>
          <ag-heading
            class="color-red"
            v-if="errors?.method"
            :title="errors?.method"
            variant="p"
          />
        </ag-div>

        <form @submit.prevent="onSubmit" v-if="!isBank">
          <ag-heading v-if="!isBank" title="Amount" variant="label" />
          <a-g-text-field
            :testId="
              genTestId(ELEMENT_TYPES.TEXT_FIELD, 'create-payment-amount')
            "
            :error="errors?.amount"
            v-model="amount"
            :value="amount"
          />
          <financial-profile-dropdown
            label="Financial Profile"
            :error="errors['financial_profile_public_id']"
            @onUpdateFinancialProfile="updateFinancialProfilePublicId"
          />
          <ag-heading title="Description" variant="label" />
          <a-g-text-field
            :testId="
              genTestId(ELEMENT_TYPES.TEXT_FIELD, 'create-payment-description')
            "
            v-model="description"
            :value="description"
          />
          <ag-div class="d-flex justify-content-end">
            <a-g-button class="margin_right_20" @click="routeToPaymentList"
              >Cancel
            </a-g-button>
            <a-g-button
              type="submit"
              :testId="genTestId(ELEMENT_TYPES.BUTTON, 'create-payment-submit')"
              :is-loading="$store.getters.isCreatingPayment"
              :disabled="$store.getters.isCreatingPayment"
              >Create Payment
            </a-g-button>
          </ag-div>
        </form>
      </ag-column>
    </ag-row>
    <ag-div class="margin_top_20" v-if="!isBank">
      <ag-heading :title="`Note: ${noteForAmount}`" variant="b" />
    </ag-div>
  </ag-card>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { PAYMENT_METHODS_TYPES } from "@/ag-portal-common/enums/PAYMENT_METHODS_TYPES";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { ISettings } from "@/ag-portal-common/interfaces/settings.interface";
import {
  formatString,
  formatStringToRoutePath,
  genTestId,
  yupValidationErrorAsSchema,
} from "@/ag-portal-common/utils/helpers";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { ValidationError } from "yup";
import { createPaymentValidationschema } from "@/modules/Payments/validations/createPaymentValidationSchema";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { PATH } from "@/ag-portal-common/constants/path";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { IBankDetails } from "@/ag-portal-common/interfaces/bankDetails.interface";
import FinancialProfileDropdown from "@/components/FinancialProfileDropdown.vue";

interface IState {
  method: PAYMENT_METHODS_TYPES;
  financialProfilePublicId: string;
  bankDetails: IBankDetails | null;
  description: string;
  amount: number;
  errors: IObject;
  isBank: boolean;
  bankTable: {
    title: string;
    value: string;
    key: string;
    sortable: boolean;
  }[];
}

export default defineComponent({
  name: "CreatePayment",
  components: { FinancialProfileDropdown },
  computed: {
    isSectorDubai() {
      return localStorage.getItem("sector") === "Aeroglobe - Dubai";
    },
    ELEMENT_TYPES() {
      return ELEMENT_TYPES;
    },
    PAYMENT_METHODS_TYPES() {
      return PAYMENT_METHODS_TYPES;
    },
    noteForAmount() {
      const settings: ISettings = this.settings();
      let ccMDR = settings ? settings.cc_payment_mdr_charge : 2;
      if (localStorage.getItem("currency") !== "PKR") {
        ccMDR = 3;
      }
      const onebillFlatCharge = settings
        ? settings.one_bill_payment_flat_charge
        : 100;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      let { amount } = this;
      const netAmountCreditCard = amount - amount * (ccMDR / 100);
      const netAmountOnebill = amount - onebillFlatCharge;

      const noteForNetAmountCreditCard = `${formatString(
        NOTIFICATION_MESSAGES.CC_CHARGES_DEDUCTION,
        ccMDR.toString()
      )} ${
        netAmountCreditCard > 0
          ? formatString(
              NOTIFICATION_MESSAGES.TOTAL_AMOUNT_DEPOSIT,
              netAmountCreditCard.toString(),
              localStorage.getItem("currency") || ""
            )
          : ""
      }`;
      const noteForIBFT = NOTIFICATION_MESSAGES.IBFT_DEDUCTION;
      const noteForNetAmountOneBill = `${formatString(
        NOTIFICATION_MESSAGES.ONE_BILL_CHARGES_DEDUCTION,
        onebillFlatCharge.toString()
      )} ${
        netAmountOnebill > 0
          ? formatString(
              NOTIFICATION_MESSAGES.TOTAL_AMOUNT_DEPOSIT,
              netAmountOnebill.toString(),
              localStorage.getItem("currency") || ""
            )
          : ""
      }`;

      if (this.method === PAYMENT_METHODS_TYPES.CREDIT_CARD)
        return noteForNetAmountCreditCard;
      if (this.method === PAYMENT_METHODS_TYPES.ONE_BILL)
        return noteForNetAmountOneBill;
      if (this.method === PAYMENT_METHODS_TYPES.IBFT) return noteForIBFT;
      return "";
    },
  },
  data(): IState {
    return {
      method: PAYMENT_METHODS_TYPES.IBFT,
      bankDetails: null,
      description: "",
      financialProfilePublicId: "",
      amount: 0,
      errors: {},
      isBank: true,
      bankTable: [
        {
          title: "Bank Name",
          value: "bankDetails.bank_name",
          key: "bank_name",
          sortable: false,
        },
        {
          title: "Bank IBAN",
          value: "bankDetails.bank_iban",
          key: "bank_iban",
          sortable: false,
        },
        {
          title: "Bank Account No",
          value: "bankDetails.bank_account_no",
          key: "bank_account_no",
          sortable: false,
        },
        {
          title: "Bank Title",
          value: "bankDetails.bank_title",
          key: "bank_title",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    genTestId,
    updateFinancialProfilePublicId(value: string) {
      this.financialProfilePublicId = value;
    },
    routeToPaymentDetail(id: string) {
      this.$router.push(formatStringToRoutePath(PATH.PAYMENT_DETAIL, { id }));
    },
    routeToPaymentList() {
      this.$router.push(PATH.PAYMENTS);
    },
    async onSubmit() {
      this.errors = {};

      try {
        const payload = {
          amount: this.amount,
          method: this.method,
          description: this.description,
          financial_profile_public_id: this.financialProfilePublicId,
        };
        await createPaymentValidationschema.validate(payload, {
          abortEarly: false,
        });
        this.$store.dispatch("createPayment", {
          payload,
          callback: this.routeToPaymentDetail,
        });
      } catch (ex) {
        if (ex instanceof ValidationError) {
          this.errors = yupValidationErrorAsSchema(ex);
        }
      }
    },
  },
  created() {
    let user = this.user() as IUserV2;
    this.bankDetails = user?.bank_details || null;
  },
  setup() {
    const settings = inject(AUTH_CONTEXT_KEYS.settings) as () => ISettings;
    const user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2;
    return { settings, user };
  },
  watch: {
    method(newMethod: string) {
      // Check if the selected method is IBFT and update isBank accordingly
      this.isBank = newMethod === PAYMENT_METHODS_TYPES.IBFT;
    },
  },
});
</script>
