import { ActionContext, ActionTree } from "vuex";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { IPaymentState } from "@/interfaces/paymentState.interface";
import PaymentsService from "@/modules/Payments/services/payments.service";
import { IPayment, IPayments } from "@/interfaces/payments.interface";
import { FetchPaymentsParamsRequestModel } from "@/modules/Payments/models/fetchPaymentsParams.request";
import { CreatePaymentBodyRequest } from "@/modules/Payments/models/createPaymentBody.request";

const actions: ActionTree<IPaymentState, IPaymentState> = {
  async fetchPayments(
    context: ActionContext<IPaymentState, IPaymentState>,
    params: FetchPaymentsParamsRequestModel
  ) {
    const methodName = "actions.fetchPayments";
    context.commit("enableIsFetchingPayments");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const paymentsService = new PaymentsService();
    const response: IPayments = await paymentsService.fetchPayments(params);
    context.commit("savePayments", response);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },

  async fetchPayment(
    context: ActionContext<IPaymentState, IPaymentState>,
    id: string
  ) {
    const methodName = "actions.fetchPayment";
    context.commit("enableIsFetchingPayment");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const paymentsService = new PaymentsService();
    const response: IPayment | null = await paymentsService.fetchPayment(id);
    context.commit("savePayment", response);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },

  async createPayment(
    context: ActionContext<IPaymentState, IPaymentState>,
    {
      payload,
      callback,
    }: { payload: CreatePaymentBodyRequest; callback: (id: string) => void }
  ) {
    const methodName = "actions.createPayment";
    context.commit("enableIsCreatingPayment");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const paymentsService = new PaymentsService();
    await paymentsService.createPayment(payload, callback);
    context.commit("disableIsCreatingPayment");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
};

export default actions;
