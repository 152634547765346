import * as yup from "yup";

export const addPackageValidation = yup.object().shape({
  active: yup.boolean().required("Required"),
  name: yup.string().required("Package name is required"),
  description: yup.string().required("Pacakge description is required"),
  start_date: yup.date().required("Pacakge start date is required"),
  end_date: yup.date().required("Pacakge end date is required"),
  price: yup.string().required("Price is required"),
});
