<template>
  <ag-modal scrollable :is-open="isOpen" @close="closeModal" modal-width="40%">
    <template #header>
      <v-card-title>Add Whatsapp Member</v-card-title>
      <a-g-button
        type="button"
        class="modal_close_icon"
        variant="link"
        @click="closeModal"
        >Close
      </a-g-button>
    </template>
    <template #body>
      <form @submit="onSubmit" class="add-member-form">
        <ag-row>
          <ag-column sm="12" md="12" cols="12">
            <AgPhoneField
              test-id=""
              default-country-code="PK"
              info-message="Please input the Whatsapp Member number
            here"
              :error="errors?.contact_number"
              @update-value="handleMobileFieldChange"
            />
          </ag-column>
          <ag-column sm="12" md="12" cols="12">
            <AGTextField
              label="Member Name"
              v-model="name"
              :value="name"
              test-id=""
              variant="outlined"
              :error="errors?.name"
            />
          </ag-column>
        </ag-row>
        <AGButton
          test-id=""
          :is-loading="$store.getters.isWhatsappActionLoading"
          type="submit"
          style="float: right"
          >Add</AGButton
        >
      </form>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { addWhatsappMemberValidation } from "@/modules/Organization/validations/addWhatsappMember.validation";
import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import { ValidationError } from "yup";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IOrganizationFromLoginResponse } from "@/ag-portal-common/interfaces/organization.interface";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";

export default defineComponent({
  name: "AddWhatsappMemberDialog",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data(): {
    name: string;
    contact_number: any;
    errors: any;
  } {
    return {
      name: "",
      contact_number: {},
      errors: {},
    };
  },
  methods: {
    clearState() {
      this.name = "";
      this.contact_number = "";
    },
    closeModal() {
      this.$emit("closeModal");
      this.clearState();
    },
    handleMobileFieldChange(value: any) {
      this.contact_number = value;
    },
    successHandler() {
      this.closeModal();
      this.fetchWhatsappMembers();
    },
    fetchWhatsappMembers() {
      const organization_id = this.getOrganizationId();
      this.$store.dispatch("getWhatsappMembers", organization_id);
    },
    isAGSuperUser() {
      const user = this.user();
      return user?.role_unique_id === USER_ROLES.AG_SUPER_USER;
    },
    getOrganizationId() {
      if (this.isAGSuperUser()) {
        const { org_id } = this.$route.query;
        return org_id;
      } else {
        return this.organization()?.organization_id;
      }
    },
    async onSubmit(e: Event) {
      this.errors = {};
      e.preventDefault();
      const contact_number = this.contact_number;
      const payload_data = {
        name: this.name,
        contact_number: contact_number?.e164?.replace(/^\+/, ""),
        isValid: contact_number?.isValid,
      };
      try {
        const payload = await addWhatsappMemberValidation.validate(
          payload_data,
          { abortEarly: false }
        );
        const orgId = this.getOrganizationId();
        this.$store.dispatch("addWhatsappMember", {
          payload,
          organizationId: orgId,
          onSuccessHandler: this.successHandler,
        });
      } catch (ex) {
        if (ex instanceof ValidationError) {
          const _errors = yupValidationErrorAsSchema(ex);
          this.errors = _errors;
        }
      }
    },
  },

  setup() {
    const organization = inject(
      AUTH_CONTEXT_KEYS.organization
    ) as () => IOrganizationFromLoginResponse;
    const user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2;

    return { organization, user };
  },
});
</script>

<style lang="scss">
.v-card-text {
  overflow: visible !important;
}
</style>
