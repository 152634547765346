import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_AgTable = _resolveComponent("AgTable")!
  const _component_ag_card = _resolveComponent("ag-card")!

  return (this.$store.getters.isFetchingOrganizations)
    ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
    : (_openBlock(), _createBlock(_component_ag_card, { key: 1 }, {
        default: _withCtx(() => [
          _createVNode(_component_AgTable, {
            headers: _ctx.headers,
            items: _ctx.transformTableData,
            search: _ctx.search,
            "items-per-page": 10,
            "has-pagination": false,
            "has-search": true
          }, {
            "col-public_id": _withCtx(({ item }) => [
              _createVNode(_component_router_link, {
                to: `${_ctx.PATH.ORGANIZATION}/${item.raw.id}`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.raw.public_id), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            button: _withCtx(() => [
              _createVNode(_component_a_g_button, {
                onClick: _ctx.onClickCreateNew,
                testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'create-new-organization')
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Create new ")
                ]),
                _: 1
              }, 8, ["onClick", "testId"])
            ]),
            "col-action": _withCtx(({ item }) => [
              _createVNode(_component_a_g_button, {
                onClick: ($event: any) => (_ctx.onClickUpdate($event, item.raw.id)),
                testId: 
            _ctx.genTestId(
              _ctx.ELEMENT_TYPES.BUTTON,
              `organization-${item.raw.id}-action`
            )
          
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.raw.action), 1)
                ]),
                _: 2
              }, 1032, ["onClick", "testId"])
            ]),
            _: 1
          }, 8, ["headers", "items", "search"])
        ]),
        _: 1
      }))
}