import { RouteRecordRaw } from "vue-router";
import sabreSegment from "@/modules/SabreSegment/views/sabreSegment.vue";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "sabreSegment",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.SABRE_SEGMENT.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.SABRE_SEGMENT.PERMISSIONS,
    },
    component: sabreSegment,
  },
];

export default routes;
