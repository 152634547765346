<template>
  <ListViewForSalesUser
    v-if="isSuperUser() || isSalesUser() || isSuperAdmin()"
  />
  <ListViewForAgentsUser v-if="isAgentUser()" />
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import ListViewForSalesUser from "@/modules/CreditLimitManagement/components/ListViewForSalesUser.vue";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import ListViewForAgentsUser from "@/modules/CreditLimitManagement/components/ListViewForAgentsUser.vue";

export default defineComponent({
  name: "CreditLimitList",
  components: { ListViewForAgentsUser, ListViewForSalesUser },
  computed: {
    USER_ROLES() {
      return USER_ROLES;
    },
    userRole() {
      let user = this.user() as IUserV2 | null;
      return user?.role_unique_id;
    },
  },
  methods: {
    isSuperAdmin() {
      return this.userRole === USER_ROLES.SUPER_ADMIN;
    },
    isSuperUser() {
      return this.userRole === USER_ROLES.AG_SUPER_USER;
    },
    isSalesUser() {
      return this.userRole === USER_ROLES.SALES;
    },
    isAgentUser() {
      return this.userRole === USER_ROLES.AGENT;
    },
  },
  setup() {
    let user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2 | null;
    return {
      user,
    };
  },
});
</script>
