<template>
  <ag-loader v-if="isLoaderVisible" />
  <template v-else>
    <ag-card>
      <ag-heading variant="h3" title="Account Details"></ag-heading>
      <hr />
      <ag-heading variant="p" title="" class="margin_top_0 margin_bottom_10">
        <strong>Name:</strong> {{ agent?.full_name || "-" }}
      </ag-heading>
      <ag-heading variant="p" title="" class="">
        <strong>Email:</strong> {{ agent?.email || "-" }}
      </ag-heading>
    </ag-card>
    <ag-row>
      <ag-column md="6">
        <br />
      </ag-column>
    </ag-row>

    <ag-card>
      <ag-heading variant="h3" title="Permissions"></ag-heading>
      <ag-heading variant="p" title="Update your Permissions here"></ag-heading>
      <hr />
      <ag-accordion :panel-value="[0]">
        <ag-accordion-panel
          :testId="
            genTestId(ELEMENT_TYPES.ACCORDION, `permissions-${permissionType}`)
          "
          v-for="(permissionType, index) in permissionsTypes"
          :title="permissionType"
          :key="index"
        >
          <template #tabSection>
            <ag-row>
              <ag-column
                cols="12"
                md="4"
                v-for="(permission, index) in systemPermissions[permissionType]"
                :key="index"
              >
                <permission-item
                  :test-id="
                    genTestId(
                      ELEMENT_TYPES.CHECK_BOX,
                      `permission-${permission.permission_code}`
                    )
                  "
                  :value="permission.permission_code"
                  v-model="selectedPermissions"
                  :label="permission.permission_name"
                />
              </ag-column>
            </ag-row>
          </template>
        </ag-accordion-panel>
      </ag-accordion>
      <hr />

      <ag-column class="bottom_inline_btn">
        <AGButton
          variant="link"
          :testId="genTestId(ELEMENT_TYPES.BUTTON, 'permissions-update-cancel')"
          :disabled="$store.getters.updatingAgentPermissions"
          @click="onCancelPermissions"
          >Cancel
        </AGButton>
        <AGButton
          variant="primary"
          :testId="genTestId(ELEMENT_TYPES.BUTTON, 'permissions-update-submit')"
          type="button"
          :disabled="$store.getters.updatingAgentPermissions"
          @click="updatePermissions"
          :is-loading="$store.getters.updatingAgentPermissions"
          >Update
        </AGButton>
      </ag-column>
    </ag-card>
    <o-t-p-dialog
      :show-dialog="otpDialog"
      v-if="otpDialog"
      :submitButtonLoading="this.isLoadingOTPVerificationSubmit"
      @onSubmitVerificationForm="onPermissionsUpdateOTPVerification"
    />
  </template>
</template>
<script>
import PermissionItem from "@/modules/Agent/components/PermissionItem.vue";
import { PERMISSIONS_TYPE } from "@/modules/Permissions/utils/enums/PERMISSIONS_TYPE";
import { systemPermissionsByPermissionTypes } from "@/modules/Permissions/utils/helpers";
import OTPDialog from "@/ag-portal-common/components/OTPDialog.vue";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { inject } from "vue";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { PATH } from "@/ag-portal-common/constants/path";

export default {
  name: "AgentView",
  components: { OTPDialog, PermissionItem },
  async beforeMount() {
    let organizationPreference = this.organizationPreference();
    await this.$store.dispatch("fetchAgentById", {
      agentId: this.$route.params.id,
      organizationId: organizationPreference.organization_id,
    });
    this.setAgent();
    await this.$store.dispatch("fetchPermissions", this.agent.role_sub_type);
  },
  methods: {
    genTestId,
    setAgent() {
      const agent = this.$store.getters.userAgent;
      if (agent) {
        this.agent = agent.agent.user;
        this.selectedPermissions = agent.permissions;
      }
    },
    onCancelPermissions() {
      this.selectedPermissions = this.$store.getters.userAgent.permissions;
      this.$router.go(-1);
    },
    onSuccessUpdatePermissions(refrenceId) {
      this.referenceId = refrenceId;
      this.otpDialog = true;
    },
    updatePermissions() {
      const userAgent = this.$store.getters.userAgent;
      const selectedPermissions = this.selectedPermissions;
      const selected = {},
        unSelected = {};
      userAgent?.permissions.forEach((permission) => {
        if (!selectedPermissions.includes(permission)) {
          unSelected[permission] = false;
        }
      });
      selectedPermissions.forEach((permission) => {
        if (!userAgent?.permissions.includes(permission)) {
          selected[permission] = true;
        }
      });

      //   Check if 'can_view_all_payments' is selected
      const isCanViewAllPaymentsSelected = selectedPermissions.includes(
        "can_view_all_payments"
      );

      if (isCanViewAllPaymentsSelected) {
        // If 'can_view_all_payments' is selected, auto-select 'can_view_self_made_payment'
        if (!selectedPermissions.includes("can_view_self_made_payments")) {
          selected["can_view_self_made_payments"] = true;
          selectedPermissions.push("can_view_self_made_payments");
        }
      }
      //   Check if 'can_view_all_flight_bookings' is selected
      const isCanViewAllBookingsSelected = selectedPermissions.includes(
        "can_view_all_flight_bookings"
      );

      if (isCanViewAllBookingsSelected) {
        // If '' is selected, auto-select 'can_view_self_made_payment'
        if (
          !selectedPermissions.includes("can_view_self_made_flight_bookings")
        ) {
          selected["can_view_self_made_flight_bookings"] = true;
          selectedPermissions.push("can_view_self_made_flight_bookings");
        }
      }

      const isCanCreatePaymentSelected =
        selectedPermissions.includes("can_create_payment");
      if (isCanCreatePaymentSelected) {
        // If '' is selected, auto-select 'can_view_self_made_payment'
        if (!selectedPermissions.includes("can_view_self_made_payments")) {
          selected["can_view_self_made_payments"] = true;
          selectedPermissions.push("can_view_self_made_payments");
        }
      }

      const isCanviewHotelsSelected = selectedPermissions.includes(
        "can_view_all_bookings_of_hotel"
      );
      if (isCanviewHotelsSelected) {
        // If '' is selected, auto-select 'can_view_self_made_payment'
        if (
          !selectedPermissions.includes(
            "can_view_only_self_made_bookings_of_hotel"
          )
        ) {
          selected["can_view_only_self_made_bookings_of_hotel"] = true;
          selectedPermissions.push("can_view_only_self_made_bookings_of_hotel");
        }
      }
      const finalSelectedPermissions = { ...selected, ...unSelected };
      if (Object.keys(finalSelectedPermissions).length === 0) {
        return;
      }
      this.$store.dispatch("updateAgentPermissions", {
        agentId: userAgent.agent.agent_id,
        permissions: finalSelectedPermissions,
        callback: this.onSuccessUpdatePermissions,
      });
    },
    disableOTPDialog() {
      this.otpDialog = false;
    },
    onPermissionsUpdateOTPVerification(otp) {
      const body = {
        reference: this.referenceId,
        otp,
      };
      this.isLoadingOTPVerificationSubmit = true;
      this.$store.dispatch("verificationOnPermissionsChange", {
        body,
        callback: () => (this.isLoadingOTPVerificationSubmit = false),
        onSuccessCallback: this.disableOTPDialog,
      });
    },
  },
  computed: {
    systemPermissions() {
      return systemPermissionsByPermissionTypes(
        this.$store.getters.systemPermissions
      );
    },
    isLoaderVisible() {
      return (
        this.$store.getters.isFetchingPermissions ||
        this.$store.getters.isFetchingAgent
      );
    },
  },
  data() {
    return {
      ELEMENT_TYPES,
      agent: null,
      otpDialog: false,
      otp: "",
      referenceId: "",
      permissionsTypes: [
        PERMISSIONS_TYPE.FLIGHTS,
        PERMISSIONS_TYPE.HOTELS,
        PERMISSIONS_TYPE.PAYMENTS,
        PERMISSIONS_TYPE.SOA,
        PERMISSIONS_TYPE.OTHERS,
      ],
      isLoadingOTPVerificationSubmit: false,
      selectedPermissions: [],
    };
  },
  setup() {
    const organizationPreference = inject(
      AUTH_CONTEXT_KEYS.organizationPreference
    );

    return {
      organizationPreference,
    };
  },
};
</script>
