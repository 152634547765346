import { ActionContext, ActionTree } from "vuex";
import { IMyBookingsState } from "@/interfaces/myBookingsState.interface";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { IMyBookingsFlight } from "@/interfaces/flightBookings.interface";
import MyBookingsService from "@/modules/MyBookings/services/myBookings.service";
import { FetchBookingsParamsRequestModel } from "@/modules/MyBookings/models/fetchBookingsParams.request";
import { SendAirlineTicketBodyRequest } from "@/modules/MyBookings/models/sendAirlineTicketBody.request";
import { IMyBookingsHotel } from "@/interfaces/hotelBookings.interface";
import { IMyBookingsTour } from "@/interfaces/tourBookings.interface";

const actions: ActionTree<IMyBookingsState, IMyBookingsState> = {
  async fetchFlightBookings(
    context: ActionContext<IMyBookingsState, IMyBookingsState>,
    {
      payload,
      isFetchNewData,
      callBack,
    }: {
      payload: FetchBookingsParamsRequestModel;
      isFetchNewData: boolean;
      callBack?: () => void;
    }
  ) {
    const methodName = "actions.fetchFlightBookings";
    context.commit("enableIsFetchFlightBookings");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const myBookingsService = new MyBookingsService();

    const response: IMyBookingsFlight =
      await myBookingsService.fetchFlightBookings(payload, isFetchNewData);
    context.commit("saveFlightBookings", response);
    if (callBack) {
      callBack();
    }
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
  async downloadBrandTicket(
    context: ActionContext<IMyBookingsState, IMyBookingsState>,
    bookingId: string
  ) {
    const methodName = "actions.downloadBrandTicket";
    context.commit("enableIsDownloadingBrandTicket");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const myBookingsService = new MyBookingsService();

    await myBookingsService.dowloadBrandTicket(bookingId);
    context.commit("disableIsDownloadingBrandTicket");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },

  async sendAirlineTicket(
    context: ActionContext<IMyBookingsState, IMyBookingsState>,
    {
      payload,
      callback,
    }: { payload: SendAirlineTicketBodyRequest; callback: () => void }
  ) {
    const methodName = "actions.downloadAirlineTicket";
    context.commit("enableIsSendingAirlineTicket");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const myBookingsService = new MyBookingsService();
    await myBookingsService.sendAirlineTicket(payload);
    context.commit("disableIsSendingAirlineTicket");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
    callback();
  },

  async fetchHotelBookings(
    context: ActionContext<IMyBookingsState, IMyBookingsState>,
    {
      payload,
      callback,
    }: { payload: FetchBookingsParamsRequestModel; callback: () => void }
  ) {
    const methodName = "actions.fetchHotelBookings";
    context.commit("enableIsFetchHotelBookings");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const myBookingsService = new MyBookingsService();

    const response: IMyBookingsHotel =
      await myBookingsService.fetchHotelBookings(payload);
    context.commit("saveHotelBookings", response);
    if (callback) {
      callback();
    }
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
  async fetchTourBookings(
    context: ActionContext<IMyBookingsState, IMyBookingsState>,
    {
      payload,
      callback,
    }: { payload: FetchBookingsParamsRequestModel; callback: () => void }
  ) {
    const methodName = "actions.fetchTourBookings";
    context.commit("enableIsFetchTourBookings");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const myBookingsService = new MyBookingsService();

    const response: IMyBookingsTour = await myBookingsService.fetchTourBookings(
      payload
    );
    context.commit("saveTourBookings", response);
    if (callback) {
      callback();
    }
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
};

export default actions;
