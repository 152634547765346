import { GetterTree } from "vuex";
import { IAgentState } from "@/interfaces/agentState.interface";
import {
  IAgent,
  IAgentDetail,
} from "@/ag-portal-common/interfaces/agent.interface";
import { IAgentPricing } from "@/interfaces/agentPricing.interface";

const getters: GetterTree<IAgentState, IAgentState> = {
  agents: (state): Array<IAgent> => state.agents,
  agentPricing: (state): Array<IAgentPricing> => state.agentPricing,
  isFetchAgentPricing: (state): boolean => state.isFetchAgentPricing,
  isFetchingAgents: (state): boolean => state.isFetchingAgents,
  organizationAgents: (state): Array<IAgentDetail> => state.organization_agents,
  isFetchingOrganizationAgents: (state): boolean =>
    state.isFetchingOrganizationAgents,
  isFetchingAgent: (state): boolean => state.isFetchingAgent,
  userAgent: (state): IAgentDetail | null => state.agent,
  updatingAgentPermissions: (state): boolean =>
    state.isUpdatingAgentPermissions,
};

export default getters;
