<template>
  <ag-card>
    <ag-row class="d-flex align-items-center">
      <template v-if="isAllowedUser()">
        <ag-column xs="12" lg="3" cols="12">
          <AgAutoComplete
            :testId="
              genTestId(
                ELEMENT_TYPES.TEXT_FIELD as any,
                'organization-autocomplete-search'
              )
            "
            v-model="organizationId"
            label="Organization"
            icon="calendar"
            :items="filterProfiles"
            :clearable="true"
            :isLoading="isFetchingOrganizations"
            class="user-input"
            :isdisabled="isFetchingOrganizations"
            @keyup="onChangeOrgInputHandler"
          >
            <template #dropdownItem="{ itemProps, itemData }">
              <div class="item" v-bind="itemProps">{{ itemData.label }}</div>
            </template>
          </AgAutoComplete>
        </ag-column>
      </template>
      <ag-column
        xs="12"
        sm="6"
        md="5"
        :lg="isAllowedUser() ? '3' : '5'"
        cols="12"
      >
        <ag-calendar
          label="Start Date"
          label-variant="float"
          v-model="startDate"
          :testId="genTestId(ELEMENT_TYPES.CALENDAR_FIELD as any, 'segment-startDate')"
          :date="startDate"
          @update:startDate="changeStartDate"
          calendar-name="startDate"
          :max-date="maxDate"
          :min-date="minStartDate"
          class="user-input"
          :isdisabled="isFetchingOrganizations"
        />
      </ag-column>
      <ag-column
        xs="12"
        sm="6"
        md="5"
        :lg="isAllowedUser() ? '3' : '5'"
        cols="12"
      >
        <ag-calendar
          label="End Date"
          label-variant="float"
          :testId="genTestId(ELEMENT_TYPES.CALENDAR_FIELD as any, 'segment-endDate')"
          v-model="endDate"
          :date="endDate"
          :min-date="startDate"
          @update:endDate="changeEndDate"
          calendar-name="endDate"
          :max-date="maxDate"
          class="user-input"
          :isdisabled="isFetchingOrganizations"
        />
      </ag-column>
      <ag-column
        xs="12"
        sm="12"
        md="2"
        :lg="isAllowedUser() ? '3' : '2'"
        cols="12"
        class="d-block"
      >
        <AGButton
          :testId="genTestId(ELEMENT_TYPES.BUTTON as any, 'sabreSegment-search')"
          :isLoading="isLoading"
          @click="fetchBookings"
          class="sb-btn user-input"
          :isdisabled="isFetchingOrganizations || !canFetch()"
        >
          {{
            isFetchingOrganizations && isAllowedUser() ? "Loading" : "Search"
          }}
        </AGButton>
      </ag-column>
    </ag-row>
  </ag-card>

  <ag-card v-if="statsDetails">
    <ag-heading variant="h2" title="Sabre Segment Stats"></ag-heading>
    <AgTable
      :headers="statsTable"
      :items="[statsDetails]"
      :has-pagination="false"
      :items-per-page="1"
      :has-search="false"
    >
      <template #col-confirmed_flight_segment_count="{ item }">
        <ag-div>{{ item.raw.confirmed_flight_segment_count }}</ag-div>
      </template>
      <template #col-flown_segment_count="{ item }">
        <ag-div>{{ item.raw.flown_segment_count }}</ag-div>
      </template>
      <template #col-issued_flight_segment_count="{ item }">
        <ag-div>{{ item.raw.issued_flight_segment_count }}</ag-div>
      </template>
      <template #col-total_segment_bonus_amount="{ item }">
        <ag-div>{{ item.raw.total_segment_bonus_amount }}</ag-div>
      </template>
      <template #col-start_date="{ item }">
        <ag-div>{{ formattedDate(item.raw.start_date) }}</ag-div>
      </template>
      <template #col-end_date="{ item }">
        <ag-div>{{ formattedDate(item.raw.end_date) }}</ag-div>
      </template>
    </AgTable>
  </ag-card>
  <ag-card v-else>
    <AgNotFound
      test-id=""
      heading="No Sabre Segment Stats found in the following date range"
      description=""
    />
  </ag-card>
  <ag-card v-if="listDetails && listDetails.length">
    <ag-heading variant="h2" title="Sabre Segment List"></ag-heading>
    <AgTable
      :headers="listTable"
      :items="listDetails"
      :has-pagination="false"
      :items-per-page="20"
      :has-search="false"
    >
      <template #col-created_date="{ item }">
        <ag-div>{{ formattedDate(item.raw.created_date) }}</ag-div>
      </template>
      <template #col-origin="{ item }">
        <ag-div>{{ item.raw.origin }}</ag-div>
      </template>
      <template #col-departure_datetime="{ item }">
        <ag-div>{{ formattedDate(item.raw.departure_datetime) }}</ag-div>
      </template>
      <template #col-destination="{ item }">
        <ag-div>{{ item.raw.destination }}</ag-div>
      </template>
      <template #col-operating_airline="{ item }">
        <ag-div>{{ item.raw.operating_airline }}</ag-div>
      </template>
      <template #col-total_amount="{ item }">
        <ag-div>{{ item.raw.total_amount }}</ag-div>
      </template>
      <template #col-status="{ item }">
        <ag-div class="flight_table_status color_chip green"
          >{{ item.raw.status }}
        </ag-div>
      </template>
      <template #col-passenger_name="{ item }">
        <ag-div>{{ item.raw.passenger_name }}</ag-div>
      </template>
    </AgTable>
  </ag-card>
  <ag-card v-else>
    <AgNotFound
      test-id=""
      heading="No Sabre Segment List found in the following date range"
      description=""
    />
  </ag-card>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { mapActions } from "vuex";
import { subDays } from "date-fns";
import {
  genTestId,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";

import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";

import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";

import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import { FORMAT_DD_MMM_YYYY } from "@/ag-portal-common/constants/dateTimeFormats";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { SABRE_SEGMENT_ANALYTICS_EVENTS } from "@/modules/SabreSegment/constants/anaylticsEvents";

import analyticsService from "@/analytic.service";
import SABREService from "@/modules/SabreSegment/services/sabreSegment.service";
import notificationService from "@/ag-portal-common/services/notification.service";
import { IOrganization } from "@/ag-portal-common/interfaces/organization.interface";

export default defineComponent({
  name: "SabreSegment",
  data(): {
    ELEMENT_TYPES: IObject;
    startDate: Date;
    endDate: Date;
    maxDate: Date;
    minStartDate: Date;
    statsDetails: null;
    listDetails: any[];
    isLoading: boolean;
    statsTable: {
      title: string;
      value: string;
      key: string;
      sortable: boolean;
    }[];
    listTable: {
      title: string;
      value: string;
      key: string;
      sortable: boolean;
    }[];
    organizationId: string;
  } {
    return {
      ELEMENT_TYPES,
      statsDetails: null,
      listDetails: [] as any[],
      isLoading: false,
      startDate: new Date(),
      endDate: new Date(),
      maxDate: new Date(),
      minStartDate: subDays(new Date(), 90),
      statsTable: [
        {
          title: "Start Date",
          value: "statsDetails.start_date",
          key: "start_date",
          sortable: false,
        },
        {
          title: "End Date",
          value: "statsDetails.end_date",
          key: "end_date",
          sortable: false,
        },
        {
          title: "Confirmed Segments",
          value: "statsDetails.confirmed_flight_segment_count",
          key: "confirmed_flight_segment_count",
          sortable: false,
        },
        {
          title: "Flown Segments",
          value: "statsDetails.flown_segment_count",
          key: "flown_segment_count",
          sortable: false,
        },
        {
          title: "Issued Segments",
          value: "statsDetails.issued_flight_segment_count",
          key: "issued_flight_segment_count",
          sortable: false,
        },
        {
          title: "Bonus Amount",
          value: "statsDetails.total_segment_bonus_amount",
          key: "total_segment_bonus_amount",
          sortable: false,
        },
      ],
      listTable: [
        {
          title: "Created Date",
          value: "listDetails.segment_list.created_date",
          key: "created_date",
          sortable: false,
        },
        {
          title: "Departure Date",
          value: "listDetails.segment_list.departure_datetime",
          key: "departure_datetime",
          sortable: false,
        },
        {
          title: "Origin",
          value: "listDetails.segment_list.origin",
          key: "origin",
          sortable: false,
        },
        {
          title: "Destination",
          value: "listDetails.segment_list.destination",
          key: "destination",
          sortable: false,
        },
        {
          title: "Airline",
          value: "listDetails.segment_list.operating_airline",
          key: "operating_airline",
          sortable: false,
        },
        {
          title: "Amount",
          value: "listDetails.segment_list.total_amount",
          key: "total_amount",
          sortable: false,
        },
        {
          title: "Status",
          value: "listDetails.segment_list.status",
          key: "status",
          sortable: false,
        },
        {
          title: "Passenger",
          value: "listDetails.segment_list.passenger_name",
          key: "passenger_name",
          sortable: false,
        },
      ],
      organizationId: "",
    };
  },
  async created() {
    const sabreService = new SABREService();

    if (this.isAllowedUser()) {
      await this.$store.dispatch("fetchOrganizations");
      return;
    }

    const segmentStatsResponse = await sabreService.getByDefaultSegmentStats();
    const segmentListResponse = await sabreService.getByDefaultSegmentList();

    this.startDate = new Date();
    this.endDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 44);

    this.statsDetails = segmentStatsResponse?.data?.data;
    this.listDetails = segmentListResponse?.data?.data.segment_list;

    this.trackAnalytics();
  },
  computed: {
    filterProfiles() {
      let filteredOrganizations = this.$store.getters.organizations;

      if (this.organizationId) {
        filteredOrganizations = this.$store.getters.organizations.filter(
          (item: IOrganization) =>
            this.isIncluded(item.name, this.organizationId) ||
            this.isIncluded(item.sector, this.organizationId) ||
            this.isIncluded(item.public_id, this.organizationId)
        );
      }

      filteredOrganizations = filteredOrganizations
        .map((item: IOrganization) => ({
          label: `${item.name} - ${item.sector}`,
          value: item.public_id,
        }))
        .slice(0, 10);

      return filteredOrganizations;
    },
    isFetchingOrganizations() {
      return this.$store.getters.isFetchingOrganizations;
    },
  },
  setup() {
    const user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2;
    return { user };
  },
  methods: {
    genTestId,
    ...mapActions(["defaultSabreSegmentList", "defaultSabreSegmentStats"]),
    changeStartDate(newDate: Date) {
      this.startDate = newDate;
    },

    changeEndDate(newDate: Date) {
      this.endDate = newDate;
    },
    formattedDate(date: Date) {
      return getFormattedDateTime(date, FORMAT_DD_MMM_YYYY);
    },

    async fetchBookings() {
      if (this.isFetchingOrganizations || !this.canFetch()) {
        return;
      }

      this.trackAnalytics();

      if (this.endDate && this.startDate) {
        const dateDiffInMillis =
          (this.endDate as any) - (this.startDate as any);

        const daysDiff = Math.floor(dateDiffInMillis / (1000 * 60 * 60 * 24)); // differnce nikaalra k 30 days sa ziada to ni ha
        if (daysDiff <= 45) {
          this.statsDetails = null;
          this.listDetails = [];
          this.isLoading = true;
          const isAllowedUser = this.isAllowedUser();

          const sabreService = new SABREService();

          const response = await sabreService.getCustomSegmenStats(
            getFormattedDateTime(this.startDate, FORMAT_YYY_MM_DD),
            getFormattedDateTime(this.endDate, FORMAT_YYY_MM_DD),
            isAllowedUser ? this.organizationId : ""
          );
          this.statsDetails = response?.data?.data;

          const response_2 = await sabreService.getCustomSegmenLists(
            getFormattedDateTime(this.startDate, FORMAT_YYY_MM_DD),
            getFormattedDateTime(this.endDate, FORMAT_YYY_MM_DD),
            isAllowedUser ? this.organizationId : ""
          );
          this.listDetails = response_2?.data?.data.segment_list;
          this.isLoading = false;
        } else {
          notificationService.type = NOTIFICATION_TYPES.ERROR;
          notificationService.description = "days cant be more than 45";
          notificationService.triggerNotification();
        }
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "please change date range";
        notificationService.triggerNotification();
      }
    },

    isIncluded(source: string, target: string): boolean {
      return source.toLowerCase().includes(target.toLowerCase());
    },
    isAllowedUser() {
      const user = this.user();
      const allowedUserTypes = [
        USER_ROLES.OPERATIONS,
        USER_ROLES.SALES,
        USER_ROLES.AG_SUPER_USER,
      ];
      return allowedUserTypes.includes(user?.role_unique_id);
    },
    canFetch() {
      const isOrganizationSelected =
        this.organizationId === "" || this.organizationId === null;
      return this.isAllowedUser() ? !isOrganizationSelected : true;
    },
    trackAnalytics() {
      const payload = {
        "user-role": this.user().role_unique_id,
        "organization-id": this.organizationId,
        "start-date": this.startDate,
        "end-date": this.endDate,
      };
      analyticsService.logActionEvent(
        SABRE_SEGMENT_ANALYTICS_EVENTS.FETCH_SEGMENTS,
        payload
      );
    },
    onChangeOrgInputHandler(event: InputEvent) {
      this.organizationId = (event.target as HTMLInputElement).value;
    },
  },
});
</script>

<style lang="scss">
.sb-btn {
  width: 100%;
  height: 56px !important;
}

.user-input {
  &,
  .v-input,
  .vue-date-picker {
    margin: 0 !important;
  }

  &[isdisabled="true"] {
    opacity: 0.5 !important;
    user-select: none;
    pointer-events: none;
    cursor: not-allowed !important;
  }
}

.v-overlay {
  box-shadow: none;
  .v-overlay__content {
    box-shadow: none !important;
    .v-list {
      margin-top: 10px;
      box-shadow: none !important;
      padding: 0;
      border-radius: 4px;
      border: 1px solid #ddd;
      min-height: 55px;
      max-height: 300px;
      position: relative;
      width: 100%;
      z-index: 10;

      .item {
        border-bottom: 1px solid #ddd;
        height: 55px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: #f8f7f7;
        }
      }
    }
  }
}
</style>
