import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ip-wrapper" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.state)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "image-previewer",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onModalCloseHandler && _ctx.onModalCloseHandler(...args)))
      }, [
        _createVNode(_Transition, { name: "bounce" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", {
                class: "ip-close-icon",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onModalCloseHandler && _ctx.onModalCloseHandler(...args)))
              }, "✕"),
              _createElementVNode("img", {
                class: "ip-image",
                src: _ctx.imageSrc,
                alt: "image preview",
                loading: "lazy"
              }, null, 8, _hoisted_2)
            ], 512), [
              [_vShow, _ctx.animationState]
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}