import mutations from "./mutations";
import actions from "./actions";
import state from "@/modules/Agent/vuex/state";
import getters from "@/modules/Agent/vuex/getters";

export default {
  state,
  mutations,
  actions,
  getters,
};
