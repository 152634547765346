<template>
  <AgCard class="organization-snapshot-header">
    <AgCard>
      <p class="heading">Statistics {{ renderOrgName() }}</p>
      <ag-row class="mt-10">
        <ag-column xs="12" sm="12" md="12" lg="4">
          <ag-calendar
            label="Start Date"
            @update:startDate="(value:any) => startDate = value"
            calendar-name="startDate"
            :max-date="new Date()"
            :date="startDate ? startDate : new Date()"
          />
        </ag-column>
        <ag-column xs="12" sm="12" md="12" lg="4">
          <ag-calendar
            label="End Date"
            @update:endDate="(value:any) => endDate = value"
            calendar-name="endDate"
            :max-date="new Date()"
            :min-date="startDate"
            :date="endDate ? endDate : new Date()"
          />
        </ag-column>
        <ag-column
          xs="12"
          sm="12"
          md="12"
          lg="4"
          class="d-flex align-items-center"
        >
          <AGButton
            @click="handleFetchData"
            :disabled="isLoading"
            :is-loading="isLoading"
            test-id=""
            variant="primary"
            class="fire_btn_wrap"
            >Fetch Statistics</AGButton
          >
        </ag-column>
      </ag-row>
    </AgCard>

    <ag-loader v-if="isOrganizationProductivityLoading" />
    <template v-else>
      <template v-if="showDetails">
        <AgCard class="card-wrapper">
          <p class="heading">Overall Counts</p>
          <hr />
          <ag-row>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <ag-agent-info-box
                title="Total Flight Bookings"
                icon="notInvoice"
                :value="
                  organizationProductivity?.hotel_and_flight_details?.aggregates
                    ?.total_flight_bookings
                "
              />
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <ag-agent-info-box
                title="Total Issued Tickets"
                icon="notInvoice"
                :value="
                  organizationProductivity?.total_tickets_issued
                    ?.total_issued_tickets
                "
              />
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <ag-agent-info-box
                title="Total Hotel Bookings"
                icon="notInvoice"
                :value="
                  organizationProductivity?.hotel_and_flight_details?.aggregates
                    ?.total_hotel_bookings
                "
              />
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <ag-agent-info-box
                title="Total Issued Hotels"
                icon="notInvoice"
                :value="
                  organizationProductivity?.hotel_and_flight_details?.aggregates
                    ?.total_hotel_issued
                "
              />
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <ag-agent-info-box
                title="Total Payment Created"
                icon="currentBalance"
                :value="
                  organizationProductivity?.payment_details?.aggregates
                    .total_payments_created
                "
              />
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <ag-agent-info-box
                title="Total Credit Limit Requests"
                icon="notInvoice"
                :value="organizationProductivity?.total_credit_limit_requests"
              />
            </ag-column>
            <ag-column xs="12" sm="12" md="6" lg="3">
              <ag-agent-info-box
                title="Total Sabre Segments"
                icon="notInvoice"
                :value="organizationProductivity?.total_sabre_segments"
              />
            </ag-column>
          </ag-row>
        </AgCard>

        <!-- Booking Card -->
        <AgCard class="card-wrapper">
          <p class="heading">Booked</p>
          <hr />
          <ag-row>
            <ag-column
              v-for="(item, index) in organizationProductivity
                ?.hotel_and_flight_details?.flight_bookings"
              :key="index"
              xs="12"
              sm="12"
              md="6"
              lg="2"
            >
              <ag-agent-info-box
                :title="item.provider_name"
                icon="notInvoice"
                :value="item.count"
              />
            </ag-column>
          </ag-row>
          <p class="empty-items-message" v-show="showBookedEmptyMsg">
            Nothing Booked Yet
          </p>
        </AgCard>

        <!-- Issued Card -->
        <AgCard class="card-wrapper">
          <p class="heading">Issued</p>
          <hr />
          <ag-row>
            <ag-column
              v-for="(item, index) in organizationProductivity
                ?.total_tickets_issued?.issued_tickes"
              :key="index"
              xs="12"
              sm="12"
              md="6"
              lg="2"
            >
              <ag-agent-info-box
                :title="item.provider_name"
                icon="notInvoice"
                :value="item.count"
              />
            </ag-column>
          </ag-row>
          <p class="empty-items-message" v-show="showIssuedEmptyMsg">
            Nothing Issued Yet
          </p>
        </AgCard>

        <!-- Payment Card -->
        <AgCard class="card-wrapper">
          <p class="heading">Payments</p>
          <hr />
          <ag-row>
            <ag-column
              v-for="(item, index) in organizationProductivity?.payment_details
                ?.payments"
              :key="index"
              xs="12"
              sm="12"
              md="6"
              lg="4"
            >
              <ag-agent-info-box
                :title="item.payment_method"
                icon="currentBalance"
                :value="item.count"
              />
            </ag-column>
          </ag-row>
          <p class="empty-items-message" v-show="showPaymentsEmptyMsg">
            No Payment Found
          </p>
        </AgCard>
      </template>
      <AgNotFound v-else test-id="" heading="Productivity Not Found" />
    </template>
  </AgCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { format } from "date-fns";
import _ from "lodash";

import { IOrganizationProductivity } from "@/ag-portal-common/interfaces/organizationProductivity.interface";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import { IOrganization } from "@/ag-portal-common/interfaces/organization.interface";
import { IOrganizationStats } from "@/ag-portal-common/interfaces/organizationStatistics.interface";

export default defineComponent({
  name: "OrganizationSnapshotProductionTab",
  data(): {
    startDate: Date;
    endDate: Date;
    selectedOrg: string;
  } {
    return {
      startDate: new Date(),
      endDate: new Date(),
      selectedOrg: "",
    };
  },
  methods: {
    handleFetchData() {
      const { org_id } = this.$route.query;
      this.$store.dispatch("getOrganizationProductivity", {
        organizationId: org_id,
        payload: {
          start_date: format(this.startDate, FORMAT_YYY_MM_DD),
          end_date: format(this.endDate, FORMAT_YYY_MM_DD),
        },
      });
    },
    renderOrgName() {
      const org_name = this.organizationStats?.organization?.org_name;
      return org_name ? `- (${org_name})` : "";
    },
  },
  computed: {
    organizationProductivity(): IOrganizationProductivity {
      return this.$store.getters.organizationProductivity;
    },
    isLoading(): boolean {
      return this.$store.getters.isFetchingOrganizations;
    },
    isOrganizationProductivityLoading(): boolean {
      return this.$store.getters.isOrganizationProductivityLoading;
    },
    showDetails(): boolean {
      const isNotEmpty = !_.isEmpty(this.organizationProductivity);
      return isNotEmpty;
    },
    showIssuedEmptyMsg(): boolean {
      return (
        !this.isOrganizationProductivityLoading &&
        this.organizationProductivity?.total_tickets_issued?.issued_tickes
          .length < 1
      );
    },
    showBookedEmptyMsg(): boolean {
      return (
        !this.isOrganizationProductivityLoading &&
        this.organizationProductivity?.hotel_and_flight_details?.flight_bookings
          .length < 1
      );
    },
    showPaymentsEmptyMsg(): boolean {
      return (
        !this.isOrganizationProductivityLoading &&
        this.organizationProductivity?.payment_details?.payments.length < 1
      );
    },
    organizationStats(): IOrganizationStats {
      return this.$store.getters.organizationStats;
    },
  },
});
</script>

<style lang="scss" scoped>
.organization-snapshot-header {
  .name-container {
    display: flex;
    align-items: center;
    img {
      width: 70px;
    }
    .agency-name-container {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .agency-name {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
        color: #000;
      }
      p {
        margin-bottom: 0;
        color: #6b7280;
      }
    }
  }
}

.card-wrapper {
  margin-block: 30px;
  border: solid 1px var(--green-color);
  border-left: solid 8px var(--green-color);
}

.heading {
  font-size: 30px;
  font-weight: bold;
}

.color-chip {
  width: 120px;
}

.website-slug {
  font-weight: bold;
  color: #6b7280;
  &:hover {
    color: #0c47bc;
  }
}
.empty-items-message {
  text-align: center;
  margin-block: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #828282;
}
</style>
