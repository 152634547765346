import { ISectorState } from "@/ag-portal-common/interfaces/sectorState.interface";

const sectorState: ISectorState = {
  sectors: [],
  isFetchingSectors: false,
  sectorProductivity: null,
  sectorStats: null,
  isSectorProductivityLoading: false,
  isSectorStatsLoading: false,
};

export default sectorState;
