<template>
  <ag-modal :is-open="isOpen" @close="isOpen = false" modal-width="40%">
    <template #header>
      <v-card-title>OTP Verification</v-card-title>
      <a-g-button
        :testId="genTestId(ELEMENT_TYPES.BUTTON, 'otp-modal-close')"
        type="button"
        class="modal_close_icon"
        variant="link"
        @click="toggleModal"
        >Close
      </a-g-button>
    </template>
    <template #body>
      <p>
        An OTP code has been sent to your registered email address. Please enter
        it below for verification
      </p>

      <form @submit="onSubmit">
        <a-g-text-field
          label="OTP"
          v-model="otp"
          :testId="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'otp-modal')"
        />
        <a-g-button
          type="submit"
          :testId="genTestId(ELEMENT_TYPES.BUTTON, 'otp-modal-submit')"
          :disabled="!otp || submitButtonLoading"
          :isLoading="submitButtonLoading"
          >Submit
        </a-g-button>
      </form>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";

export default defineComponent({
  name: "OTPDialog",
  events: ["onSubmitVerificationForm"],
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    submitButtonLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isOpen: this.showDialog,
      otp: "",
      ELEMENT_TYPES,
    };
  },
  methods: {
    toggleModal() {
      this.isOpen = !this.isOpen;
    },
    genTestId,
    onSubmit(e: any) {
      e.preventDefault();
      this.$emit("onSubmitVerificationForm", this.otp);
    },
  },
  watch: {
    showDialog(value) {
      this.isOpen = value;
    },
  },
});
</script>
