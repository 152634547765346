import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgAgentListInfoFilterForm = _resolveComponent("AgAgentListInfoFilterForm")!
  const _component_financial_profile_box = _resolveComponent("financial-profile-box")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_organization_and_agent_list = _resolveComponent("organization-and-agent-list")!
  const _component_ag_loader = _resolveComponent("ag-loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AgAgentListInfoFilterForm, { onFetchOrganization: _ctx.fetchOrganization }, null, 8, ["onFetchOrganization"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters.organization?.financial_profiles, (profile, index) => {
      return (_openBlock(), _createBlock(_component_ag_div, { key: index }, {
        default: _withCtx(() => [
          _createVNode(_component_financial_profile_box, { "financial-profile": profile }, null, 8, ["financial-profile"])
        ]),
        _: 2
      }, 1024))
    }), 128)),
    _createVNode(_component_organization_and_agent_list, {
      email: _ctx.searchedEmail,
      organization: _ctx.$store.getters.organization
    }, null, 8, ["email", "organization"]),
    (_ctx.$store.getters.isFetchingFinancialProfiles)
      ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}