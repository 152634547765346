import { PASSENGER_TYPE } from "../enums/PASSENGER_TYPES";

export const PAX_TITLES = [
  { label: "Mr", value: "MR", type: PASSENGER_TYPE.ADULT },
  { label: "Mrs", value: "MRS", type: PASSENGER_TYPE.ADULT },
  { label: "Ms", value: "MS", type: PASSENGER_TYPE.ADULT },
  { label: "Master", value: "MASTER", type: PASSENGER_TYPE.CHILD },
  { label: "Ms", value: "MS", type: PASSENGER_TYPE.CHILD },
  { label: "Master", value: "MASTER", type: PASSENGER_TYPE.INFANT },
  { label: "Ms", value: "MS", type: PASSENGER_TYPE.INFANT },
];

export const PAX_TYPES = [
  { label: "Adult", value: PASSENGER_TYPE.ADULT },
  { label: "Child", value: PASSENGER_TYPE.CHILD },
  { label: "Infant", value: PASSENGER_TYPE.INFANT },
];
