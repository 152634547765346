import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_financial_profile_dropdown = _resolveComponent("financial-profile-dropdown")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_select = _resolveComponent("ag-select")!
  const _component_ag_calendar = _resolveComponent("ag-calendar")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ag_card = _resolveComponent("ag-card")!

  return (_openBlock(), _createBlock(_component_ag_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ag_heading, {
        variant: "h2",
        title: "Select Date Range"
      }),
      _createVNode(_component_ag_row, { class: "soa_main_wrapper" }, {
        default: _withCtx(() => [
          (_ctx.isUserAgent)
            ? (_openBlock(), _createBlock(_component_ag_column, {
                key: 0,
                xs: "12",
                sm: "8",
                md: "4",
                lg: "2",
                cols: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_heading, {
                    title: "Financial Profile",
                    variant: "label"
                  }),
                  _createVNode(_component_financial_profile_dropdown, { onOnUpdateFinancialProfile: _ctx.updateFinancialProfilePublicId }, null, 8, ["onOnUpdateFinancialProfile"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ag_column, {
            xs: "12",
            sm: "4",
            md: "3",
            lg: "2",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_heading, {
                title: "Sort By",
                variant: "label"
              }),
              _createVNode(_component_ag_select, {
                items: _ctx.items,
                modelValue: _ctx.sortBy,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sortBy) = $event)),
                testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.SELECT_FIELD, 'generate-soa-sortBy'),
                value: _ctx.sortBy,
                variant: "outlined"
              }, null, 8, ["items", "modelValue", "testId", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            xs: "12",
            sm: "6",
            md: "2",
            lg: "2",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_calendar, {
                label: "Start Date",
                modelValue: _ctx.startDate,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.startDate) = $event)),
                testId: 
            _ctx.genTestId(_ctx.ELEMENT_TYPES.CALENDAR_FIELD, 'generate-soa-startDate')
          ,
                date: _ctx.startDate,
                "onUpdate:startDate": _ctx.changeStartDate,
                "calendar-name": "startDate",
                "max-date": _ctx.maxDate,
                "min-date": _ctx.minStartDate
              }, null, 8, ["modelValue", "testId", "date", "onUpdate:startDate", "max-date", "min-date"])
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            xs: "12",
            sm: "6",
            md: "2",
            lg: "2",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_calendar, {
                label: "End Date",
                testId: 
            _ctx.genTestId(_ctx.ELEMENT_TYPES.CALENDAR_FIELD, 'generate-soa-endDate')
          ,
                modelValue: _ctx.endDate,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.endDate) = $event)),
                date: _ctx.endDate,
                "min-date": _ctx.startDate,
                "onUpdate:endDate": _ctx.changeEndDate,
                "calendar-name": "endDate",
                "max-date": _ctx.maxDate
              }, null, 8, ["testId", "modelValue", "date", "min-date", "onUpdate:endDate", "max-date"])
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            xs: "12",
            sm: "6",
            md: "5",
            lg: "4",
            cols: "12",
            class: "d-flex align-items-center"
          }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_a_g_button, {
                onClick: _ctx.onDownloadPDF,
                disabled: _ctx.disableButtons,
                testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'generate-soa-download-pdf'),
                isLoading: _ctx.$store.getters.isDownloadingSOA,
                class: "margin_right_10 align_multiple_btn"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Download PDF ")
                ]),
                _: 1
              }, 8, ["onClick", "disabled", "testId", "isLoading"]), [
                [_vShow, _ctx.disableSendBtn]
              ]),
              _withDirectives(_createVNode(_component_a_g_button, {
                onClick: _ctx.onSendEmail,
                disabled: _ctx.disableButtons,
                testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'generate-soa-send-email'),
                isLoading: _ctx.$store.getters.isSendingSOA,
                class: "align_multiple_btn"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Send Email ")
                ]),
                _: 1
              }, 8, ["onClick", "disabled", "testId", "isLoading"]), [
                [_vShow, _ctx.isUserFinance]
              ]),
              _withDirectives(_createVNode(_component_a_g_button, {
                onClick: _ctx.onDownloadOldSOA,
                disabled: _ctx.disableButtons,
                testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'generate-soa-send-email'),
                isLoading: _ctx.$store.getters.isSendingSOA,
                class: "align_multiple_btn"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Download Old SOA ")
                ]),
                _: 1
              }, 8, ["onClick", "disabled", "testId", "isLoading"]), [
                [_vShow, !_ctx.isUserAgent]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}