import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  defaultSegmentList: null,
  defaultSegmentStats: null,
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  // ...
});
