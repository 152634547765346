<template>
  <ag-loader v-if="$store.getters.isSalesMyTeamLoading" />
  <template v-else>
    <AgCard>
      <ag-heading variant="h2" title="My Team"></ag-heading>
    </AgCard>

    <AgCard v-for="(item, index) in myTeam" :key="index">
      <AgIconInfoBar :title="renderSectoName(item.sector_name)" />
      <ag-accordion
        class="flight_accordion_wrap margin_bottom_0"
        :panel-value="[0]"
      >
        <ag-accordion-panel>
          <template #tabSection>
            <AgTable
              :headers="headers"
              :items="item.my_team_members"
              :items-per-page="10"
              :has-search="false"
              :simpleDataTable="true"
            >
              <template #col-role="{ item }">
                {{ item.raw?.role?.replace("_", " ") }}
              </template>
            </AgTable>
          </template>
        </ag-accordion-panel>
      </ag-accordion>
    </AgCard>
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IMyTeam } from "@/ag-portal-common/interfaces/salesMyTeam.interface";
import { IHeading } from "@/ag-portal-common/interfaces/header.interface";

export default defineComponent({
  name: "SalesMyTeam",
  data(): { headers: IHeading[] } {
    return {
      headers: [
        {
          title: "Email",
          value: "email",
          key: "email",
          sortable: true,
        },
        {
          title: "Role",
          value: "role",
          key: "role",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    renderSectoName(city: string): string {
      return `${city} Sector`;
    },
  },
  computed: {
    myTeam(): IMyTeam[] {
      return this.$store.getters.salesMyTeam;
    },
  },
  mounted() {
    this.$store.dispatch("getSalesMyTeam");
  },
});
</script>
