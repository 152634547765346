<template>
  <ag-loader v-if="$store.getters.isFetchingOrganization" />
  <ag-row>
    <ag-column class="text-right margin_bottom_20">
      <a-g-button
        :testId="genTestId(ELEMENT_TYPES.BUTTON, 'enable-fp-modal')"
        @click="toggleLinkFinancialProfileModal"
        v-if="shouldEnableFinancialActions"
      >
        {{
          $store.getters.organization?.financial_profiles.length
            ? "Link Another Financial Profile"
            : "Link Financial Profile"
        }}
      </a-g-button>
    </ag-column>
  </ag-row>

  <financial-profile-box
    @unlinkFinancialProfile="unlinkFinancialProfile"
    v-bind:key="financialProfile.public_id"
    v-for="financialProfile in $store.getters.organization?.financial_profiles"
    :financial-profile="financialProfile"
    :enable-actions="shouldEnableFinancialActions"
  />

  <organization-and-agent-list
    :enable-actions="true"
    @onAddAgent="toggleAddAgentModal"
    @updateOrganizationStatus="onUpdateStatus"
    v-if="$store.getters.organization"
    :organization="$store.getters.organization"
  />

  <add-agent-modal
    :is-open="showAddAgentDialog"
    v-if="showAddAgentDialog"
    @toggle="toggleAddAgentModal"
    @onSubmit="onAddEmail"
  />

  <link-financial-profile-modal
    :organization-id="$route.params.id"
    v-if="showLinkFPDialog"
    :is-open="showLinkFPDialog"
    @toggle="toggleLinkFinancialProfileModal"
  />
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import FinancialProfileBox from "@/modules/Agent/components/FinancialProfileBox.vue";
import OrganizationAndAgentList from "@/modules/Agent/components/OrganizationAndAgentList.vue";
import { AddAgentDto } from "@/modules/Organization/dtos/agent.dto";
import AddAgentModal from "@/modules/Organization/components/AddAgentModal.vue";
import LinkFinancialProfileModal from "@/modules/Organization/components/LinkFinancialProfileModal.vue";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { UpdateOrganizationDto } from "@/modules/Organization/dtos/organization.dto";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";

export default defineComponent({
  name: "OrganizationDetail",
  components: {
    LinkFinancialProfileModal,
    AddAgentModal,
    FinancialProfileBox,
    OrganizationAndAgentList,
  },
  methods: {
    genTestId,
    unlinkFinancialProfile(id: string) {
      this.$store.dispatch("unlinkFinancialProfile", id);
    },
    onUpdateStatus(body: UpdateOrganizationDto) {
      this.$store.dispatch("updateStatus", {
        body: body,
      });
    },
    toggleAddAgentModal() {
      this.showAddAgentDialog = !this.showAddAgentDialog;
    },
    toggleLinkFinancialProfileModal() {
      this.showLinkFPDialog = !this.showLinkFPDialog;
    },
    onAddEmail(payload: AddAgentDto) {
      this.$store.dispatch("addAgentInOrganization", {
        body: payload,
        orgId: this.$route.params.id,
        callback: this.closeDialog,
      });
    },
    closeDialog() {
      this.showAddAgentDialog = false;
    },
  },
  data(): {
    showAddAgentDialog: boolean;
    showLinkFPDialog: boolean;
    ELEMENT_TYPES: IObject;
  } {
    return {
      ELEMENT_TYPES,
      showLinkFPDialog: false,
      showAddAgentDialog: false,
    };
  },
  computed: {
    shouldEnableFinancialActions() {
      let user: any = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2 | null;
      user = user();
      return user?.role_unique_id === USER_ROLES.AG_SUPER_USER;
    },
  },
  created() {
    this.$store.dispatch("fetchOrganization", this.$route.params.id);
  },
  inject: [AUTH_CONTEXT_KEYS.user],
});
</script>
