import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "instruction" }
const _hoisted_2 = { class: "otp-input" }
const _hoisted_3 = { class: "action-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    scrollable: "",
    "is-open": _ctx.isOpen,
    onClose: _ctx.closeDialog,
    "modal-width": "30%"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createTextVNode("Whatsapp Member Authorization")
        ]),
        _: 1
      }),
      _createVNode(_component_a_g_button, {
        type: "button",
        class: "modal_close_icon",
        variant: "link",
        onClick: _ctx.closeDialog
      }, {
        default: _withCtx(() => [
          _createTextVNode("Close ")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otpDigits, (_, index) => {
            return (_openBlock(), _createBlock(_component_v_text_field, {
              class: "digit-input",
              key: index,
              type: "text",
              modelValue: _ctx.otpDigits[index],
              "onUpdate:modelValue": ($event: any) => ((_ctx.otpDigits[index]) = $event),
              maxlength: "1",
              onKeydown: ($event: any) => (_ctx.handleInput(index, $event)),
              onPaste: _ctx.handlePaste,
              ref_for: true,
              ref: _ctx.renderOTPRef(index)
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "onPaste"]))
          }), 128))
        ]),
        _withDirectives(_createElementVNode("p", { class: "error" }, _toDisplayString(_ctx.error), 513), [
          [_vShow, _ctx.error]
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_AGButton, {
            "test-id": "",
            onClick: _ctx.submitOTP,
            "is-loading": _ctx.isSubmitLoading,
            variant: "primary",
            type: "button"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Submit ")
            ]),
            _: 1
          }, 8, ["onClick", "is-loading"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["is-open", "onClose"]))
}