import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_financial_profile_box = _resolveComponent("financial-profile-box")!
  const _component_organization_and_agent_list = _resolveComponent("organization-and-agent-list")!
  const _component_add_agent_modal = _resolveComponent("add-agent-modal")!
  const _component_link_financial_profile_modal = _resolveComponent("link-financial-profile-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$store.getters.isFetchingOrganization)
      ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_ag_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_ag_column, { class: "text-right margin_bottom_20" }, {
          default: _withCtx(() => [
            (_ctx.shouldEnableFinancialActions)
              ? (_openBlock(), _createBlock(_component_a_g_button, {
                  key: 0,
                  testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'enable-fp-modal'),
                  onClick: _ctx.toggleLinkFinancialProfileModal
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$store.getters.organization?.financial_profiles.length
            ? "Link Another Financial Profile"
            : "Link Financial Profile"), 1)
                  ]),
                  _: 1
                }, 8, ["testId", "onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters.organization?.financial_profiles, (financialProfile) => {
      return (_openBlock(), _createBlock(_component_financial_profile_box, {
        onUnlinkFinancialProfile: _ctx.unlinkFinancialProfile,
        key: financialProfile.public_id,
        "financial-profile": financialProfile,
        "enable-actions": _ctx.shouldEnableFinancialActions
      }, null, 8, ["onUnlinkFinancialProfile", "financial-profile", "enable-actions"]))
    }), 128)),
    (_ctx.$store.getters.organization)
      ? (_openBlock(), _createBlock(_component_organization_and_agent_list, {
          key: 1,
          "enable-actions": true,
          onOnAddAgent: _ctx.toggleAddAgentModal,
          onUpdateOrganizationStatus: _ctx.onUpdateStatus,
          organization: _ctx.$store.getters.organization
        }, null, 8, ["onOnAddAgent", "onUpdateOrganizationStatus", "organization"]))
      : _createCommentVNode("", true),
    (_ctx.showAddAgentDialog)
      ? (_openBlock(), _createBlock(_component_add_agent_modal, {
          key: 2,
          "is-open": _ctx.showAddAgentDialog,
          onToggle: _ctx.toggleAddAgentModal,
          onOnSubmit: _ctx.onAddEmail
        }, null, 8, ["is-open", "onToggle", "onOnSubmit"]))
      : _createCommentVNode("", true),
    (_ctx.showLinkFPDialog)
      ? (_openBlock(), _createBlock(_component_link_financial_profile_modal, {
          key: 3,
          "organization-id": _ctx.$route.params.id,
          "is-open": _ctx.showLinkFPDialog,
          onToggle: _ctx.toggleLinkFinancialProfileModal
        }, null, 8, ["organization-id", "is-open", "onToggle"]))
      : _createCommentVNode("", true)
  ], 64))
}