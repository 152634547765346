import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { AGENT_API_PATH } from "@/modules/Agent/constants";
import { formatString } from "@/ag-portal-common/utils/helpers";
import { AgentPermissionsChangeVerificationRequestModel } from "@/modules/Agent/models/agentPermissionsChangeVerification.request";
import { AgentPricingResponseModel } from "@/modules/Agent/models/agentPricing.response";
import { AgentPricingMapper } from "@/modules/Agent/responseMappers/agentPricing.mapper";
import { IAgentPricing } from "@/interfaces/agentPricing.interface";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";

class AgentService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public updateAgentPermissions(
    organizationId: string,
    agentId: string,
    permissions: { [key: string]: boolean }
  ): Promise<IAGResponse<any>> {
    return this.restClient.post(
      formatString(
        AGENT_API_PATH.UPDATE_AGENT_PERMISSIONS,
        organizationId,
        agentId
      ),
      permissions
    );
  }

  public agentPermissionsChangeVerification(
    body: AgentPermissionsChangeVerificationRequestModel
  ) {
    return this.restClient.post(
      AGENT_API_PATH.PERMISSIONS_CHANGE_VERIFICATION,
      body
    );
  }

  public async fetchAgentPricing(
    customerCode: string
  ): Promise<IAgentPricing[]> {
    const methodName = "AgentService.fetchAgentPricing";
    let mappedAgentPricing: IAgentPricing[] = [];
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse<AgentPricingResponseModel[]> =
        await this.restClient.get(AGENT_API_PATH.GET_AGENT_PRICING, {
          customer_code: customerCode,
        });

      if (response.success) {
        const agentPricingMapper = new AgentPricingMapper();
        mappedAgentPricing = agentPricingMapper.responseMapper(
          response.data || []
        );
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    return mappedAgentPricing;
  }

  public deleteAgent(id: string): Promise<IAGResponse<any>> {
    return this.restClient.delete(AGENT_API_PATH.DELETE_AGENT, id);
  }
}

export default AgentService;
