import { RouteRecordRaw } from "vue-router";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import MyBookings from "@/modules/MyBookings/views/MyBookings.vue";
import IssuanceLogs from "@/modules/MyBookings/views/IssuanceLogs.vue";
import { PATH } from "@/ag-portal-common/constants/path";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "myBookings",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.MY_BOOKINGS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.MY_BOOKINGS.PERMISSIONS,
    },
    component: MyBookings,
  },
  {
    path: PATH.ISSUANCE_LOGS,
    name: "IssuanceLogs",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.MY_BOOKINGS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.MY_BOOKINGS.PERMISSIONS,
    },
    component: IssuanceLogs,
  },
  {
    path: PATH.ISSUANCE_LOGS_WITHOUT_PNR,
    name: "IssuanceLogsWithoutPNR",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.ISSUANCE_LOGS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.ISSUANCE_LOGS.PERMISSIONS,
    },
    component: IssuanceLogs,
  },
];

export default routes;
