import { RouteRecordRaw } from "vue-router";
import fareManagement from "@/modules/FareManagement/views/fareManagement.vue";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "faremanagement",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FARE_MANAGEMENT.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FARE_MANAGEMENT.PERMISSIONS,
    },
    component: fareManagement,
  },
];

export default routes;
