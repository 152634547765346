import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ag_table = _resolveComponent("ag-table")!
  const _component_ag_not_found = _resolveComponent("ag-not-found")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_PaymentDetailModal = _resolveComponent("PaymentDetailModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ag_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ag_div, { class: "d-flex justify-content-end" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_g_button, {
              testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'payment-list-create-new'),
              onClick: _ctx.routeToCreatePayment
            }, {
              default: _withCtx(() => [
                _createTextVNode("Create New Payment ")
              ]),
              _: 1
            }, 8, ["testId", "onClick"])
          ]),
          _: 1
        }),
        (_ctx.$store.getters.isFetchingPayments)
          ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
          : (
        !_ctx.$store.getters.isFetchingPayments &&
        _ctx.$store.getters.payments.payments.length
      )
            ? (_openBlock(), _createBlock(_component_ag_table, {
                key: 1,
                "items-per-page": _ctx.$store.getters.payments.pageSize,
                page: _ctx.$store.getters.payments.pageNumber,
                "total-page-count": _ctx.$store.getters.payments.totalPages,
                onOnPageChange: _ctx.onPageChange,
                headers: _ctx.headers,
                items: _ctx.$store.getters.payments.payments,
                "has-pagination": true,
                "has-search": true
              }, {
                "col-transactionType": _withCtx(({ item }) => [
                  _createVNode(_component_ag_div, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.TRANSACTION_TYPES[item.raw.transactionType]), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                "col-paymentStatus": _withCtx(({ item }) => [
                  _createVNode(_component_ag_div, {
                    class: _normalizeClass(_ctx.paymentStatusClass(item.raw.paymentStatus))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.PAYMENT_STATUSES[item.raw.paymentStatus]), 1)
                    ]),
                    _: 2
                  }, 1032, ["class"])
                ]),
                "col-receiptStatus": _withCtx(({ item }) => [
                  _createVNode(_component_ag_div, {
                    class: _normalizeClass(_ctx.receiptStatusClass(item.raw.receiptStatus))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.RECEIPT_STATUSES[item.raw.receiptStatus]), 1)
                    ]),
                    _: 2
                  }, 1032, ["class"])
                ]),
                "col-dateTime": _withCtx(({ item }) => [
                  _createVNode(_component_ag_div, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.formattedDate(item.raw.dateTime)), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_ag_div, { class: "text-color-green" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.formattedTime(item.raw.dateTime)), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                "col-amount": _withCtx(({ item }) => [
                  _createVNode(_component_ag_div, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.formattedCurrency(item.raw.amount)), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                "col-action": _withCtx(({ item }) => [
                  _createVNode(_component_ag_div, { class: "table_last_col_link" }, {
                    default: _withCtx(() => [
                      _createElementVNode("strong", null, [
                        _createVNode(_component_router_link, {
                          "data-test-id": 
                _ctx.genTestId(_ctx.ELEMENT_TYPES.LINK, `payment-list-${item.raw.id}`)
              ,
                          to: 
                _ctx.formatStringToRoutePath(_ctx.PATH.PAYMENT_DETAIL, {
                  id: item.raw.id,
                })
              
                        }, {
                          default: _withCtx(() => [
                            (
                  item.raw.transactionType === _ctx.PAYMENT_METHODS_TYPES.ONE_BILL
                )
                              ? (_openBlock(), _createBlock(_component_ag_heading, {
                                  key: 0,
                                  title: "Update Status",
                                  variant: "p"
                                }))
                              : (_openBlock(), _createBlock(_component_ag_heading, {
                                  key: 1,
                                  title: "View",
                                  variant: "p"
                                }))
                          ]),
                          _: 2
                        }, 1032, ["data-test-id", "to"])
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["items-per-page", "page", "total-page-count", "onOnPageChange", "headers", "items"]))
            : (_openBlock(), _createBlock(_component_ag_not_found, {
                key: 2,
                heading: _ctx.NOTIFICATION_MESSAGES.NO_RESULTS_FOUND
              }, null, 8, ["heading"]))
      ]),
      _: 1
    }),
    (_ctx.showDetailModal)
      ? (_openBlock(), _createBlock(_component_PaymentDetailModal, {
          key: 0,
          "show-modal": _ctx.showDetailModal,
          onOnCloseModal: _ctx.onCloseModal
        }, null, 8, ["show-modal", "onOnCloseModal"]))
      : _createCommentVNode("", true)
  ], 64))
}