export const CREDIT_LIMIT_MANAGEMENT_API_PATH = {
  GET: "v2/limit-request",
  POST: "v2/limit-request/create",
  POST_PROCESS: "v2/limit-request/process",
  GET_BY_ID: "v2/limit-request/{0}",
};

export const CREDIT_LIMIT_REQUEST_PRIORITIES = [
  {
    label: "Low",
    value: "LOW",
  },
  {
    label: "Normal",
    value: "NORMAL",
  },
  {
    label: "Urgent",
    value: "URGENT",
  },
];
