import { MutationTree } from "vuex";
import { IPermissionsState } from "@/ag-portal-common/interfaces/permissionsState.interface";
import { IPermission } from "@/ag-portal-common/interfaces/permission.interface";

const mutations: MutationTree<IPermissionsState> = {
  fetchingPermissions(state, fetching: boolean) {
    state.isFetchingPermission = fetching;
  },
  fetchPermissionsSuccess(state, permissions: Array<IPermission>) {
    state.isFetchingPermission = false;
    state.permissions = permissions;
  },
};

export default mutations;
