<template>
  <span>
    <b v-if="boldNums">{{ duration }}</b>
    <span v-else>{{ duration }}</span>
    {{ title }}{{ duration > 1 ? "s" : "" }}&nbsp;
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DurationSegment",
  props: {
    duration: { type: Number, required: true },
    boldNums: { type: Boolean, default: false },
    title: { type: String, required: true },
  },
});
</script>
