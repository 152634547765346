import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { PERMISSIONS_API_PATH } from "@/modules/Permissions/constants";
import { AGENT_SUB_ROLES } from "@/ag-portal-common/enums/AGENT_SUB_ROLES";
import { formatString } from "@/ag-portal-common/utils/helpers";

class PermissionsService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public getAll(agentType: AGENT_SUB_ROLES): Promise<IAGResponse<any>> {
    return this.restClient.get(
      formatString(PERMISSIONS_API_PATH.GET_ALL, agentType)
    );
  }
}

export default PermissionsService;
