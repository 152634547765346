<template>
  <AgCard class="sector-snapshot-header">
    <ag-loader v-if="$store.getters.isSalesLoading" />
    <template v-else>
      <ag-row>
        <ag-column sm="12" lg="4">
          <AgDiv class="autocomplete-container">
            <p class="label">Select Sector</p>
            <ag-select
              label="Sector Name"
              :items="sectors"
              v-model="selectedSector"
              :value="selectedSector"
              variant="outlined"
            />
          </AgDiv>
        </ag-column>
        <ag-column sm="12" lg="8" v-show="selectedSector">
          <AgDiv class="autocomplete-container">
            <p class="label">Sales People</p>
            <ag-select
              label="Sales People"
              :items="salesUsers"
              v-model="selectedSalesUser"
              :value="selectedSalesUser"
              variant="outlined"
            />
          </AgDiv>
        </ag-column>
      </ag-row>
      <ag-loader v-if="$store.getters.isSalesLoading" />
      <template v-else>
        <template v-if="showDetails">
          <!-- General Stats Card -->
          <AgCard class="card-wrapper">
            <p class="heading">General Stats</p>
            <hr />
            <ag-row>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Enabled Websites"
                  icon="provider"
                  :value="salesSnapshot?.aggregates?.enabled_website_count"
                />
              </ag-column>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Disabled Websites"
                  icon="provider"
                  :value="salesSnapshot?.aggregates?.disabled_website_count"
                />
              </ag-column>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Logo Added"
                  icon="provider"
                  :value="salesSnapshot?.aggregates?.logo_added_count"
                />
              </ag-column>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Color Added"
                  icon="provider"
                  :value="salesSnapshot?.aggregates?.color_added_count"
                />
              </ag-column>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Default Color"
                  icon="provider"
                  :value="salesSnapshot?.aggregates?.default_color_count"
                />
              </ag-column>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Default Logo"
                  icon="provider"
                  :value="salesSnapshot?.aggregates?.default_logo_count"
                />
              </ag-column>
            </ag-row>
          </AgCard>

          <!-- Users Card -->
          <AgCard class="card-wrapper">
            <p class="heading">Users</p>
            <hr />
            <ag-row>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Owners"
                  icon="customerStatus"
                  :value="salesSnapshot?.users?.owners"
                />
              </ag-column>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Employees"
                  icon="customerStatus"
                  :value="salesSnapshot?.users?.employees"
                />
              </ag-column>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Sub Agents"
                  icon="customerStatus"
                  :value="salesSnapshot?.users?.sub_agents"
                />
              </ag-column>
            </ag-row>
          </AgCard>

          <!-- Organization Card -->
          <AgCard class="card-wrapper">
            <p class="heading">Organizations</p>
            <hr />
            <AgTable
              v-if="showOrganizationTable"
              :headers="headers"
              :items="salesSnapshot?.organizations"
              :items-per-page="10"
              :has-pagination="false"
              :simpleDataTable="true"
              :hasSearch="true"
              @onClickRow="handleRowClick"
            >
              <template #col-logo="{ item }">
                <AgDiv><img width="60" :src="item.raw.logo" /></AgDiv>
              </template>
              <template #col-org_color="{ item }">
                <AgDiv>
                  <ag-color-chip
                    :color-name="item.raw.org_color"
                    :color-code="item.raw.org_color"
                  />
                </AgDiv>
              </template>
              <template #col-name="{ item }">
                <AgDiv class="name-column">{{ item.raw.name }}</AgDiv>
              </template>
              <template #col-domain_name="{ item }">
                <AgDiv>{{
                  item.raw.domain_name ? item.raw.domain_name : "N/A"
                }}</AgDiv>
              </template>
              <template #col-display_name="{ item }">
                <AgDiv>{{
                  item.raw.display_name ? item.raw.display_name : "N/A"
                }}</AgDiv>
              </template>
            </AgTable>
            <AgNotFound v-else test-id="" heading="Organizations not found" />
          </AgCard>
        </template>
        <AgNotFound v-else test-id="" heading="Sales Details Not Found" />
      </template>
    </template>
  </AgCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "lodash";

import { formatQueryPath } from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";
import { IHeading } from "@/ag-portal-common/interfaces/header.interface";
import {
  ISalesSector,
  SalesUser,
  ISalesSnapshot,
} from "@/ag-portal-common/interfaces/salesProductivity.interface";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";

export default defineComponent({
  name: "SalesSnapshotAnalyticsTab",
  data(): {
    salesmanId: number | null;
    headers: IHeading[];
    salesUsers: any[];
    selectedSalesUser: number | null;
    selectedSector: number | null;
  } {
    return {
      salesmanId: null,
      selectedSector: null,
      headers: [
        {
          title: "Organization Name",
          value: "name",
          key: "name",
          sortable: true,
        },
        {
          title: "Owners",
          value: "owner_count",
          key: "owner_count",
          sortable: true,
        },
        {
          title: "Sub Agents",
          value: "sub_agent_count",
          key: "sub_agent_count",
          sortable: true,
        },
        {
          title: "Employees",
          value: "employee_count",
          key: "employee_count",
          sortable: true,
        },
        {
          title: "Logo",
          value: "logo",
          key: "logo",
          sortable: false,
        },
        {
          title: "Display Name",
          value: "display_name",
          key: "display_name",
          sortable: true,
        },
        {
          title: "Website Slugs",
          value: "website_slug",
          key: "website_slug",
          sortable: true,
        },
        {
          title: "Website Status",
          value: "website_status",
          key: "website_status",
          sortable: true,
        },
        {
          title: "Domain Name",
          value: "domain_name",
          key: "domain_name",
          sortable: true,
        },
        {
          title: "Organization Color",
          value: "org_color",
          key: "org_color",
          sortable: false,
        },
      ],
      salesUsers: [],
      selectedSalesUser: null,
    };
  },
  methods: {
    handleSalesPersonChange() {
      if (this.selectedSalesUser) {
        const path = `${PATH.SALES_SNAPSHOT}${formatQueryPath({
          sectorId: this.selectedSector,
          salesmanId: this.selectedSalesUser,
        })}`;
        this.$router.push(path);
      }
    },
    fetchSalesStats() {
      const { salesmanId } = this.$route.query;
      this.$store.dispatch("getSalesStats", salesmanId);
    },
    updateSalesUsers() {
      const salesSectors: ISalesSector[] =
        this.$store.getters.salesSectors || [];
      const selectedObj = salesSectors.find(
        (item) => item.sector_id === this.selectedSector
      );
      if (selectedObj) {
        this.salesUsers = selectedObj.sales_users.map((user: SalesUser) => {
          return {
            label: user.name + " - (" + user.email + ")",
            value: user.id,
          };
        });
      } else {
        this.salesUsers = [];
      }
    },
    handleRowClick(e: any, row: any) {
      const org_id = row?.item?.raw?.public_id;
      const url = this.$router.resolve({
        path: "/organization/snapshot",
        query: { org_id: org_id },
      });

      // Open the route in a new tab
      window.open(url.href, "_blank");
    },
  },
  computed: {
    sectors(): IObject[] {
      const sectors = this.$store.getters.salesSectors;
      return (
        sectors?.map((item: ISalesSector) => {
          return {
            label: item.sector_name,
            value: item.sector_id,
          };
        }) || []
      );
    },
    salesSnapshot(): ISalesSnapshot {
      return this.$store.getters.salesSnapshot;
    },
    showDetails(): boolean {
      const isNotEmpty = !_.isEmpty(this.salesSnapshot);
      return isNotEmpty;
    },
    showOrganizationTable(): boolean {
      const isLoading = this.$store.getters.isSalesLoading;
      const organizations =
        this.$store.getters.salesSnapshot.organizations || [];
      return !isLoading && organizations.length > 0;
    },
  },
  watch: {
    "$route.query": {
      handler: function (value) {
        const isNotEmpty = !_.isEmpty(value);
        if (isNotEmpty) {
          this.fetchSalesStats();
        }
      },
      immediate: true,
    },
    selectedSector: {
      handler: function () {
        this.selectedSalesUser = null;
        this.updateSalesUsers();
      },
    },
    selectedSalesUser: {
      handler: function () {
        this.handleSalesPersonChange();
      },
    },
  },
  mounted() {
    this.$store.dispatch("fetchSectors");
    const { salesmanId, sectorId } = this.$route.query;
    if (salesmanId) {
      setTimeout(() => {
        this.selectedSector = Number(sectorId);
      }, 1000);
    }
  },
});
</script>

<style lang="scss" scoped>
.sector-snapshot-header {
  .name-container {
    display: flex;
    align-items: center;
    img {
      width: 70px;
    }
    .agency-name-container {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .agency-name {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
        color: #000;
      }
      p {
        margin-bottom: 0;
        color: #6b7280;
      }
    }
  }
}

.autocomplete-container {
  max-width: 400px;
  .label {
    font-weight: bold;
  }
}

.heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}

.card-wrapper {
  margin-block: 30px;
  border: solid 1px var(--green-color);
  border-left: solid 8px var(--green-color);
}
.empty-items-message {
  text-align: center;
  margin-block: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #828282;
}
.color-chip {
  width: 120px;
}

.website-slug {
  font-weight: bold;
  color: #6b7280;
  &:hover {
    color: #0c47bc;
  }
}

.name-column {
  min-width: 210px;
}
</style>
