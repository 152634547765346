import * as yup from "yup";

export const organizationCreateUpdateValidation = yup.object().shape({
  name: yup.string().required("Name is required"),
  sector: yup.number().nullable().required("Sector is required"),
  currency: yup.string().nullable().required("Currency is required"),
  relatedPerson: yup.string().nullable().required("Related Person is required"),
  pia_provider: yup.string().nullable().required("PIA provide is required"),
  contact_person_phone: yup
    .string()
    .nullable()
    .required("Phone number is required"),
  contact_person_email: yup
    .string()
    .required("Email is required")
    .email("Invalid Email"),
});
