import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "margin_bottom_10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AGTextField = _resolveComponent("AGTextField")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_AgTable = _resolveComponent("AgTable")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ag_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ag_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ag_column, {
              sm: "12",
              lg: "5"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AGTextField, {
                  name: "pnr",
                  label: "Enter PNR",
                  modelValue: _ctx.inputPNR,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputPNR) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              lg: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AGButton, {
                  variant: "primary",
                  "is-loading": _ctx.isLoading,
                  onClick: _ctx.buttonClick,
                  type: "button"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Get PNR Logs")
                  ]),
                  _: 1
                }, 8, ["is-loading", "onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.pnrValue)
      ? (_openBlock(), _createBlock(_component_ag_div, { key: 0 }, {
          default: _withCtx(() => [
            _createElementVNode("b", _hoisted_1, "PNR: " + _toDisplayString(_ctx.pnrValue), 1),
            (_ctx.apiResponse && _ctx.apiResponse.length > 0)
              ? (_openBlock(), _createBlock(_component_AgTable, {
                  key: 0,
                  headers: _ctx.tableHeaders,
                  items: _ctx.apiResponse,
                  "has-pagination": false,
                  "items-per-page": 20,
                  "has-search": false
                }, {
                  "col-created_at": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.created_at
              ? _ctx.getFormattedDateTime(
                  item.raw.created_at,
                  _ctx.FORMAT_YYY_MM_DD_HH_SS_A
                )
              : "NA"), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  "col-log_type": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, { class: "color_chip green" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.log_type), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  "col-requestData": _withCtx(({ item }) => [
                    _createVNode(_component_AGButton, {
                      "test-id": item.raw.request_data,
                      variant: "primary",
                      type: "button",
                      onClick: ($event: any) => (_ctx.toggleExpandedViewRequest(item))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" View More ")
                      ]),
                      _: 2
                    }, 1032, ["test-id", "onClick"])
                  ]),
                  "col-responseData": _withCtx(({ item }) => [
                    _createVNode(_component_AGButton, {
                      "test-id": item.raw.response_data,
                      variant: "primary",
                      type: "button",
                      onClick: ($event: any) => (_ctx.toggleExpandedViewResponse(item))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" View More ")
                      ]),
                      _: 2
                    }, 1032, ["test-id", "onClick"])
                  ]),
                  _: 1
                }, 8, ["headers", "items"]))
              : (_openBlock(), _createBlock(_component_AgNotFound, {
                  key: 1,
                  "test-id": "",
                  heading: "No PNR Details Found",
                  description: "Please Try Modify Your PNR"
                }))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ag_modal, {
      "is-open": _ctx.isResponseOpen,
      onClose: _ctx.closeResponseModal,
      "modal-width": "70%"
    }, {
      body: _withCtx(() => [
        _createElementVNode("pre", null, _toDisplayString(_ctx.selectedItemResponse.raw.response_data), 1)
      ]),
      _: 1
    }, 8, ["is-open", "onClose"]),
    _createVNode(_component_ag_modal, {
      "is-open": _ctx.isRequestOpen,
      onClose: _ctx.closeRequestModal,
      "modal-width": "70%"
    }, {
      body: _withCtx(() => [
        _createElementVNode("pre", null, _toDisplayString(_ctx.selectedItemRequest.raw.request_data), 1)
      ]),
      _: 1
    }, 8, ["is-open", "onClose"])
  ], 64))
}