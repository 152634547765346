import { IPermission } from "@/ag-portal-common/interfaces/permission.interface";
import { PERMISSIONS_TYPE } from "@/modules/Permissions/utils/enums/PERMISSIONS_TYPE";

export function systemPermissionsByPermissionTypes(
  permissions: Array<IPermission>
) {
  const newPermissions: { [key: string]: Array<IPermission> } = {
    [PERMISSIONS_TYPE.FLIGHTS]: [],
    [PERMISSIONS_TYPE.HOTELS]: [],
    [PERMISSIONS_TYPE.PAYMENTS]: [],
    [PERMISSIONS_TYPE.ISSUANCE]: [],
    [PERMISSIONS_TYPE.SOA]: [],
    [PERMISSIONS_TYPE.OTHERS]: [],
  };
  permissions.forEach((permission) => {
    if (
      permission.permission_name
        .toLowerCase()
        .includes(PERMISSIONS_TYPE.FLIGHTS)
    ) {
      newPermissions[PERMISSIONS_TYPE.FLIGHTS].push(permission);
      return;
    }
    if (
      permission.permission_name.toLowerCase().includes(PERMISSIONS_TYPE.HOTELS)
    ) {
      newPermissions[PERMISSIONS_TYPE.HOTELS].push(permission);
      return;
    }
    if (
      permission.permission_name
        .toLowerCase()
        .includes(PERMISSIONS_TYPE.PAYMENTS)
    ) {
      newPermissions[PERMISSIONS_TYPE.PAYMENTS].push(permission);
      return;
    }
    if (
      permission.permission_name.toLowerCase().includes(PERMISSIONS_TYPE.SOA)
    ) {
      newPermissions[PERMISSIONS_TYPE.SOA].push(permission);
      return;
    }
    if (
      permission.permission_name
        .toLowerCase()
        .includes(PERMISSIONS_TYPE.ISSUANCE)
    ) {
      newPermissions[PERMISSIONS_TYPE.ISSUANCE].push(permission);
      return;
    }
    newPermissions[PERMISSIONS_TYPE.OTHERS].push(permission);
  });
  return newPermissions;
}

export function agentPermissionsAsDTORequest(
  allPermissions: Array<IPermission>,
  selectedPermissions: Array<string>
) {
  const allPermissionsAsDTO = allPermissions.reduce(
    (obj, permission) => ({
      ...obj,
      [permission.permission_code]: false,
    }),
    {}
  );
  const selectedPermissionsAsDTO = selectedPermissions.reduce(
    (obj, permission) => ({
      ...obj,
      [permission]: true,
    }),
    {}
  );
  return {
    ...allPermissionsAsDTO,
    ...selectedPermissionsAsDTO,
  };
}
