import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-345705a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_ag_calendar = _resolveComponent("ag-calendar")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_AgCard = _resolveComponent("AgCard")!
  const _component_FlightIssuance = _resolveComponent("FlightIssuance")!
  const _component_FlightBookings = _resolveComponent("FlightBookings")!
  const _component_HotelBookings = _resolveComponent("HotelBookings")!
  const _component_SabreSegments = _resolveComponent("SabreSegments")!
  const _component_Payments = _resolveComponent("Payments")!
  const _component_CreditLimit = _resolveComponent("CreditLimit")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isSectorProductivityLoading)
      ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_AgCard, { class: "sector-snapshot-header" }, {
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_1, "Statistics " + _toDisplayString(_ctx.selectedSector), 1),
        _createVNode(_component_ag_row, { class: "mt-10" }, {
          default: _withCtx(() => [
            _createVNode(_component_ag_column, {
              xs: "12",
              sm: "12",
              md: "12",
              lg: "4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_calendar, {
                  label: "Start Date",
                  "onUpdate:startDate": _cache[0] || (_cache[0] = (value) => _ctx.startDate = value),
                  "calendar-name": "startDate",
                  "max-date": new Date(),
                  date: _ctx.startDate ? _ctx.startDate : new Date()
                }, null, 8, ["max-date", "date"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              xs: "12",
              sm: "12",
              md: "12",
              lg: "4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_calendar, {
                  label: "End Date",
                  "onUpdate:endDate": _cache[1] || (_cache[1] = (value) => _ctx.endDate = value),
                  "calendar-name": "endDate",
                  "max-date": new Date(),
                  "min-date": _ctx.startDate,
                  date: _ctx.endDate ? _ctx.endDate : new Date()
                }, null, 8, ["max-date", "min-date", "date"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              xs: "12",
              sm: "12",
              md: "12",
              lg: "4",
              class: "d-flex align-items-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AGButton, {
                  onClick: _ctx.handleFetchData,
                  disabled: _ctx.isSectorProductivityLoading,
                  "is-loading": _ctx.isSectorProductivityLoading,
                  "test-id": "",
                  variant: "primary",
                  class: "fire_btn_wrap"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Fetch Statistics")
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled", "is-loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.showDetails)
      ? (_openBlock(), _createBlock(_component_AgCard, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_FlightIssuance, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8, ["handleOrganizationRowClick"]),
            _createVNode(_component_FlightBookings, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8, ["handleOrganizationRowClick"]),
            _createVNode(_component_HotelBookings, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8, ["handleOrganizationRowClick"]),
            _createVNode(_component_SabreSegments, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8, ["handleOrganizationRowClick"]),
            _createVNode(_component_Payments, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8, ["handleOrganizationRowClick"]),
            _createVNode(_component_CreditLimit, { handleOrganizationRowClick: _ctx.handleRowClick }, null, 8, ["handleOrganizationRowClick"])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_AgNotFound, {
          key: 2,
          "test-id": "",
          heading: "Sector productivity not found"
        }))
  ], 64))
}