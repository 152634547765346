import { RouteRecordRaw } from "vue-router";
import FourZeroOne from "@/modules/FourZeroOne/views/FourZeroOne.vue";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "401",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.AG_FOUR_ZERO_FOUR.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.AG_FOUR_ZERO_FOUR.PERMISSIONS,
    },
    component: FourZeroOne,
  },
];

export default routes;
