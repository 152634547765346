import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_AgSelect = _resolveComponent("AgSelect")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_AGTextField = _resolveComponent("AGTextField")!
  const _component_ag_calendar = _resolveComponent("ag-calendar")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    scrollable: "",
    "is-open": _ctx.isOpen,
    onClose: _ctx.closeModal,
    "modal-width": "40%"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.isEdit ? "Edit" : "Add") + " Passenger", 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_g_button, {
        type: "button",
        class: "modal_close_icon",
        variant: "link",
        onClick: _ctx.closeModal
      }, {
        default: _withCtx(() => [
          _createTextVNode("Close ")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    body: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
      }, [
        _createVNode(_component_ag_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "6",
              cols: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AgSelect, {
                  items: _ctx.passenger_types,
                  label: "Passenger Type",
                  variant: "outlined",
                  modelValue: _ctx.passenger_type,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.passenger_type) = $event)),
                  value: _ctx.passenger_type,
                  "test-id": "",
                  error: _ctx.errors?.passenger_type
                }, null, 8, ["items", "modelValue", "value", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "6",
              cols: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AgSelect, {
                  items: _ctx.renderTitleData,
                  modelValue: _ctx.title,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.title) = $event)),
                  value: _ctx.title,
                  label: "Title",
                  variant: "outlined",
                  "test-id": "",
                  error: _ctx.errors?.title
                }, null, 8, ["items", "modelValue", "value", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "6",
              cols: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AGTextField, {
                  label: "First Name and Middle Name (if any)",
                  modelValue: _ctx.first_name,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.first_name) = $event)),
                  value: _ctx.first_name,
                  "test-id": "",
                  variant: "outlined",
                  error: _ctx.errors?.first_name
                }, null, 8, ["modelValue", "value", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "6",
              cols: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AGTextField, {
                  label: "Last Name",
                  modelValue: _ctx.last_name,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.last_name) = $event)),
                  value: _ctx.last_name,
                  "test-id": "",
                  variant: "outlined",
                  error: _ctx.errors?.last_name
                }, null, 8, ["modelValue", "value", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "6",
              cols: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AgSelect, {
                  items: _ctx.nationalities,
                  value: _ctx.nationality,
                  label: "Nationality",
                  variant: "outlined",
                  modelValue: _ctx.nationality,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.nationality) = $event)),
                  "test-id": "",
                  error: _ctx.errors?.nationality
                }, null, 8, ["items", "value", "modelValue", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "6",
              cols: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AGTextField, {
                  label: "CNIC Number",
                  modelValue: _ctx.cnic,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.cnic) = $event)),
                  value: _ctx.cnic,
                  "test-id": "",
                  variant: "outlined",
                  error: _ctx.errors?.cnic
                }, null, 8, ["modelValue", "value", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "6",
              cols: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AgSelect, {
                  items: _ctx.genders,
                  value: _ctx.gender,
                  label: "Gender",
                  variant: "outlined",
                  modelValue: _ctx.gender,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.gender) = $event)),
                  "test-id": "",
                  error: _ctx.errors?.gender
                }, null, 8, ["items", "value", "modelValue", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "6",
              cols: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AGTextField, {
                  label: "Passport Number",
                  modelValue: _ctx.passport_number,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.passport_number) = $event)),
                  value: _ctx.passport_number,
                  "test-id": "",
                  variant: "outlined",
                  error: _ctx.errors?.passport_number
                }, null, 8, ["modelValue", "value", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "12",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_calendar, {
                  label: "Passport Expiry",
                  "onUpdate:passport_expiry": _cache[8] || (_cache[8] = (value) => _ctx.passport_expiry = value),
                  "calendar-name": "passport_expiry",
                  date: _ctx.passport_expiry,
                  "min-date": _ctx.currentDate,
                  error: _ctx.errors?.passport_expiry
                }, null, 8, ["date", "min-date", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              md: "12",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_calendar, {
                  label: "Date of Birth",
                  "onUpdate:dob": _cache[9] || (_cache[9] = (value) => _ctx.dob = value),
                  "calendar-name": "dob",
                  "min-date": _ctx.disabledDates.to,
                  "max-date": _ctx.disabledDates.from,
                  date: _ctx.dob ? _ctx.dob : _ctx.disabledDates.from,
                  "year-range": _ctx.disabledDates.yearsToShow,
                  error: _ctx.errors?.dob
                }, null, 8, ["min-date", "max-date", "date", "year-range", "error"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_AGButton, {
          "test-id": "",
          "is-loading": _ctx.isLoading,
          type: "submit",
          style: {"float":"right"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.isEdit ? "Save" : "Add"), 1)
          ]),
          _: 1
        }, 8, ["is-loading"])
      ], 32)
    ]),
    _: 1
  }, 8, ["is-open", "onClose"]))
}