import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { ActionContext, ActionTree } from "vuex";
import { ISOAState } from "@/ag-portal-common/interfaces/soaState.interface";
import SOAService from "@/modules/SOA/services/soa.service";
import {
  NOTIFICATION_EVENTS,
  notificationBus,
} from "@/ag-portal-common/eventBusses/notification";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { SOAFetchActionModel } from "@/modules/SOA/models/soaFetchAction.model";
import { formatString } from "@/ag-portal-common/utils/helpers";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import dowload from "downloadjs";

const actions: ActionTree<ISOAState, ISOAState> = {
  async fetchSOA(
    context: ActionContext<ISOAState, ISOAState>,
    payload: SOAFetchActionModel
  ) {
    const methodName = "actions.fetchSOA";
    const { params, fileInfo } = payload;
    const fileName = `${fileInfo.customerName}_(${fileInfo.startDate})_(${fileInfo.endDate}).pdf`;
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit(
        params.send_email ? "enableSendingSOA" : "enableDownloadingSOA"
      );
      const soaService = new SOAService();
      const response: IAGResponse<any> = await soaService.get(params);
      if (response.status === StatusCodes.OK) {
        if (!params.send_email) {
          dowload(response.data, fileName);
        }
        notificationBus.emit(NOTIFICATION_EVENTS.TRIGGER, {
          type: NOTIFICATION_TYPES.SUCCESS,
          description: params.send_email
            ? formatString(
                NOTIFICATION_MESSAGES.SOA_SEND_EMAIL_SUCCESS,
                fileInfo.customerName || ""
              )
            : formatString(
                NOTIFICATION_MESSAGES.SOA_DOWNLOAD_SUCCESS,
                fileInfo.customerName || ""
              ),
        });
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (err) {
      notificationBus.emit(NOTIFICATION_EVENTS.TRIGGER, {
        type: NOTIFICATION_TYPES.ERROR,
        description: params.send_email
          ? NOTIFICATION_MESSAGES.SOA_SEND_EMAIL_ERROR
          : NOTIFICATION_MESSAGES.SOA_DOWNLOAD_ERROR,
      });
      loggerService.logError(`${methodName}:`, err);
    }
    context.commit(
      params.send_email ? "disableSendingSOA" : "disableDownloadingSOA"
    );
  },

  async sendSOAEmail(
    context: ActionContext<ISOAState, ISOAState>,
    payload: SOAFetchActionModel
  ) {
    const methodName = "actions.sendSOAEmail";
    const { params, fileInfo } = payload;
    const fileName = `${fileInfo.customerName}_(${fileInfo.startDate})_(${fileInfo.endDate}).pdf`;
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit(
        params.send_email ? "enableSendingSOA" : "enableDownloadingSOA"
      );
      const soaService = new SOAService();
      const response: IAGResponse<any> = await soaService.getEmail(params);
      if (response.status === StatusCodes.OK) {
        if (!params.send_email) {
          dowload(response.data, fileName);
        }
        notificationBus.emit(NOTIFICATION_EVENTS.TRIGGER, {
          type: NOTIFICATION_TYPES.SUCCESS,
          description: params.send_email
            ? formatString(
                NOTIFICATION_MESSAGES.SOA_SEND_EMAIL_SUCCESS,
                fileInfo.customerName || ""
              )
            : formatString(
                NOTIFICATION_MESSAGES.SOA_DOWNLOAD_SUCCESS,
                fileInfo.customerName || ""
              ),
        });
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (err) {
      notificationBus.emit(NOTIFICATION_EVENTS.TRIGGER, {
        type: NOTIFICATION_TYPES.ERROR,
        description: params.send_email
          ? NOTIFICATION_MESSAGES.SOA_SEND_EMAIL_ERROR
          : NOTIFICATION_MESSAGES.SOA_DOWNLOAD_ERROR,
      });
      loggerService.logError(`${methodName}:`, err);
    }
    context.commit(
      params.send_email ? "disableSendingSOA" : "disableDownloadingSOA"
    );
  },

  async fetchPendingReceiptAndInvoice(
    context: ActionContext<ISOAState, ISOAState>,
    { id }: { id: string }
  ) {
    const methodName = "actions.fetchPendingReceiptAndInvoice";

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);

      const soaService = new SOAService();
      const response: IAGResponse<any> = await soaService.getPendingInvoice(id);
      if (response.success && response.status === StatusCodes.OK) {
        const { invoices, receipts } = response.data;
        return { invoices, receipts };
      }
    } catch (err) {
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        "Error while trying to fetch pending invoices";
      notificationService.triggerNotification();
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
  },
};

export default actions;
