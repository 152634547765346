import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RevenueChart = _resolveComponent("RevenueChart")!
  const _component_MyBookingsTable = _resolveComponent("MyBookingsTable")!
  const _component_AgCard = _resolveComponent("AgCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_RevenueChart),
    _createVNode(_component_AgCard, null, {
      default: _withCtx(() => [
        _createVNode(_component_MyBookingsTable, { isDashboardView: true })
      ]),
      _: 1
    })
  ]))
}