import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import DashboardServices from "@/modules/Dashboard/services/dashboard.service";
import { ActionContext, ActionTree } from "vuex";

import { IDashboardState } from "@/ag-portal-common/interfaces/dashboard.interface";
import { GetInsightsDto } from "../dtos/dashboardInsights.dto";

const actions: ActionTree<IDashboardState, IDashboardState> = {
  async getInsights(
    context: ActionContext<IDashboardState, IDashboardState>,
    { value, aggregated_by }: GetInsightsDto
  ) {
    let response: IAGResponse<any> = {
      success: false,
    };
    const methodName = "actions.getInsights";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setInsightsLoading", true);

      const dashboardServices = new DashboardServices();
      response = await dashboardServices.getInsights(value, aggregated_by);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("saveInsights", response.data?.data?.graph);
        context.commit(
          "saveBookingAggregate",
          response.data?.data?.booking_aggregate
        );
      } else {
        throw response;
      }
    } catch (err) {
      loggerService.logError(`${methodName}:`, err);
    } finally {
      context.commit("setInsightsLoading", false);
    }
  },
  async getExpirySoon() {
    let response: IAGResponse<any> = {
      success: false,
    };
    const methodName = "actions.getExpirySoon";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const dashboardServices = new DashboardServices();
      response = await dashboardServices.getExpirySoon();
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        return response.data?.data.count;
        //
      } else {
        throw response;
      }
    } catch (err) {
      loggerService.logError(`${methodName}:`, err);
    } finally {
      //
    }
  },
};

export default actions;
