import { GetterTree } from "vuex";
import { IPaymentState } from "@/interfaces/paymentState.interface";
import { IPayment, IPayments } from "@/interfaces/payments.interface";

const getters: GetterTree<IPaymentState, IPaymentState> = {
  isFetchingPayments: (state): boolean => state.isFetchingPayments,
  isFetchingPayment: (state): boolean => state.isFetchingPayment,
  isCreatingPayment: (state): boolean => state.isCreatingPayment,
  payments: (state): IPayments => state.payments,
  payment: (state): IPayment | null => state.payment,
};

export default getters;
