<template>
  <ag-modal
    :is-open="isOpen"
    class="change_search_modal"
    modal-width="30%"
    @close="onClose"
  >
    <template #header>
      <a-g-button
        type="button"
        class="modal_close_icon"
        variant="link"
        @click="isOpen = !isOpen"
        >Close
      </a-g-button>
    </template>
    <template #body>
      <AgDiv test-id="" class="text-center">
        <AgAlertBox
          test-id=""
          :alert-type="type"
          class="modal_alert"
        ></AgAlertBox>
        <AgIcon name="" class="margin_bottom_20"></AgIcon>

        <AgHeading
          :title="title"
          variant="h2"
          :test-id="genTestId(ELEMENT_TYPES.TEXT, 'notification-popup-title')"
        ></AgHeading>

        <AgHeading
          :title="description"
          variant="p"
          :test-id="
            genTestId(ELEMENT_TYPES.TEXT, 'notification-popup-description')
          "
        ></AgHeading>

        <AgHeading
          :title="currentDate"
          variant="h3"
          :test-id="
            genTestId(ELEMENT_TYPES.TEXT, 'notification-popup-date-time')
          "
        ></AgHeading>

        <AGButton
          :test-id="
            genTestId(ELEMENT_TYPES.BUTTON, 'notification-popup-action-button')
          "
          @click="$emit('onClickAction')"
          variant="primary"
          type="button"
          >{{ actionButtonText }}
        </AGButton>
      </AgDiv>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  genTestId,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { FORMAT_DD_MMM_YYYY_HH_SS_A } from "@/ag-portal-common/constants/dateTimeFormats";

export default defineComponent({
  name: "NotificationPopup",
  props: {
    showModal: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    actionButtonText: {
      type: String,
    },
    type: {
      type: Object as PropType<NOTIFICATION_TYPES>,
    },
  },
  computed: {
    ELEMENT_TYPES() {
      return ELEMENT_TYPES;
    },

    currentDate() {
      let date = new Date();
      return getFormattedDateTime(date, FORMAT_DD_MMM_YYYY_HH_SS_A);
    },
  },
  methods: {
    genTestId,
    onClose() {
      this.$emit("onClose");
    },
  },
  data() {
    return {
      isOpen: this.showModal,
    };
  },
});
</script>
