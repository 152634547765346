import * as yup from "yup";
import { CREDIT_LIMIT_REQUEST_PRIORITIES } from "@/modules/CreditLimitManagement/constants";

export const createCreditLimitRequestValidationSchema = yup.object().shape({
  totalAmount: yup
    .number()
    .nullable()
    .required("Total amount is required")
    .min(1, "Total amount should be greater than 0"),
  totalDays: yup
    .number()
    .nullable()
    .required("Number of days are required")
    .min(1, "Number of days should be greater than 0")
    .max(7, "Number of days should not be more than 7"),
  financialProfilePublicId: yup
    .string()
    .nullable()
    .required("Financial profile is required"),
  priority: yup
    .string()
    .required("Priority is required")
    .oneOf(
      CREDIT_LIMIT_REQUEST_PRIORITIES.map((priority) => priority.value),
      "Priority is required"
    )
    .nullable(),
});
