<template>
  <ag-loader v-if="isSalesLoading" />
  <AgCard class="sector-snapshot-header">
    <p class="heading">Statistics {{ selectedSalesman }}</p>
    <ag-row class="mt-10">
      <ag-column xs="12" sm="12" md="12" lg="4">
        <ag-calendar
          label="Start Date"
          @update:startDate="(value:any) => startDate = value"
          calendar-name="startDate"
          :max-date="new Date()"
          :date="startDate ? startDate : new Date()"
        />
      </ag-column>
      <ag-column xs="12" sm="12" md="12" lg="4">
        <ag-calendar
          label="End Date"
          @update:endDate="(value:any) => endDate = value"
          calendar-name="endDate"
          :max-date="new Date()"
          :min-date="startDate"
          :date="endDate ? endDate : new Date()"
        />
      </ag-column>
      <ag-column
        xs="12"
        sm="12"
        md="12"
        lg="4"
        class="d-flex align-items-center"
      >
        <AGButton
          @click="handleFetchData"
          :disabled="isSalesLoading"
          :is-loading="isSalesLoading"
          test-id=""
          variant="primary"
          class="fire_btn_wrap"
          >Fetch Statistics</AGButton
        >
      </ag-column>
    </ag-row>
  </AgCard>

  <AgCard v-if="showDetails">
    <FlightIssuance :handleOrganizationRowClick="handleRowClick" />
    <FlightBookings :handleOrganizationRowClick="handleRowClick" />
    <HotelBookings :handleOrganizationRowClick="handleRowClick" />
    <SabreSegments :handleOrganizationRowClick="handleRowClick" />
    <Payments :handleOrganizationRowClick="handleRowClick" />
    <CreditLimit :handleOrganizationRowClick="handleRowClick" />
  </AgCard>
  <AgNotFound v-else test-id="" heading="Sector productivity not found" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { format } from "date-fns";
import _ from "lodash";

import { ISectorProductivity } from "@/ag-portal-common/interfaces/sectorProductivity.interface";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import FlightIssuance from "./productivityTabComponents/flightIssuance.vue";
import FlightBookings from "./productivityTabComponents/flightBookings.vue";
import HotelBookings from "./productivityTabComponents/hotelBookings.vue";
import SabreSegments from "./productivityTabComponents/sabreSegment.vue";
import Payments from "./productivityTabComponents/paymentsData.vue";
import CreditLimit from "./productivityTabComponents/creditLimit.vue";
import { ISalesSector } from "@/ag-portal-common/interfaces/salesProductivity.interface";

export default defineComponent({
  name: "SalesSnapshotProductionTab",
  components: {
    FlightIssuance,
    FlightBookings,
    HotelBookings,
    SabreSegments,
    Payments,
    CreditLimit,
  },
  data(): {
    startDate: Date;
    endDate: Date;
    selectedSalesman: string;
  } {
    return {
      startDate: new Date(),
      endDate: new Date(),
      selectedSalesman: "",
    };
  },
  methods: {
    handleFetchData() {
      const { salesmanId } = this.$route.query;
      this.$store.dispatch("getSalesProductivity", {
        salesmanId: salesmanId,
        payload: {
          start_date: format(this.startDate, FORMAT_YYY_MM_DD),
          end_date: format(this.endDate, FORMAT_YYY_MM_DD),
        },
      });
    },
    renderSalemanName() {
      const { salesmanId, sectorId } = this.$route.query;
      this.startDate = new Date();
      this.endDate = new Date();
      if (salesmanId) {
        const sectors: ISalesSector[] = this.$store.getters.salesSectors;
        const selectedSector = sectors.find(
          (x: ISalesSector) => x.sector_id === Number(sectorId)
        );
        const name = selectedSector?.sales_users?.find(
          (x) => x.id === Number(salesmanId)
        )?.name;
        return ` - (${name})`;
      }
      return "";
    },
    handleRowClick(e: any, row: any) {
      const org_id = row?.item?.raw?.public_id;
      const url = this.$router.resolve({
        path: "/organization/snapshot",
        query: { org_id: org_id },
      });

      // Open the route in a new tab
      window.open(url.href, "_blank");
    },
  },
  computed: {
    salesProductivity(): ISectorProductivity {
      return this.$store.getters.salesProductivity;
    },
    isSalesLoading(): boolean {
      return this.$store.getters.isSalesLoading;
    },
    showDetails(): boolean {
      const isLoading = this.$store.getters.isSalesLoading;
      const salesProductivity = this.$store.getters.salesProductivity;
      return !isLoading && salesProductivity;
    },
  },
  unmounted() {
    this.$store.dispatch("setSalesProductivity", null);
  },
  watch: {
    "$route.query": {
      handler: function () {
        const name = this.renderSalemanName();
        this.selectedSalesman = name;
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.sector-snapshot-header {
  .name-container {
    display: flex;
    align-items: center;
    img {
      width: 70px;
    }
    .agency-name-container {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .agency-name {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
        color: #000;
      }
      p {
        margin-bottom: 0;
        color: #6b7280;
      }
    }
  }
}

.card-wrapper {
  margin-block: 30px;
  border: solid 1px var(--green-color);
  border-top: solid 8px var(--green-color);
}

.heading {
  font-size: 30px;
  font-weight: bold;
}

.color-chip {
  width: 120px;
}

.website-slug {
  font-weight: bold;
  color: #6b7280;
  &:hover {
    color: #0c47bc;
  }
}
.empty-items-message {
  text-align: center;
  margin-block: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #828282;
}
</style>
