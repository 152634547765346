<template>
  <ag-loader
    v-if="
      this.$store.getters.isFetchingSectors ||
      this.$store.getters.isFetchingFinancialProfiles ||
      this.$store.getters.isFetchingOrganization
    "
  />
  <div v-else>
    <ag-card v-if="showSelectByDropdown">
      <ag-radio
        :testId="genTestId(ELEMENT_TYPES.RADIO_BUTTON, 'soa-selectBy')"
        v-model="selectBy"
        label="Whether to show individual or group?"
        inline
      >
        <ag-radio-item
          name="selectBy"
          :testId="
            genTestId(
              ELEMENT_TYPES.RADIO_BUTTON,
              `soa-selectBy-${selectByOpts.group.value}`
            )
          "
          :value="selectByOpts.group.value"
          :label="selectByOpts.group.label"
        ></ag-radio-item>
        <ag-radio-item
          name="selectBy"
          :testId="
            genTestId(
              ELEMENT_TYPES.RADIO_BUTTON,
              `soa-selectBy-${selectByOpts.individual.value}`
            )
          "
          :value="selectByOpts.individual.value"
          :label="selectByOpts.individual.label"
        ></ag-radio-item>
      </ag-radio>

      <AgentAutoComplete
        v-if="selectBy === selectByOpts.individual.value"
        :value="profileValueByAutocomplete"
        @onChange="onChangeProfileAutoComplete"
      />
    </ag-card>

    <ag-card v-if="selectBy === selectByOpts.group.value">
      <ag-radio
        label="Please select the city."
        v-model="selectedCity"
        inline
        :testId="genTestId(ELEMENT_TYPES.RADIO_BUTTON, 'soa-selectedCity')"
      >
        <ag-radio-item
          name="selectedCity"
          v-for="(city, index) in cities"
          v-bind:key="index"
          :testId="
            genTestId(
              ELEMENT_TYPES.RADIO_BUTTON,
              `soa-selectedCity-${city.toLowerCase()}`
            )
          "
          :value="city.toLowerCase()"
          :label="city"
        ></ag-radio-item>
      </ag-radio>
    </ag-card>

    <ag-card v-if="selectBy === selectByOpts.group.value">
      <a-g-text-field label="Search Profile" v-model="searchProfile" />
      <ag-column class="agency-scroll">
        <ag-checkbox
          :testId="genTestId(ELEMENT_TYPES.CHECK_BOX, `soa-profiles-selectAll`)"
          v-if="!searchProfile"
          @click="onSelectAll"
          label="Select all"
          v-model="checkSelectAll"
        />
        <ag-checkbox
          v-bind:key="profile.public_id"
          :testId="
            genTestId(
              ELEMENT_TYPES.CHECK_BOX,
              `soa-profiles-${profile.public_id}`
            )
          "
          v-model="selectedProfiles"
          v-for="profile in filteredProfiles"
          :value="profile.public_id"
          :label="`${profile.financial_profile_name} - ${profile.platform_id}`"
        />
      </ag-column>
    </ag-card>
    <GenerateSOA
      @fetchSOA="fetchSOA"
      :disableActionBtns="!isProfileSelected"
      @sendSOAEmail="sendSOAEmail"
      :disableSendBtn="!isGroupSelected"
      @downloadOldSOA="downloadOldSOA"
    />
  </div>
  <AgDiv>
    <ag-card>
      <ag-heading variant="h2" title="Pending Receipt and Invoice"></ag-heading>

      <ag-heading
        variant="h2"
        title="Invoices"
        v-if="pendingPostingInvoices.length > 0"
      ></ag-heading>

      <AgTable
        v-if="pendingPostingInvoices && pendingPostingInvoices.length > 0"
        :headers="tableHeaders_Invoices"
        :items="pendingPostingInvoices"
        :has-pagination="false"
        :items-per-page="5"
        :has-search="false"
      >
        <template #col-ticket_no="{ item }">
          <ag-div>{{ item.raw.ticket_no }}</ag-div>
        </template>
        <template #col-pnr="{ item }">
          <ag-div class="color_chip green">{{ item.raw.pnr }}</ag-div>
        </template>
        <template #col-posting_status="{ item }">
          <ag-div class="color_chip green"
            >{{ item.raw.posting_status }}
          </ag-div>
        </template>
      </AgTable>

      <ag-heading
        variant="h2"
        title="Receipts"
        v-if="pendingPostingReceipts.length > 0"
        style="margin-bottom: 0.5cm; margin-top: 0.5cm"
      ></ag-heading>
      <AgTable
        v-if="pendingPostingReceipts && pendingPostingReceipts.length > 0"
        :headers="tableHeaders_Receipts"
        :items="pendingPostingReceipts"
        :has-pagination="false"
        :items-per-page="5"
        :has-search="false"
      >
        <template #col-payment_id="{ item }">
          <ag-div>{{ item.raw.payment_id }}</ag-div>
        </template>
        <template #col-amount="{ item }">
          <ag-div class="color_chip green">{{ item.raw.amount }}</ag-div>
        </template>
        <template #col-posting_status="{ item }">
          <ag-div class="color_chip green"
            >{{ item.raw.posting_status }}
          </ag-div>
        </template>
      </AgTable>
      <AgNotFound
        v-if="
          pendingPostingInvoices.length === 0 &&
          pendingPostingReceipts.length === 0
        "
        test-id=""
        heading="No Pending Receipts & Invoices Found"
        description=""
      />
    </ag-card>
  </AgDiv>
</template>

<script>
import { format } from "date-fns";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { SOA_DATE_FORMAT } from "@/modules/SOA/constants";
import GenerateSOA from "@/modules/SOA/components/GenerateSOA.vue";
import AgentAutoComplete from "@/components/AgentAutoComplete.vue";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { inject } from "vue";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";

const selectByOpts = {
  group: { label: "Group", value: "group" },
  individual: { label: "Individual", value: "individual" },
};
export default {
  name: "SOA",
  data() {
    return {
      ELEMENT_TYPES,
      showSelectByDropdown: false,
      selectByOpts,
      searchProfile: "",
      selectBy: "",
      profileValueByAutocomplete: "",
      selectedCity: "",
      filteredProfiles: [],
      selectedProfiles: [],
      cities: [],
      pendingPostingReceipts: [],
      pendingPostingInvoices: [],
      tableHeaders_Receipts: [
        {
          title: "Payment Id",
          value: "payment_id",
          key: "payment_id",
          sortable: false,
        },
        {
          title: "Amount",
          value: "amount",
          key: "amount",
          sortable: false,
        },
        {
          title: "Posting Status",
          value: "posting_status",
          key: "posting_status",
          sortable: false,
        },
      ],
      tableHeaders_Invoices: [
        {
          title: "Ticket Number",
          value: "ticket_no",
          key: "ticket_no",
          sortable: false,
        },
        {
          title: "PNR",
          value: "pnr",
          key: "pnr",
          sortable: false,
        },
        {
          title: "Posting Status",
          value: "posting_status",
          key: "posting_status",
          sortable: false,
        },
      ],
    };
  },
  async beforeMount() {
    if (this.user()?.role_unique_id !== USER_ROLES.AGENT) {
      this.$store.dispatch("fetchFinancialProfiles");

      await this.$store.dispatch("fetchSectors");
      this.cities = this.$store.getters.sectors.map((sector) => sector.city);
      this.showSelectByDropdown = true;
    } else {
      let organizationPreference = this.organizationPreference();
      this.$store.dispatch(
        "fetchOrganization",
        organizationPreference?.organization_id
      );
    }
    if (this.user()?.role_unique_id === USER_ROLES.AGENT) {
      const responseData = await this.$store.dispatch(
        "fetchPendingReceiptAndInvoice",
        {
          id: localStorage.getItem("fp"),
        }
      );
      this.pendingPostingInvoices = responseData.invoices;
      this.pendingPostingReceipts = responseData.receipts;
    }
  },
  mounted() {
    // Add an event listener to the 'storage' event
    window.addEventListener("fp", this.handleStorageChange);
  },
  computed: {
    checkSelectAll() {
      return this.selectedProfiles.length === this.filteredProfiles.length;
    },
    isProfileSelected() {
      if (this.user()?.role_unique_id !== USER_ROLES.AGENT) {
        if (
          this.selectBy === selectByOpts.group.value &&
          this.selectedProfiles.length
        ) {
          return true;
        } else if (
          this.selectBy === selectByOpts.individual.value &&
          this.profileValueByAutocomplete
        ) {
          return true;
        }
        return false;
      }
      return true;
    },
    isGroupSelected() {
      if (this.user()?.role_unique_id !== USER_ROLES.AGENT) {
        if (
          this.selectBy === selectByOpts.group.value &&
          this.selectedProfiles.length
        ) {
          return true;
        } else if (
          this.selectBy === selectByOpts.individual.value &&
          this.profileValueByAutocomplete
        ) {
          return false;
        }
        return false;
      }
      return false;
    },
  },
  methods: {
    genTestId,
    onChangeProfileAutoComplete(value) {
      this.profileValueByAutocomplete = value;
    },
    onSelectAll() {
      if (this.selectedProfiles.length === this.filteredProfiles.length) {
        this.selectedProfiles = [];
      } else {
        this.selectedProfiles = this.filteredProfiles.map(
          (profile) => profile.public_id
        );
      }
    },
    filterProfileByGroup() {
      return this.$store.getters.financialProfiles.filter(
        (profile) =>
          this.selectedCity &&
          profile?.sector?.toLowerCase()?.includes(this.selectedCity)
      );
    },
    async fetchSOA(payload) {
      let financialProfilePublicIds = [];
      let { startDate, endDate, financialProfilePublicId } = payload;

      let customerNameIfUserIsAgent = "";
      if (this.user()?.role_unique_id === USER_ROLES.AGENT) {
        let financialProfileIfUserIsAgent =
          this.$store.getters.organization?.financial_profiles.find(
            (item) => item.public_id === financialProfilePublicId
          );
        financialProfilePublicIds.push(financialProfilePublicId);
        customerNameIfUserIsAgent =
          financialProfileIfUserIsAgent.financial_profile_name;
      } else {
        if (this.selectBy === this.selectByOpts.individual.value) {
          let financialProfile = this.$store.getters.financialProfiles.find(
            (profile) => profile.public_id === this.profileValueByAutocomplete
          );
          if (financialProfile) {
            financialProfilePublicIds.push(financialProfile.public_id);
          }
        } else {
          financialProfilePublicIds.push(...this.selectedProfiles);
        }
      }
      for (let financialProfilePublicId of financialProfilePublicIds) {
        let customer = this.$store.getters.financialProfiles.find(
          (profile) => profile.public_id === financialProfilePublicId
        );
        let formattedStartDate = format(startDate, SOA_DATE_FORMAT);
        let formattedEndDate = format(endDate, SOA_DATE_FORMAT);
        const fileInfo = {
          customerName:
            customer?.financial_profile_name || customerNameIfUserIsAgent,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };
        const params = {
          start_date: formattedStartDate,
          end_date: formattedEndDate,
          send_email: payload.sendEmail,
          sort_by: payload.sortBy.toLowerCase(),
          financial_profile_public_id: financialProfilePublicId,
        };

        await this.$store.dispatch("fetchSOA", {
          params,
          fileInfo,
        });
      }
    },
    async downloadOldSOA(payload) {
      let financialProfilePublicIds = [];
      let { startDate, endDate, financialProfilePublicId } = payload;

      let customerNameIfUserIsAgent = "";
      if (this.user()?.role_unique_id === USER_ROLES.AGENT) {
        let financialProfileIfUserIsAgent =
          this.$store.getters.organization?.financial_profiles.find(
            (item) => item.public_id === financialProfilePublicId
          );
        financialProfilePublicIds.push(financialProfilePublicId);
        customerNameIfUserIsAgent =
          financialProfileIfUserIsAgent.financial_profile_name;
      } else {
        if (this.selectBy === this.selectByOpts.individual.value) {
          let financialProfile = this.$store.getters.financialProfiles.find(
            (profile) => profile.public_id === this.profileValueByAutocomplete
          );
          if (financialProfile) {
            financialProfilePublicIds.push(financialProfile.public_id);
          }
        } else {
          financialProfilePublicIds.push(...this.selectedProfiles);
        }
      }
      for (let financialProfilePublicId of financialProfilePublicIds) {
        let customer = this.$store.getters.financialProfiles.find(
          (profile) => profile.public_id === financialProfilePublicId
        );
        let formattedStartDate = format(startDate, SOA_DATE_FORMAT);
        let formattedEndDate = format(endDate, SOA_DATE_FORMAT);
        const fileInfo = {
          customerName:
            customer?.financial_profile_name || customerNameIfUserIsAgent,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };
        const params = {
          start_date: formattedStartDate,
          end_date: formattedEndDate,
          send_email: payload.sendEmail,
          sort_by: payload.sortBy.toLowerCase(),
          financial_profile_public_id: financialProfilePublicId,
        };

        await this.$store.dispatch("sendSOAEmail", {
          params,
          fileInfo,
        });
      }
    },
    async sendSOAEmail(payload) {
      let financialProfilePublicIds = [];
      let { startDate, endDate, financialProfilePublicId } = payload;

      let customerNameIfUserIsAgent = "";
      if (this.user()?.role_unique_id === USER_ROLES.AGENT) {
        let financialProfileIfUserIsAgent =
          this.$store.getters.organization?.financial_profiles.find(
            (item) => item.public_id === financialProfilePublicId
          );
        financialProfilePublicIds.push(financialProfilePublicId);
        customerNameIfUserIsAgent =
          financialProfileIfUserIsAgent.financial_profile_name;
      } else {
        if (this.selectBy === this.selectByOpts.individual.value) {
          let financialProfile = this.$store.getters.financialProfiles.find(
            (profile) => profile.public_id === this.profileValueByAutocomplete
          );
          if (financialProfile) {
            financialProfilePublicIds.push(financialProfile.public_id);
          }
        } else {
          financialProfilePublicIds.push(...this.selectedProfiles);
        }
      }
      for (let financialProfilePublicId of financialProfilePublicIds) {
        let customer = this.$store.getters.financialProfiles.find(
          (profile) => profile.public_id === financialProfilePublicId
        );
        let formattedStartDate = format(startDate, SOA_DATE_FORMAT);
        let formattedEndDate = format(endDate, SOA_DATE_FORMAT);
        const fileInfo = {
          customerName:
            customer?.financial_profile_name || customerNameIfUserIsAgent,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };
        const params = {
          start_date: formattedStartDate,
          end_date: formattedEndDate,
          send_email: payload.sendEmail,
          sort_by: payload.sortBy.toLowerCase(),
          financial_profile_public_id: financialProfilePublicId,
        };

        await this.$store.dispatch("sendSOAEmail", {
          params,
          fileInfo,
        });
      }
    },
  },
  watch: {
    selectedCity() {
      this.filteredProfiles = this.filterProfileByGroup();
      this.selectedProfiles = this.filteredProfiles.map(
        (profile) => profile.public_id
      );
    },
    selectBy(value) {
      if (value === selectByOpts.individual.value) {
        this.selectedCity = "";
        this.selectedProfiles = [];
        this.filteredProfiles = [];
      } else if (value === selectByOpts.group.value) {
        this.profileValueByAutocomplete = "";
      }
    },
    searchProfile(value) {
      if (value) {
        this.filteredProfiles = this.filterProfileByGroup().filter(
          (profile) => {
            return (
              profile.platform_id.includes(value) ||
              profile.financial_profile_name
                .toLowerCase()
                .includes(value.toLowerCase())
            );
          }
        );
      } else {
        this.filteredProfiles = this.filterProfileByGroup();
      }
    },
  },
  components: { AgentAutoComplete, GenerateSOA },

  setup() {
    const user = inject(AUTH_CONTEXT_KEYS.user);
    const organizationPreference = inject(
      AUTH_CONTEXT_KEYS.organizationPreference
    );
    return {
      user,
      organizationPreference,
    };
  },
};
</script>
