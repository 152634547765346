export const ORGANIZATION_ANALYTICS_EVENTS = {
  UPDATE_ORGANIZATION_PREFERENCE: "update-organization-preference",
  UPDATE_WEBSITE_CONFIGURATION: "update-website-configuration",
  WEBSITE_CONTENT_UPLOAD: "website-content-upload",
  // Whatsapp Management Events
  GET_WHATSAPP_MEMBERS: "get-whatsapp-members",
  ADD_WHATSAPP_MEMBERS: "add-whatsapp-member",
  DELETE_WHATSAPP_MEMBERS: "delete-whatsapp-member",
  GENERATE_OTP_WHATAPP_MEMBER: "generate-otp-for-whatsapp-member",
  AUTHORIZE_OTP_WHATAPP_MEMBER: "authorize-otp-for-whatsapp-member",
  // Passenger Management Events
  ADD_NEW_PASSENGER_MANAGEMENT: "add-new-passenger-management",
  UPDATE_PASSENGER_MANAGEMENT: "update-passenger-management",
  DELETE_PASSENGER_MANAGEMENT: "delete-passenger-management",
  FETCH_ORGANIZATION_SNAPSHOT: "fetch-organization-snapshot",
  FETCH_ORGANIZATION_PRODUCTIVITY: "fetch-organization-productivity",
  // Sales my Team
  SALES_MY_TEAM_FETCHED: "sales-my-team-fetched",
};

export const CURRENCY_CONVERSION_ANALYTICS_EVENTS = {
  CURRENCY_SELECTED: "currency-selected",
  CURRENCY_FETCHED: "currency-fetched",
};
