<template>
  <MyBookingsTable />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MyBookingsTable from "@/modules/MyBookings/components/MyBookingsTable.vue";

export default defineComponent({
  name: "MyBookings",
  components: { MyBookingsTable },
});
</script>
