import { IFinancialProfileState } from "@/ag-portal-common/interfaces/financialProfileState.interface";

const financialProfileState: IFinancialProfileState = {
  isFetchingFinancialProfiles: false,
  isFetchingFinancialProfile: false,
  isUnlinkingFinancialProfile: false,
  financialProfile: null,
  financialProfiles: [],
  isLinkingFinancialProfile: false,
};

export default financialProfileState;
