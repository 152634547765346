<template>
  <ag-checkbox :label="label" :value="value" :testId="testId" />
</template>

<script>
export default {
  name: "PermissionItem",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    testId: {
      type: String,
      required: true,
    },
  },
};
</script>
