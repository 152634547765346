import { MutationTree } from "vuex";
import { IMyBookingsState } from "@/interfaces/myBookingsState.interface";
import { IMyBookingsFlight } from "@/interfaces/flightBookings.interface";
import { IMyBookingsHotel } from "@/interfaces/hotelBookings.interface";
import { IMyBookingsTour } from "@/interfaces/tourBookings.interface";

const mutations: MutationTree<IMyBookingsState> = {
  enableIsFetchFlightBookings(state) {
    state.isFetchingFlightBookings = true;
  },
  saveFlightBookings(state, payload: IMyBookingsFlight) {
    state.flightBookings = payload;
    state.isFetchingFlightBookings = false;
  },
  enableIsDownloadingBrandTicket(state) {
    state.isDownloadingBrandTicket = true;
  },
  disableIsDownloadingBrandTicket(state) {
    state.isDownloadingBrandTicket = false;
  },

  enableIsSendingAirlineTicket(state) {
    state.isSendingAirlineTicket = true;
  },
  disableIsSendingAirlineTicket(state) {
    state.isSendingAirlineTicket = false;
  },
  enableIsFetchHotelBookings(state) {
    state.isFetchingHotelBookings = true;
  },
  enableIsFetchTourBookings(state) {
    state.isFetchingTourBookings = true;
  },

  saveHotelBookings(state, payload: IMyBookingsHotel) {
    state.hotelBookings = payload;
    state.isFetchingHotelBookings = false;
  },
  saveTourBookings(state, payload: IMyBookingsTour) {
    state.tourBookings = payload;
    state.isFetchingTourBookings = false;
  },
};

export default mutations;
