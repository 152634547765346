import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgNotFound = _resolveComponent("AgNotFound")!
  const _component_ag_image = _resolveComponent("ag-image")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_ag_card = _resolveComponent("ag-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.normalNewsResponse.id === null)
      ? (_openBlock(), _createBlock(_component_AgNotFound, {
          key: 0,
          "test-id": "",
          heading: "No News Found",
          description: ""
        }))
      : _createCommentVNode("", true),
    (_ctx.normalNewsResponse.id !== null)
      ? (_openBlock(), _createBlock(_component_ag_div, { key: 1 }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.normalNewsResponse, (normalNews, index) => {
              return (_openBlock(), _createBlock(_component_ag_card, { key: index }, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_column, {
                        sm: "12",
                        md: "2",
                        cols: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_image, {
                            class: "renderImage",
                            src: normalNews?.featured_image,
                            alt: "news",
                            width: "100%"
                          }, null, 8, ["src"])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ag_column, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_div, {
                            class: "news-content-box",
                            shaped: "",
                            outlined: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ag_row, { style: {"justify-content":"space-between"} }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ag_heading, {
                                    variant: "h1",
                                    title: normalNews?.news_title,
                                    class: "news-title"
                                  }, null, 8, ["title"]),
                                  (normalNews?.show_on_banner === true)
                                    ? (_openBlock(), _createBlock(_component_ag_div, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_AGButton, {
                                            class: "pulse",
                                            variant: "primary",
                                            disabled: true,
                                            style: {"margin-bottom":"0.5cm"}
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("Featured ")
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ag_heading, {
                                variant: "p",
                                title: normalNews?.news_description,
                                class: "news-description"
                              }, null, 8, ["title"]),
                              _createVNode(_component_a_g_button, {
                                class: "news-action-btn",
                                onClick: ($event: any) => (_ctx.onNormalActionButtonClick(normalNews?.action_url))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(normalNews?.call_to_action_label), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"]),
                              _createVNode(_component_ag_div, { class: "margin_top_10" })
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}