<template>
  <ag-modal test-id="" :is-open="isOpen" @close="closeModal" modal-width="40%">
    <template #header>
      <v-card-title>{{ isEdit ? "Edit" : "Add" }} Package</v-card-title>
      <AGButton
        test-id=""
        type="button"
        class="modal_close_icon"
        variant="link"
        @click="closeModal"
        >Close
      </AGButton>
    </template>
    <template #body>
      <form @submit="onSubmit">
        <v-switch
          test-id=""
          hide-details
          inset
          color="primary"
          v-model="active"
          :value="true"
          :label="renderPackageActiveLabel"
        />

        <AgFile
          test-id=""
          class="ag-file-theme"
          label="Package Image"
          :multiple="false"
          @on:change="onFileChangeHandler"
        />

        <AGTextField
          :test-id="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'package-name')"
          v-model="name"
          :value="name"
          type="text"
          label="Package Name"
          :error="errors?.name"
        />
        <AGTextField
          :test-id="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'package-description')"
          v-model="description"
          :value="description"
          type="text"
          label="Description"
          :error="errors?.description"
        />

        <div class="date-select-wrapper">
          <ag-calendar
            class="date-select"
            :test-id="
              genTestId(ELEMENT_TYPES.CALENDAR_FIELD, 'package-start-date')
            "
            label="Start Date"
            label-variant="float"
            variant="outlined"
            v-model="start_date"
            :date="start_date"
            :min-date="min_date"
            :error="errors?.start_date"
            @update:startDate="changeStartDate"
            calendar-name="startDate"
          />

          <ag-calendar
            class="date-select"
            :test-id="
              genTestId(ELEMENT_TYPES.CALENDAR_FIELD, 'package-end-date')
            "
            label="End Date"
            label-variant="float"
            variant="outlined"
            v-model="end_date"
            :date="end_date"
            :min-date="min_date"
            :error="errors?.end_date"
            @update:endDate="changeEndDate"
            calendar-name="endDate"
          />
        </div>

        <AGTextField
          :test-id="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'package-price')"
          v-model="price"
          :value="price"
          type="text"
          label="Price"
          :error="errors?.price"
        />
        <AGButton
          test-id=""
          type="submit"
          class="submit-btn"
          :disabled="isLoading"
        >
          {{ isEdit ? "Save" : "Add" }}
        </AGButton>
      </form>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { ValidationError } from "yup";
import { addDays } from "date-fns";

import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import { addPackageValidation } from "../validations/addPackage.validation";
import {
  ContentUpload,
  UPLOAD_CONTENT_CATEGORY,
  UPLOAD_CONTENT_SUB_CATEGORY,
} from "@/modules/Organization/dtos/organization.dto";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IOrganizationFromLoginResponse } from "@/ag-portal-common/interfaces/organization.interface";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";

export default defineComponent({
  name: "AddPackageModal",
  emits: ["closeModal", "onSubmit"],
  data(): {
    active: boolean;
    name: string;
    display_image_id?: string | null;
    description: string;
    start_date: Date;
    end_date: Date;
    min_date: Date;
    price: string;
    errors: any;
    ELEMENT_TYPES: typeof ELEMENT_TYPES;
    selectedFile: File | null;
    isLoading: boolean;
  } {
    return {
      active: true,
      name: "",
      display_image_id: null,
      description: "",
      start_date: new Date(),
      end_date: addDays(new Date(), 10),
      min_date: new Date(),
      price: "",
      errors: {},
      ELEMENT_TYPES,
      selectedFile: null,
      isLoading: false,
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
  },
  methods: {
    genTestId,
    closeModal() {
      this.$emit("closeModal");
      this.clearState();
    },
    changeStartDate(newDate: Date) {
      this.start_date = newDate;
    },
    changeEndDate(newDate: Date) {
      this.end_date = newDate;
    },
    initializeState() {
      if (this.isEdit) {
        this.active = this.data?.active;
        this.name = this.data?.name;
        this.display_image_id = this.data?.display_image_id;
        this.description = this.data?.description;
        this.start_date = new Date(this.data?.start_date);
        this.end_date = new Date(this.data?.end_date);
        this.price = this.data?.price;
      }
    },
    clearState() {
      this.active = true;
      this.name = "";
      this.display_image_id = null;
      this.description = "";
      this.start_date = new Date();
      this.end_date = addDays(new Date(), 10);
      this.price = "";
      this.selectedFile = null;
    },
    async onSubmit(e: Event) {
      this.isLoading = true;
      this.errors = {};
      e.preventDefault();

      await this.onContentUploadHandler();

      try {
        const payload = await addPackageValidation.validate(
          {
            active: this.active,
            name: this.name,
            display_image_id: this.display_image_id,
            description: this.description,
            start_date: this.start_date,
            end_date: this.end_date,
            price: this.price,
          },
          { abortEarly: false }
        );
        this.$emit("onSubmit", payload);
        this.clearState();
      } catch (ex) {
        if (ex instanceof ValidationError) {
          const _errors = yupValidationErrorAsSchema(ex);
          this.errors = _errors;
        }
      }
      this.isLoading = false;
    },
    onFileChangeHandler(files: FileList) {
      const file = files[0];
      this.selectedFile = file;
    },
    async onContentUploadHandler() {
      if (this.selectedFile) {
        const payload: ContentUpload = {
          image_content: this.selectedFile,
          content_category: UPLOAD_CONTENT_CATEGORY.WEBSITE,
          content_sub_category: UPLOAD_CONTENT_SUB_CATEGORY.PACKAGE,
        };

        const organization =
          (this.organization() as IOrganizationFromLoginResponse) || null;

        const response = await this.$store.dispatch("websiteUploadContent", {
          payload,
          organizationId: organization.organization_id,
        });

        this.display_image_id = response;
      }
    },
  },
  watch: {
    isOpen(val: boolean) {
      if (val) {
        setTimeout(() => {
          this.initializeState();
          this.min_date = this.start_date;
        }, 0);
      }
    },
  },
  computed: {
    renderPackageActiveLabel() {
      return `Package is ${this.active ? "Active" : "Disabled"}`;
    },
  },
  setup() {
    const organization = inject(
      AUTH_CONTEXT_KEYS.organization
    ) as () => IOrganizationFromLoginResponse;
    return { organization };
  },
});
</script>

<style scoped lang="scss">
#packageImageUploader {
  transition: scale 0.2s ease-in-out;

  &:active {
    transform: scale(0.98);
  }
}
.submit-btn {
  float: right;
}
</style>

<style lang="scss">
.date-select-wrapper {
  display: flex;
  gap: 0 20px;

  .date-select {
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    & {
      flex-wrap: wrap;
    }
  }
}
</style>
