import { RouteRecordRaw } from "vue-router";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import userProfile from "@/modules/Profile/view/profile.vue"; // yet just for running rest will change
import changeProfile from "@/modules/Profile/view/changeProfile.vue"; // yet just for running rest will change
import { PATH } from "@/ag-portal-common/constants/path";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "profile",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.USERPROFILE.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.USERPROFILE.PERMISSIONS,
    },
    component: userProfile, // yet just for running rest will change
  },
  {
    path: PATH.CHANGE_PASSWORD,
    name: "changeProfile",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.USERPROFILE.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.USERPROFILE.PERMISSIONS,
    },
    component: changeProfile, // yet just for running rest will change
  },
];

export default routes;
