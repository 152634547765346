<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <ag-modal
    :is-open="isEditModalOpen"
    @close="closeEditModal"
    modal-width="30%"
  >
    <template #body>
      <AgDiv style="align-items: center !important">
        <AGTextField name="Fare" label="Fare" v-model="inputAmount" />
        <AGButton test-id="" @click="(e: MouseEvent) => onSave()"
          >Save</AGButton
        >
        <AGButton
          test-id=""
          @click="(e: MouseEvent) => closeEditModal()"
          style="margin-left: 20px"
          >Cancel</AGButton
        >
      </AgDiv>
    </template>
  </ag-modal>
  <AgNotFound
    test-id=""
    heading="No Quotations Added Yet"
    description=""
    v-if="!haveData && !haveReturnData"
  />
  <div v-else>
    <AgDiv test-id="" class="text-right dontAddThis margin_bottom_10">
      <AGButton test-id="" @click="(e: MouseEvent) => handleDownloadClick()"
        >Download Quotations</AGButton
      >
    </AgDiv>
    <AgDiv
      test-id=""
      class="d-flex justify-content-end margin_bottom_10 dontAddThis"
    >
      <AgFilterDropdown
        test-id=""
        class="fare_calculator_main dontAddThis"
        label="bulk edit"
        icon="calculator"
        @click.capture="null"
      >
        <template #Items>
          <AgFareCalculator test-id="">
            <AgTextFieldNew
              :label="renderLabel()"
              test-id=""
              type="number"
              variant="outlined"
              v-model="selectedAmount"
              :sign="renderSign()"
              :error="fieldError"
            ></AgTextFieldNew>
            <AgRow test-id="">
              <AgColumn test-id="">
                <AgDiv test-id="" class="fare_calc_box">
                  <AgHeading
                    title="Select Amount Type"
                    variant="label"
                    test-id=""
                  ></AgHeading>
                  <AgRadio test-id="" v-model="selectedAmountType">
                    <AgRadioItem
                      test-id=""
                      name="amount_type"
                      value="fixed"
                      label="Fixed Amount"
                    ></AgRadioItem>
                    <AgRadioItem
                      test-id=""
                      name="amount_type"
                      value="percentage"
                      label="Percentage Amount"
                    ></AgRadioItem>
                  </AgRadio>
                </AgDiv>
              </AgColumn>
            </AgRow>
            <AgRow test-id="">
              <AgColumn test-id="" class="text-right">
                <AGButton
                  class="full-width"
                  test-id=""
                  variant="primary"
                  @click="handelApply"
                  >APPLY</AGButton
                ></AgColumn
              >
            </AgRow>
          </AgFareCalculator>
        </template>
      </AgFilterDropdown>
    </AgDiv>
    <div style="display: flex; align-content: end">
      <div>
        <img
          class="custom-print-content"
          :src="urlLogo()"
          style="
            border-right: 1px dashed #dedede;
            max-height: 54px;
            padding: 0.25cm;
          "
        />
      </div>
      <div>
        <ag-heading
          style="margin-left: 0.25cm; margin-top: 8px; padding: auto"
          class="custom-print-content"
          variant="h1"
          :title="organizationName()"
        ></ag-heading>
      </div>
    </div>

    <br />
    <div
      v-show="haveData"
      v-for="(flightItem, index) in Data"
      :key="index"
      style="margin-bottom: 0.5cm; background-color: white"
      class="AddThisOnly"
    >
      <AgColumn test-id="">
        <AgFlightDetailCard
          style="padding: auto"
          test-id=""
          :airline-logo="flightItem?.airline_logo"
          :airline-code="flightItem?.airline_code"
          :duration="flightItem.duration"
          :from-destination="flightItem?.from_destination"
          :from-time="flightItem?.from_time"
          :to-time="flightItem?.to_time"
          :to-destination="flightItem?.to_destination"
          :airline-name="flightItem?.airline_name"
          fare-info="true"
        >
        </AgFlightDetailCard>

        <hr class="border-dashed" />
        <AgRow test-id="">
          <AgFlightFare
            v-for="(fare, indexs) in flightItem?.rdbs"
            test-id=""
            :key="indexs"
            :type="fare?.rbd"
            :price="
              formatCurrency(
                calculateFlightPrice(fare?.price),
                fare.price.gross_fare.currency
              )
            "
            :piece="renderBaggage(fare)"
            pieceIcon="bagIcon"
          >
            <template #button>
              <AGButton
                test-id=""
                @click="(e: MouseEvent) => handleEditClick(index,indexs)"
                class="dontAddThis"
                >Edit Fare</AGButton
              >
            </template>
          </AgFlightFare>
        </AgRow>

        <AgDiv test-id="" class="text-right" style="margin: 0.5cm">
          <ag-icon
            test-id="delete"
            name="deleteIcon"
            class="dontAddThis"
            @click="handleDeleteIconClick(index)"
          ></ag-icon>
        </AgDiv>
      </AgColumn>
    </div>
    <div
      v-if="haveReturnData"
      v-for="(returnFlightItem, index) in ReturnData"
      :key="index"
      style="margin-bottom: 0.5cm; background-color: white"
      class="AddThisOnly"
    >
      <AgColumn test-id="">
        <AgReturnFlightDetailCard
          test-id=""
          :airline-logo="returnFlightItem.logo"
          :airline-code="returnFlightItem.goingFlightNo"
          :rairline-code="returnFlightItem.comingFlightNo"
          :duration="returnFlightItem.jaanayKiduration"
          :rduration="returnFlightItem.wapsiKiduration"
          :from-destination="returnFlightItem.source"
          :from-time="returnFlightItem.jaanayKaDepartureTime"
          :from-timer="returnFlightItem.wapsiKaArrivalTime"
          :to-time="returnFlightItem.jaanayKaArrivalTime"
          :to-destination="returnFlightItem.destination"
          :rfrom-destination="returnFlightItem.source"
          :rto-time="returnFlightItem.wapsiKaDepartureTime"
          :rto-destination="returnFlightItem.destination"
          :airline-name="returnFlightItem.name"
          fare-info="true"
        />
        <hr class="border-dashed" />
        <AgRow test-id="" style="align-items: center">
          <AgFlightFare
            :type="returnFlightItem?.rbdFirst"
            :price="
              returnFlightItem.source + '-' + returnFlightItem.destination
            "
            :piece="
              returnFlightItem.goingBagage
                ? returnFlightItem.goingBagage.pieces +
                  'piece - ' +
                  ' ' +
                  returnFlightItem.goingBagage.weight +
                  returnFlightItem.goingBagage.unit
                : 'No-Baggage'
            "
            pieceIcon="bagIcon"
          >
          </AgFlightFare>
          <AgFlightFare
            :type="returnFlightItem?.rbdSecond"
            :price="
              returnFlightItem.destination + '-' + returnFlightItem.source
            "
            :piece="
              returnFlightItem.comingBagage
                ? returnFlightItem.comingBagage.pieces +
                  'piece - ' +
                  ' ' +
                  returnFlightItem.comingBagage.weight +
                  returnFlightItem.comingBagage.unit
                : 'No-Baggage'
            "
            pieceIcon="bagIcon"
          />
          <AGButton
            test-id=""
            class="fire_btn_wrap"
            style="max-height: fit-content; margin-right: 1cm"
            @click="(e: MouseEvent) => null"
            >{{
              formatCurrency(returnFlightItem.price, returnFlightItem.currency)
            }}</AGButton
          >
          <AGButton
            test-id=""
            style="max-height: fit-content"
            @click="(e: MouseEvent) => handleReturnEditClick(index)"
            class="dontAddThis"
            >Edit Fare</AGButton
          >
        </AgRow>

        <AgDiv test-id="" class="text-right" style="margin: 0.5cm">
          <ag-icon
            test-id="delete"
            name="deleteIcon"
            class="dontAddThis"
            @click="handleReturnDeleteIconClick(index)"
          ></ag-icon>
        </AgDiv>
      </AgColumn>
    </div>
    <div
      style="margin-left: 10px; align-items: center"
      class="custom-print-content-generated-by"
    >
      <ag-heading variant="h3" :title="generatedBy"></ag-heading>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent, inject } from "vue";
import { QUOTATIONS_ANALYTICS_EVENTS } from "@/modules/ManageQuotations/constants/anaylticsEvents";
import analyticsService from "@/analytic.service";
import _ from "lodash";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";

import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";

import {
  FareOption,
  FlightOption,
  FlightLeg,
  returnFlightPairs,
} from "@/modules/ManageQuotations/views/utils/Flights";
import { Price } from "@/modules/ManageQuotations/views/utils/BookingResponse";
import { TimeUtility } from "@/modules/ManageQuotations/views/utils/TimeUtility";
import {
  getCurrencyFormatter,
  renderBaggageInfo,
} from "@/modules/ManageQuotations/views/utils/index";
import { number } from "yup";

import { parseLocalStorageData } from "@/ag-portal-common/utils/helpers";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";

export default defineComponent({
  name: "FlightDetailCardItem",
  components: {},
  setup() {
    let user = (inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2) || null;

    return { user };
  },
  data(): {
    fareOption: FareOption | null;
    isPanelExpanded: boolean;
    Data: FareOption | null;
    ReturnData: returnFlightPairs | null;
    isEditModalOpen: boolean;
    inputAmount: number;
    mainIndex: string;
    subIndex: string;
    selectedAmount: string;
    selectedAmountType: string;
    selectedFareType: string;
    sign: string;
    fieldError: string;
    state: boolean;
  } {
    return {
      fareOption: null,
      ReturnData: null,
      isPanelExpanded: false,
      Data: null,
      isEditModalOpen: false,
      inputAmount: 0,
      mainIndex: "",
      subIndex: "",
      selectedAmount: "",
      selectedAmountType: "fixed",
      selectedFareType: "",
      sign: "PKR",
      fieldError: "",
      state: false,
    };
  },
  props: {
    flightItem: {
      type: Object as PropType<FlightOption>,
      default: () => ({}),
    },
    returnFlightItem: {
      type: Object as PropType<returnFlightPairs>,
      default: () => ({}),
    },
    showFareBreakDown: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.currentJourneyLeg();
    this.returnJourneyLeg();
  },
  methods: {
    urlLogo() {
      const organizationPreference = parseLocalStorageData(
        STORAGE_KEYS.PREFERENCES
      );
      return organizationPreference?.logo;
    },
    organizationName() {
      const organizationPreference = parseLocalStorageData(
        STORAGE_KEYS.PREFERENCES
      );
      return organizationPreference?.org_name_to_display;
    },
    handleEditClick(index: any, indexs: any) {
      this.mainIndex = index;
      this.subIndex = indexs;
      this.isEditModalOpen = true;
      localStorage.setItem("isReturn", "false");
      analyticsService.logActionEvent(QUOTATIONS_ANALYTICS_EVENTS.EDIT_FARE, {
        editted: this.Data,
      });
    },
    handleReturnEditClick(index: any) {
      localStorage.setItem("isReturn", "true");
      this.mainIndex = index;
      this.isEditModalOpen = true;
      analyticsService.logActionEvent(QUOTATIONS_ANALYTICS_EVENTS.EDIT_FARE, {
        editted: this.Data,
      });
    },

    closeEditModal() {
      this.isEditModalOpen = false;
    },
    async handleDownloadClick() {
      window.print();
      analyticsService.logActionEvent(
        QUOTATIONS_ANALYTICS_EVENTS.DOWNLOAD_PDF,
        {
          downloadedQuotations: this.Data,
        }
      );
    },
    handelApply() {
      if (this.selectedAmountType !== AMOUNT_TYPE.PERCENTAGE) {
        this.handleFixedAmountToAdd();
      } else {
        this.handlePercentageAmountToAdd();
      }
    },
    handleFixedAmountToAdd() {
      const quotations = localStorage.getItem("quotations");
      const myList = quotations ? JSON.parse(quotations) : [];

      if (Number(this.selectedAmount) != 0) {
        for (let i = 0; i < myList.length; i++) {
          for (let j = 0; j < myList[i].rdbs.length; j++) {
            myList[i].rdbs[j].price.ag_gross_fare.value =
              Number(myList[i].rdbs[j].price.ag_gross_fare.value) +
              Number(this.selectedAmount);
          }
        }
        localStorage.setItem("quotations", JSON.stringify(myList));
        const quotations = localStorage.getItem("quotations-return");
        const mysecondList = quotations ? JSON.parse(quotations) : [];
        for (let i = 0; i < mysecondList.length; i++) {
          mysecondList[i].price =
            Number(mysecondList[i].price) + Number(this.selectedAmount);
        }
        localStorage.setItem("quotations-return", JSON.stringify(mysecondList));
        this.selectedAmount = "";
        this.currentJourneyLeg();
        this.returnJourneyLeg();
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Enter Amount to continue";
        notificationService.triggerNotification();
      }
      return myList;
    },
    handlePercentageAmountToAdd() {
      const quotations = localStorage.getItem("quotations");
      const myList = quotations ? JSON.parse(quotations) : [];

      if (Number(this.selectedAmount) != 0) {
        const percentage = Number(this.selectedAmount) / 100;

        for (let i = 0; i < myList.length; i++) {
          for (let j = 0; j < myList[i].rdbs.length; j++) {
            const currentFareValue = Number(
              myList[i].rdbs[j].price.ag_gross_fare.value
            );
            const change = currentFareValue * percentage;
            myList[i].rdbs[j].price.ag_gross_fare.value =
              currentFareValue + change;
          }
        }
        const quotations = localStorage.getItem("quotations-return");
        const mysecondList = quotations ? JSON.parse(quotations) : [];
        for (let i = 0; i < mysecondList.length; i++) {
          const currentFareValue = Number(mysecondList[i].price);
          const change = currentFareValue * percentage;
          mysecondList[i].price = currentFareValue + change;
        }
        localStorage.setItem("quotations", JSON.stringify(myList));
        localStorage.setItem("quotations-return", JSON.stringify(mysecondList));
        this.selectedAmount = "";
        this.currentJourneyLeg();
        this.returnJourneyLeg();
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Enter Percentage value to continue";
        notificationService.triggerNotification();
      }
      return myList;
    },
    handleDeleteIconClick(index: any) {
      const quotations = localStorage.getItem("quotations");
      let parsedQuotes = quotations ? JSON.parse(quotations) : [];

      const returnString = localStorage.getItem("quotations-return");
      let returnItem = returnString ? JSON.parse(returnString) : [];
      const indexToDelete = index;
      parsedQuotes.splice(indexToDelete, 1); // deleted
      localStorage.setItem("quotations", JSON.stringify(parsedQuotes));
      notificationService.type = NOTIFICATION_TYPES.SUCCESS;
      notificationService.description = "Deleted Flight Item Successfully";
      notificationService.triggerNotification();
      this.currentJourneyLeg();
      if (
        (JSON.stringify(parsedQuotes) === "[]" ||
          JSON.stringify(parsedQuotes) === null) &&
        (JSON.stringify(returnItem) === "[]" ||
          JSON.stringify(returnItem) === null)
      ) {
        localStorage.removeItem("quotations");
        window.location.reload();
      }
      this.currentJourneyLeg();
      analyticsService.logActionEvent(
        QUOTATIONS_ANALYTICS_EVENTS.DELETE_QUOTE,
        {
          deletedQuotations: this.Data,
        }
      );
    },
    handleReturnDeleteIconClick(index: any) {
      const quotations = localStorage.getItem("quotations-return");
      let parsedQuotes = quotations ? JSON.parse(quotations) : [];
      const returnString = localStorage.getItem("quotations");
      let returnItem = returnString ? JSON.parse(returnString) : [];
      const indexToDelete = index;
      parsedQuotes.splice(indexToDelete, 1); // deleted
      localStorage.setItem("quotations-return", JSON.stringify(parsedQuotes));
      notificationService.type = NOTIFICATION_TYPES.SUCCESS;
      notificationService.description = "Deleted Flight Item Successfully";
      notificationService.triggerNotification();
      this.returnJourneyLeg();
      if (
        (JSON.stringify(parsedQuotes) === "[]" ||
          JSON.stringify(parsedQuotes) === null) &&
        (JSON.stringify(returnItem) === "[]" ||
          JSON.stringify(returnItem) === null)
      ) {
        localStorage.removeItem("quotations-return");
        window.location.reload();
      }
      this.returnJourneyLeg();
      analyticsService.logActionEvent(
        QUOTATIONS_ANALYTICS_EVENTS.DELETE_QUOTE,
        {
          deletedQuotations: this.Data,
        }
      );
    },
    onSave() {
      //
      const isReturn = localStorage.getItem("isReturn");
      if (isReturn === "false") {
        const quotations = localStorage.getItem("quotations");
        const myList = quotations ? JSON.parse(quotations) : [];
        if (this.inputAmount > 0 && this.inputAmount) {
          myList[this.mainIndex].rdbs[this.subIndex].price.ag_gross_fare.value =
            this.inputAmount;
          localStorage.setItem("quotations", JSON.stringify(myList));
          this.currentJourneyLeg();
          this.isEditModalOpen = false;
          this.inputAmount = 0;

          analyticsService.logActionEvent(
            QUOTATIONS_ANALYTICS_EVENTS.SAVE_QUOTE,
            {
              savedQuotations: JSON.stringify(myList),
            }
          );
        } else {
          notificationService.type = NOTIFICATION_TYPES.ERROR;
          notificationService.description =
            "Fare cant be empty or negative or in words";
          notificationService.triggerNotification();
        }
      } else {
        const quotations = localStorage.getItem("quotations-return");
        const myList = quotations ? JSON.parse(quotations) : [];
        if (this.inputAmount > 0 && this.inputAmount) {
          myList[this.mainIndex].price = this.inputAmount;
          localStorage.setItem("quotations-return", JSON.stringify(myList));
          this.returnJourneyLeg();
          this.isEditModalOpen = false;
          this.inputAmount = 0;

          analyticsService.logActionEvent(
            QUOTATIONS_ANALYTICS_EVENTS.SAVE_QUOTE,
            {
              savedQuotations: JSON.stringify(myList),
            }
          );
        } else {
          notificationService.type = NOTIFICATION_TYPES.ERROR;
          notificationService.description =
            "Fare cant be empty or negative or in words";
          notificationService.triggerNotification();
        }
      }
    },
    title(amount: number) {
      if (amount > 0) {
        return "Fee";
      } else {
        return "Discount";
      }
    },
    formatTime: (time: Date) => {
      return TimeUtility.parsedFlightTimeorDate(time);
    },
    legDuration: (durationInNumber: number) => {
      const duration = TimeUtility.getDurationInTextByMinutes(durationInNumber);
      return duration;
    },
    calculateFlightPrice(priceObject: Price) {
      return Number(
        priceObject.ag_gross_fare?.value
          ? priceObject.ag_gross_fare.value
          : priceObject.gross_fare.value
      );
    },
    formatCurrency: (amount: number, currency_format: string) => {
      return getCurrencyFormatter(currency_format).format(amount);
    },
    getNextDepartureTime(index: number) {
      return index + 1 < this.fareOption!.segments.length
        ? this.fareOption!.segments[index + 1].departure_datetime
        : new Date();
    },
    renderDepartureDate: (departure_date: Date) => {
      return TimeUtility.parsedFlightTimeorDate(
        departure_date,
        "EEEE, MMMM dd yyyy"
      );
    },
    renderBaggage(fareOption: FareOption) {
      const has_baggage = _.get(fareOption, "baggage_info");
      return has_baggage
        ? renderBaggageInfo(fareOption?.baggage_info)
        : "No Baggage";
    },
    renderRBD(rbd: string) {
      return `RBD: ${rbd}`;
    },
    currentJourneyLeg() {
      const quotations = localStorage.getItem("quotations");
      this.Data = quotations ? JSON.parse(quotations) : [];
      return this.Data;
    },
    returnJourneyLeg() {
      const quotations = localStorage.getItem("quotations-return");
      this.ReturnData = quotations ? JSON.parse(quotations) : [];
      return this.ReturnData;
    },
    renderSign() {
      const currency = localStorage.getItem("currency");
      return this.selectedAmountType === AMOUNT_TYPE.PERCENTAGE
        ? "%"
        : currency;
    },
    renderLabel() {
      return this.selectedAmountType === AMOUNT_TYPE.FIXED
        ? "Enter Fix Value of Amount"
        : "Enter Percentage of Amount";
    },
  },
  computed: {
    generatedBy() {
      return "Quotations generated by " + this.user()?.email;
    },
    haveData() {
      console.log("haveData" + localStorage.getItem("quotations"));
      if (
        localStorage.getItem("quotations")?.length === 2 ||
        localStorage.getItem("quotations") === null
      ) {
        return false;
      } else {
        return true;
      }
    },
    haveReturnData() {
      console.log("haveData" + localStorage.getItem("quotations"));
      if (
        localStorage.getItem("quotations-return")?.length === 2 ||
        localStorage.getItem("quotations-return") === null
      ) {
        return false;
      } else {
        return true;
      }
    },
    extractToDate() {
      const url = this.$route.query.trips;
      const urlToString = new String(url);
      const [origin, destination, departureDate, returnDate] =
        urlToString?.split(",");

      return {
        departureDate,
        returnDate: returnDate || departureDate,
      };
    },
  },
});
export enum AMOUNT_TYPE {
  FIXED = "fixed",
  PERCENTAGE = "percentage",
}

export enum FARE_TYPE {
  GROSS = "gross",
  BASE = "base",
}
</script>

<style scoped>
.AddThisOnly {
  background-color: white;
  color: black;
}
@media screen {
  .custom-print-content {
    display: none;
  }
  .custom-print-content-generated-by {
    display: none;
  }
}
@media print {
  .AddThisOnly {
    display: block;
  }
  .dontAddThis {
    display: none;
  }
  .custom-print-content {
    display: block !important;
  }
  .custom-print-content-generated-by {
    width: 100%;
    background-color: #f0f0f0;
    text-align: center;
    padding: 10px;
    bottom: 0;
  }
}
@page {
  size: A4;
}
</style>
