import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.boldNums)
      ? (_openBlock(), _createElementBlock("b", _hoisted_1, _toDisplayString(_ctx.duration), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.duration), 1)),
    _createTextVNode(" " + _toDisplayString(_ctx.title) + _toDisplayString(_ctx.duration > 1 ? "s" : "") + "  ", 1)
  ]))
}