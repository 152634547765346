<template>
  <ag-card>
    <ag-row>
      <ag-column sm="12" lg="5">
        <AGTextField name="pnr" label="Enter PNR" v-model="inputPNR" />
      </ag-column>
      <ag-column sm="12" lg="6">
        <AGButton
          variant="primary"
          :is-loading="isLoading"
          @click="buttonClick"
          type="button"
          >Get PNR Logs</AGButton
        >
      </ag-column>
    </ag-row>
  </ag-card>
  <ag-div v-if="pnrValue">
    <b class="margin_bottom_10">PNR: {{ pnrValue }}</b>
    <AgTable
      v-if="apiResponse && apiResponse.length > 0"
      :headers="tableHeaders"
      :items="apiResponse"
      :has-pagination="false"
      :items-per-page="20"
      :has-search="false"
    >
      <template #col-created_at="{ item }">
        <ag-div
          >{{
            item.raw.created_at
              ? getFormattedDateTime(
                  item.raw.created_at,
                  FORMAT_YYY_MM_DD_HH_SS_A
                )
              : "NA"
          }}
        </ag-div>
      </template>
      <template #col-log_type="{ item }">
        <ag-div class="color_chip green">{{ item.raw.log_type }} </ag-div>
      </template>
      <template #col-requestData="{ item }">
        <AGButton
          :test-id="item.raw.request_data"
          variant="primary"
          type="button"
          @click="toggleExpandedViewRequest(item)"
        >
          View More
        </AGButton>
      </template>
      <template #col-responseData="{ item }">
        <AGButton
          :test-id="item.raw.response_data"
          variant="primary"
          type="button"
          @click="toggleExpandedViewResponse(item)"
        >
          View More
        </AGButton>
      </template>
    </AgTable>
    <AgNotFound
      v-else
      test-id=""
      heading="No PNR Details Found"
      description="Please Try Modify Your PNR"
    />
  </ag-div>
  <ag-modal
    :is-open="isResponseOpen"
    @close="closeResponseModal"
    modal-width="70%"
  >
    <template #body>
      <pre>{{ selectedItemResponse.raw.response_data }}</pre>
    </template>
  </ag-modal>
  <ag-modal
    :is-open="isRequestOpen"
    @close="closeRequestModal"
    modal-width="70%"
  >
    <template #body>
      <pre>{{ selectedItemRequest.raw.request_data }}</pre>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MyBookingsService from "@/modules/MyBookings/services/myBookings.service";
import {
  getFormattedDateTime,
  formatStringToRoutePath,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import notificationService from "@/ag-portal-common/services/notification.service";
import { FORMAT_YYY_MM_DD_HH_SS_A } from "@/ag-portal-common/constants/dateTimeFormats";
import { PATH } from "@/ag-portal-common/constants/path";

export default defineComponent({
  name: "IssuanceLogs",

  async created() {
    this.pnrValue = this.$route.params.pnr as string;
    this.apiResponse = null;
    if (this.haveProperLength(this.pnrValue)) {
      await this.onViewIssuanceLogs();
    }
  },

  methods: {
    getFormattedDateTime,
    formatStringToRoutePath,
    haveProperLength(value: string) {
      return value.length >= 6;
    },
    haveData() {
      if (this.apiResponse === "[]") {
        return false;
      } else {
        return true;
      }
    },
    closeRequestModal() {
      this.isRequestOpen = false;
    },
    async buttonClick() {
      if (this.haveProperLength(this.inputPNR)) {
        //
        this.pnrValue = this.inputPNR;

        formatStringToRoutePath(PATH.ISSUANCE_LOGS, { id: this.pnrValue });
        await this.onViewIssuanceLogs();
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Enter A Valid PNR";
        notificationService.triggerNotification();
      }
    },
    closeResponseModal() {
      this.isResponseOpen = false;
    },
    async onViewIssuanceLogs() {
      this.isLoading = true;
      const myBookingService = new MyBookingsService();
      const response = await myBookingService.fetchIssuanceLogs(this.pnrValue);
      this.apiResponse = response.data;
      this.isLoading = false;
    },
    toggleExpandedViewRequest(item: any) {
      if (this.selectedItemRequest === item) {
        this.selectedItemRequest = null;
        this.isRequestOpen = false;
      } else {
        this.selectedItemRequest = item;
        this.isRequestOpen = true;
      }
    },
    toggleExpandedViewResponse(item: any) {
      if (this.selectedItemResponse === item) {
        this.selectedItemResponse = null;
        this.isResponseOpen = false;
      } else {
        this.selectedItemResponse = item;
        this.isResponseOpen = true;
      }
    },
  },

  computed: {
    FORMAT_YYY_MM_DD_HH_SS_A() {
      return FORMAT_YYY_MM_DD_HH_SS_A;
    },
  },

  data() {
    return {
      isRequestOpen: false,
      isResponseOpen: false,
      isLoading: false,
      pnrValue: "",
      apiResponse: null,
      selectedItemRequest: null,
      selectedItemResponse: null,
      inputPNR: "",
      tableHeaders: [
        {
          title: "Created At",
          value: "created_at",
          key: "created_at",
          sortable: false,
        },
        {
          title: "Log Type",
          value: "log_type",
          key: "log_type",
          sortable: false,
        },
        {
          title: "Request Data",
          value: "requestData",
          key: "requestData",
          sortable: false,
        },
        {
          title: "Response Data",
          value: "responseData",
          key: "responseData",
          sortable: false,
        },
      ],
    };
  },
});
</script>
