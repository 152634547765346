<template>
  <ag-modal :is-open="isOpen" @close="toggleModal" modal-width="40%">
    <template #header>
      <v-card-title>Link Financial Profile</v-card-title>
      <a-g-button
        type="button"
        class="modal_close_icon"
        variant="link"
        :testId="
          genTestId(ELEMENT_TYPES.BUTTON, 'link-financial-profile-modal-close')
        "
        @click="toggleModal"
        >Close
      </a-g-button>
    </template>
    <template #body>
      <agent-auto-complete @onChange="onChangeProfile" />
      <hr />
      <ag-checkbox
        :testId="
          genTestId(
            ELEMENT_TYPES.CHECK_BOX,
            'link-financial-profile-modal-isConfirmed'
          )
        "
        v-if="showConfirmCheck"
        v-model="isConfirmed"
        :label="isConfirmedLabel"
      />
      <hr />
      <a-g-button
        :disabled="
          !selectedFinancialProfile || $store.getters.isLinkingFinancialProfile
        "
        :testId="
          genTestId(ELEMENT_TYPES.BUTTON, 'link-financial-profile-modal-submit')
        "
        @click="onLink"
        :is-loading="$store.getters.isLinkingFinancialProfile"
        >Link
      </a-g-button>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AgentAutoComplete from "@/components/AgentAutoComplete.vue";
import { LinkOrganizationDto } from "@/modules/FinancialProfile/dtos/linkOrganization.dto";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";

export default defineComponent({
  name: "LinkFinancialProfileModal",
  components: { AgentAutoComplete },
  beforeMount() {
    this.$store.dispatch("fetchFinancialProfiles");
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    organizationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ELEMENT_TYPES,
      selectedFinancialProfile: "",
      isConfirmed: false,
      isConfirmedLabel:
        "This financial profile is already linked with another organization. Are you sure you want to overwrite?",
      showConfirmCheck: false,
    };
  },
  methods: {
    genTestId,
    toggleModal() {
      this.$emit("toggle");
    },
    onChangeProfile(value: string) {
      this.selectedFinancialProfile = value;
    },
    enableConfirmationCheckBox() {
      this.showConfirmCheck = true;
    },
    onLink() {
      const body: LinkOrganizationDto = {
        organization: this.organizationId,
        confirmed: this.isConfirmed,
      };
      this.$store.dispatch("linkFinancialProfileWithOrganization", {
        body,
        params: {
          financial_data: "show",
        },
        failureCallback: this.enableConfirmationCheckBox,
        successCallback: this.toggleModal,
        financialProfileId: this.selectedFinancialProfile,
      });
    },
  },
});
</script>
