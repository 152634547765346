<template>
  <AgAgentListInfoFilterForm @fetchOrganization="fetchOrganization" />

  <ag-div
    v-for="(profile, index) in $store.getters.organization?.financial_profiles"
    :key="index"
  >
    <financial-profile-box :financial-profile="profile" />
  </ag-div>

  <organization-and-agent-list
    :email="searchedEmail"
    :organization="$store.getters.organization"
  />

  <ag-loader v-if="$store.getters.isFetchingFinancialProfiles" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FinancialProfileBox from "@/modules/Agent/components/FinancialProfileBox.vue";
import AgAgentListInfoFilterForm from "@/modules/Agent/components/AgentListInfoFilterForm.vue";
import OrganizationAndAgentList from "@/modules/Agent/components/OrganizationAndAgentList.vue";
import { IOrganizationDetail } from "@/ag-portal-common/interfaces/organizationDetail.interface";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { GetFinancialProfileDto } from "@/modules/FinancialProfile/dtos/financialProfile.dto";

export default defineComponent({
  name: "AgentListView",
  components: {
    OrganizationAndAgentList,
    FinancialProfileBox,
    AgAgentListInfoFilterForm,
  },
  data(): {
    organization: IOrganizationDetail | null;
    searchedEmail?: string | null;
  } {
    return {
      organization: null,
      searchedEmail: null,
    };
  },
  beforeMount() {
    this.$store.dispatch("fetchFinancialProfiles", {
      is_linked: true,
    } as GetFinancialProfileDto);
    this.$store.dispatch("resetOrganization");
  },
  computed: {},
  methods: {
    fetchOrganization(id?: string, email?: string) {
      this.searchedEmail = email;
      if (email) {
        this.$store.dispatch("fetchOrganization", email);
      } else {
        const financialProfile: IFinancialProfile =
          this.$store.getters.financialProfiles.find(
            (item: IFinancialProfile) => item.public_id === id
          );

        if (financialProfile) {
          this.$store.dispatch(
            "fetchOrganization",
            financialProfile.organization_public_id
          );
        }
      }
    },
  },
});
</script>
