import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DurationSegment = _resolveComponent("DurationSegment")!

  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.getDuration().days)
      ? (_openBlock(), _createBlock(_component_DurationSegment, {
          key: 0,
          title: "day",
          duration: _ctx.getDuration().days,
          boldNums: _ctx.boldNums
        }, null, 8, ["duration", "boldNums"]))
      : _createCommentVNode("", true),
    (_ctx.getDuration().hours)
      ? (_openBlock(), _createBlock(_component_DurationSegment, {
          key: 1,
          title: "hr",
          duration: _ctx.getDuration().hours,
          boldNums: _ctx.boldNums
        }, null, 8, ["duration", "boldNums"]))
      : _createCommentVNode("", true),
    (_ctx.getDuration().minutes)
      ? (_openBlock(), _createBlock(_component_DurationSegment, {
          key: 2,
          title: "min",
          duration: _ctx.getDuration().minutes,
          boldNums: _ctx.boldNums
        }, null, 8, ["duration", "boldNums"]))
      : _createCommentVNode("", true)
  ]))
}