<template>
  <AgAutoComplete
    v-model="searchValue"
    type="text"
    :testId="
      genTestId(ELEMENT_TYPES.TEXT_FIELD, 'agent-autocomplete-searchValue')
    "
    icon="calendar"
    :items="filterProfiles"
    :onkeyup="onChangeSearchField"
    @update:modelValue="onUpdateModelValue"
    label="Customer"
    :is-loading="isFetchingAgents"
    class="agent-feild"
    :clearable="false"
  >
    <template #dropdownItem="{ itemProps, itemData }">
      <AgTextFieldDropDownItem
        v-bind="itemProps"
        :testId="
          genTestId(
            ELEMENT_TYPES.SELECT_FIELD,
            `agent-autocomplete-${itemData.value}`
          )
        "
      >
        <p>{{ itemData.label }}</p>
      </AgTextFieldDropDownItem>
    </template>
  </AgAutoComplete>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";

export default defineComponent({
  name: "AgentAutoComplete",
  data() {
    return {
      searchValue: "",
      filteredProfiles: [],
      ELEMENT_TYPES,
    };
  },
  methods: {
    genTestId,
    onChangeSearchField(e: any) {
      this.searchValue = e.target.value;
    },
    onUpdateModelValue(value: string) {
      this.$emit("onChange", value);
      localStorage.setItem("fp", value);
    },
  },
  computed: {
    filterProfiles() {
      let filteredProfiles = this.$store.getters.financialProfiles;
      if (this.searchValue) {
        filteredProfiles = this.$store.getters.financialProfiles.filter(
          (item: IFinancialProfile) =>
            item.financial_profile_name
              .toLowerCase()
              .includes(this.searchValue.toLowerCase()) ||
            item?.platform_id
              ?.toLowerCase()
              .includes(this.searchValue.toLowerCase()) ||
            item?.public_id
              ?.toLowerCase()
              .includes(this.searchValue.toLowerCase())
        );
      }

      filteredProfiles = filteredProfiles
        .map((item: IFinancialProfile, i: number) => ({
          label: `${item.financial_profile_name} - ${item.platform_id}`,
          value: item.public_id,
        }))
        .slice(0, 100);
      return filteredProfiles;
    },
    isFetchingAgents() {
      return this.$store.getters.isFetchingAgents;
    },
  },
});
</script>
