<template>
  <ag-modal scrollable :is-open="isOpen" @close="closeDialog" modal-width="30%">
    <template #header>
      <v-card-title>Whatsapp Member Authorization</v-card-title>
      <a-g-button
        type="button"
        class="modal_close_icon"
        variant="link"
        @click="closeDialog"
        >Close
      </a-g-button>
    </template>
    <template #body>
      <div>
        <p class="instruction">{{ title }}</p>
        <div class="otp-input">
          <v-text-field
            class="digit-input"
            v-for="(_, index) in otpDigits"
            :key="index"
            type="text"
            v-model="otpDigits[index]"
            maxlength="1"
            @keydown="handleInput(index, $event)"
            @paste="handlePaste"
            :ref="renderOTPRef(index)"
          />
        </div>
        <p v-show="error" class="error">{{ error }}</p>

        <div class="action-buttons">
          <AGButton
            test-id=""
            @click="submitOTP"
            :is-loading="isSubmitLoading"
            variant="primary"
            type="button"
            >Submit
          </AGButton>
        </div>
      </div>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "OTPInputModal",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Please enter the OTP sent to your registered email address",
    },
    isSubmitLoading: {
      type: Boolean,
      default: false,
    },
  },
  data(): { otpDigits: Array<string>; error: string | null } {
    return {
      otpDigits: ["", "", "", "", "", ""],
      error: "",
    };
  },
  methods: {
    renderOTPRef(index: number) {
      return `otpRef-${index}`;
    },
    closeDialog() {
      this.otpDigits = ["", "", "", "", "", ""];
      this.$emit("handleCloseDialog");
    },
    handleInput(index: number, event: KeyboardEvent) {
      this.error = "";
      if (event.key === "Backspace") {
        // Delete the current digit and move focus to the previous input field
        if (index > 0) {
          this.otpDigits[index] = "";
          const ele = this.$refs["otpRef-" + (index - 1)] as HTMLInputElement[];
          ele[0].focus();
        }
      } else if (this.otpDigits[index]) {
        // Move focus to the next input field
        if (index < this.otpDigits.length - 1) {
          const ele = this.$refs["otpRef-" + (index + 1)] as HTMLInputElement[];
          ele[0].focus();
        } else {
          // Focus out from the last input field
          const ele = this.$refs["otpRef-" + index] as HTMLInputElement[];
          ele[0].blur();
        }
      }
    },
    handlePaste(event: ClipboardEvent) {
      event.preventDefault();
      const pastedText = event.clipboardData?.getData("text/plain") || "";
      const otpArray = pastedText.trim().split("");
      otpArray.forEach((digit, index) => {
        if (index < this.otpDigits.length) {
          this.otpDigits[index] = digit;
        }
      });
      this.$nextTick(() => {
        const lastInput = this.$refs[
          `otpRef-${otpArray.length - 1}`
        ] as HTMLInputElement[];
        lastInput[0].blur();
      });
    },
    submitOTP() {
      const otp = this.otpDigits.join("").replace(/^\s+|\s+$/g, "");
      if (otp.length === 6) {
        this.$emit("submitOtp", otp);
      } else {
        this.error = "Please enter all digits of OTP";
      }
    },
  },
});
</script>

<style lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: #8e2121;
  margin-bottom: 15px;
}

.instruction {
  text-align: center;
  margin-bottom: 15px;
}

.otp-input {
  display: flex;
  justify-content: center;
}

.digit-input {
  width: 40px;
  height: 45px;
  text-align: center;
  margin: 10px 5px;
  background: #bababa;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  input {
    text-align: center;
  }
}

.error {
  font-weight: bold;
  color: red;
  margin-top: 10px;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
