import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_g_text_field = _resolveComponent("a-g-text-field")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_select = _resolveComponent("ag-select")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_ag_card = _resolveComponent("ag-card")!

  return (_openBlock(), _createBlock(_component_ag_card, null, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
      }, [
        _createVNode(_component_ag_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ag_column, {
              sm: "12",
              lg: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_g_text_field, {
                  testId: 
              _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT_FIELD, 'organization-form-name')
            ,
                  label: "Name",
                  modelValue: _ctx.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                  value: _ctx.name,
                  error: _ctx.errors['name']
                }, null, 8, ["testId", "modelValue", "value", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              lg: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_select, {
                  label: "Sector Name",
                  testId: 
              _ctx.genTestId(_ctx.ELEMENT_TYPES.SELECT_FIELD, 'organization-form-sector')
            ,
                  items: _ctx.sectors,
                  modelValue: _ctx.sector,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sector) = $event)),
                  value: _ctx.sector,
                  error: _ctx.errors['sector'],
                  variant: "outlined",
                  onChange: _ctx.updateRelatedPersons
                }, null, 8, ["testId", "items", "modelValue", "value", "error", "onChange"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ag_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ag_column, {
              sm: "12",
              lg: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_select, {
                  label: "PIA Provider",
                  testId: 
              _ctx.genTestId(
                _ctx.ELEMENT_TYPES.SELECT_FIELD,
                'organization-form-pia_provider'
              )
            ,
                  items: _ctx.apiResponse?.pia_provider,
                  modelValue: _ctx.pia_provider,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pia_provider) = $event)),
                  value: _ctx.pia_provider,
                  error: _ctx.errors['pia_provider'],
                  variant: "outlined"
                }, null, 8, ["testId", "items", "modelValue", "value", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              lg: "6",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_g_text_field, {
                  label: "Phone",
                  testId: 
              _ctx.genTestId(
                _ctx.ELEMENT_TYPES.TEXT_FIELD,
                'organization-form-contact_person_phone'
              )
            ,
                  modelValue: _ctx.contact_person_phone,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.contact_person_phone) = $event)),
                  value: _ctx.contact_person_phone,
                  error: _ctx.errors['contact_person_phone']
                }, null, 8, ["testId", "modelValue", "value", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              lg: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_g_text_field, {
                  type: "email",
                  label: "Email",
                  testId: 
              _ctx.genTestId(
                _ctx.ELEMENT_TYPES.SELECT_FIELD,
                'organization-form-contact_person_email'
              )
            ,
                  modelValue: _ctx.contact_person_email,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.contact_person_email) = $event)),
                  value: _ctx.contact_person_email,
                  error: _ctx.errors['contact_person_email']
                }, null, 8, ["testId", "modelValue", "value", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              lg: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_select, {
                  label: "Currency",
                  testId: 
              _ctx.genTestId(
                _ctx.ELEMENT_TYPES.SELECT_FIELD,
                'organization-form-currency'
              )
            ,
                  items: _ctx.apiResponse?.currency,
                  modelValue: _ctx.currency,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.currency) = $event)),
                  value: _ctx.currency,
                  error: _ctx.errors['currency'],
                  variant: "outlined"
                }, null, 8, ["testId", "items", "modelValue", "value", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              sm: "12",
              lg: "6"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_ag_select, {
                  label: "Related Person",
                  testId: 
              _ctx.genTestId(
                _ctx.ELEMENT_TYPES.SELECT_FIELD,
                'organization-form-related_person'
              )
            ,
                  items: _ctx.relatedPersons,
                  modelValue: _ctx.relatedPerson,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.relatedPerson) = $event)),
                  value: _ctx.relatedPerson,
                  error: _ctx.errors['relatedPerson'],
                  variant: "outlined"
                }, null, 8, ["testId", "items", "modelValue", "value", "error"]), [
                  [_vShow, _ctx.sector]
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_g_button, {
          type: "submit",
          isLoading: _ctx.onLoading,
          testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'organization-form-submit'),
          disabled: _ctx.onLoading
        }, {
          default: _withCtx(() => [
            _createTextVNode("Submit ")
          ]),
          _: 1
        }, 8, ["isLoading", "testId", "disabled"])
      ], 32)
    ]),
    _: 1
  }))
}