import { MutationTree } from "vuex";
import { ICreditLimitManagementState } from "@/interfaces/creditLimitManagementState.interface";
import { IPaginatedCreditLimitRequests } from "@/interfaces/creditLimitRequest.interface";
import { ICreditLimitRequestDetail } from "@/interfaces/creditLimitRequestDetail.interface";

const mutations: MutationTree<ICreditLimitManagementState> = {
  saveCreditLimitRequests(
    state: ICreditLimitManagementState,
    payload: IPaginatedCreditLimitRequests
  ) {
    state.creditLimitRequests = payload;
    state.isFetchingCreditLimitRequests = false;
  },
  saveCreditLimitRequestDetail(
    state: ICreditLimitManagementState,
    payload: ICreditLimitRequestDetail
  ) {
    state.creditLimitRequestDetail = payload;
    state.isFetchingCreditLimitRequestDetail = false;
  },
  enableIsFetchCreditLimitRequests(state: ICreditLimitManagementState) {
    state.isFetchingCreditLimitRequests = true;
  },
  enableIsCreatingCreditLimitRequests(state: ICreditLimitManagementState) {
    state.isCreatingCreditLimitRequest = true;
  },
  enableIsFetchingCreditLimitRequestDetail(state: ICreditLimitManagementState) {
    state.creditLimitRequestDetail = null;
    state.isFetchingCreditLimitRequestDetail = true;
  },
  disableIsCreatingCreditLimitRequests(state: ICreditLimitManagementState) {
    state.isCreatingCreditLimitRequest = false;
  },
  enableIsProcessingCreditLimitRequest(state: ICreditLimitManagementState) {
    state.isProcessingCreditLimitRequest = true;
  },
  disableIsProcessingCreditLimitRequest(state: ICreditLimitManagementState) {
    state.isProcessingCreditLimitRequest = false;
  },
};

export default mutations;
