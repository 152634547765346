<template>
  <ag-card>
    <ag-heading variant="h2" title="Select Pricing" />
    <ag-row class="soa_main_wrapper">
      <ag-column xs="12" md="7" lg="9" cols="12">
        <AgAutoComplete
          testId=""
          v-model="financialProfileId"
          label="Financial Profiles"
          :items="filteredProfiles"
          :clearable="true"
          :isLoading="isFetchingFinancialProfiles"
          class="user-input"
          :isdisabled="isFetchingFinancialProfiles || isLoading"
          @keyup="onChangeFPInputHandler"
        >
          <template #dropdownItem="{ itemProps, itemData }">
            <div class="item" v-bind="itemProps">{{ itemData.label }}</div>
          </template>
        </AgAutoComplete>
      </ag-column>
      <ag-column
        xs="12"
        md="5"
        lg="3"
        cols="12"
        class="d-flex align-items-center"
      >
        <AGButton
          @click="onFetchAgPricing"
          :disabled="disableButtons"
          :testId="genTestId(ELEMENT_TYPES.BUTTON, 'get-AgPricing')"
          :isdisabled="isFetchingFinancialProfiles || !canFetch() || isLoading"
          class="sb-btn user-input"
        >
          {{ isFetchingFinancialProfiles ? "Loading" : "Fetch Pricing" }}
        </AGButton>
      </ag-column>
    </ag-row>
  </ag-card>
  <ag-card v-if="pricings.length">
    <AgTable
      :headers="tableHeaders"
      :items="pricings"
      :has-pagination="true"
      :items-per-page="100"
      :has-search="true"
      :simpleDataTable="true"
    >
      <template #col-sales_channel="{ item }">
        <AgDiv class="table-column-width">{{ item.raw.sales_channel }}</AgDiv>
      </template>
      <template #col-airline="{ item }">
        <AgDiv class="table-column-width">{{ item.raw.airline }}</AgDiv>
      </template>
      <template #col-apply_on_gross_fare="{ item }">
        <AgDiv class="table-column-width">{{
          item.raw.apply_on_gross_fare ? "✅" : "❌"
        }}</AgDiv>
      </template>
      <template #col-sale_on_and_after="{ item }">
        <AgDiv class="table-column-width">{{
          item.raw.sale_on_and_after ? item.raw.sale_on_and_after : "-"
        }}</AgDiv>
      </template>
      <template #col-sale_on_and_before="{ item }">
        <AgDiv class="table-column-width">{{
          item.raw.sale_on_and_before ? item.raw.sale_on_and_before : "-"
        }}</AgDiv>
      </template>
      <template #col-travel_on_and_after="{ item }">
        <AgDiv class="table-column-width">{{
          item.raw.travel_on_and_after ? item.raw.travel_on_and_after : "-"
        }}</AgDiv>
      </template>
      <template #col-travel_on_and_before="{ item }">
        <AgDiv class="table-column-width">{{
          item.raw.travel_on_and_before ? item.raw.travel_on_and_before : "-"
        }}</AgDiv>
      </template>
      <template #col-remarks="{ item }">
        <AgDiv class="table-column-width">{{
          item.raw.remarks ? item.raw.remarks : "-"
        }}</AgDiv>
      </template>
      <template #col-rbds="{ item }">
        <AgDiv>{{ displayRbds(item.raw.rbds) }}</AgDiv>
      </template>
    </AgTable>
  </ag-card>
  <AgNotFound v-else test-id="" heading="No Ag Pricing Found" description="" />
</template>

<script lang="ts">
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { defineComponent, inject } from "vue";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";

import analyticsService from "@/analytic.service";
import AgPricingService from "@/modules/AgPricing/services/agPricing.service";
import notificationService from "@/ag-portal-common/services/notification.service";

import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { AG_PRICING_ANALYTICS_EVENTS } from "@/modules/AgPricing/constants/anaylticsEvents";

export default defineComponent({
  name: "AgentPricing",
  data() {
    return {
      ELEMENT_TYPES,
      financialProfileId: "",
      disableButtons: false,
      isLoading: false,
      pricings: [],
      tableHeaders: [
        {
          title: "Sales Channel",
          value: "sales_channel",
          key: "sales_channel",
          sortable: true,
        },
        {
          title: "Airline",
          value: "airline",
          key: "airline",
          sortable: true,
        },
        {
          title: "Pricing",
          value: "pricing",
          key: "pricing",
          sortable: true,
        },
        {
          title: "Region",
          value: "region",
          key: "region",
          sortable: true,
        },
        {
          title: "apply on gross fare",
          value: "apply_on_gross_fare",
          key: "apply_on_gross_fare",
          sortable: true,
        },
        {
          title: "rbds",
          value: "rbds",
          key: "rbds",
          sortable: true,
        },
        {
          title: "sale on and after",
          value: "sale_on_and_after",
          key: "sale_on_and_after",
          sortable: true,
        },
        {
          title: "sale on and before",
          value: "sale_on_and_before",
          key: "sale_on_and_before",
          sortable: true,
        },
        {
          title: "travel on and after",
          value: "travel_on_and_after",
          key: "travel_on_and_after",
          sortable: true,
        },
        {
          title: "travel on and before",
          value: "travel_on_and_before",
          key: "travel_on_and_before",
          sortable: true,
        },
        {
          title: "remarks",
          value: "remarks",
          key: "remarks",
          sortable: true,
        },
      ],
    };
  },
  async created() {
    await this.$store.dispatch("fetchFinancialProfiles");
  },
  computed: {
    filteredProfiles(): any[] {
      let filteredFinancialProfiles = this.$store.getters.financialProfiles;

      if (this.financialProfileId) {
        filteredFinancialProfiles =
          this.$store.getters.financialProfiles.filter(
            (item: IFinancialProfile) =>
              this.isIncluded(
                item.financial_profile_name,
                this.financialProfileId
              ) || this.isIncluded(item.public_id, this.financialProfileId)
          );
      }

      filteredFinancialProfiles = filteredFinancialProfiles
        .map((item: IFinancialProfile) => ({
          label: item.financial_profile_name,
          value: item.public_id,
        }))
        .slice(0, 10);

      return filteredFinancialProfiles;
    },
    isFetchingFinancialProfiles(): boolean {
      return this.$store.getters.isFetchingFinancialProfiles;
    },
  },
  methods: {
    genTestId,
    displayRbds(rbds: []): string {
      if (rbds.length === 0) {
        return "-";
      } else {
        return rbds.join(" ").toUpperCase();
      }
    },
    async onFetchAgPricing(): Promise<void> {
      this.pricings = [];
      this.isLoading = true;

      this.trackAnalytics();

      try {
        const service = new AgPricingService();
        const response = await service.get(this.financialProfileId);
        this.pricings = response.data;
        this.isLoading = false;
      } catch {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Error while fetching ag-pricing";
        notificationService.triggerNotification();
      }
    },
    isIncluded(source: string, target: string): boolean {
      return source.toLowerCase().includes(target.toLowerCase());
    },
    canFetch(): boolean {
      const isOrganizationSelected =
        this.financialProfileId === "" || this.financialProfileId === null;
      return !isOrganizationSelected;
    },
    trackAnalytics(): void {
      const payload = {
        "user-role": this.user().role_unique_id,
        "financial-profile-id": this.financialProfileId,
      };
      analyticsService.logActionEvent(
        AG_PRICING_ANALYTICS_EVENTS.FETCH_AG_PRICING,
        payload
      );
    },
    onChangeFPInputHandler(event: InputEvent): void {
      this.financialProfileId = (event.target as HTMLInputElement).value;
      localStorage.setItem("fp", this.financialProfileId);
    },
  },
  setup() {
    const user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2;
    return { user };
  },
});
</script>

<style lang="scss" scoped>
.table-column-width {
  min-width: 150px;
}
</style>
