<template>
  <AgAutoComplete
    v-model="searchValue"
    type="text"
    :testId="
      genTestId(
        ELEMENT_TYPES.TEXT_FIELD,
        'organization-autocomplete-searchValue'
      )
    "
    :items="filterOrganizations"
    :onkeyup="onChangeSearchField"
    @update:modelValue="onUpdateModelValue"
    label="Organizations"
    :is-loading="isFetchingOrganizations"
    class="organization-feild"
    :clearable="false"
  >
    <template #dropdownItem="{ itemProps, itemData }">
      <AgTextFieldDropDownItem
        v-bind="itemProps"
        :testId="
          genTestId(
            ELEMENT_TYPES.SELECT_FIELD,
            `organization-autocomplete-${itemData.value}`
          )
        "
      >
        <p>{{ itemData.label }}</p>
      </AgTextFieldDropDownItem>
    </template>
  </AgAutoComplete>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IOrganization } from "@/ag-portal-common/interfaces/organization.interface";

export default defineComponent({
  name: "OrganizationAutoComplete",
  data() {
    return {
      searchValue: "",
      ELEMENT_TYPES,
    };
  },
  methods: {
    genTestId,
    onChangeSearchField(e: any) {
      this.searchValue = e.target.value;
    },
    onUpdateModelValue(value: string) {
      this.$emit("onChange", value);
    },
  },
  computed: {
    filterOrganizations() {
      let filterOrganizations = this.$store.getters.organizations;
      if (this.searchValue) {
        filterOrganizations = this.$store.getters.organizations.filter(
          (item: IOrganization) =>
            item.name.toLowerCase().includes(this.searchValue.toLowerCase()) ||
            item?.public_id
              ?.toLowerCase()
              .includes(this.searchValue.toLowerCase())
        );
      }

      filterOrganizations = filterOrganizations
        .map((item: IOrganization, i: number) => ({
          label: item.name,
          value: item.public_id,
        }))
        .slice(0, 100);
      return filterOrganizations;
    },
    isFetchingOrganizations() {
      return this.$store.getters.isFetchingOrganizations;
    },
  },
});
</script>
