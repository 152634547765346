import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrganizationForm = _resolveComponent("OrganizationForm")!

  return (_ctx.$store.getters.organization)
    ? (_openBlock(), _createBlock(_component_OrganizationForm, {
        key: 0,
        organizationData: _ctx.$store.getters.organization,
        onOnSubmitOrganizationForm: _ctx.updateOrganization,
        onLoading: _ctx.$store.getters.isUpdatingOrganization
      }, null, 8, ["organizationData", "onOnSubmitOrganizationForm", "onLoading"]))
    : _createCommentVNode("", true)
}