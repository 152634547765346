<template>
  <AgCard class="sector-snapshot-header">
    <ag-loader v-if="$store.getters.isFetchingSectors" />
    <template v-else>
      <AgDiv class="autocomplete-container">
        <p class="label">Select Sector</p>
        <ag-select
          label="Sector Name"
          :items="sectors"
          v-model="sector"
          :value="sector"
          variant="outlined"
          @change="handleSectorChange"
        />
      </AgDiv>
      <ag-loader v-if="$store.getters.isSectorStatsLoading" />
      <template v-else>
        <template v-if="showDetails">
          <!-- General Stats Card -->
          <AgCard class="card-wrapper">
            <p class="heading">General Stats</p>
            <hr />
            <ag-row>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Enabled Websites"
                  icon="provider"
                  :value="sectorStats?.aggregates?.enabled_website_count"
                />
              </ag-column>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Disabled Websites"
                  icon="provider"
                  :value="sectorStats?.aggregates?.disabled_website_count"
                />
              </ag-column>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Logo Added"
                  icon="provider"
                  :value="sectorStats?.aggregates?.logo_added_count"
                />
              </ag-column>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Color Added"
                  icon="provider"
                  :value="sectorStats?.aggregates?.color_added_count"
                />
              </ag-column>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Default Color"
                  icon="provider"
                  :value="sectorStats?.aggregates?.default_color_count"
                />
              </ag-column>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Default Logo"
                  icon="provider"
                  :value="sectorStats?.aggregates?.default_logo_count"
                />
              </ag-column>
            </ag-row>
          </AgCard>

          <!-- Users Card -->
          <AgCard class="card-wrapper">
            <p class="heading">Users</p>
            <hr />
            <ag-row>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Owners"
                  icon="customerStatus"
                  :value="sectorStats?.users?.owners"
                />
              </ag-column>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Employees"
                  icon="customerStatus"
                  :value="sectorStats?.users?.employees"
                />
              </ag-column>
              <ag-column xs="12" sm="12" md="6" lg="3">
                <ag-agent-info-box
                  title="Sub Agents"
                  icon="customerStatus"
                  :value="sectorStats?.users?.sub_agents"
                />
              </ag-column>
            </ag-row>
          </AgCard>

          <!-- Organization Card -->
          <AgCard class="card-wrapper">
            <p class="heading">Organizations</p>
            <hr />
            <AgTable
              v-if="showOrganizationTable"
              :headers="headers"
              :items="sectorStats.organizations"
              :items-per-page="10"
              :has-pagination="false"
              :simpleDataTable="true"
              :hasSearch="true"
              @onClickRow="handleRowClick"
            >
              <template #col-logo="{ item }">
                <AgDiv><img width="60" :src="item.raw.logo" /></AgDiv>
              </template>
              <template #col-org_color="{ item }">
                <AgDiv>
                  <ag-color-chip
                    :color-name="item.raw.org_color"
                    :color-code="item.raw.org_color"
                  />
                </AgDiv>
              </template>
              <template #col-name="{ item }">
                <AgDiv class="name-column">{{ item.raw.name }}</AgDiv>
              </template>
              <template #col-domain_name="{ item }">
                <AgDiv>{{
                  item.raw.domain_name ? item.raw.domain_name : "N/A"
                }}</AgDiv>
              </template>
              <template #col-display_name="{ item }">
                <AgDiv>{{
                  item.raw.display_name ? item.raw.display_name : "N/A"
                }}</AgDiv>
              </template>
            </AgTable>
            <AgNotFound v-else test-id="" heading="Organizations not found" />
          </AgCard>
        </template>
        <AgNotFound v-else test-id="" heading="Sector Details Not Found" />
      </template>
    </template>
  </AgCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "lodash";

import { formatQueryPath } from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";
import { ISector } from "@/ag-portal-common/interfaces/sector.interface";
import { ISectorStatistics } from "@/ag-portal-common/interfaces/sectorProductivity.interface";
import { IHeading } from "@/ag-portal-common/interfaces/header.interface";

export default defineComponent({
  name: "OrganizationSnapshotAnalyticsTab",
  data(): {
    sector: number | null;
    headers: IHeading[];
  } {
    return {
      sector: null,
      headers: [
        {
          title: "Organization Name",
          value: "name",
          key: "name",
          sortable: true,
        },
        {
          title: "Owners",
          value: "owner_count",
          key: "owner_count",
          sortable: true,
        },
        {
          title: "Sub Agents",
          value: "sub_agent_count",
          key: "sub_agent_count",
          sortable: true,
        },
        {
          title: "Employees",
          value: "employee_count",
          key: "employee_count",
          sortable: true,
        },
        {
          title: "Logo",
          value: "logo",
          key: "logo",
          sortable: false,
        },
        {
          title: "Display Name",
          value: "display_name",
          key: "display_name",
          sortable: true,
        },
        {
          title: "Website Slugs",
          value: "website_slug",
          key: "website_slug",
          sortable: true,
        },
        {
          title: "Website Status",
          value: "website_status",
          key: "website_status",
          sortable: true,
        },
        {
          title: "Domain Name",
          value: "domain_name",
          key: "domain_name",
          sortable: true,
        },
        {
          title: "Organization Color",
          value: "org_color",
          key: "org_color",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    handleSectorChange() {
      if (this.sector) {
        const path = `${PATH.SECTOR_SNAPSHOT}${formatQueryPath({
          sector_id: this.sector,
        })}`;
        this.$router.push(path);
      }
    },
    fetchSectorStats() {
      const { sector_id } = this.$route.query;
      this.$store.dispatch("getSectorStats", sector_id);
    },
    handleRowClick(e: any, row: any) {
      const org_id = row?.item?.raw?.public_id;
      const url = this.$router.resolve({
        path: "/organization/snapshot",
        query: { org_id: org_id },
      });

      // Open the route in a new tab
      window.open(url.href, "_blank");
    },
  },
  computed: {
    sectors(): ISector[] {
      return (
        this.$store.getters.sectors?.map((item: ISector) => {
          return {
            label: item.sector_name,
            value: item.id,
          };
        }) || []
      );
    },
    sectorStats(): ISectorStatistics {
      return this.$store.getters.sectorStats;
    },
    showDetails(): boolean {
      const isNotEmpty = !_.isEmpty(this.sectorStats);
      return isNotEmpty;
    },
    showOrganizationTable(): boolean {
      const isLoading = this.$store.getters.isFetchingSectors;
      const organizations = this.$store.getters.sectorStats.organizations || [];
      return !isLoading && organizations.length > 0;
    },
  },
  watch: {
    "$route.query": {
      handler: function (value) {
        const isNotEmpty = !_.isEmpty(value);
        if (isNotEmpty) {
          this.fetchSectorStats();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch("fetchSectors");
    const { sector_id } = this.$route.query;
    if (sector_id) {
      this.sector = Number(sector_id);
    }
  },
});
</script>

<style lang="scss" scoped>
.sector-snapshot-header {
  .name-container {
    display: flex;
    align-items: center;
    img {
      width: 70px;
    }
    .agency-name-container {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .agency-name {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
        color: #000;
      }
      p {
        margin-bottom: 0;
        color: #6b7280;
      }
    }
  }
}

.autocomplete-container {
  max-width: 400px;
  .label {
    font-weight: bold;
  }
}

.heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}

.card-wrapper {
  margin-block: 30px;
  border: solid 1px var(--green-color);
  border-left: solid 8px var(--green-color);
}
.empty-items-message {
  text-align: center;
  margin-block: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #828282;
}
.color-chip {
  width: 120px;
}

.website-slug {
  font-weight: bold;
  color: #6b7280;
  &:hover {
    color: #0c47bc;
  }
}

.name-column {
  min-width: 210px;
}
</style>
