import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_ag_table = _resolveComponent("ag-table")!
  const _component_ag_not_found = _resolveComponent("ag-not-found")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_AgTabs = _resolveComponent("AgTabs")!
  const _component_ag_card = _resolveComponent("ag-card")!

  return (_openBlock(), _createBlock(_component_ag_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_AgTabs, {
        "test-id": "test",
        "panel-value": [1],
        class: "no_tab_icon"
      }, {
        TabHeading: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.CREDIT_LIMIT_REQUEST_STATUSES), (status, index) => {
            return (_openBlock(), _createBlock(_component_v_tab, {
              key: index,
              value: index,
              onClick: ($event: any) => (_ctx.onClickTab($event, _ctx.CREDIT_LIMIT_REQUEST_STATUSES[status]))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.formatWordIntoCapitalize(status.replaceAll("_", " "))), 1)
              ]),
              _: 2
            }, 1032, ["value", "onClick"]))
          }), 128))
        ]),
        TabDetail: _withCtx(() => [
          _createVNode(_component_ag_div, null, {
            default: _withCtx(() => [
              (_ctx.isFetchingCreditLimitRequests)
                ? (_openBlock(), _createBlock(_component_ag_loader, {
                    key: 0,
                    class: "padding_top_20"
                  }))
                : (
              !_ctx.isFetchingCreditLimitRequests && _ctx.creditLimitRequests.data.length
            )
                  ? (_openBlock(), _createBlock(_component_ag_table, {
                      key: 1,
                      headers: _ctx.headers,
                      items: _ctx.creditLimitRequests.data,
                      "total-page-count": _ctx.creditLimitRequests.totalPages,
                      page: _ctx.page,
                      onOnPageChange: _ctx.onPageChange,
                      onOnClickRow: _ctx.onItemClick,
                      "items-per-page": _ctx.itemsPerPage,
                      "has-pagination": true,
                      "has-search": false
                    }, {
                      "col-requestInitiatedAt": _withCtx(({ item }) => [
                        _createVNode(_component_ag_heading, {
                          title: 
                  _ctx.getFormattedDateTime(
                    item.raw.requestInitiatedAt,
                    _ctx.FORMAT_YYY_MM_DD
                  )
                ,
                          variant: "p",
                          class: "margin_bottom_0"
                        }, null, 8, ["title"]),
                        _createVNode(_component_ag_heading, {
                          class: "margin_bottom_0 text-color-green",
                          title: 
                  _ctx.getFormattedDateTime(
                    item.raw.requestInitiatedAt,
                    _ctx.FORMAT_HH_SS_A
                  )
                ,
                          variant: "p"
                        }, null, 8, ["title"])
                      ]),
                      "col-requestedAmount": _withCtx(({ item }) => [
                        _createTextVNode(_toDisplayString(_ctx.formatNumber(item.raw.requestedAmount)), 1)
                      ]),
                      _: 1
                    }, 8, ["headers", "items", "total-page-count", "page", "onOnPageChange", "onOnClickRow", "items-per-page"]))
                  : (_openBlock(), _createBlock(_component_ag_not_found, {
                      key: 2,
                      heading: _ctx.NOTIFICATION_MESSAGES.NO_RESULTS_FOUND,
                      description: _ctx.NOTIFICATION_MESSAGES.NO_RESULTS_FOUND_DESCRIPTION
                    }, null, 8, ["heading", "description"]))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}