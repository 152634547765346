<template>
  <ag-loader v-if="isFetchingCreditLimitRequestDetail" />

  <ag-div
    v-else-if="!isFetchingCreditLimitRequestDetail && creditLimitRequestDetail"
  >
    <ag-heading
      variant="h3"
      title="Credit Limit Request"
      class="margin_bottom_30"
    ></ag-heading>

    <ag-card>
      <ag-heading variant="h3" title="Agent Information"></ag-heading>
      <hr />
      <ag-row>
        <ag-column xs="12" md="4" cols="12">
          <ag-div class="margin_bottom_30">
            <ag-heading
              variant="label"
              title="Email"
              class="heading_label"
            ></ag-heading>

            <ag-heading
              variant="p"
              :title="creditLimitRequestDetail?.agentEmail || ''"
            ></ag-heading>
          </ag-div>
          <ag-div class="margin_bottom_30">
            <ag-heading
              variant="label"
              title="Sector"
              class="heading_label"
            ></ag-heading>

            <ag-heading
              variant="p"
              :title="creditLimitRequestDetail?.agentSectorName || ''"
            ></ag-heading>
          </ag-div>
        </ag-column>
        <ag-column xs="12" md="4" cols="12">
          <ag-div class="margin_bottom_30">
            <ag-heading
              variant="label"
              title="Requested Amount"
              class="heading_label"
            ></ag-heading>

            <ag-heading variant="p" :title="requestedAmount"></ag-heading>
          </ag-div>

          <ag-div class="margin_bottom_30">
            <ag-heading
              variant="label"
              title="Credit Days Amount"
              class="heading_label"
            ></ag-heading>

            <ag-heading variant="p" :title="requestedDays"></ag-heading>
          </ag-div>
          <ag-div class="margin_bottom_30">
            <ag-heading
              variant="label"
              title="Request Priority"
              class="heading_label"
            ></ag-heading>

            <ag-heading
              variant="p"
              :title="
                formatWordIntoCapitalize(
                  creditLimitRequestDetail?.priority || ''
                )
              "
            ></ag-heading>
          </ag-div>
        </ag-column>

        <ag-column xs="12" md="4" cols="12">
          <ag-div class="margin_bottom_30">
            <ag-heading
              variant="label"
              title="Request Status"
              class="heading_label"
            ></ag-heading>

            <ag-heading
              variant="p"
              :title="
                formatWordIntoCapitalize(creditLimitRequestDetail?.status || '')
              "
            ></ag-heading>
          </ag-div>
          <ag-div class="margin_bottom_30">
            <ag-heading
              variant="label"
              title="Agent Comments"
              class="heading_label"
            ></ag-heading>

            <ag-heading
              variant="p"
              :title="creditLimitRequestDetail?.requestNotes || 'N/A'"
            ></ag-heading>
          </ag-div>
        </ag-column>
      </ag-row>
    </ag-card>

    <ag-div>
      <ag-loader v-if="isFetchingFinancialProfile" />
      <financial-profile-box
        v-else-if="!isFetchingFinancialProfile && financialProfile"
        :financial-profile="financialProfile"
      />
    </ag-div>

    <ag-card v-if="creditLimitRequestDetail?.requestHistory?.length">
      <ag-heading variant="h3" title="Request History"></ag-heading>
      <ag-table
        :headers="headers"
        :items="creditLimitRequestDetail?.requestHistory || []"
        :items-per-page="creditLimitRequestDetail?.requestHistory?.length || 0"
        :has-pagination="false"
        :has-search="false"
      >
        <template #col-lastUpdatedAt="{ item }">
          <v-chip
            >{{
              getFormattedDateTime(
                item.raw.lastUpdatedAt,
                FORMAT_YYY_MM_DD_HH_SS_A
              )
            }}
          </v-chip>
        </template>
      </ag-table>
    </ag-card>

    <ag-card v-if="creditLimitRequestDetail?.canApprove">
      <ag-heading
        variant="h3"
        title="Sales Person Review"
        class="margin_bottom_30"
      ></ag-heading>
      <AgTextarea
        rows=""
        v-model="salesPersonComments"
        :value="salesPersonComments"
        label="Comments"
        error=""
        class="margin_bottom_20"
      ></AgTextarea>
      <AGButton
        test-id=""
        :is-loading="isProcessingCreditLimitRequest && approvedStatus"
        class="margin_right_10"
        @click="onProcessCreditLimitRequest(true)"
        >Approve
      </AGButton>
      <AGButton
        test-id=""
        :is-loading="isProcessingCreditLimitRequest && !approvedStatus"
        variant="danger"
        @click="onProcessCreditLimitRequest(false)"
        >Reject
      </AGButton>
    </ag-card>
  </ag-div>

  <ag-not-found
    v-else
    :title="NOTIFICATION_MESSAGES.NO_RESULTS_FOUND"
    :description="NOTIFICATION_MESSAGES.NO_RESULTS_FOUND_DESCRIPTION"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ICreditLimitRequestDetail } from "@/interfaces/creditLimitRequestDetail.interface";
import {
  formatNumber,
  formatWordIntoCapitalize,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import { FORMAT_YYY_MM_DD_HH_SS_A } from "../../../ag-portal-common/constants/dateTimeFormats";
import { ProcessCreditLimitRequestBody } from "@/modules/CreditLimitManagement/models/processCreditLimitRequestBody.request";
import { PATH } from "@/ag-portal-common/constants/path";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import FinancialProfileBox from "@/modules/Agent/components/FinancialProfileBox.vue";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { GetFinancialProfileDto } from "@/modules/FinancialProfile/dtos/financialProfile.dto";

export default defineComponent({
  name: "CreditLimitRequestDetail",
  components: { FinancialProfileBox },
  methods: {
    getFormattedDateTime,
    formatWordIntoCapitalize,
    onProcessCreditLimitRequest(status: boolean) {
      this.approvedStatus = status;
      let body: ProcessCreditLimitRequestBody = {
        comments: this.salesPersonComments,
        limit_request_id: this.creditLimitRequestDetail?.id || "",
        status,
      };

      this.$store.dispatch("processCreditLimitRequest", {
        body,
        callback: this.routeToCreditLimitManagement,
      });
    },
    routeToCreditLimitManagement() {
      this.$router.push(PATH.CREDIT_LIMIT_MANAGEMENT);
    },
  },
  data() {
    return {
      salesPersonComments: "",
      approvedStatus: false,
      headers: [
        {
          key: "approvedBy",
          title: "Approved By",
          align: "start",
          value: "approvedBy",
        },
        {
          key: "actionType",
          title: "Status",
          value: "actionType",
        },
        {
          key: "lastUpdatedAt",
          title: "Last Updated At",
          value: "lastUpdatedAt",
        },

        {
          key: "comments",
          title: "Comments",
          value: "comments",
        },
      ],
    };
  },
  computed: {
    NOTIFICATION_MESSAGES() {
      return NOTIFICATION_MESSAGES;
    },
    FORMAT_YYY_MM_DD_HH_SS_A() {
      return FORMAT_YYY_MM_DD_HH_SS_A;
    },
    isFetchingFinancialProfile(): boolean {
      return this.$store.getters.isFetchingFinancialProfile;
    },
    financialProfile(): IFinancialProfile | null {
      return this.$store.getters.financialProfile;
    },
    creditLimitRequestDetail(): ICreditLimitRequestDetail | null {
      return this.$store.getters.creditLimitRequestDetail;
    },
    isFetchingCreditLimitRequestDetail(): boolean {
      return this.$store.getters.isFetchingCreditLimitRequestDetail;
    },
    isProcessingCreditLimitRequest(): boolean {
      return this.$store.getters.isProcessingCreditLimitRequest;
    },
    requestedAmount() {
      return `PKR ${formatNumber(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.creditLimitRequestDetail?.requestedAmount || 0
      )}`;
    },
    requestedDays() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return `${this.creditLimitRequestDetail?.requestedDays} Days`;
    },
  },
  beforeMount() {
    this.$store.dispatch(
      "fetchCreditLimitRequestDetail",
      this.$route.params.id
    );
  },
  watch: {
    creditLimitRequestDetail() {
      if (this.creditLimitRequestDetail?.canApprove) {
        let params: GetFinancialProfileDto = {
          financial_data: "show",
        };
        this.$store.dispatch("fetchFinancialProfile", {
          id: this.creditLimitRequestDetail.financialProfileId,
          params,
        });
      }
    },
  },
});
</script>
