import { RouteRecordRaw } from "vue-router";
import SOA from "@/modules/SOA/views/SOA.vue";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "soa",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.SOA.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.SOA.PERMISSIONS,
    },
    component: SOA,
  },
];

export default routes;
