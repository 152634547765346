import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { AG_PRICING_API_PATH } from "@/modules/AgPricing/constants";
import { formatString } from "@/ag-portal-common/utils/helpers";

class AgPricingService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public get(id: string): Promise<IAGResponse<any>> {
    return this.restClient.get(
      formatString(AG_PRICING_API_PATH.GET + id + "/")
    );
  }
}

export default AgPricingService;
