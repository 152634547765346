<template>
  <AGAppContainer class="default-page-color" fullWidth>
    <a-g-full-width-page>
      <template v-slot:content-area>
        <AGContainer class="login_wrapper">
          <AGPanel>
            <AGLogo :url="urlLogo" is-small />
            <h5>Forgot Your <span>Password?</span></h5>
            <p>
              Enter your registered email address below and we will send you a
              link to reset your password.
            </p>
            <form :onsubmit="onSubmit">
              <AGTextField
                :testId="
                  genTestId(ELEMENT_TYPES.TEXT_FIELD, 'forgot-password-email')
                "
                :value="payload.email"
                v-model="payload.email"
                :onchange="onChangeEmail"
                type="email"
                name="email"
                label="Email"
              />

              <div class="forgot_card">
                <router-link
                  :to="path.ROOT"
                  :testId="genTestId(ELEMENT_TYPES.LINK, 'login')"
                >
                  <AGButton
                    :testId="genTestId(ELEMENT_TYPES.BUTTON, 'login')"
                    variant="link"
                    >Back to Login
                  </AGButton>
                </router-link>
              </div>

              <div class="error-container">
                <div>
                  {{ error }}
                </div>
              </div>

              <div class="d-grid">
                <AGButton
                  :is-loading="isLoading"
                  variant="primary"
                  type="submit"
                  >Submit
                </AGButton>
              </div>
            </form>
          </AGPanel>
        </AGContainer>
      </template>
    </a-g-full-width-page>
  </AGAppContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PATH } from "@/ag-portal-common/constants/path";
import {
  genTestId,
  parseLocalStorageData,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IOrganizationPreference } from "@/ag-portal-common/interfaces/organization.interface";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";

export default defineComponent({
  name: "ForgotPasswordView",
  created() {
    this.path = PATH;
  },
  data() {
    return {
      path: {},
      isLoading: false,
      ELEMENT_TYPES,
      error: "",
      payload: { email: "" },
    };
  },
  computed: {
    urlLogo() {
      const organizationPreference: IOrganizationPreference | null =
        parseLocalStorageData(STORAGE_KEYS.PREFERENCES);
      return organizationPreference?.logo;
    },
  },
  methods: {
    genTestId,
    onChangeEmail(e: any) {
      this.payload.email = e.target.value;
    },
    async onSubmit(e: any) {
      e.preventDefault();
      this.isLoading = true;
      this.$store.dispatch("forgotPassword", {
        body: this.payload,
        callback: () => (this.isLoading = false),
      });
    },
  },
});
</script>
<style>
header {
  line-height: 1.5;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

.error-container {
  color: red;
  margin: 10px 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}
</style>
