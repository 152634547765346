import { RouteRecordRaw } from "vue-router";
import pricing from "@/modules/AgPricing/views/AgPricing.vue";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "agPricing",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.AG_PRICING.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.AG_PRICING.PERMISSIONS,
    },
    component: pricing,
  },
];

export default routes;
