<template>
  <ag-loader v-if="this.$store.getters.isFetchingOrganizations" />
  <ag-card v-else>
    <AgTable
      :headers="headers"
      :items="transformTableData"
      :search="search"
      :items-per-page="10"
      :has-pagination="false"
      :has-search="true"
    >
      <template #col-public_id="{ item }">
        <router-link :to="`${PATH.ORGANIZATION}/${item.raw.id}`">
          {{ item.raw.public_id }}
        </router-link>
      </template>
      <template #button>
        <a-g-button
          @click="onClickCreateNew"
          :testId="genTestId(ELEMENT_TYPES.BUTTON, 'create-new-organization')"
        >
          Create new
        </a-g-button>
      </template>
      <template #col-action="{ item }">
        <a-g-button
          @click="onClickUpdate($event, item.raw.id)"
          :testId="
            genTestId(
              ELEMENT_TYPES.BUTTON,
              `organization-${item.raw.id}-action`
            )
          "
          >{{ item.raw.action }}
        </a-g-button>
      </template>
    </AgTable>
  </ag-card>
</template>

<script lang="ts">
import { PATH } from "@/ag-portal-common/constants/path";
import { defineComponent } from "vue";
import { IOrganization } from "@/ag-portal-common/interfaces/organization.interface";
import {
  formatStringToRoutePath,
  genTestId,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";

export default defineComponent({
  name: "OrganizationListView",
  beforeMount() {
    this.fetchOrganizations();
  },
  methods: {
    genTestId,
    fetchOrganizations() {
      this.$store.dispatch("fetchOrganizations");
    },
    onClickCreateNew() {
      this.$router.push(PATH.CREATE_ORGANIZATION);
    },
    onClickUpdate(e: any, id: string) {
      e.stopImmediatePropagation();
      this.$router.push(
        formatStringToRoutePath(PATH.UPDATE_ORGANIZATION, { id })
      );
    },
  },
  computed: {
    PATH() {
      return PATH;
    },
    transformTableData() {
      return this.$store.getters.organizations.map((item: IOrganization) => ({
        id: item.public_id,
        public_id: `${item.public_id} (${item.default_financial_profile?.platform_id})`,
        name: item.name,
        sector_name: item.sector_name,
        pia_provider: item.pia_provider,
        phone: item.contact_person_phone,
        email: item.contact_person_email,
        status: item.status,
        action: "Update",
      }));
    },
  },
  data() {
    return {
      search: "",
      isMobile: false,
      ELEMENT_TYPES,
      headers: [
        {
          key: "public_id",
          title: "Public Id",
          align: "start",
          value: "public_id",
        },
        {
          key: "name",
          title: "Name",
          align: "start",
          value: "name",
        },
        {
          title: "Sector Name",
          value: "sector_name",
          key: "sector_name",
          sortable: true,
        },
        {
          title: "PIA Provider",
          value: "pia_provider",
          key: "pia_provider",
          sortable: true,
        },
        {
          title: "Phone",
          value: "phone",
          key: "phone",
          sortable: true,
        },
        {
          title: "Email",
          value: "email",
          key: "email",
          sortable: true,
        },
        {
          title: "Status",
          value: "status",
          key: "status",
          sortable: true,
        },
        {
          title: "Action",
          value: "action",
          key: "action",
        },
      ],
    };
  },
});
</script>
