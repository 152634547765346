import { IMyBookingsFlight } from "@/interfaces/flightBookings.interface";
import { MyBookingsResponseModel } from "@/modules/MyBookings/models/myBookings.response";
import { IMyBookingsHotel } from "@/interfaces/hotelBookings.interface";
import { IMyBookingsTour } from "@/interfaces/tourBookings.interface";

export class MyBookingsMapper {
  private myFlightBookings: IMyBookingsFlight = {
    count: 0,
    next: "",
    previous: "",
    bookings: [],
  };

  private myHotelBookings: IMyBookingsHotel = {
    count: 0,
    next: "",
    previous: "",
    bookings: [],
  };

  private myTourBookings: IMyBookingsTour = {
    count: 0,
    next: "",
    previous: "",
    bookings: [],
  };

  public mapFlightBookingResponse(
    data: MyBookingsResponseModel
  ): IMyBookingsFlight {
    this.myFlightBookings.count = data.count;
    this.myFlightBookings.previous = data.previous;
    this.myFlightBookings.next = data.next;
    this.myFlightBookings.bookings = data.results.map((item) => ({
      id: item.booking_id,
      bookingId: item.booking_id,
      bookingTime: item.booking_time,
      travelDate: item.flight_details?.travel_dates[0] || "",
      status: item.status,
      travelers: item.flight_details?.passengers_names || [],
      sector: item.flight_details?.sector || "",
      pnr: item.flight_details?.supplier_pnr || "",
      supplier: item.flight_details?.supplier || "",
      agent: item.agent?.email || "",
      initiatedBookingExpiresAt: new Date(item.initiated_booking_expires_at),
      confirmedBookingExpiresAt: item.confirmed_booking_expires_at
        ? new Date(item.confirmed_booking_expires_at)
        : null,
    }));

    return this.myFlightBookings;
  }

  public mapHotelBookingResponse(
    data: MyBookingsResponseModel
  ): IMyBookingsHotel {
    this.myHotelBookings.count = data.count;
    this.myHotelBookings.previous = data.previous;
    this.myHotelBookings.next = data.next;
    this.myHotelBookings.bookings = data.results.map((item) => ({
      id: item.booking_id,
      bookingId: item.booking_id,
      bookingTime: item.booking_time,
      guests: item.hotel_details?.guests || [],
      status: item.status,
      checkOut: item.hotel_details?.checkout || "",
      checkIn: item.hotel_details?.checkin || "",
      agent: item.agent?.email || "",
      initiatedBookingExpiresAt: new Date(item.initiated_booking_expires_at),
      confirmedBookingExpiresAt: item.confirmed_booking_expires_at
        ? new Date(item.confirmed_booking_expires_at)
        : null,
    }));

    return this.myHotelBookings;
  }
  public mapTourBookingResponse(
    data: MyBookingsResponseModel
  ): IMyBookingsTour {
    this.myTourBookings.count = data.count;
    this.myTourBookings.previous = data.previous;
    this.myTourBookings.next = data.next;
    this.myTourBookings.bookings = data.results.map((item) => ({
      id: item.booking_id,
      bookingId: item.booking_id,
      title: item.tour_details?.title,
      passportRequired: item?.tour_details?.passport_required,
      additionalDocuments: item?.tour_details?.additionalDocuments || [],
      guests: item?.tour_details?.number_of_guest,
      bookingTime: item?.booking_time,
      travelDate: item?.tour_details?.start_date[0] || "",
      currency: item?.tour_details?.selected_option?.option_currency || "",
      price: item?.tour_details?.total_fare,
      status: item?.status,
      travellers: item?.tour_details?.passenger_list || [],
      tourType: item?.tour_details?.tour_type,
      endDate: item?.tour_details?.end_date,
      startDate: item?.tour_details?.start_date,
      agent: item.agent?.email || "",
      initiatedBookingExpiresAt: new Date(item.initiated_booking_expires_at),
      confirmedBookingExpiresAt: item.confirmed_booking_expires_at
        ? new Date(item.confirmed_booking_expires_at)
        : null,
    }));

    return this.myTourBookings;
  }
}
