<template>
  <ag-card>
    <ag-heading variant="h3" title="Website management"></ag-heading>
    <ag-heading
      variant="p"
      title="Update your website configuration here"
    ></ag-heading>
    <hr />
  </ag-card>
  <ag-loader v-if="$store.getters.isWebsiteConfigLoading" />
  <template v-else>
    <AgDiv class="form-container">
      <AgRow test-id="" class="justify-content-center">
        <AgColumn test-id="" md="12" lg="8">
          <!-- ********** BASIC CONFIGURATION ********** -->
          <AgCard test-id="">
            <AgIconInfoBar
              test-id=""
              icon="contactTravelerIcon"
              title="Basic website configuration"
            />
            <ag-accordion
              class="flight_accordion_wrap margin_bottom_0"
              :panel-value="[0]"
            >
              <ag-accordion-panel>
                <template #tabSection>
                  <AgRow test-id="">
                    <AgColumn test-id="" md="12" lg="10">
                      <p>
                        <b>Note:</b> Slug for subdomain should start with a
                        letter or number, contain only letters, numbers, or
                        hyphens, and not start or end with a hyphen.
                      </p>
                      <AGTextField
                        v-model="website_slug"
                        :value="website_slug"
                        type="text"
                        label="Website slug"
                        :onkeyup="validateSubdomain"
                        :error="renderSlugError"
                        :test-id="
                          genTestId(ELEMENT_TYPES.TEXT_FIELD, 'website-slug')
                        "
                      />
                      <AgDiv>
                        <h5>Your website address will look like this.</h5>

                        <p>
                          <a
                            class="website-slug"
                            :href="`https://${generateWebsiteDomain(
                              response_website_slug
                            )}`"
                            target="_blank"
                          >
                            {{ generateWebsiteDomain(website_slug) }}
                          </a>
                        </p>
                      </AgDiv>
                      <hr />
                      <p>
                        You can toggle your website's accessibility using this
                        checkbox.
                      </p>
                      <v-switch
                        hide-details
                        inset
                        color="primary"
                        v-model="is_website_enabled"
                        :value="true"
                        :label="renderWebsiteEnabledLabel"
                      />
                      <hr />
                      <!-- <p>You can toggle OTA search box visibilty in your website using this checkbox.</p>
                <v-switch
                  hide-details
                  inset
                  color="primary"
                  v-model="show_ota_search"
                  :value="true"
                  :label="renderOTAEnabledLabel"
                /> 

                <hr />
                -->
                    </AgColumn>
                    <AgColumn test-id="" md="12" lg="12">
                      <AGButton
                        test-id=""
                        style="float: right"
                        :disabled="$store.getters.isUpdateWebsiteLoading"
                        :isLoading="$store.getters.isUpdateWebsiteLoading"
                        @click="onWebsiteConfigUpdate"
                        >Save
                      </AGButton>
                    </AgColumn>
                  </AgRow>
                </template>
              </ag-accordion-panel>
            </ag-accordion>
          </AgCard>

          <!-- ********** BANNER SECTION ********** -->
          <AgCard test-id="">
            <AgIconInfoBar
              test-id=""
              icon="contactTravelerIcon"
              title="Banner Section"
            />
            <ag-accordion
              class="flight_accordion_wrap margin_bottom_0"
              :panel-value="[0]"
            >
              <ag-accordion-panel>
                <template #tabSection>
                  <AgRow test-id="">
                    <AgColumn test-id="" md="12" lg="10">
                      <AGTextField
                        v-model="banner_title"
                        :value="banner_title"
                        type="text"
                        label="Banner Title"
                        placeholder="Enter the title for your banner"
                        :onkeyup="validateSubdomain"
                        :error="renderSlugError"
                        :testId="
                          genTestId(ELEMENT_TYPES.TEXT_FIELD, 'banner-title')
                        "
                      />
                      <AgTextarea
                        rows="6"
                        v-model="banner_body"
                        :value="banner_body"
                        label="Banner Body"
                        placeholder="Enter the banner body for your banner"
                      />

                      <hr />
                    </AgColumn>
                  </AgRow>
                </template>
              </ag-accordion-panel>
            </ag-accordion>
          </AgCard>

          <!-- ********** CONTACT SECTION ********** -->
          <AgCard test-id="">
            <AgIconInfoBar
              test-id=""
              icon="contactTravelerIcon"
              title="Contact Section"
            />
            <ag-accordion
              class="flight_accordion_wrap margin_bottom_0"
              :panel-value="[0]"
            >
              <ag-accordion-panel>
                <template #tabSection>
                  <AgRow test-id="">
                    <AgColumn test-id="" md="12" lg="10">
                      <AGTextField
                        :test-id="
                          genTestId(ELEMENT_TYPES.TEXT_FIELD, 'website-email')
                        "
                        v-model="email_address"
                        :value="email_address"
                        type="email"
                        label="Email Address"
                      />
                      <AGTextField
                        :test-id="
                          genTestId(
                            ELEMENT_TYPES.TEXT_FIELD,
                            'website-whatsapp-number'
                          )
                        "
                        v-model="whatsapp_number"
                        :value="whatsapp_number"
                        type="number"
                        label="Whatsapp Number"
                      />
                      <AGTextField
                        :test-id="
                          genTestId(
                            ELEMENT_TYPES.TEXT_FIELD,
                            'website-phone-number'
                          )
                        "
                        v-model="phone_number"
                        :value="phone_number"
                        type="number"
                        label="Phone Number"
                      />
                      <AGTextField
                        :test-id="
                          genTestId(
                            ELEMENT_TYPES.TEXT_FIELD,
                            'website-mobile-number'
                          )
                        "
                        v-model="mobile_number"
                        :value="mobile_number"
                        type="number"
                        label="Mobile Number"
                      />
                      <AgTextarea
                        rows="6"
                        :value="physical_address"
                        v-model="physical_address"
                        label="Physical Address"
                      />
                    </AgColumn>
                  </AgRow>
                </template>
              </ag-accordion-panel>
            </ag-accordion>
          </AgCard>

          <!-- ********** ABOUT US SECTION ********** -->
          <AgCard test-id="">
            <AgIconInfoBar
              test-id=""
              icon="contactTravelerIcon"
              title="About Us Section"
            />
            <ag-accordion
              class="flight_accordion_wrap margin_bottom_0"
              :panel-value="[0]"
            >
              <ag-accordion-panel>
                <template #tabSection>
                  <AgRow test-id="">
                    <AgColumn test-id="" md="12" lg="10">
                      <AGTextField
                        :test-id="
                          genTestId(
                            ELEMENT_TYPES.TEXT_FIELD,
                            'website-about-us-title'
                          )
                        "
                        v-model="about_us_title"
                        :value="about_us_title"
                        type="text"
                        label="About Us Title"
                        :onkeyup="validateSubdomain"
                        :error="renderSlugError"
                      />
                      <AgTextarea
                        rows="6"
                        v-model="about_us_description"
                        :value="about_us_description"
                        label="About Us Description"
                        placeholder="Enter the banner body for your banner"
                      />

                      <hr />
                    </AgColumn>
                  </AgRow>
                </template>
              </ag-accordion-panel>
            </ag-accordion>
          </AgCard>

          <!-- ********** PACKAGES SECTION ********** -->
          <AgCard test-id="">
            <AgIconInfoBar
              test-id=""
              icon="contactTravelerIcon"
              title="Packages Section"
            />
            <ag-accordion
              class="flight_accordion_wrap margin_bottom_0"
              :panel-value="[0]"
            >
              <ag-accordion-panel>
                <template #tabSection>
                  <AgRow test-id="">
                    <AgColumn test-id="" md="12" lg="10">
                      <v-switch
                        hide-details
                        inset
                        color="primary"
                        v-model="show_packages"
                        :value="true"
                        :label="renderPackagesActiveLabel"
                      />

                      <AgDiv
                        class="package_list_item"
                        v-for="(item, index) in package_list"
                        :key="index"
                      >
                        <div class="list-header">
                          <template v-if="item.display_image_url">
                            <img
                              class="list-thumb"
                              :src="item.display_image_url"
                              alt="package-image"
                            />
                          </template>
                          <p>{{ item?.name }}</p>
                        </div>
                        <div class="edit_container">
                          <p>{{ item?.active ? "Active" : "Disabled" }}</p>
                          <AGButton
                            test-id=""
                            @click="handleEditPackage(item, index)"
                            >Edit</AGButton
                          >
                          <AGButton
                            test-id=""
                            @click="handleOpenRemovePackageItemDialog(index)"
                            >Remove</AGButton
                          >
                        </div>
                      </AgDiv>
                      <hr />
                      <AGButton test-id="" @click="handleOpenPackageDialog"
                        >Add New Package</AGButton
                      >
                    </AgColumn>
                  </AgRow>
                </template>
              </ag-accordion-panel>
            </ag-accordion>
          </AgCard>

          <!-- ********** SERVICES SECTION ********** -->
          <AgCard test-id="">
            <AgIconInfoBar
              test-id=""
              icon="contactTravelerIcon"
              title="Services Section"
            />
            <ag-accordion
              class="flight_accordion_wrap margin_bottom_0"
              :panel-value="[0]"
            >
              <ag-accordion-panel>
                <template #tabSection>
                  <AgRow test-id="">
                    <AgColumn test-id="" md="12" lg="10">
                      <v-switch
                        hide-details
                        inset
                        color="primary"
                        v-model="show_services"
                        :value="true"
                        :label="renderServicesActiveLabel"
                      />

                      <AgDiv
                        class="package_list_item"
                        v-for="(item, index) in services_list"
                        :key="index"
                      >
                        <div class="list-header">
                          <template v-if="item.display_image_url">
                            <img
                              class="list-thumb"
                              :src="item.display_image_url"
                              alt="package-image"
                            />
                          </template>
                          <p>{{ item?.name }}</p>
                        </div>
                        <div class="edit_container">
                          <AGButton
                            test-id=""
                            @click="handleEditServices(item, index)"
                            >Edit</AGButton
                          >
                          <AGButton
                            test-id=""
                            @click="handleOpenRemoveServiceItemDialog(index)"
                            >Remove</AGButton
                          >
                        </div>
                      </AgDiv>
                      <hr />
                      <AGButton test-id="" @click="handleOpenServiceDialog"
                        >Add New Service</AGButton
                      >
                    </AgColumn>
                  </AgRow>
                </template>
              </ag-accordion-panel>
            </ag-accordion>
          </AgCard>

          <!-- ********** SOCIAL SECTION ********** -->
          <AgCard test-id="">
            <AgIconInfoBar
              test-id=""
              icon="contactTravelerIcon"
              title="Social Address Section"
            />
            <ag-accordion
              class="flight_accordion_wrap margin_bottom_0"
              :panel-value="[0]"
            >
              <ag-accordion-panel>
                <template #tabSection>
                  <AgRow test-id="">
                    <AgColumn test-id="" md="12" lg="10">
                      <AGTextField
                        :test-id="
                          genTestId(
                            ELEMENT_TYPES.TEXT_FIELD,
                            'website-facebook'
                          )
                        "
                        v-model="facebook"
                        :value="facebook"
                        type="text"
                        label="Facebook"
                      />
                      <AGTextField
                        :test-id="
                          genTestId(ELEMENT_TYPES.TEXT_FIELD, 'website-youtube')
                        "
                        v-model="youtube"
                        :value="youtube"
                        type="text"
                        label="Youtube"
                      />
                      <AGTextField
                        :test-id="
                          genTestId(
                            ELEMENT_TYPES.TEXT_FIELD,
                            'website-instagram'
                          )
                        "
                        v-model="instagram"
                        :value="instagram"
                        type="text"
                        label="Instagram"
                      />
                    </AgColumn>
                  </AgRow>
                </template>
              </ag-accordion-panel>
            </ag-accordion>
          </AgCard>

          <AgCard test-id="">
            <AgRow test-id="">
              <AgColumn test-id="" md="12" lg="12">
                <AGButton
                  test-id=""
                  style="float: right"
                  :disabled="$store.getters.isWebsiteContentLoading"
                  :isLoading="$store.getters.isWebsiteContentLoading"
                  @click="handleSaveSettings"
                  >Save Settings
                </AGButton>
              </AgColumn>
            </AgRow>
          </AgCard>
        </AgColumn>
      </AgRow>
    </AgDiv>
    <AddPackageModal
      :is-edit="packageDialogModeIsEdit"
      :data="selectedPackage"
      :is-open="isPackageModelOpen"
      @closeModal="closeAddPackageModal"
      @onSubmit="onPackageSave"
    />
    <AddServiceModal
      :is-edit="serviceDialogModeIsEdit"
      :data="selectedService"
      :is-open="isServiceModelOpen"
      @closeModal="closeAddServiceModal"
      @onSubmit="onServiceSave"
    />
    <RemoveItemConfirmationModal
      :is-open="isRemoveItemModelOpen"
      :type="removeItemDialogType"
      @handleReject="handleCloseRemoveItemDialog"
      @handleAccept="handleAcceptRemoveItemDialog"
    />
  </template>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { format } from "date-fns";
import { clone } from "lodash";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { WebsiteStatus, WebsiteType } from "@/ag-portal-common/enums/WEBSITE";
import { IOrganizationFromLoginResponse } from "@/ag-portal-common/interfaces/organization.interface";
import AddPackageModal from "@/modules/Organization/components/AddPackageModel.vue";
import AddServiceModal from "@/modules/Organization/components/AddServiceModel.vue";
import RemoveItemConfirmationModal from "@/modules/Organization/components/RemoveItemConfirmationModel.vue";
import { AddPackageDTO } from "@/modules/Organization/dtos/package.dto";
import { AddServiceDTO } from "@/modules/Organization/dtos/websiteService.dto";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";

export default defineComponent({
  name: "WebsiteMangement",
  components: {
    AddPackageModal,
    AddServiceModal,
    RemoveItemConfirmationModal,
  },
  data(): {
    response_website_slug: string;
    website_slug: string;
    is_valid_subdomain: boolean;
    is_website_enabled: boolean;
    show_ota_search: boolean;
    banner_title: string;
    banner_body: string;
    email_address: string;
    whatsapp_number: string;
    phone_number: string;
    mobile_number: string;
    physical_address: string;
    facebook: string;
    instagram: string;
    youtube: string;
    about_us_title: string;
    about_us_description: string;
    show_packages: boolean;
    package_list: any[];
    show_services: boolean;
    services_list: any[];
    isPackageModelOpen: boolean;
    selectedPackageIndex: number;
    packageDialogModeIsEdit: boolean;
    selectedPackage: object;
    isServiceModelOpen: boolean;
    selectedServiceIndex: number;
    serviceDialogModeIsEdit: boolean;
    selectedService: object;
    isRemoveItemModelOpen: boolean;
    removeItemDialogType: string;
    removeItemIndex: number;
    ELEMENT_TYPES: typeof ELEMENT_TYPES;
  } {
    return {
      ELEMENT_TYPES,
      response_website_slug: "",
      website_slug: "",
      is_valid_subdomain: true,
      is_website_enabled: true,
      show_ota_search: false,
      banner_title: "",
      banner_body: "",
      email_address: "",
      whatsapp_number: "",
      phone_number: "",
      mobile_number: "",
      physical_address: "",
      facebook: "",
      instagram: "",
      youtube: "",
      about_us_title: "",
      about_us_description: "",
      show_packages: true,
      package_list: [],
      show_services: true,
      services_list: [],
      // Packages
      isPackageModelOpen: false,
      selectedPackageIndex: 0,
      packageDialogModeIsEdit: false,
      selectedPackage: {},
      // Services
      isServiceModelOpen: false,
      selectedServiceIndex: 0,
      serviceDialogModeIsEdit: false,
      selectedService: {},
      // Remove Item
      isRemoveItemModelOpen: false,
      removeItemDialogType: "",
      removeItemIndex: 0,
    };
  },
  methods: {
    genTestId,
    onWebsiteConfigLoadHandler() {
      let organization =
        (this.organization() as IOrganizationFromLoginResponse) || null;
      this.$store.dispatch("getWebsiteConfiguration", {
        organizationId: organization.organization_id,
      });
    },
    isValidEmail() {
      const emailRegex = /^\w+([.-]?\w+)*(\+\w+)?@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      return emailRegex.test(this.email_address);
    },
    validateSubdomain() {
      const pattern =
        /^(?!:\/\/)(?!-)[a-zA-Z0-9-]{1,63}(?<!-)(?:\.[a-zA-Z0-9-]{1,63})*$/;
      this.is_valid_subdomain = pattern.test(this.website_slug);
      return this.is_valid_subdomain;
    },
    onWebsiteConfigUpdate() {
      const pattern =
        /^(?!:\/\/)(?!-)[a-zA-Z0-9-]{1,63}(?<!-)(?:\.[a-zA-Z0-9-]{1,63})*$/;
      if (!pattern.test(this.website_slug)) {
        this.slugErrorOnSubmit;
      } else {
        let organization =
          (this.organization() as IOrganizationFromLoginResponse) || null;

        const payload = {
          web_slug: this.website_slug,
          search_and_book_enabled: this.show_ota_search,
          enabled: this.is_website_enabled,
          domain_name: "web.aeroglobe.pk",
        };
        this.response_website_slug = this.website_slug;
        this.$store.dispatch("updateWebsiteConfiguration", {
          payload,
          organizationId: organization.organization_id,
        });
      }
    },
    closeAddPackageModal() {
      this.isPackageModelOpen = false;
    },
    closeAddServiceModal() {
      this.isServiceModelOpen = false;
    },
    clearPackageDetails() {
      this.selectedPackage = {};
      this.selectedPackageIndex = 0;
      this.packageDialogModeIsEdit = false;
    },
    clearServiceDetails() {
      this.selectedService = {};
      this.selectedServiceIndex = 0;
      this.serviceDialogModeIsEdit = false;
    },
    onPackageSave(payload: AddPackageDTO) {
      const updatedPackages = this.package_list;
      const updatedPackage = { ...payload, display_image_url: null };
      if (this.packageDialogModeIsEdit) {
        const oldPackage = updatedPackages[this.selectedPackageIndex];

        if (oldPackage.display_image_id === updatedPackage.display_image_id) {
          updatedPackage.display_image_url = oldPackage.display_image_url;
        }

        updatedPackages[this.selectedPackageIndex] = updatedPackage;
      } else {
        updatedPackages.push(updatedPackage);
      }

      this.package_list = updatedPackages;
      this.closeAddPackageModal();
      this.clearPackageDetails();
    },
    onServiceSave(payload: AddServiceDTO) {
      const updatedServices = this.services_list;
      const updatedService = { ...payload, display_image_url: null };
      if (this.serviceDialogModeIsEdit) {
        const oldService = updatedServices[this.selectedServiceIndex];

        if (oldService.display_image_id === updatedService.display_image_id) {
          updatedService.display_image_url = oldService.display_image_url;
        }

        updatedServices[this.selectedServiceIndex] = updatedService;
      } else {
        updatedServices.push(updatedService);
      }

      this.services_list = updatedServices;
      this.closeAddServiceModal();
      this.clearServiceDetails();
    },
    handleOpenPackageDialog() {
      this.clearPackageDetails();
      this.isPackageModelOpen = true;
    },
    handleOpenServiceDialog() {
      this.clearServiceDetails();
      this.isServiceModelOpen = true;
    },
    isValidLink() {
      const validInstagram = "https://www.instagram.com/";
      const validYoutube = "https://www.youtube.com/" || "https://youtube.com/";
      const validFacebook = "https://www.facebook.com/";
      if (
        this.youtube.includes(validYoutube) &&
        this.instagram.includes(validInstagram) &&
        this.facebook.includes(validFacebook)
      ) {
        return true;
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Invalid Social Link";
        notificationService.triggerNotification();
        return false;
      }
    },
    handleSaveSettings() {
      if (this.isValidEmail()) {
        if (this.isValidLink()) {
          const payload = {
            social_links: {
              facebook: this.facebook,
              instagram: this.instagram,
              youtube: this.youtube,
            },
            contact_info: {
              email_address: this.email_address,
              whatsapp_number: this.whatsapp_number,
              phone_number: this.phone_number,
              mobile_number: this.mobile_number,
              physical_address: this.physical_address,
            },
            header_section_details: {
              heading_text: this.banner_title,
              body_text: this.banner_body,
              cta_text: null,
            },
            services_details: {
              is_enabled: this.show_services,
              service_list: this.services_list,
            },
            packages_details: {
              is_enabled: this.show_packages,
              package_list: this.package_list.map((item: AddPackageDTO) => {
                return {
                  ...item,
                  start_date: item?.start_date
                    ? format(new Date(item?.start_date), FORMAT_YYY_MM_DD)
                    : "",
                  end_date: item?.end_date
                    ? format(new Date(item?.end_date), FORMAT_YYY_MM_DD)
                    : "",
                };
              }),
            },
            about_us: {
              title: this.about_us_title,
              description: this.about_us_description,
            },
          };
          const organization =
            (this.organization() as IOrganizationFromLoginResponse) || null;

          this.$store.dispatch("updateWebsiteContent", {
            payload,
            organizationId: organization.organization_id,
          });

          this.onWebsiteConfigLoadHandler();
        }
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Invalid Email";
        notificationService.triggerNotification();
      }
    },
    handleEditPackage(item: AddPackageDTO, index: number) {
      this.isPackageModelOpen = true;
      this.selectedPackage = item;
      this.selectedPackageIndex = index;
      this.packageDialogModeIsEdit = true;
    },

    handleEditServices(item: AddServiceDTO, index: number) {
      this.isServiceModelOpen = true;
      this.selectedService = item;
      this.selectedServiceIndex = index;
      this.serviceDialogModeIsEdit = true;
    },
    handleOpenRemoveServiceItemDialog(index: number) {
      this.isRemoveItemModelOpen = true;
      this.removeItemDialogType = "Service";
      this.removeItemIndex = index;
    },
    handleOpenRemovePackageItemDialog(index: number) {
      this.isRemoveItemModelOpen = true;
      this.removeItemDialogType = "Package";
      this.removeItemIndex = index;
    },
    handleRemovePackageItem(index: number) {
      const _packages_list = clone(this.package_list);
      _packages_list.splice(index, 1);
      this.package_list = _packages_list;
      this.handleCloseRemoveItemDialog();
    },
    handleRemoveServiceItem(index: number) {
      const _services_list = clone(this.services_list);
      _services_list.splice(index, 1);
      this.services_list = _services_list;
      this.handleCloseRemoveItemDialog();
    },
    handleCloseRemoveItemDialog() {
      this.isRemoveItemModelOpen = false;
      this.removeItemDialogType = "";
      this.removeItemIndex = 0;
    },
    handleAcceptRemoveItemDialog() {
      if (this.removeItemDialogType == "Service") {
        this.handleRemoveServiceItem(this.removeItemIndex);
      } else {
        this.handleRemovePackageItem(this.removeItemIndex);
      }
    },
    generateWebsiteDomain(slug: string) {
      const isDev = process.env.NODE_ENV === "development";
      return `${slug}${isDev ? ".dev" : ""}.web.aeroglobe.pk`;
    },
  },
  computed: {
    slugErrorOnSubmit() {
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        "Please enter a valid slug for subdomain";
      notificationService.triggerNotification();
      return null;
    },
    renderSlugError() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.is_valid_subdomain
        ? ""
        : "Please enter a valid slug for subdomain";
    },
    renderWebsiteEnabledLabel() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return `Website ${this.is_website_enabled ? "Enabled" : "Disabled"}`;
    },
    renderOTAEnabledLabel() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return `OTA search box ${this.show_ota_search ? "Enabled" : "Disabled"}`;
    },
    renderPackagesActiveLabel() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return `${this.show_packages ? "Show" : "Hide"} Packages Section`;
    },
    renderServicesActiveLabel() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return `${this.show_services ? "Show" : "Hide"} Services Section`;
    },
  },
  watch: {
    "$store.getters.websiteConfig": {
      handler(data) {
        this.response_website_slug = data?.web_slug;
        this.website_slug = data?.web_slug;
        this.is_website_enabled = data?.status === WebsiteStatus.ENABLED;
        this.show_ota_search = data?.website_type === WebsiteType.OTA;
      },
    },
    "$store.getters.websiteContent": {
      handler(data) {
        this.facebook = data?.social_links?.facebook || "";
        this.instagram = data?.social_links?.instagram || "";
        this.youtube = data?.social_links?.youtube || "";
        this.email_address = data?.contact_detail?.email_address || "";
        this.whatsapp_number = data?.contact_detail?.whatsapp_number || "";
        this.mobile_number = data?.contact_detail?.mobile_number || "";
        this.phone_number = data?.contact_detail?.phone_number || "";
        this.physical_address = data?.contact_detail?.physical_address || "";
        this.banner_title = data?.header_section_data?.heading_text || "";
        this.banner_body = data?.header_section_data?.body_text || "";
        this.about_us_title = data?.about_us?.title || "";
        this.about_us_description = data?.about_us?.description || "";
        this.package_list = data?.packages_detail?.package_list || [];
        this.show_packages = data?.packages_detail?.is_enabled || false;
        this.show_services = data?.services_detail?.is_enabled || false;
        this.services_list = data?.services_detail?.service_list || [];
      },
    },
  },
  created() {
    this.onWebsiteConfigLoadHandler();
  },
  setup() {
    const organization = inject(
      AUTH_CONTEXT_KEYS.organization
    ) as () => IOrganizationFromLoginResponse;
    return { organization };
  },
});
</script>

<style scoped lang="scss">
.form-container {
  background: #f6f6f6;
  padding: 100px 0;
  border-radius: 8px;
}

.website-slug {
  font-weight: bold;
  color: var(--green-color);
}
.list-header {
  display: flex;
  gap: 10px;
  align-items: center;
  .list-thumb {
    width: 45px;
    height: 45px;
    border-radius: 5px;
  }
}

.package_list_item {
  background: #f6f6f6;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  & > p {
    color: var(--green-color) !important;
  }
  p {
    margin: 0;
    font-weight: 600;
    font-size: 17px;
  }
  button {
    margin-right: 10px;
  }
  .edit_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      margin-right: 20px;
    }
  }
}
</style>

<style lang="scss">
.v-switch {
  .text-primary {
    color: var(--green-color) !important;
  }
}
</style>
