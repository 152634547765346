<template>
  <ag-card>
    <ag-heading variant="h3" title="Request Limit"></ag-heading>
    <ag-heading variant="p" title="Update your Request Limit here"></ag-heading>
    <hr />
    <form @submit.prevent="onSubmit">
      <ag-row class="margin_top_20">
        <ag-column xs="12" md="6" cols="12">
          <financial-profile-dropdown
            label="Financial Profile"
            :error="errors['financialProfilePublicId']"
            @onUpdateFinancialProfile="updateFinancialProfilePublicId"
          />
        </ag-column>
        <ag-column xs="12" md="2" cols="12">
          <a-g-text-field
            label="Total Amount"
            :error="errors['totalAmount']"
            type="number"
            v-model="formData.totalAmount"
            :value="formData.totalAmount"
          />
        </ag-column>
        <ag-column xs="12" md="2" cols="12">
          <a-g-text-field
            type="number"
            label="Total Days"
            :error="errors['totalDays']"
            v-model="formData.totalDays"
            :value="formData.totalDays"
          ></a-g-text-field>
        </ag-column>
        <ag-column xs="12" md="2" cols="12">
          <ag-select
            label="Request Priority"
            v-model="formData.priority"
            :value="formData.priority"
            :error="errors['priority']"
            :items="CREDIT_LIMIT_REQUEST_PRIORITIES"
            variant="outlined"
          />
        </ag-column>
        <ag-column xs="12" md="8" cols="12">
          <AgTextarea
            rows=""
            label="Comment"
            :error="errors['comment']"
            :value="formData.comment"
            v-model="formData.comment"
          ></AgTextarea>
        </ag-column>
      </ag-row>
      <ag-row>
        <ag-column xs="12" md="3" cols="12">
          <a-g-button
            :is-loading="isCreatingCreditLimitRequest"
            class="full-width"
            type="submit"
            test-id=""
            >Create Limit Request
          </a-g-button>
        </ag-column>
      </ag-row>
    </form>
  </ag-card>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { CREDIT_LIMIT_REQUEST_PRIORITIES } from "@/modules/CreditLimitManagement/constants";
import { createCreditLimitRequestValidationSchema } from "@/modules/CreditLimitManagement/validations/createCreditLimitRequestValidationSchema";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { ValidationError } from "yup";
import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import { CreateCreditLimitRequestsBody } from "@/modules/CreditLimitManagement/models/createCreditLimitRequestsBody.request";
import { PATH } from "@/ag-portal-common/constants/path";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IOrganizationFromLoginResponse } from "@/ag-portal-common/interfaces/organization.interface";
import FinancialProfileDropdown from "@/components/FinancialProfileDropdown.vue";

interface ICreateCreditLimitRequestState {
  errors: IObject;
  formData: {
    totalAmount: number;
    totalDays: number;
    financialProfilePublicId: string;
    priority: string;
    comment: string;
  };
}

export default defineComponent({
  name: "CreateCreditLimitRequest",
  components: { FinancialProfileDropdown },
  computed: {
    CREDIT_LIMIT_REQUEST_PRIORITIES() {
      return CREDIT_LIMIT_REQUEST_PRIORITIES;
    },
    isCreatingCreditLimitRequest(): boolean {
      return this.$store.getters.isCreatingCreditLimitRequest;
    },
  },
  data(): ICreateCreditLimitRequestState {
    return {
      errors: {},
      formData: {
        totalAmount: 0,
        financialProfilePublicId: "",
        totalDays: 0,
        priority: CREDIT_LIMIT_REQUEST_PRIORITIES[0].value,
        comment: "",
      },
    };
  },
  methods: {
    routeToCreditLimitRequests() {
      this.$router.push(PATH.CREDIT_LIMIT_MANAGEMENT);
    },
    updateFinancialProfilePublicId(value: string) {
      this.formData.financialProfilePublicId = value;
    },
    async onSubmit() {
      try {
        await createCreditLimitRequestValidationSchema.validate(this.formData, {
          abortEarly: false,
        });
        let body: CreateCreditLimitRequestsBody = {
          financial_profile_id: this.formData.financialProfilePublicId,
          total_amount: this.formData.totalAmount,
          total_days: this.formData.totalDays,
          priority: this.formData.priority,
          notes: this.formData.comment,
        };
        this.$store.dispatch("createCreditLimitRequest", {
          body,
          callback: this.routeToCreditLimitRequests,
        });
      } catch (ex) {
        if (ex instanceof ValidationError) {
          this.errors = yupValidationErrorAsSchema(ex);
        }
      }
    },
  },
  setup() {
    let injectOrganization = inject(
      AUTH_CONTEXT_KEYS.organization
    ) as () => IOrganizationFromLoginResponse | null;
    let organization =
      injectOrganization() as IOrganizationFromLoginResponse | null;
    return {
      organization,
    };
  },
});
</script>
