<template>
  <AgDiv class="not_found_wrap">
    <AgDiv class="icon_404"></AgDiv>
    <AgHeading title="Page Not Found" variant="h3"></AgHeading>
    <AgHeading
      title="May be this page doesn't exists or you don't have permission to view it"
      variant="p"
    ></AgHeading>
  </AgDiv>
</template>

<script lang="ts">
export default {
  name: "AgFourZeroFour",
  props: {
    icon: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.not_found_wrap {
  text-align: center;
  .icon_404 {
    background: url("../assets/AG404.svg") no-repeat center;
    width: 100%;
    background-size: contain;
    height: 500px;
    margin: auto;
  }
}
</style>
