import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_color_chip = _resolveComponent("ag-color-chip")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ag_agent_info_box = _resolveComponent("ag-agent-info-box")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_ag_agent_list_customer_balance = _resolveComponent("ag-agent-list-customer-balance")!
  const _component_agheading = _resolveComponent("agheading")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_AgTable = _resolveComponent("AgTable")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.financialProfile?.financial_data)
      ? (_openBlock(), _createBlock(_component_ag_agent_list_customer_balance, { key: 0 }, {
          heading: _withCtx(() => [
            _createVNode(_component_ag_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_ag_column, {
                  md: "8",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ag_heading, {
                      title: 'Profile Name: ' + _ctx.financialProfile.financial_profile_name,
                      variant: "h3"
                    }, null, 8, ["title"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ag_column, {
                  md: "4",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ag_column, { class: "profile_chip_wrap" }, {
                      default: _withCtx(() => [
                        (_ctx.financialProfile.is_default === _ctx.defaultFinancialProfileTag)
                          ? (_openBlock(), _createBlock(_component_ag_color_chip, {
                              key: 0,
                              "color-name": "Default",
                              "color-code": _ctx.COLOR_CODES.SUCCESS_GREEN
                            }, null, 8, ["color-code"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_ag_color_chip, {
                          "color-name": _ctx.financialProfile.plan_name,
                          "color-code": _ctx.planStatusColorCode
                        }, null, 8, ["color-name", "color-code"]),
                        _createVNode(_component_ag_color_chip, {
                          "color-name": 
                _ctx.financialProfile.status.charAt(0).toUpperCase() +
                _ctx.financialProfile.status.slice(1).toLowerCase()
              ,
                          "color-code": _ctx.statusColorCode
                        }, null, 8, ["color-name", "color-code"]),
                        _withDirectives(_createVNode(_component_ag_color_chip, {
                          "color-name": "view not invoiced sales\n                ",
                          "color-code": _ctx.statusColorCode,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal(_ctx.financialProfile.public_id)))
                        }, null, 8, ["color-code"]), [
                          [_vShow, _ctx.isAgSuperUser]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          items: _withCtx(() => [
            _createVNode(_component_ag_agent_info_box, {
              title: "Total Available Current Limit",
              icon: "userType",
              value: 
          _ctx.formatNumber(
            _ctx.financialProfile?.financial_data.total_balance_current || 0
          )
        
            }, {
              value: _withCtx(() => [
                _createVNode(_component_ag_heading, {
                  title: 
              _ctx.formatNumber(
                _ctx.financialProfile?.financial_data.total_balance_current || 0
              )
            ,
                  variant: "p",
                  class: "info-details color-red"
                }, null, 8, ["title"])
              ]),
              _: 1
            }, 8, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Customer Status",
              icon: "customerStatus",
              value: 
          _ctx.financialProfile?.financial_data.customer_status +
            ' (' +
            _ctx.financialProfile?.status_remarks.toLocaleLowerCase() +
            ')' || ''
        
            }, null, 8, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Current Balance",
              icon: "currentBalance",
              value: 
          _ctx.formatNumber(_ctx.financialProfile?.financial_data.current_balance || 0)
        
            }, null, 8, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Total Not Invoiced Sales",
              icon: "notInvoice",
              value: 
          _ctx.formatNumber(
            _ctx.financialProfile?.financial_data.total_of_not_invoiced_tickets || 0
          )
        ,
              class: "no_border"
            }, null, 8, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Plan Name",
              icon: "provider",
              value: _ctx.financialProfile?.plan_name
            }, null, 8, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Temporary Credit Limit",
              icon: "tempCreditLimit",
              value: 
          _ctx.formatNumber(
            _ctx.financialProfile?.financial_data.temporary_credit_limit || 0
          )
        
            }, null, 8, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Permanent Credit Limit",
              icon: "permanentCredit",
              value: 
          _ctx.formatNumber(_ctx.financialProfile?.financial_data.credit_limit || 0)
        
            }, null, 8, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Financial Profile",
              icon: "profileCardLight",
              class: "no_border",
              value: _ctx.financialProfile?.financial_data.customer_code
            }, null, 8, ["value"]),
            (_ctx.enableActions)
              ? (_openBlock(), _createBlock(_component_a_g_button, {
                  key: 0,
                  "is-loading": 
          _ctx.selectedId === _ctx.financialProfile.public_id &&
          _ctx.isUnlinkingFinancialProfile
        ,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onUnlinkFinancialProfile(_ctx.financialProfile.public_id)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Unlink ")
                  ]),
                  _: 1
                }, 8, ["is-loading"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ag_modal, {
      modelValue: _ctx.isModalOpen,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isModalOpen) = $event)),
      "modal-width": "60%"
    }, {
      header: _withCtx(() => [
        _createVNode(_component_agheading, null, {
          default: _withCtx(() => [
            _createTextVNode("Pending Receipt and Invoice of "),
            _createElementVNode("b", null, _toDisplayString(_ctx.financialProfile.financial_profile_name), 1)
          ]),
          _: 1
        })
      ]),
      body: _withCtx(() => [
        _createVNode(_component_ag_card, null, {
          default: _withCtx(() => [
            (_ctx.pendingPostingInvoices.length > 0)
              ? (_openBlock(), _createBlock(_component_ag_heading, {
                  key: 0,
                  variant: "h2",
                  title: "Invoices"
                }))
              : _createCommentVNode("", true),
            (_ctx.pendingPostingInvoices && _ctx.pendingPostingInvoices.length > 0)
              ? (_openBlock(), _createBlock(_component_AgTable, {
                  key: 1,
                  headers: _ctx.tableHeaders_Invoices,
                  items: _ctx.pendingPostingInvoices,
                  "has-pagination": true,
                  "items-per-page": 5,
                  "has-search": false
                }, {
                  "col-ticket_no": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.ticket_no), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  "col-pnr": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, { class: "color_chip green" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.pnr), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  "col-posting_status": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, { class: "color_chip green" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.posting_status), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["headers", "items"]))
              : _createCommentVNode("", true),
            (_ctx.pendingPostingReceipts.length > 0)
              ? (_openBlock(), _createBlock(_component_ag_heading, {
                  key: 2,
                  variant: "h2",
                  title: "Receipts",
                  style: {"margin-bottom":"0.5cm","margin-top":"0.5cm"}
                }))
              : _createCommentVNode("", true),
            (_ctx.pendingPostingReceipts && _ctx.pendingPostingReceipts.length > 0)
              ? (_openBlock(), _createBlock(_component_AgTable, {
                  key: 3,
                  headers: _ctx.tableHeaders_Receipts,
                  items: _ctx.pendingPostingReceipts,
                  "has-pagination": true,
                  "items-per-page": 5,
                  "has-search": false
                }, {
                  "col-payment_id": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.payment_id), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  "col-amount": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, { class: "color_chip green" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.amount), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  "col-posting_status": _withCtx(({ item }) => [
                    _createVNode(_component_ag_div, { class: "color_chip green" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.raw.posting_status), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["headers", "items"]))
              : _createCommentVNode("", true),
            (
            _ctx.pendingPostingInvoices.length === 0 &&
            _ctx.pendingPostingReceipts.length === 0
          )
              ? (_openBlock(), _createBlock(_component_AgNotFound, {
                  key: 4,
                  "test-id": "",
                  heading: "No Pending Receipts & Invoices Found",
                  description: ""
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_AGButton, {
          onClick: _ctx.closeModal,
          variant: "primary"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Close")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}