import { MutationTree } from "vuex";
import {
  IDashboardState,
  IBookingAggregate,
  IDashboardGraph,
} from "@/ag-portal-common/interfaces/dashboard.interface";

const mutations: MutationTree<IDashboardState> = {
  setInsightsLoading(state, payload: boolean) {
    state.isInsightsLoading = payload;
  },
  saveInsights(state, payload: IDashboardGraph) {
    state.insights = payload;
  },
  saveBookingAggregate(state, payload: IBookingAggregate[]) {
    state.bookingAggregate = payload;
  },
};

export default mutations;
