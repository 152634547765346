import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { FINANCIAL_PROFILE_API_PATH } from "@/modules/FinancialProfile/constants";
import { LinkOrganizationDto } from "@/modules/FinancialProfile/dtos/linkOrganization.dto";
import { formatString } from "@/ag-portal-common/utils/helpers";
import { GetFinancialProfileDto } from "@/modules/FinancialProfile/dtos/financialProfile.dto";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_STYLES } from "@/ag-portal-common/enums/NOTIFICATION_STYLES";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";

class FinancialProfileService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public getAll(params?: GetFinancialProfileDto): Promise<IAGResponse<any>> {
    return this.restClient.get(
      FINANCIAL_PROFILE_API_PATH.GET_ALL,
      params || {}
    );
  }

  public getById(
    id: string,
    params: GetFinancialProfileDto
  ): Promise<IAGResponse<any>> {
    return this.restClient.get(
      formatString(FINANCIAL_PROFILE_API_PATH.GET, id),
      params
    );
  }

  public linkWithOrganization(
    body: LinkOrganizationDto,
    id: string,
    params?: GetFinancialProfileDto
  ): Promise<IAGResponse<any>> {
    return this.restClient.patch(
      formatString(FINANCIAL_PROFILE_API_PATH.PATCH, id),
      body,
      params
    );
  }

  public async unlinkFinancialProfile(id: string): Promise<void> {
    const methodName = "FinancialProfileService.unlinkFinancialProfile";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response: IAGResponse<any> = await this.restClient.put(
        formatString(FINANCIAL_PROFILE_API_PATH.UNLINK, id)
      );

      notificationService.notificationStyle = NOTIFICATION_STYLES.SNACKBAR;
      if (response.success && response.data) {
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          NOTIFICATION_MESSAGES.UNLINK_FINANCIAL_PROFILE_SUCCESS;
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.error || NOTIFICATION_MESSAGES.DEFAULT;
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    notificationService.triggerNotification();
  }
}

export default FinancialProfileService;
