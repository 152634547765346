<template>
  <notification-wrapper>
    <authentication-wrapper>
      <router-view />
    </authentication-wrapper>
  </notification-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NotificationWrapper from "@/ag-portal-common/components/NotificationWrapper.vue";
import Mitt from "mitt";
import { AUTH_EVENTS } from "@/ag-portal-common/eventBusses/auth";
import { NOTIFICATION_EVENTS } from "@/ag-portal-common/eventBusses/notification";
import AuthenticationWrapper from "@/ag-portal-common/components/AuthWrapper.vue";
import { setThemeColor } from "@/ag-portal-common/utils/colortheme";
import { AGENT_FINANCES_EVENTS } from "@/ag-portal-common/eventBusses/agentFinances";

export default defineComponent({
  name: "AppView",
  components: {
    AuthenticationWrapper,
    NotificationWrapper,
  },
  beforeCreate() {
    setThemeColor();
  },
  beforeUnmount() {
    const mitt = Mitt();
    mitt.off(NOTIFICATION_EVENTS.TRIGGER);
    mitt.off(AUTH_EVENTS.LOGIN);
    mitt.off(AUTH_EVENTS.LOGOUT);
    mitt.off(AGENT_FINANCES_EVENTS.REFRESH);
  },
});
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
