import { MutationTree } from "vuex";
import { ISOAState } from "@/ag-portal-common/interfaces/soaState.interface";

const mutations: MutationTree<any> = {
  setDefaultSegmentList: (state, data) => {
    state.defaultSegmentList = data;
  },
  setDefaultSegmentStats: (state, data) => {
    state.defaultSegmentStats = data;
  },
};

export default mutations;
