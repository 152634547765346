import { MutationTree } from "vuex";
import { IOrganizationState } from "@/ag-portal-common/interfaces/organizationState.interface";
import { IOrganizationStats } from "@/ag-portal-common/interfaces/organizationStatistics.interface";
import { IOrganizationProductivity } from "@/ag-portal-common/interfaces/organizationProductivity.interface";
import { IOrganization } from "@/ag-portal-common/interfaces/organization.interface";
import { IOrganizationDetail } from "@/ag-portal-common/interfaces/organizationDetail.interface";
import { IAgentV2 } from "@/ag-portal-common/interfaces/agent.interface";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { IWhatsappMember } from "@/ag-portal-common/interfaces/whatsappMember.interface";
import { IPassenger } from "@/ag-portal-common/interfaces/passenger.interface";
import { CurrencyConversion } from "@/ag-portal-common/types/index";
import { IMyTeam } from "@/ag-portal-common/interfaces/salesMyTeam.interface";

const mutations: MutationTree<IOrganizationState> = {
  enableCreateOrganizationLoading(state) {
    state.isCreatingOrganization = true;
  },
  disableCreateOrganizationLoading(state) {
    state.isCreatingOrganization = false;
  },
  enableUpdateOrganizationLoading(state) {
    state.isUpdatingOrganization = true;
  },
  disableUpdateOrganizationLoading(state) {
    state.isUpdatingOrganization = false;
  },
  enableFetchOrganizationsLoading(state) {
    state.isFetchingOrganizations = true;
  },
  enableFetchOrganizationLoading(state) {
    state.isFetchingOrganization = true;
    state.organization = null;
  },
  enableAddAgentLoading(state) {
    state.isAddingAgent = true;
  },
  disableAddAgentLoading(state) {
    state.isAddingAgent = false;
  },
  saveOrganizations(state, organizations: IOrganization[]) {
    state.isFetchingOrganizations = false;
    state.organizations = organizations;
  },
  saveOrganization(state, organization: IOrganizationDetail) {
    if (state.isFetchingOrganization) {
      state.isFetchingOrganization = false;
      state.organization = organization;
    }
  },
  updateOrganizationStateChange(state, isInProgress: boolean) {
    state.isUpdatingOrganization = isInProgress;
  },
  updateAgents(state, agents: IAgentV2[]) {
    if (state.organization) {
      state.organization.agents = agents;
    }
    state.isAddingAgent = false;
  },
  appendFinancialProfileInOrganization(
    state,
    financialProfile: IFinancialProfile
  ) {
    state.organization?.financial_profiles.push(financialProfile);
  },
  removeFinancialProfileFromOrganization(state, id: string) {
    if (state.organization) {
      let tempFinancialProfiles: IFinancialProfile[] = [
        ...state.organization.financial_profiles,
      ];
      tempFinancialProfiles = tempFinancialProfiles.filter(
        (financialProfile: IFinancialProfile) =>
          financialProfile.public_id !== id
      );
      state.organization.financial_profiles = tempFinancialProfiles;
    }
  },
  updateStatus(state, organization: IOrganizationDetail) {
    state.isUpdatingOrganization = false;
    if (state.organization) {
      state.organization.status = organization.status;
    }
  },
  resetOrganizationState(state) {
    state.isAddingAgent = false;
    state.isFetchingOrganization = false;
    state.organization = null;
    state.organizations = [];
    state.isFetchingOrganizations = false;
    state.isCreatingOrganization = false;
    state.isUpdatingOrganization = false;
    state.isDeletingAgentFromOrganization = false;
  },
  enableIsDeletingAgentFromOrganization(state) {
    state.isDeletingAgentFromOrganization = true;
  },
  disableIsDeletingAgentFromOrganization(state) {
    state.isDeletingAgentFromOrganization = false;
  },
  deleteAgentFromOrganization(state, agentId: string) {
    if (state.organization?.agents) {
      const agents = [...state.organization.agents];
      const deletedAgentIndex = agents
        .map((agent) => agent.agent_id)
        .indexOf(agentId);

      agents.splice(deletedAgentIndex, 1);
      state.organization.agents = agents;
    }
  },
  setUpdateWebsiteConfigurationLoading(state, payload: boolean) {
    state.isUpdateWebsiteConfigurationLoading = payload;
  },
  setWebsiteConfigLoading(state, payload: boolean) {
    state.isWebsiteConfigLoading = payload;
  },
  setWebsiteConfig(state, payload: any) {
    state.websiteConfig = payload;
  },
  setWebsiteContent(state, payload: any) {
    state.websiteContent = payload;
  },
  setUpdateWebsiteContentLoading(state, payload: boolean) {
    state.isUpdateWebsiteContentLoading = payload;
  },
  setWhatsappActionLoading(state, payload: boolean) {
    state.whatsappActionLoading = payload;
  },
  setGenerateOTPLoading(state, payload: boolean) {
    state.isGenerateOTPLoading = payload;
  },
  setWhatsappMembersLoading(state, payload: boolean) {
    state.isWhatsappMembersLoading = payload;
  },
  setWhatsMembers(state, payload: IWhatsappMember[]) {
    state.whatsappMembers = payload;
  },
  fetchingPassengers(state, payload: boolean) {
    state.isFetchingPassengers = payload;
  },
  setPassengerActionLoading(state, payload: boolean) {
    state.passengerActionLoading = payload;
  },
  setPassengers(state, payload: IPassenger[]) {
    state.passengers = payload;
  },
  updatePassenger(
    state,
    payload: { passengerId: string; passenger: IPassenger }
  ) {
    const passengers = state.passengers;

    if (passengers) {
      const updatedPassengers: IPassenger[] = passengers.map(
        (item: IPassenger) => {
          if (item.public_id === payload.passengerId) {
            return { ...payload.passenger, public_id: item.public_id };
          }
          return item;
        }
      );

      state.passengers = updatedPassengers;
    }
  },
  addPassenger(state, payload: { passenger: IPassenger }) {
    const passengers = state.passengers || [];
    const updatedPassengers = [...passengers, payload.passenger];
    state.passengers = updatedPassengers;
  },
  setOrganizationStatsLoading(state, payload: boolean) {
    state.isOrganizationStatsLoading = payload;
  },
  setOrganizationProductivityLoading(state, payload: boolean) {
    state.isOrganizationProductivityLoading = payload;
  },
  setOrganizationStats(state, payload: IOrganizationStats) {
    state.organizationStats = payload;
  },
  setOrganizationProductivity(state, payload: IOrganizationProductivity) {
    state.organizationProductivity = payload;
  },
  setCurrencyLoading(state, payload: boolean) {
    state.isCurrenciesLoading = payload;
  },
  setCurrencies(state, payload: CurrencyConversion[]) {
    state.currencies = payload;
  },
  setSalesMyTeam(state, payload: IMyTeam[]) {
    state.salesMyTeam = payload;
  },
  setSalesMyTeamLoading(state, payload: boolean) {
    state.isSalesMyTeamLoading = payload;
  },
};

export default mutations;
