import { GetterTree } from "vuex";
import { ISectorState } from "@/ag-portal-common/interfaces/sectorState.interface";
import { ISector } from "@/ag-portal-common/interfaces/sector.interface";
import {
  ISectorProductivity,
  ISectorStatistics,
} from "@/ag-portal-common/interfaces/sectorProductivity.interface";

const getters: GetterTree<ISectorState, ISectorState> = {
  sectors: (state): Array<ISector> => state.sectors,
  isFetchingSectors: (state): boolean => state.isFetchingSectors,
  isSectorStatsLoading: (state): boolean => state.isSectorStatsLoading,
  isSectorProductivityLoading: (state): boolean =>
    state.isSectorProductivityLoading,
  sectorStats: (state): ISectorStatistics | null => state.sectorStats,
  sectorProductivity: (state): ISectorProductivity | null =>
    state.sectorProductivity,
};

export default getters;
