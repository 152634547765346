<template>
  <ag-card>
    <ag-heading variant="h3" title="Update Profile"></ag-heading>
    <ag-heading variant="p" title="Update your profile info here"></ag-heading>
    <hr />
    <div class="userProfile">
      <form @submit.prevent>
        <ag-row>
          <ag-column xs="12" sm="6" md="3" lg="2" cols="12">
            <label>Username</label>

            <div class="input-container">
              <div class="input-field">
                <input
                  type="text"
                  placeholder=""
                  v-model="username"
                  disabled
                  @input="username = username.replace(/[^A-Za-z]/g, '')"
                />
              </div>
            </div>
          </ag-column>
          <ag-column xs="12" sm="6" md="4" lg="3" cols="12">
            <label>Email</label>
            <div class="input-container">
              <div class="input-field">
                <input
                  type="text"
                  placeholder="email"
                  v-model="email"
                  disabled
                  @input="email = email.replace(/[^A-Za-z0-9@._-]/g, '')"
                />
              </div>
            </div>
          </ag-column>
          <ag-column xs="12" sm="6" md="4" lg="3" cols="12">
            <label>Phone number</label>
            <div class="input-container">
              <div class="input-field">
                <input
                  type="tel"
                  placeholder="phone"
                  maxlength="12"
                  v-model="phoneNoInput"
                  @input="phoneNoInput = phoneNoInput.replace(/[^z0-9]/g, '')"
                />
              </div>
            </div>
          </ag-column>

          <ag-column xs="12" sm="6" md="5" lg="4" cols="12" class="d-flex">
            <AGButton
              variant="primary"
              @click="updatePhoneNumber"
              class="margin_right_10 align_multiple_btn"
              >Update</AGButton
            >
            <router-link
              to="/profile/changePassword"
              class="align_multiple_btn"
            >
              <AGButton variant="primary" class="full-height full-width"
                >Change Password
              </AGButton>
            </router-link>
          </ag-column>
        </ag-row>

        <span
          >{{ errorMessage }}
          <br />
        </span>
      </form>
    </div>
  </ag-card>
</template>

<style scoped>
label {
  font-size: 14px;
  font-weight: 400;
  padding: 0%;
  margin: 0%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

button {
  padding-left: 6px;
  padding-right: 12px;
  margin-top: 10px;
  min-width: 120px;
  font-size: 14px;
  background-color: #2eb85c;
  color: aliceblue;
  width: 3cm;
  border-radius: 5px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  align-items: center;
  font-weight: 400;
  border-color: white;
}

i {
  color: #3fc79a;
  padding-right: 0.1cm;
}

input {
  border: 1px solid #3fc79a;
  width: 100%;
  box-sizing: border-box;
  height: 57px;
  padding: 10px 20px;
}

.container {
  display: flex;
}

.column {
  flex: 1;
}

.input-container {
  display: flex;
  align-items: center;
  padding-bottom: 0.25%;
}

.input-field {
  flex: 1;
}

.exploreButton {
  padding-left: 6px;
  padding-right: 12px;
  min-width: 70px;
  min-height: 0.5cm;
  font-size: 14px;
  background-color: #2eb85c;
  color: aliceblue;
  width: 1cm;
  border-radius: 3px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  align-items: center;
  font-weight: 400;
  border-color: white;
}

h3 {
  padding: 0.25%;
  margin: 0;
}

input:disabled {
  background-color: #d8dbe0;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.span {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
</style>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";

export default defineComponent({
  name: "userProfile",

  methods: {
    updatePhoneNumber() {
      this.errorMessage = "";
      const myCondition =
        this.phoneNoInput.length === 12 && this.phoneNoInput.startsWith("92");

      if (this.phoneNoInput === localStorage.getItem("phone-number")!) {
        this.errorMessage = "The current phone number is already saved.";
      } else if (myCondition) {
        try {
          const body = {
            phone_number: this.phoneNoInput,
          };

          const payload = {
            body,
            successCallback: () => {
              localStorage.setItem("phone-number", this.phoneNoInput);
            },
            failureCallback: () => {
              //
            },
          };

          this.$store.dispatch("changeNumber", payload);
        } catch (error) {
          notificationService.type = NOTIFICATION_TYPES.ERROR;
          notificationService.description = "Error";
          notificationService.triggerNotification();
        }
      } else {
        this.errorMessage =
          "Make sure your phone number starts with 92 and has a proper length.";
      }
    },
  },
  data() {
    return {
      username: "",
      email: "",
      phoneNoInput: "",
      phoneNoviaApi: "",
      errorMessage: "",
    };
  },
  created() {
    const user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2 | null;
    const currentUser = user();

    if (currentUser) {
      const storedPhoneNumber = localStorage.getItem("phone-number");
      if (!storedPhoneNumber) {
        localStorage.setItem("phone-number", currentUser.phone_number);
      }
    }
  },

  mounted() {
    // this case is of reload

    const user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2 | null;
    const currentUser = user();
    if (currentUser) {
      this.email = currentUser.email || "";
      this.username = currentUser.full_name || "";
      this.phoneNoInput = localStorage.getItem("phone-number")!; // api wala
      this.phoneNoviaApi = localStorage.getItem("phone-number")!; // api wala to check current input vs api thing
    }
  },
});
</script>
