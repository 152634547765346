<template>
  <OrganizationForm
    v-if="$store.getters.organization"
    :organizationData="$store.getters.organization"
    @onSubmitOrganizationForm="updateOrganization"
    :onLoading="$store.getters.isUpdatingOrganization"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OrganizationForm from "@/modules/Organization/components/OrganizationForm.vue";
import { UpdateOrganizationDto } from "@/modules/Organization/dtos/organization.dto";
import { PATH } from "@/ag-portal-common/constants/path";

export default defineComponent({
  name: "UpdateOrganization",
  components: { OrganizationForm },
  beforeMount() {
    if (this.$route.params.id) {
      this.$store.dispatch("fetchOrganization", this.$route.params.id);
    }
  },
  methods: {
    updateOrganization(payload: UpdateOrganizationDto) {
      this.$store.dispatch("updateOrganization", {
        body: payload,
        callback: this.routeToOrganizationDetail,
      });
    },
    routeToOrganizationDetail() {
      this.$router.push(`${PATH.ORGANIZATION}/${this.$route.params.id}`);
    },
  },
});
</script>
