import actions from "./actions";
import states from "./state";
import mutations from "./mutations";
import getters from "./getters";

export default {
  actions,
  states,
  getters,
  mutations,
};
