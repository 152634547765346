import { createApp, h } from "vue";
import singleSpaVue from "single-spa-vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AgCoreUI from "@aeroglobe/ag-core-ui";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import HighchartsVue from "highcharts-vue";

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {
        // single-spa props are available on the "this" object. Forward them to your component as needed.
        // https://single-spa.js.org/docs/building-applications#lifecycle-props
        // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
        /*
                                                                                                name: this.name,
                                                                                                mountParcel: this.mountParcel,
                                                                                                singleSpa: this.singleSpa,
                                                                                                */
      });
    },
  },
  handleInstance(app) {
    app.use(router);
    app.use(store);
    app.use(AgCoreUI);
    app.use(HighchartsVue);
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
