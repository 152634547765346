<template>
  <div>
    <div class="notification-container">
      <ag-snack-bar
        v-if="notificationStyle === NOTIFICATION_STYLES.SNACKBAR"
        :is-open="show"
        :description="description"
        :type="type"
        :testId="genTestId(ELEMENT_TYPES.SNACK_BAR, `${type}-notification`)"
        icon="mdi-home"
        @close="disable"
      />

      <NotificationPopup
        v-else-if="notificationStyle === NOTIFICATION_STYLES.POPUP"
        :show-modal="show"
        :title="title"
        @onClose="show = false"
        :description="description"
        :action-button-text="actionButtonText"
        @onClickAction="action"
      />
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { INotification } from "@/ag-portal-common/interfaces/notification.interface";
import {
  NOTIFICATION_EVENTS,
  notificationBus,
} from "@/ag-portal-common/eventBusses/notification";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { NOTIFICATION_STYLES } from "@/ag-portal-common/enums/NOTIFICATION_STYLES";
import NotificationPopup from "@/ag-portal-common/components/NotificationPopup.vue";

export default defineComponent({
  name: "NotificationWrapper",
  components: { NotificationPopup },
  computed: {
    NOTIFICATION_STYLES() {
      return NOTIFICATION_STYLES;
    },
    ELEMENT_TYPES() {
      return ELEMENT_TYPES;
    },
  },
  data() {
    return {
      show: false,
      notificationStyle: NOTIFICATION_STYLES.SNACKBAR,
      description: "",
      actionButtonText: "",
      title: "",
      type: NOTIFICATION_TYPES.INFO,
    };
  },
  methods: {
    genTestId,
    disable() {
      this.show = false;
    },
    action() {
      return;
    },
    triggerNotification(data: INotification) {
      this.show = false;
      this.description = data.description;
      this.type = data.type;
      this.show = true;
      this.title = data?.title || "";
      this.actionButtonText = data?.actionButtonText || "";
      this.notificationStyle =
        data?.notificationStyle || this.notificationStyle;
      this.action = data?.action || this.action;
    },
  },
  beforeMount() {
    notificationBus.on(NOTIFICATION_EVENTS.TRIGGER, (data) => {
      this.triggerNotification(data as INotification);
    });
  },
});
</script>
