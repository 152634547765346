import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_a_g_text_field = _resolveComponent("a-g-text-field")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_select = _resolveComponent("ag-select")!
  const _component_ag_calendar = _resolveComponent("ag-calendar")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ag_card = _resolveComponent("ag-card")!

  return (_openBlock(), _createBlock(_component_ag_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ag_row, { class: "d-flex align-items-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_ag_column, {
            sm: "6",
            md: "4",
            lg: "2",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_heading, {
                variant: "label",
                title: "Booking Id"
              }),
              _createVNode(_component_a_g_text_field, {
                testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT_FIELD, 'myBookings-bookingId'),
                modelValue: _ctx.bookingId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.bookingId) = $event)),
                value: _ctx.bookingId
              }, null, 8, ["testId", "modelValue", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            sm: "6",
            md: "4",
            lg: "2",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_heading, {
                variant: "label",
                title: "Email"
              }),
              _createVNode(_component_a_g_text_field, {
                testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT_FIELD, 'myBookings-email'),
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                value: _ctx.email
              }, null, 8, ["testId", "modelValue", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            sm: "6",
            md: "4",
            lg: "2",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_heading, {
                variant: "label",
                title: "Status"
              }),
              _createVNode(_component_ag_select, {
                value: _ctx.statusField,
                testId: 
            _ctx.genTestId(_ctx.ELEMENT_TYPES.SELECT_FIELD, 'myBookings-statusField')
          ,
                variant: "outlined",
                modelValue: _ctx.statusField,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.statusField) = $event)),
                items: _ctx.bookingStatuses
              }, null, 8, ["value", "testId", "modelValue", "items"])
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            sm: "6",
            md: "4",
            lg: "2",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_calendar, {
                label: "Booking Date (From)",
                testId: 
            _ctx.genTestId(
              _ctx.ELEMENT_TYPES.CALENDAR_FIELD,
              'myBookings-bookingFromField'
            )
          ,
                variant: "outlined",
                modelValue: _ctx.bookingFromField,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.bookingFromField) = $event)),
                date: _ctx.bookingFromField,
                "max-date": new Date(),
                onOnChangeDate: _ctx.changeBookingFromField,
                "onUpdate:bookingFromField": _ctx.changeBookingFromField
              }, null, 8, ["testId", "modelValue", "date", "max-date", "onOnChangeDate", "onUpdate:bookingFromField"])
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            sm: "6",
            md: "4",
            lg: "2",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_calendar, {
                label: "Booking Date (To)",
                testId: 
            _ctx.genTestId(_ctx.ELEMENT_TYPES.CALENDAR_FIELD, 'myBookings-bookingToField')
          ,
                variant: "outlined",
                modelValue: _ctx.bookingToField,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.bookingToField) = $event)),
                date: _ctx.bookingToField,
                "min-date": _ctx.bookingFromField,
                "max-date": new Date(),
                onOnChangeDate: _ctx.changeBookingToField,
                "onUpdate:bookingToField": _ctx.changeBookingToField
              }, null, 8, ["testId", "modelValue", "date", "min-date", "max-date", "onOnChangeDate", "onUpdate:bookingToField"])
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            sm: "6",
            md: "5",
            lg: "3",
            cols: "12",
            class: "d-flex"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_g_button, {
                testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'myBookings-search'),
                onClick: _ctx.fetchBookings,
                class: "margin_right_10 align_multiple_btn"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Search ")
                ]),
                _: 1
              }, 8, ["testId", "onClick"]),
              _createVNode(_component_a_g_button, {
                onClick: _ctx.clearSearchFilters,
                testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'myBookings-clearAll'),
                class: "align_multiple_btn"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Clear All ")
                ]),
                _: 1
              }, 8, ["onClick", "testId"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}