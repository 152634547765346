import { validateMobileNumber } from "@/ag-portal-common/utils/helpers";
import * as yup from "yup";

export const addWhatsappMemberValidation = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .min(2, "Please enter a valid Name")
    .required("Name is required"),
  isValid: yup.boolean().nullable().required(""),
  contact_number: yup
    .string()
    .nullable()
    .required("Phone number is required")
    .test("valid", "Phone number is not valid", (value, { parent }) => {
      return parent.isValid;
    }),
});
