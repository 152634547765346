<template>
  <OrganizationForm
    @onSubmitOrganizationForm="createOrganization"
    :onLoading="$store.getters.isCreatingOrganization"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OrganizationForm from "@/modules/Organization/components/OrganizationForm.vue";
import { CreateOrganizationDto } from "@/modules/Organization/dtos/organization.dto";
import { PATH } from "@/ag-portal-common/constants/path";

export default defineComponent({
  name: "CreateOrganization",
  components: { OrganizationForm },
  methods: {
    createOrganization(payload: CreateOrganizationDto) {
      this.$store.dispatch("createOrganization", {
        body: payload,
        callback: this.routeToOrganizationDetail,
      });
    },
    routeToOrganizationDetail(id: string) {
      this.$router.push(`${PATH.ORGANIZATION}/${id}`);
    },
  },
});
</script>
