<template>
  <ag-loader v-if="isFetchingOrganizationAgents" />
  <AgTable
    v-else
    :headers="headers"
    :items="agentsList"
    :items-per-page="10"
    :has-pagination="false"
    :has-search="false"
  >
    <template #col-action="{ item }">
      <a-g-button
        :testId="genTestId(ELEMENT_TYPES.BUTTON, `agent-${item.raw.id}-action`)"
        :disabled="item.raw.agent_type === AGENT_SUB_ROLES.OWNER"
        v-if="item.raw.agent_type === AGENT_SUB_ROLES.OWNER"
      >
        {{ item.raw.action }}
      </a-g-button>

      <ag-div class="btn_large" v-else>
        <strong>
          <router-link
            :testId="
              genTestId(ELEMENT_TYPES.LINK, `agent-${item.raw.id}-action`)
            "
            :to="`${PATH.AGENT_PERMISSIONS}/${item.raw.id}`"
          >
            {{ item.raw.action }}
          </router-link>
        </strong>
      </ag-div>
    </template>
  </AgTable>
</template>

<script>
import { PATH } from "@/ag-portal-common/constants/path";
import { AGENT_SUB_ROLES } from "@/ag-portal-common/enums/AGENT_SUB_ROLES";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { inject } from "vue";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";

export default {
  name: "AgentManagement",
  beforeMount() {
    this.$store.dispatch(
      "getOrganizationAgents",
      this.organizationPreference()?.organization_id
    );
  },
  methods: {
    genTestId,
    onClickUpdatePermissions(e, agent) {
      e.stopImmediatePropagation();
      this.$router.push(`${PATH.AGENT_PERMISSIONS}/${agent.id}`);
    },
  },
  computed: {
    PATH() {
      return PATH;
    },
    agentsList() {
      const agentsList = this.$store.getters.organizationAgents;
      return agentsList.map((item) => ({
        id: item.agent_id || item.user.id,
        name: item.user.full_name,
        email: item.user.email,
        is_active: item.user.is_active ? "Active" : "In-Active",
        agent_type: item.user.role_sub_type,
        action: "Update Permissions",
      }));
    },
    isFetchingOrganizationAgents() {
      return this.$store.getters.isFetchingOrganizationAgents;
    },
  },
  data() {
    return {
      ELEMENT_TYPES,
      AGENT_SUB_ROLES,
      headers: [
        {
          title: "Name",
          value: "name",
          key: "name",
          sortable: true,
        },
        {
          title: "Email",
          value: "email",
          key: "email",
          sortable: true,
        },
        {
          title: "Agent Type",
          value: "agent_type",
          key: "agent_type",
          sortable: false,
        },
        {
          title: "Active",
          value: "is_active",
          key: "is_active",
          sortable: true,
        },
        {
          title: "Action",
          value: "action",
          key: "action",
          sortable: false,
        },
      ],
    };
  },
  setup() {
    const organizationPreference = inject(
      AUTH_CONTEXT_KEYS.organizationPreference
    );
    return {
      organizationPreference,
    };
  },
};
</script>
