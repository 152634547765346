import { GetterTree } from "vuex";
import { IPermissionsState } from "@/ag-portal-common/interfaces/permissionsState.interface";
import { IPermission } from "@/ag-portal-common/interfaces/permission.interface";

const getters: GetterTree<IPermissionsState, IPermissionsState> = {
  isFetchingPermissions: (state): boolean => state.isFetchingPermission,
  systemPermissions: (state): Array<IPermission> => {
    return state.permissions;
  },
};

export default getters;
