import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_AgAlertBox = _resolveComponent("AgAlertBox")!
  const _component_AgIcon = _resolveComponent("AgIcon")!
  const _component_AgHeading = _resolveComponent("AgHeading")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    "is-open": _ctx.isOpen,
    class: "change_search_modal",
    "modal-width": "30%",
    onClose: _ctx.onClose
  }, {
    header: _withCtx(() => [
      _createVNode(_component_a_g_button, {
        type: "button",
        class: "modal_close_icon",
        variant: "link",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Close ")
        ]),
        _: 1
      })
    ]),
    body: _withCtx(() => [
      _createVNode(_component_AgDiv, {
        "test-id": "",
        class: "text-center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AgAlertBox, {
            "test-id": "",
            "alert-type": _ctx.type,
            class: "modal_alert"
          }, null, 8, ["alert-type"]),
          _createVNode(_component_AgIcon, {
            name: "",
            class: "margin_bottom_20"
          }),
          _createVNode(_component_AgHeading, {
            title: _ctx.title,
            variant: "h2",
            "test-id": _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT, 'notification-popup-title')
          }, null, 8, ["title", "test-id"]),
          _createVNode(_component_AgHeading, {
            title: _ctx.description,
            variant: "p",
            "test-id": 
            _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT, 'notification-popup-description')
          
          }, null, 8, ["title", "test-id"]),
          _createVNode(_component_AgHeading, {
            title: _ctx.currentDate,
            variant: "h3",
            "test-id": 
            _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT, 'notification-popup-date-time')
          
          }, null, 8, ["title", "test-id"]),
          _createVNode(_component_AGButton, {
            "test-id": 
            _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'notification-popup-action-button')
          ,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClickAction'))),
            variant: "primary",
            type: "button"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.actionButtonText), 1)
            ]),
            _: 1
          }, 8, ["test-id"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open", "onClose"]))
}