import { IPayment, IPayments } from "@/interfaces/payments.interface";
import { PaymentsResponseModel } from "@/modules/Payments/models/payments.response";
import { PAYMENT_METHODS_TYPES } from "@/ag-portal-common/enums/PAYMENT_METHODS_TYPES";
import { PAYMENT_STATUS } from "@/ag-portal-common/enums/PAYMENT_STATUS";
import { RECEIPT_STATUS } from "@/ag-portal-common/enums/RECEIPT_STATUS";
import { PaymentDetailResponseModel } from "@/modules/Payments/models/paymentDetail.response";

export class PaymentsMapper {
  private payments: IPayments = {
    pageNumber: 0,
    pageSize: 0,
    hasNext: false,
    totalPages: 0,
    totalRecords: 0,
    payments: [],
  };

  private payment: IPayment = {
    id: "",
    transactionId: "string",
    transactionType: PAYMENT_METHODS_TYPES.ONE_BILL,
    amount: 0,
    financialProfile: "",
    dateTime: new Date(),
    paymentStatus: PAYMENT_STATUS.INITIATED,
    oneBillDetail: null,
    creditCardlDetail: null,
    description: "",
    receiptStatus: RECEIPT_STATUS.PAYMENT_PENDING,
    ibftDetail: null,
    agentEmail: "",
    agentName: "",
  };

  public mapPaymentsResponse(data: PaymentsResponseModel): IPayments {
    this.payments.pageSize = data.pagination_meta.page_size;
    this.payments.hasNext = data.pagination_meta.has_next;
    this.payments.totalPages = data.pagination_meta.total_pages;
    this.payments.totalRecords = data.pagination_meta.total_records;
    this.payments.pageNumber = data.pagination_meta.page_no;
    this.payments.payments = data.payments.map((item) => ({
      id: item.payment_id,
      transactionId: item.payment_id,
      amount: item.amount,
      financialProfile: item.financial_profile,
      dateTime: new Date(item.created_at),
      transactionType: item.method as PAYMENT_METHODS_TYPES,
      paymentStatus: item.payment_status as PAYMENT_STATUS,
      receiptStatus: item.receipt_status as RECEIPT_STATUS,
      description: item.description,
      creditCardlDetail: null,
      oneBillDetail: null,
      agentEmail: item.agent_details.agent_email,
      agentName: item.agent_details.agent_name,
      ibftDetail: null,
    }));

    return this.payments;
  }

  public mapPaymentResponse(data: PaymentDetailResponseModel): IPayment {
    this.payment.id = data.payment.payment_id;
    this.payment.transactionId = data.payment.payment_id;
    this.payment.description = data.payment.description;
    this.payment.amount = data.payment.amount;
    this.payment.financialProfile = data.payment.financial_profile;
    this.payment.dateTime = new Date(data.payment.created_at);
    this.payment.transactionType = data.payment.method as PAYMENT_METHODS_TYPES;
    this.payment.paymentStatus = data.payment.payment_status as PAYMENT_STATUS;
    this.payment.receiptStatus = data.payment.receipt_status as RECEIPT_STATUS;
    this.payment.agentEmail = data.payment.agent_details.agent_email;
    this.payment.agentName = data.payment.agent_details.agent_name;

    if (data.payment.credit_card_payment_detail) {
      this.payment.creditCardlDetail = {
        paymentId: data.payment.credit_card_payment_detail.payment_id,
        paymentCode: data.payment.credit_card_payment_detail.payment_code,
        paymentUrl: data.payment.credit_card_payment_detail.payment_url,
        expiryDate: data.payment.credit_card_payment_detail.expiry_date,
      };
    }
    if (data.payment.one_bill_payment_detail) {
      this.payment.oneBillDetail = {
        billCode: data.payment.one_bill_payment_detail.bill_code,
        billId: data.payment.one_bill_payment_detail.bill_id,
        expiryDate: data.payment.one_bill_payment_detail.expiry_date,
      };
    }
    if (data.payment.ibft_payment_detail) {
      this.payment.ibftDetail = {
        paymentCode: data.payment.ibft_payment_detail.payment_code,
      };
    }
    return this.payment;
  }

  public mapCreatePaymentResponse(data: PaymentDetailResponseModel): {
    id: string;
  } {
    const payload = {
      id: "",
    };

    payload.id = data.payment.payment_id;
    return payload;
  }
}
