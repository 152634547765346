import { RouteRecordRaw } from "vue-router";
import AgFourZeroFour from "@/ag-portal-common/components/AgFourZeroFour.vue";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "404",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.AG_FOUR_ZERO_FOUR.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.AG_FOUR_ZERO_FOUR.PERMISSIONS,
    },
    component: AgFourZeroFour,
  },
];

export default routes;
