<template>
  <ag-select
    :items="financialProfiles"
    v-model="financialProfilePublicId"
    :error="error"
    :value="financialProfilePublicId"
    :label="label"
    variant="outlined"
  />
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { IOrganizationFromLoginResponse } from "@/ag-portal-common/interfaces/organization.interface";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";

export default defineComponent({
  name: "FinancialProfileDropdown",
  props: {
    error: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
  data(): {
    financialProfilePublicId: string | null;
  } {
    return {
      financialProfilePublicId: null,
    };
  },
  computed: {
    financialProfiles() {
      let organization = this.organization() as IOrganizationFromLoginResponse;

      return organization?.financial_profiles.map((item) => ({
        label: `${item.plan_name}: ${item.financial_profile_name} (${item.platform_id})`,
        value: item.public_id,
      }));
    },
  },
  created() {
    let organization =
      (this.organization() as IOrganizationFromLoginResponse) || null;
    let defaultFinancialProfile = organization.financial_profiles.find(
      (item) => item.is_default
    );

    if (defaultFinancialProfile) {
      this.financialProfilePublicId = defaultFinancialProfile.public_id;
    }
  },
  setup() {
    const organization = inject(
      AUTH_CONTEXT_KEYS.organization
    ) as () => IOrganizationFromLoginResponse;
    return { organization };
  },
  watch: {
    financialProfilePublicId(value) {
      this.$emit("onUpdateFinancialProfile", value);
    },
  },
});
</script>
