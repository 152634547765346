export const ORGANIZATION_API_PATH = {
  GET: "v2/organization/",
  POST: "v2/organization/",
  PATCH: "v2/organization/",
  GET_BY_ID: "v2/organization/",
  ADD_AGENT_IN_ORGANIZATION: "v2/organization/{0}/create_allowed_email/",
  GET_ORGANIZATION_AGENT_BY_ID: "v2/organization/{0}/agent/{1}/",
  ORGANIZATION_FORM: "v2/organization/creation-form/",
  WEBSITE_CONFIGURATION: "v2/organization/{0}/website/",
  WEBSITE_CONTENT: "v2/organization/{0}/website/content/",
  WEBSITE_CONTENT_UPDATE: "v2/organization/{0}/website/content/update/",
  GET_ORGANIZATION_PASSENGERS: "v2/organization/{0}/passengers",
  POST_ORGANIZATION_PASSENGERS: "v2/organization/{0}/passengers",
  UPDATE_ORGANIZATION_PASSENGERS: "v2/organization/{0}/passengers/{1}",
  CONTENT_UPLOAD: "v2/content/upload/{0}/",
  ORGANIZATION_SNAPSHOT: "v2/organization/{0}/usage-snapshot/",
  ORGANIZATION_PRODUCTIVITY: "v2/organization/{0}/productivity-snapshot/",
  CURRENCIES: "v1/currency/",
  SALES_MY_TEAM: "salesuser/sales-user-team",
};

export const ORGANIZATION_COLORS = [
  {
    code: "#10B981",
    name: "Aeroglobe",
  },
  {
    code: "#000000",
    name: "Black",
  },
  {
    code: "#014B4C",
    name: "Teal",
  },
  {
    code: "#022E1F",
    name: "Green",
  },
  {
    code: "#595B00",
    name: "Olive",
  },
  {
    code: "#9A8872",
    name: "Beige",
  },
  {
    code: "#E87F78",
    name: "Peach",
  },
  {
    code: "#870000",
    name: "Red",
  },
  {
    code: "#5B002B",
    name: "Maroon",
  },
  {
    code: "#820FC0",
    name: "Purple",
  },
  {
    code: "#02005B",
    name: "Navy Blue",
  },
  {
    code: "#986A33",
    name: "Brown",
  },
  {
    code: "#EFB701",
    name: "Yellow",
  },
  {
    code: "#D54B01",
    name: "Orange",
  },
];

export const PIA_PROVIDERS = [
  {
    label: "Quality Aviation",
    value: "QualityAviation",
  },
  {
    label: "Quality Aviation - Lite",
    value: "QA_LITE",
  },
  { label: "Soneri - Lite", value: "AG_LITE_RWP" },
  {
    label: "Soneri",
    value: "Soneri",
  },
  {
    label: "Fleet Aviation",
    value: "AG_LHE_FA",
  },
  {
    label: "Fleet Aviation - Lite",
    value: "AG_LHE_FA_LITE",
  },
];

export const CATEGORIES = [
  "Travel Agent",
  "Staff Individuals Account",
  "Corporate CWT Referred",
  "Local Corporate Clients",
  "Vox Labs",
  "Sasta Ticket Account",
];

export const WHATSAPP_API_PATH = {
  GET: "v2/organization/{0}/whatsapp-whitelist-numbers/",
  POST: "v2/organization/{0}/whatsapp-whitelist-numbers/",
  GENERATE_OTP:
    "v2/organization/{0}/whatsapp-whitelist-number/{1}/generate-otp/",
  AUTHORIZE_OTP:
    "v2/organization/{0}/whatsapp-whitelist-number/{1}/authorize-contact-number/",
  DELETE_WHATSAPP: "v2/organization/{0}/whatsapp-whitelist-numbers",
};
