import { IMyBookingsState } from "@/interfaces/myBookingsState.interface";

const state: IMyBookingsState = {
  flightBookings: {
    count: 0,
    previous: "",
    next: "",
    bookings: [],
  },
  hotelBookings: {
    count: 0,
    previous: "",
    next: "",
    bookings: [],
  },
  tourBookings: {
    count: 0,
    previous: "",
    next: "",
    bookings: [],
  },
  isFetchingHotelBookings: false,
  isDownloadingBrandTicket: false,
  isSendingAirlineTicket: false,
  isFetchingFlightBookings: false,
  isFetchingTourBookings: false,
};

export default state;
