import { createStore, ModuleTree } from "vuex";
import authModule from "@/modules/Auth/vuex";
import agentModule from "@/modules/Agent/vuex";
import organizationModule from "@/modules/Organization/vuex";
import soaModule from "@/modules/SOA/vuex";
import sectorModule from "@/modules/Sector/vuex";
import permissionsModule from "@/modules/Permissions/vuex";
import financialProfileModule from "@/modules/FinancialProfile/vuex";
import myBookingsModule from "@/modules/MyBookings/vuex";
import paymentsModule from "@/modules/Payments/vuex";
import creditLimitManagementModule from "@/modules/CreditLimitManagement/vuex";
import dashboardModule from "@/modules/Dashboard/vuex";
import sabreSegmentModule from "@/modules/SabreSegment/vuex";
import newsModule from "@/modules/News/vuex";
import salesModule from "@/modules/Sales/vuex";

const modules: ModuleTree<any> = {
  authModule,
  agentModule,
  organizationModule,
  soaModule,
  permissionsModule,
  sectorModule,
  financialProfileModule,
  myBookingsModule,
  paymentsModule,
  creditLimitManagementModule,
  dashboardModule,
  sabreSegmentModule,
  newsModule,
  salesModule,
};

export default createStore({
  modules,
});
