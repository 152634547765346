<template>
  <ag-modal test-id="" :is-open="isOpen" @close="closeModal" modal-width="40%">
    <template #header>
      <v-card-title>{{ isEdit ? "Edit" : "Add" }} Service</v-card-title>
      <AGButton
        test-id=""
        type="button"
        class="modal_close_icon"
        variant="link"
        @click="closeModal"
        >Close
      </AGButton>
    </template>
    <template #body>
      <form @submit="onSubmit">
        <AgFile
          test-id=""
          class="ag-file-theme"
          label="Service Image"
          :multiple="false"
          @on:change="onFileChangeHandler"
        />
        <AGTextField
          :test-id="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'service-price')"
          v-model="name"
          :value="name"
          type="text"
          label="Service Name"
          :error="errors?.name"
        />
        <AGTextField
          :test-id="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'service-price')"
          v-model="description"
          :value="description"
          type="text"
          label="Description"
          :error="errors?.description"
        />

        <AGButton
          test-id=""
          type="submit"
          class="submit-btn"
          :disabled="isLoading"
        >
          {{ isEdit ? "Save" : "Add" }}
        </AGButton>
      </form>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { ValidationError } from "yup";

import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import {
  ContentUpload,
  UPLOAD_CONTENT_CATEGORY,
  UPLOAD_CONTENT_SUB_CATEGORY,
} from "@/modules/Organization/dtos/organization.dto";
import { addServiceValidation } from "../validations/addService.validation";
import { IOrganizationFromLoginResponse } from "@/ag-portal-common/interfaces/organization.interface";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";

export default defineComponent({
  name: "AddPackageModal",
  emits: ["closeModal", "onSubmit"],
  data(): {
    name: string;
    display_image_id?: string | null;
    description: string;
    errors: any;
    ELEMENT_TYPES: typeof ELEMENT_TYPES;
    selectedFile: File | null;
    isLoading: boolean;
  } {
    return {
      name: "",
      display_image_id: null,
      description: "",
      errors: {},
      ELEMENT_TYPES,
      selectedFile: null,
      isLoading: false,
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
  },
  methods: {
    genTestId,
    closeModal() {
      this.$emit("closeModal");
      this.clearState();
    },
    initializeState() {
      if (this.isEdit) {
        this.name = this.data?.name;
        this.description = this.data?.description;
        this.display_image_id = this.data?.display_image_id;
      }
    },
    clearState() {
      this.name = "";
      this.description = "";
      this.display_image_id = null;
      this.selectedFile = null;
    },
    async onSubmit(e: any) {
      this.isLoading = true;
      this.errors = {};
      e.preventDefault();

      await this.onContentUploadHandler();

      try {
        const payload = await addServiceValidation.validate(
          {
            name: this.name,
            display_image_id: this.display_image_id,
            description: this.description,
          },
          { abortEarly: false }
        );
        this.$emit("onSubmit", payload);
        this.clearState();
      } catch (ex) {
        if (ex instanceof ValidationError) {
          const _errors = yupValidationErrorAsSchema(ex);
          this.errors = _errors;
        }
      }
      this.isLoading = false;
    },
    onFileChangeHandler(files: FileList) {
      const file = files[0];
      this.selectedFile = file;
    },
    async onContentUploadHandler() {
      if (this.selectedFile) {
        const payload: ContentUpload = {
          image_content: this.selectedFile,
          content_category: UPLOAD_CONTENT_CATEGORY.WEBSITE,
          content_sub_category: UPLOAD_CONTENT_SUB_CATEGORY.PACKAGE,
        };

        const organization =
          (this.organization() as IOrganizationFromLoginResponse) || null;

        const response = await this.$store.dispatch("websiteUploadContent", {
          payload,
          organizationId: organization.organization_id,
        });

        this.display_image_id = response;
      }
    },
  },
  watch: {
    isOpen(val: boolean) {
      if (val) {
        setTimeout(() => {
          this.initializeState();
        }, 0);
      }
    },
  },
  setup() {
    const organization = inject(
      AUTH_CONTEXT_KEYS.organization
    ) as () => IOrganizationFromLoginResponse;
    return { organization };
  },
});
</script>

<style scoped lang="scss">
#packageImageUploader {
  transition: scale 0.2s ease-in-out;

  &:active {
    transform: scale(0.98);
  }
}

.submit-btn {
  float: right;
}
</style>
