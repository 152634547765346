User
<template>
  <ag-card>
    <form @submit="onSubmit">
      <ag-row>
        <ag-column sm="12" lg="6">
          <a-g-text-field
            :testId="
              genTestId(ELEMENT_TYPES.TEXT_FIELD, 'organization-form-name')
            "
            label="Name"
            v-model="name"
            :value="name"
            :error="errors['name']"
          />
        </ag-column>
        <ag-column sm="12" lg="6">
          <ag-select
            label="Sector Name"
            :testId="
              genTestId(ELEMENT_TYPES.SELECT_FIELD, 'organization-form-sector')
            "
            :items="sectors"
            v-model="sector"
            :value="sector"
            :error="errors['sector']"
            variant="outlined"
            @change="updateRelatedPersons"
          />
        </ag-column>
      </ag-row>

      <ag-row>
        <ag-column sm="12" lg="6">
          <ag-select
            label="PIA Provider"
            :testId="
              genTestId(
                ELEMENT_TYPES.SELECT_FIELD,
                'organization-form-pia_provider'
              )
            "
            :items="apiResponse?.pia_provider"
            v-model="pia_provider"
            :value="pia_provider"
            :error="errors['pia_provider']"
            variant="outlined"
          />
        </ag-column>

        <ag-column sm="12" lg="6" cols="12">
          <a-g-text-field
            label="Phone"
            :testId="
              genTestId(
                ELEMENT_TYPES.TEXT_FIELD,
                'organization-form-contact_person_phone'
              )
            "
            v-model="contact_person_phone"
            :value="contact_person_phone"
            :error="errors['contact_person_phone']"
          />
        </ag-column>
        <ag-column sm="12" lg="6">
          <a-g-text-field
            type="email"
            label="Email"
            :testId="
              genTestId(
                ELEMENT_TYPES.SELECT_FIELD,
                'organization-form-contact_person_email'
              )
            "
            v-model="contact_person_email"
            :value="contact_person_email"
            :error="errors['contact_person_email']"
          />
        </ag-column>
        <ag-column sm="12" lg="6">
          <ag-select
            label="Currency"
            :testId="
              genTestId(
                ELEMENT_TYPES.SELECT_FIELD,
                'organization-form-currency'
              )
            "
            :items="apiResponse?.currency"
            v-model="currency"
            :value="currency"
            :error="errors['currency']"
            variant="outlined"
          />
        </ag-column>
        <ag-column sm="12" lg="6">
          <ag-select
            v-show="sector"
            label="Related Person"
            :testId="
              genTestId(
                ELEMENT_TYPES.SELECT_FIELD,
                'organization-form-related_person'
              )
            "
            :items="relatedPersons"
            v-model="relatedPerson"
            :value="relatedPerson"
            :error="errors['relatedPerson']"
            variant="outlined"
          />
        </ag-column>
      </ag-row>

      <a-g-button
        type="submit"
        :isLoading="onLoading"
        :testId="genTestId(ELEMENT_TYPES.BUTTON, 'organization-form-submit')"
        :disabled="onLoading"
        >Submit
      </a-g-button>
    </form>
  </ag-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { ISector } from "@/ag-portal-common/interfaces/sector.interface";
import { IOrganizationDetail } from "@/ag-portal-common/interfaces/organizationDetail.interface";
import { organizationCreateUpdateValidation } from "@/modules/Organization/validations/organizationCreateUpdateForm.validation";
import { ValidationError } from "yup";
import {
  genTestId,
  yupValidationErrorAsSchema,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { object } from "firebase-functions/v1/storage";

// Define the type for sales user objects
interface SalesUser {
  id: number;
  name: string;
  email: string;
}

export default defineComponent({
  name: "OrganizationForm",
  props: {
    onLoading: {
      type: Boolean,
    },
    organizationData: {
      type: Object as PropType<IOrganizationDetail>,
      required: false,
    },
  },
  async created() {
    this.apiResponse = await this.$store.dispatch("fetchCreateFormDetails");
    this.sectors = this.apiResponse.sector.map(
      (sectorObj: any) => sectorObj.sector_name
    );
    this.relatedPersons = [];
  },
  data(): {
    name: string;
    sector: string;
    sectors: string[];
    pia_provider: string;
    contact_person_phone: string;
    contact_person_email: string;
    is_active: boolean;
    is_delete: boolean;
    errors: any;
    ELEMENT_TYPES: IObject;
    apiResponse: any;
    currency: string;
    relatedPersons: string[];
    relatedPerson: string;
  } {
    return {
      ELEMENT_TYPES,
      name: this.organizationData?.name || "",
      sector: "",
      sectors: [],
      pia_provider: "",
      contact_person_phone: this.organizationData?.contact_person_phone || "",
      contact_person_email: this.organizationData?.contact_person_email || "",
      is_active: this.organizationData?.is_active || true,
      is_delete: this.organizationData?.is_delete || false,
      errors: {},
      apiResponse: {},
      currency: "",
      relatedPersons: [],
      relatedPerson: "",
    };
  },
  methods: {
    genTestId,
    onSubmit: async function (event: any) {
      event.preventDefault();
      try {
        let {
          name,
          pia_provider,
          contact_person_phone,
          contact_person_email,
          is_delete,
          is_active,
          relatedPerson,
        } = this;
        const payload = await organizationCreateUpdateValidation.validate(
          {
            name,
            sector: this.selectedSectorId,
            pia_provider,
            contact_person_phone,
            contact_person_email,
            is_delete,
            is_active,
            currency: this.currency,
            relatedPerson: this.selectedRelatedPersonId,
            sales_poc: this.selectedRelatedPersonId,
          },
          { abortEarly: false }
        );

        this.$emit("onSubmitOrganizationForm", {
          ...payload,
          public_id: this.organizationData?.public_id,
        });
      } catch (exception) {
        if (exception instanceof ValidationError) {
          this.errors = yupValidationErrorAsSchema(exception);
        }
      }
    },
    updateRelatedPersons() {
      const selectedSector = this.sector;
      const sectorObj = this.apiResponse.sector.find(
        (sector: any) => sector.sector_name === selectedSector
      );
      if (sectorObj) {
        this.relatedPersons = sectorObj.sales_users.map(
          (user: any) => user.name + " - (" + user.email + ")"
        );
      } else {
        this.relatedPersons = [];
      }
      this.relatedPerson = "";
      this.selectedSectorId;
    },
  },
  computed: {
    selectedSectorId(): number | null {
      // selected sector ki id laa kr de mereko
      const selectedSector = this.apiResponse.sector.find(
        (sector: any) => sector.sector_name === this.sector
      );
      return selectedSector ? selectedSector.sector_id : null;
    },
    selectedRelatedPersonId(): number | null {
      // Find the related person object with the selected name
      const selectedRelatedPerson = this.apiResponse.sector
        .flatMap((sector: { sales_users: SalesUser[] }) => sector.sales_users)
        .find(
          (user: SalesUser) =>
            user.name + " - (" + user.email + ")" === this.relatedPerson
        );

      // If a related person is found, return their ID; otherwise, return null
      return selectedRelatedPerson ? selectedRelatedPerson.id : null;
    },
  },
});
</script>
