<template>
  <ag-card>
    <AgTabs test-id="test" :panel-value="[1]" class="no_tab_icon">
      <template #TabHeading>
        <v-tab
          v-for="(status, index) in Object.keys(CREDIT_LIMIT_REQUEST_STATUSES)"
          v-bind:key="index"
          :value="index"
          @click="onClickTab($event, CREDIT_LIMIT_REQUEST_STATUSES[status])"
          ><span>{{
            formatWordIntoCapitalize(status.replaceAll("_", " "))
          }}</span></v-tab
        >
      </template>
      <template #TabDetail>
        <ag-div>
          <ag-loader
            class="padding_top_20"
            v-if="isFetchingCreditLimitRequests"
          />

          <ag-table
            v-else-if="
              !isFetchingCreditLimitRequests && creditLimitRequests.data.length
            "
            :headers="headers"
            :items="creditLimitRequests.data"
            :total-page-count="creditLimitRequests.totalPages"
            :page="page"
            @onPageChange="onPageChange"
            @on-click-row="onItemClick"
            :items-per-page="itemsPerPage"
            :has-pagination="true"
            :has-search="false"
          >
            <template #col-requestInitiatedAt="{ item }">
              <ag-heading
                :title="
                  getFormattedDateTime(
                    item.raw.requestInitiatedAt,
                    FORMAT_YYY_MM_DD
                  )
                "
                variant="p"
                class="margin_bottom_0"
              />

              <ag-heading
                class="margin_bottom_0 text-color-green"
                :title="
                  getFormattedDateTime(
                    item.raw.requestInitiatedAt,
                    FORMAT_HH_SS_A
                  )
                "
                variant="p"
              />
            </template>
            <template #col-requestedAmount="{ item }">
              {{ formatNumber(item.raw.requestedAmount) }}
            </template>
          </ag-table>

          <ag-not-found
            v-else
            :heading="NOTIFICATION_MESSAGES.NO_RESULTS_FOUND"
            :description="NOTIFICATION_MESSAGES.NO_RESULTS_FOUND_DESCRIPTION"
          />
        </ag-div>
      </template>
    </AgTabs>
  </ag-card>
</template>

<script lang="ts">
import { CREDIT_LIMIT_REQUEST_STATUSES } from "@/ag-portal-common/enums/CREDIT_LIMIT_REQUEST_STATUSES";
import {
  formatNumber,
  formatStringToRoutePath,
  formatWordIntoCapitalize,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import { IPaginatedCreditLimitRequests } from "@/interfaces/creditLimitRequest.interface";
import {
  FORMAT_HH_SS_A,
  FORMAT_YYY_MM_DD,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { defineComponent } from "vue";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { PATH } from "@/ag-portal-common/constants/path";

export default defineComponent({
  name: "ListViewForSalesUser",
  data() {
    return {
      itemsPerPage: 0,
      page: 1,
      activeTab: CREDIT_LIMIT_REQUEST_STATUSES.PENDING,
      headers: [
        {
          key: "requestInitiatedAt",
          title: "Request Initiated At",
          align: "start",
          value: "requestInitiatedAt",
        },
        {
          key: "organizationName",
          title: "Organization",
          value: "organizationName",
        },
        {
          key: "agentEmail",
          title: "Requester Email",
          value: "agentEmail",
        },
        {
          key: "agentSectorName",
          title: "Sector",
          value: "agentSectorName",
        },
        {
          key: "requestNotes",
          title: "Request Notes",
          value: "requestNotes",
        },
        {
          key: "priority",
          title: "Priority",
          value: "priority",
        },
        {
          key: "status",
          title: "Status",
          value: "status",
        },
        {
          key: "requestedAmount",
          title: "Requested Amount",
          value: "requestedAmount",
        },
        {
          key: "requestedDays",
          title: "Requested Days",
          value: "requestedDays",
        },
      ],
    };
  },
  methods: {
    formatWordIntoCapitalize,
    formatNumber,
    getFormattedDateTime,
    onItemClick(event: PointerEvent, row: any) {
      this.$router.push(
        formatStringToRoutePath(PATH.CREDIT_LIMIT_REQUEST_DETAIL, {
          id: row.item.raw.id,
        })
      );
    },
    setItemsPerPage() {
      let requestsLength = this.$store.getters.creditLimitRequests.data.length;
      this.itemsPerPage = requestsLength;
    },
    fetchCreditLimitRequests(callback?: () => void) {
      let params = {
        status: this.activeTab,
        page: this.page,
      };
      let payload = {
        params,
        callback,
      };

      this.$store.dispatch("fetchCreditLimitRequests", payload);
    },
    onPageChange(num: number) {
      let nextPage = this.$store.getters.creditLimitRequests.next;
      let prevPage = this.$store.getters.creditLimitRequests.previous;
      let currentPage: number = this.page || 1;
      if ((num > currentPage && nextPage) || (currentPage > num && prevPage)) {
        this.page = num;
        this.fetchCreditLimitRequests();
      }
    },
    onClickTab(e: PointerEvent, tab: CREDIT_LIMIT_REQUEST_STATUSES) {
      this.activeTab = tab;
    },
  },
  computed: {
    FORMAT_HH_SS_A() {
      return FORMAT_HH_SS_A;
    },
    NOTIFICATION_MESSAGES() {
      return NOTIFICATION_MESSAGES;
    },
    CREDIT_LIMIT_REQUEST_STATUSES() {
      return CREDIT_LIMIT_REQUEST_STATUSES;
    },
    creditLimitRequests(): IPaginatedCreditLimitRequests {
      return this.$store.getters.creditLimitRequests;
    },
    isFetchingCreditLimitRequests(): boolean {
      return this.$store.getters.isFetchingCreditLimitRequests;
    },
    FORMAT_YYY_MM_DD() {
      return FORMAT_YYY_MM_DD;
    },
  },
  watch: {
    activeTab() {
      this.page = 1;
      this.fetchCreditLimitRequests(this.setItemsPerPage);
    },
  },
  beforeMount() {
    this.fetchCreditLimitRequests(this.setItemsPerPage);
  },
});
</script>
