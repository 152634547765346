import { MutationTree } from "vuex";
import { IFinancialProfileState } from "@/ag-portal-common/interfaces/financialProfileState.interface";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";

const mutations: MutationTree<IFinancialProfileState> = {
  enableIsFetchingFinancialProfiles(state: IFinancialProfileState) {
    state.isFetchingFinancialProfiles = true;
  },
  disableIsFetchingFinancialProfiles(state: IFinancialProfileState) {
    state.isFetchingFinancialProfiles = false;
  },
  enableIsFetchingFinancialProfile(state: IFinancialProfileState) {
    state.isFetchingFinancialProfile = true;
  },
  disableIsFetchingFinancialProfile(state: IFinancialProfileState) {
    state.isFetchingFinancialProfile = false;
  },
  enableIsLinkingFinancialProfile(state: IFinancialProfileState) {
    state.isLinkingFinancialProfile = true;
  },
  disableIsLinkingFinancialProfile(state: any) {
    state.isLinkingFinancialProfile = false;
  },
  enableIsUnlinkingFinancialProfile(state: IFinancialProfileState) {
    state.isUnlinkingFinancialProfile = true;
  },
  disableIsUnlinkingFinancialProfile(state: IFinancialProfileState) {
    state.isUnlinkingFinancialProfile = false;
  },
  saveFinancialProfiles(
    state: IFinancialProfileState,
    payload: IFinancialProfile[]
  ) {
    state.financialProfiles = payload;
    state.isFetchingFinancialProfiles = false;
  },

  saveFinancialProfile(
    state: IFinancialProfileState,
    payload: IFinancialProfile
  ) {
    state.financialProfile = payload;
    state.isFetchingFinancialProfile = false;
  },
};

export default mutations;
