import { GetterTree } from "vuex";
import { ICreditLimitManagementState } from "@/interfaces/creditLimitManagementState.interface";
import { IPaginatedCreditLimitRequests } from "@/interfaces/creditLimitRequest.interface";
import { ICreditLimitRequestDetail } from "@/interfaces/creditLimitRequestDetail.interface";

const getters: GetterTree<
  ICreditLimitManagementState,
  ICreditLimitManagementState
> = {
  isFetchingCreditLimitRequests: (
    state: ICreditLimitManagementState
  ): boolean => state.isFetchingCreditLimitRequests,
  isFetchingCreditLimitRequestDetail: (
    state: ICreditLimitManagementState
  ): boolean => state.isFetchingCreditLimitRequestDetail,
  isCreatingCreditLimitRequest: (state: ICreditLimitManagementState): boolean =>
    state.isCreatingCreditLimitRequest,
  isProcessingCreditLimitRequest: (
    state: ICreditLimitManagementState
  ): boolean => state.isProcessingCreditLimitRequest,
  creditLimitRequests: (
    state: ICreditLimitManagementState
  ): IPaginatedCreditLimitRequests => state.creditLimitRequests,
  creditLimitRequestDetail: (
    state: ICreditLimitManagementState
  ): ICreditLimitRequestDetail | null => state.creditLimitRequestDetail,
};

export default getters;
