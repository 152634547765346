import { toDisplayString as _toDisplayString, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgAutoComplete = _resolveComponent("AgAutoComplete")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_calendar = _resolveComponent("ag-calendar")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_AgTable = _resolveComponent("AgTable")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ag_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ag_row, { class: "d-flex align-items-center" }, {
          default: _withCtx(() => [
            (_ctx.isAllowedUser())
              ? (_openBlock(), _createBlock(_component_ag_column, {
                  key: 0,
                  xs: "12",
                  lg: "3",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AgAutoComplete, {
                      testId: 
              _ctx.genTestId(
                _ctx.ELEMENT_TYPES.TEXT_FIELD as any,
                'organization-autocomplete-search'
              )
            ,
                      modelValue: _ctx.organizationId,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.organizationId) = $event)),
                      label: "Organization",
                      icon: "calendar",
                      items: _ctx.filterProfiles,
                      clearable: true,
                      isLoading: _ctx.isFetchingOrganizations,
                      class: "user-input",
                      isdisabled: _ctx.isFetchingOrganizations,
                      onKeyup: _ctx.onChangeOrgInputHandler
                    }, {
                      dropdownItem: _withCtx(({ itemProps, itemData }) => [
                        _createElementVNode("div", _mergeProps({ class: "item" }, itemProps), _toDisplayString(itemData.label), 17)
                      ]),
                      _: 1
                    }, 8, ["testId", "modelValue", "items", "isLoading", "isdisabled", "onKeyup"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_ag_column, {
              xs: "12",
              sm: "6",
              md: "5",
              lg: _ctx.isAllowedUser() ? '3' : '5',
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_calendar, {
                  label: "Start Date",
                  "label-variant": "float",
                  modelValue: _ctx.startDate,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.startDate) = $event)),
                  testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.CALENDAR_FIELD as any, 'segment-startDate'),
                  date: _ctx.startDate,
                  "onUpdate:startDate": _ctx.changeStartDate,
                  "calendar-name": "startDate",
                  "max-date": _ctx.maxDate,
                  "min-date": _ctx.minStartDate,
                  class: "user-input",
                  isdisabled: _ctx.isFetchingOrganizations
                }, null, 8, ["modelValue", "testId", "date", "onUpdate:startDate", "max-date", "min-date", "isdisabled"])
              ]),
              _: 1
            }, 8, ["lg"]),
            _createVNode(_component_ag_column, {
              xs: "12",
              sm: "6",
              md: "5",
              lg: _ctx.isAllowedUser() ? '3' : '5',
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_calendar, {
                  label: "End Date",
                  "label-variant": "float",
                  testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.CALENDAR_FIELD as any, 'segment-endDate'),
                  modelValue: _ctx.endDate,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.endDate) = $event)),
                  date: _ctx.endDate,
                  "min-date": _ctx.startDate,
                  "onUpdate:endDate": _ctx.changeEndDate,
                  "calendar-name": "endDate",
                  "max-date": _ctx.maxDate,
                  class: "user-input",
                  isdisabled: _ctx.isFetchingOrganizations
                }, null, 8, ["testId", "modelValue", "date", "min-date", "onUpdate:endDate", "max-date", "isdisabled"])
              ]),
              _: 1
            }, 8, ["lg"]),
            _createVNode(_component_ag_column, {
              xs: "12",
              sm: "12",
              md: "2",
              lg: _ctx.isAllowedUser() ? '3' : '2',
              cols: "12",
              class: "d-block"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AGButton, {
                  testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON as any, 'sabreSegment-search'),
                  isLoading: _ctx.isLoading,
                  onClick: _ctx.fetchBookings,
                  class: "sb-btn user-input",
                  isdisabled: _ctx.isFetchingOrganizations || !_ctx.canFetch()
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isFetchingOrganizations && _ctx.isAllowedUser() ? "Loading" : "Search"), 1)
                  ]),
                  _: 1
                }, 8, ["testId", "isLoading", "onClick", "isdisabled"])
              ]),
              _: 1
            }, 8, ["lg"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.statsDetails)
      ? (_openBlock(), _createBlock(_component_ag_card, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_ag_heading, {
              variant: "h2",
              title: "Sabre Segment Stats"
            }),
            _createVNode(_component_AgTable, {
              headers: _ctx.statsTable,
              items: [_ctx.statsDetails],
              "has-pagination": false,
              "items-per-page": 1,
              "has-search": false
            }, {
              "col-confirmed_flight_segment_count": _withCtx(({ item }) => [
                _createVNode(_component_ag_div, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.confirmed_flight_segment_count), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-flown_segment_count": _withCtx(({ item }) => [
                _createVNode(_component_ag_div, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.flown_segment_count), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-issued_flight_segment_count": _withCtx(({ item }) => [
                _createVNode(_component_ag_div, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.issued_flight_segment_count), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-total_segment_bonus_amount": _withCtx(({ item }) => [
                _createVNode(_component_ag_div, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.total_segment_bonus_amount), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-start_date": _withCtx(({ item }) => [
                _createVNode(_component_ag_div, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formattedDate(item.raw.start_date)), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-end_date": _withCtx(({ item }) => [
                _createVNode(_component_ag_div, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formattedDate(item.raw.end_date)), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["headers", "items"])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_ag_card, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_AgNotFound, {
              "test-id": "",
              heading: "No Sabre Segment Stats found in the following date range",
              description: ""
            })
          ]),
          _: 1
        })),
    (_ctx.listDetails && _ctx.listDetails.length)
      ? (_openBlock(), _createBlock(_component_ag_card, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_ag_heading, {
              variant: "h2",
              title: "Sabre Segment List"
            }),
            _createVNode(_component_AgTable, {
              headers: _ctx.listTable,
              items: _ctx.listDetails,
              "has-pagination": false,
              "items-per-page": 20,
              "has-search": false
            }, {
              "col-created_date": _withCtx(({ item }) => [
                _createVNode(_component_ag_div, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formattedDate(item.raw.created_date)), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-origin": _withCtx(({ item }) => [
                _createVNode(_component_ag_div, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.origin), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-departure_datetime": _withCtx(({ item }) => [
                _createVNode(_component_ag_div, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formattedDate(item.raw.departure_datetime)), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-destination": _withCtx(({ item }) => [
                _createVNode(_component_ag_div, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.destination), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-operating_airline": _withCtx(({ item }) => [
                _createVNode(_component_ag_div, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.operating_airline), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-total_amount": _withCtx(({ item }) => [
                _createVNode(_component_ag_div, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.total_amount), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-status": _withCtx(({ item }) => [
                _createVNode(_component_ag_div, { class: "flight_table_status color_chip green" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.status), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-passenger_name": _withCtx(({ item }) => [
                _createVNode(_component_ag_div, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.passenger_name), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["headers", "items"])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_ag_card, { key: 3 }, {
          default: _withCtx(() => [
            _createVNode(_component_AgNotFound, {
              "test-id": "",
              heading: "No Sabre Segment List found in the following date range",
              description: ""
            })
          ]),
          _: 1
        }))
  ], 64))
}