import { parseLocalStorageData } from "./helpers";
import { STORAGE_KEYS } from "../constants/storageKeys";
import { IOrganizationPreference } from "../interfaces/organization.interface";

class ColorTheme {
  mainColor: string;
  opacity: number;

  constructor(mainColor: string, opacity: number) {
    this.mainColor = mainColor;
    this.opacity = opacity;
  }

  hexToRGB(hex: string): number[] {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  }

  rgbToHex(r: number, g: number, b: number): string {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  }

  calculateHoverColor(brightness: number): string {
    const rgb = this.hexToRGB(this.mainColor);
    const hoverRGB = rgb.map((channel) =>
      Math.min(255, channel + brightness)
    ) as [number, number, number];
    return this.rgbToHex(...hoverRGB);
  }

  calculateLighterColor(opacity: number): string {
    const rgb = this.hexToRGB(this.mainColor);
    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
  }
}

export const setThemeColor = () => {
  const organizationPreferences = parseLocalStorageData(
    STORAGE_KEYS.PREFERENCES
  ) as IOrganizationPreference;

  if (organizationPreferences) {
    const theme = new ColorTheme(
      organizationPreferences?.default_color || "#10B981",
      0.13
    );

    const root = document.documentElement;
    root.style.setProperty("--green-color", theme.mainColor);
    root.style.setProperty(
      "--primary-color-hover",
      theme.calculateHoverColor(1)
    );
    root.style.setProperty(
      "--login-screen-color",
      theme.calculateLighterColor(0.13)
    );
  }
};
