<template>
  <AGAppContainer class="default-page-color" fullWidth>
    <a-g-full-width-page>
      <template v-slot:content-area>
        <AGContainer class="login_wrapper">
          <AGPanel>
            <AGLogo :url="urlLogo" is-small />
            <h5>
              Welcome to <span>{{ agencyName }}</span>
            </h5>
            <p>Sign in to your account to continue...</p>
            <form :onsubmit="onSubmit">
              <AGTextField
                :testId="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'login-email')"
                type="email"
                :value="loginPayload.email"
                v-model="loginPayload.email"
                :onchange="onChangeEmail"
                name="email"
                label="Email"
              />

              <AGTextField
                v-model="loginPayload.password"
                :testId="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'login-password')"
                :value="loginPayload.password"
                :onchange="onChangePassword"
                name="password"
                label="Password"
                :appendIcon="showText ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showText ? 'text' : 'password'"
                @click:append="showText = !showText"
              />
              <div class="forgot_card">
                <router-link
                  :to="path.FORGOT_PASSWORD"
                  :testId="genTestId(ELEMENT_TYPES.LINK, 'forgot-password')"
                >
                  <AGButton
                    variant="link"
                    :testId="genTestId(ELEMENT_TYPES.BUTTON, 'forgot-password')"
                    >Forgot password
                  </AGButton>
                </router-link>
              </div>

              <div class="error-container">
                <div>
                  {{ error }}
                </div>
              </div>

              <div class="d-grid">
                <AGButton
                  type="submit"
                  :testId="genTestId(ELEMENT_TYPES.BUTTON, 'login-submit')"
                  :is-loading="isAuthenticating()"
                  variant="primary"
                  >Log In
                </AGButton>
              </div>
            </form>
          </AGPanel>
        </AGContainer>
      </template>
    </a-g-full-width-page>
  </AGAppContainer>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { PATH } from "@/ag-portal-common/constants/path";
import {
  genTestId,
  parseLocalStorageData,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IOrganizationPreference } from "@/ag-portal-common/interfaces/organization.interface";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";

export default defineComponent({
  name: "LoginView",
  created() {
    this.path = PATH;
  },
  data() {
    return {
      showText: false,
      password: "Password",
      ELEMENT_TYPES,
      path: {},
      loginPayload: { email: "", password: "" },
      error: "",
    };
  },
  methods: {
    genTestId,
    onChangeEmail(e: any) {
      this.loginPayload.email = e.target.value;
    },
    onChangePassword(e: any) {
      this.loginPayload.password = e.target.value;
    },
    async onSubmit(e: any) {
      e.preventDefault();
      this.enableIsAuthenticating();
      this.$store.dispatch("loginUser", {
        payload: this.loginPayload,
        callback: this.disableIsAuthenticating,
      });
    },
  },
  computed: {
    urlLogo() {
      const organizationPreference: IOrganizationPreference | null =
        parseLocalStorageData(STORAGE_KEYS.PREFERENCES);
      return organizationPreference?.logo;
    },
    agencyName() {
      const organizationPreference: IOrganizationPreference | null =
        parseLocalStorageData(STORAGE_KEYS.PREFERENCES);
      return organizationPreference?.display_name
        ? organizationPreference?.display_name
        : "Aeroglobe";
    },
  },
  setup() {
    const isAuthenticating = inject(
      AUTH_CONTEXT_KEYS.isAuthenticating
    ) as () => boolean;

    const enableIsAuthenticating = inject(
      AUTH_CONTEXT_KEYS.enableIsAuthenticating
    ) as () => void;

    const disableIsAuthenticating = inject(
      AUTH_CONTEXT_KEYS.disableIsAuthenticating
    ) as () => void;

    const organizationPreference = inject(
      AUTH_CONTEXT_KEYS.organizationPreference
    ) as () => IOrganizationPreference;

    return {
      isAuthenticating,
      enableIsAuthenticating,
      disableIsAuthenticating,
      organizationPreference,
    };
  },
});
</script>
<style>
header {
  line-height: 1.5;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

.error-container {
  color: red;
  margin: 10px 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}

.wrapper {
  padding-left: 24px !important;
}

.login_box {
  margin: auto !important;
}
</style>
