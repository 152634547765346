import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "agent_type_wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_ag_agent_info_box = _resolveComponent("ag-agent-info-box")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ag_agent_list_organization_and_agents = _resolveComponent("ag-agent-list-organization-and-agents")!
  const _component_agent_delete_confirmation_modal = _resolveComponent("agent-delete-confirmation-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.organization)
      ? (_openBlock(), _createBlock(_component_ag_agent_list_organization_and_agents, { key: 0 }, _createSlots({
          headingOrganization: _withCtx(() => [
            _createVNode(_component_ag_heading, {
              title: "Organization",
              variant: "h2"
            })
          ]),
          organizationItems: _withCtx(() => [
            _createVNode(_component_ag_agent_info_box, {
              title: "Status",
              icon: "profileCard",
              value: _ctx.organization.status
            }, null, 8, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Name",
              icon: "profileCard",
              value: _ctx.organization.name
            }, null, 8, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Sector Name",
              icon: "mapLocation",
              value: _ctx.organization.sector
            }, null, 8, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Currency",
              icon: "currentBalance",
              value: _ctx.organization.currency
            }, null, 8, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Default Financial Profile",
              icon: "profileCardLight",
              value: 
          _ctx.organization?.financial_profiles[0]
            ? `${_ctx.organization?.financial_profiles[0]?.public_id} (${_ctx.organization?.financial_profiles[0]?.platform_id})`
            : ''
        
            }, null, 8, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "PIA Provider",
              icon: "provider",
              value: _ctx.organization.pia_provider || ''
            }, null, 8, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Sales POC",
              icon: "profileCard",
              value: _ctx.organization.sales_poc || ''
            }, null, 8, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Phone",
              icon: "phone",
              value: _ctx.organization.contact_person_phone || ''
            }, null, 8, ["value"]),
            _createVNode(_component_ag_agent_info_box, {
              title: "Email",
              icon: "mail",
              value: _ctx.organization.contact_person_email || ''
            }, null, 8, ["value"]),
            (_ctx.isShowUpdateStatusButton)
              ? (_openBlock(), _createBlock(_component_a_g_button, {
                  key: 0,
                  testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'organization-update-status'),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (
          _ctx.onStatusUpdate(
            _ctx.organization.public_id,
            _ctx.organization.status === _ctx.ORGANIZATION_STATUSES.ACTIVE
              ? _ctx.ORGANIZATION_STATUSES.INACTIVE
              : _ctx.ORGANIZATION_STATUSES.ACTIVE
          )
        )),
                  disabled: _ctx.$store.getters.isUpdatingOrganization,
                  "is-loading": _ctx.$store.getters.isUpdatingOrganization
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.organizationStatus) + " Account ", 1)
                  ]),
                  _: 1
                }, 8, ["testId", "disabled", "is-loading"]))
              : _createCommentVNode("", true)
          ]),
          headingAgents: _withCtx(() => [
            _createVNode(_component_ag_heading, {
              title: "Agents",
              variant: "h2"
            })
          ]),
          agentItems: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizationAgents, (agent) => {
              return (_openBlock(), _createBlock(_component_ag_column, {
                class: "custom-agent-list",
                key: agent.agent_id,
                icon: "mail"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_ag_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ag_column, {
                          sm: "12",
                          md: "8"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_agent_info_box, {
                              icon: "mail",
                              title: "Email",
                              value: agent.user.email
                            }, null, 8, ["value"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ag_column, {
                          sm: "12",
                          md: "4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_agent_info_box, {
                              icon: "persons",
                              title: "Agent Sub Type",
                              value: agent.user.role_sub_type,
                              class: "user_type_btn"
                            }, null, 8, ["value"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    (agent.user.permissions.length)
                      ? (_openBlock(), _createBlock(_component_ag_column, {
                          key: 0,
                          class: "organization_permission_wrap"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_heading, { variant: "h4" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Permissions")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ag_column, { class: "permission_inner" }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(agent.user.permissions, (permission, index) => {
                                  return (_openBlock(), _createBlock(_component_ag_heading, {
                                    variant: "span",
                                    class: "inner_row",
                                    key: index
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.formatPermissionString(permission)), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ag_column, null, {
                      default: _withCtx(() => [
                        (_ctx.isEnableUserDeletion(agent.user.role_sub_type))
                          ? (_openBlock(), _createBlock(_component_a_g_button, {
                              key: 0,
                              testId: 
                _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'organization-delete-agent')
              ,
                              onClick: ($event: any) => (_ctx.onDeleteAgentRequest(agent))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Delete ")
                              ]),
                              _: 2
                            }, 1032, ["testId", "onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 2
        }, [
          (_ctx.enableActions)
            ? {
                name: "addNewAgentButton",
                fn: _withCtx(() => [
                  _createVNode(_component_a_g_button, {
                    type: "button",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onAddAgent'))),
                    testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'organization-add-new-agent')
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Add New Agent ")
                    ]),
                    _: 1
                  }, 8, ["testId"])
                ]),
                key: "0"
              }
            : undefined
        ]), 1024))
      : _createCommentVNode("", true),
    (_ctx.selectedAgent)
      ? (_openBlock(), _createBlock(_component_agent_delete_confirmation_modal, {
          key: 1,
          "is-open": _ctx.isOpenAgentDeletionModal,
          agent: _ctx.selectedAgent,
          onOnCloseModal: _ctx.closeAgentDeletionModal,
          onOnDeleteAgent: _ctx.deleteAgent
        }, null, 8, ["is-open", "agent", "onOnCloseModal", "onOnDeleteAgent"]))
      : _createCommentVNode("", true)
  ], 64))
}