<template>
  <my-bookings-filters-view
    v-show="showFilters"
    @fetchBookings="onUpdateFilter"
  />
  <ag-card>
    <ag-loader v-if="$store.getters.isFetchingTourBookings" />
    <ag-table
      v-else-if="
        !$store.getters.isFetchingTourBookings &&
        $store.getters.tourBookings.bookings.length
      "
      :page="fetchBookingParams.page"
      :headers="headers"
      :items="$store.getters.tourBookings.bookings"
      :total-page-count="totalPageCount"
      :has-pagination="true"
      :items-per-page="itemsPerPage"
      @onPageChange="onPageChange"
      :has-search="false"
    >
      <template #col-bookingId="{ item }">
        <router-link
          :data-test-id="
            genTestId(ELEMENT_TYPES.LINK, `tour-booking-${item.raw.bookingId}`)
          "
          :to="
            formatStringToRoutePath(PATH.FDG_BOOKING_DETAILS, {
              id: item.raw.bookingId,
            })
          "
          >{{ item.raw.bookingId }}
        </router-link>
      </template>
      <template #col-title="{ item }">
        {{ item.raw.title }}
      </template>
      <template #col-status="{ item }">
        <div class="d-flex align-items-center">
          <ag-div class="flight_table_status color_chip green"
            >{{ item.raw.status }}
          </ag-div>

          <ag-div
            v-if="
              item.raw.confirmedBookingExpiresAt === null &&
              item.raw.status !== isIssued
            "
          >
            PNR Expiry will be available soon
          </ag-div>
          <ag-div
            v-else-if="
              isDateExpired(
                getConfirmOrInitiateDate(
                  item.raw.status,
                  item.raw.initiatedBookingExpiresAt,
                  item.raw.confirmedBookingExpiresAt
                )
              ) && item.raw.status !== isIssued
            "
            class="color-red"
          >
            Expired
          </ag-div>
          <ag-div v-else>
            <ag-div v-if="item.raw.status !== isIssued">
              Expires in <br />
              <AgDuration
                v-if="item.raw.status !== isIssued"
                :to="
                  getConfirmOrInitiateDate(
                    item.raw.status,
                    item.raw.initiatedBookingExpiresAt,
                    item.raw.confirmedBookingExpiresAt
                  )
                "
              />
            </ag-div>
          </ag-div>
        </div>
      </template>
      <template #col-agent="{ item }">
        {{ item.raw.agent }}
      </template>
      <template #col-number_of_guest="{ item }">
        <ag-div class="flight_table_status color_chip green"
          >{{ item.raw.guests }}
        </ag-div>
      </template>
      <template #col-tour_type="{ item }">
        <ag-div class="flight_table_status color_chip green"
          >{{ parse(item.raw.tourType) }}
        </ag-div>
      </template>
      <template #col-price="{ item }">
        <ag-div class="flight_table_status color_chip green"
          >{{ item.raw.currency }} {{ item.raw.price }}
        </ag-div>
      </template>
      <template #col-endDate="{ item }">
        {{ formattedDate(item.raw.endDate) }}
      </template>
      <template #col-startDate="{ item }">
        {{ formattedDate(item.raw.startDate) }}
      </template>
      <template #col-bookingTime="{ item }">
        <ag-div>{{ formattedDate(item.raw.bookingTime) }}</ag-div>
        <ag-div class="text-color-green"
          >{{ formattedTime(item.raw.bookingTime) }}
        </ag-div>
      </template>
      <template #col-travellers="{ item }">
        <AGButtonDropDown
          class="table_dropdown_wrap"
          text="Test"
          :items="item.raw.travellers"
        >
          <template #label>
            <i>{{ item.raw.travellers[0] }}</i>
          </template>
          <AGDropDownButtonItem
            v-for="(item, index) in item.raw.travellers"
            :key="index"
            :name="item"
          />
        </AGButtonDropDown>
      </template>
    </ag-table>

    <ag-not-found v-else :heading="NOTIFICATION_MESSAGES.NO_RESULTS_FOUND" />
  </ag-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  genTestId,
  getFormattedDateTime,
  isDateExpired,
  formatStringToRoutePath,
} from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";
import {
  FORMAT_DD_MMM_YYYY,
  FORMAT_HH_SS,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { BOOKING_STATUS } from "@/ag-portal-common/enums/BOOKING_STATUS";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { FetchBookingsParamsRequestModel } from "@/modules/MyBookings/models/fetchBookingsParams.request";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { BOOKING_TYPES } from "@/ag-portal-common/enums/BOOKING_TYPES";
import MyBookingsFiltersView from "@/modules/MyBookings/components/MyTourBookingsFilters.vue";

export default defineComponent({
  name: "TourBookings",
  components: { MyBookingsFiltersView },
  props: {
    isDashboardView: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    this.fetchBookings(this.setItemsPerPage);
  },
  computed: {
    showFilters() {
      return this.isDashboardView === false;
    },
    totalPageCount() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      let itemsPerPage = this.itemsPerPage;
      let totalCount = this.$store.getters.tourBookings.count;

      return Math.ceil(totalCount / itemsPerPage);
    },
    isIssued() {
      return (
        BOOKING_STATUS.ISSUED.toUpperCase() ||
        BOOKING_STATUS.ISSUED_BUT_NOT_PAID.toUpperCase()
      );
    },
    PATH() {
      return PATH;
    },
  },
  methods: {
    genTestId,
    isDateExpired,
    formatStringToRoutePath,
    onUpdateFilter(filters: FetchBookingsParamsRequestModel) {
      this.fetchBookingParams = {
        ...filters,
        booking_type: this.fetchBookingParams.booking_type,
        page: 1,
      };
      this.fetchBookings();
    },
    parse(value: string) {
      // khaali ha to dash daldo
      if (!value) {
        return "-";
      }

      // aur ye - aara beech me to gap daldo
      const processedValue = value.replace(/_/g, " ").trim();

      return processedValue;
    },
    onPageChange(num: number) {
      let nextPage = this.$store.getters.flightBookings.next;
      let prevPage = this.$store.getters.flightBookings.previous;
      let currentPage: number = this.fetchBookingParams.page || 1;
      if ((num > currentPage && nextPage) || (currentPage > num && prevPage)) {
        this.fetchBookingParams.page = num;
        this.fetchBookings();
      }
    },
    setItemsPerPage() {
      let bookingsLength = this.$store.getters.tourBookings.bookings.length;
      this.itemsPerPage = bookingsLength;
    },
    getConfirmOrInitiateDate(
      status: BOOKING_STATUS,
      initiatedBookingExpiresAt: Date,
      confirmedBookingExpiresAt: null | Date
    ) {
      if (status === BOOKING_STATUS.CONFIRMED.toUpperCase())
        return confirmedBookingExpiresAt || NOTIFICATION_MESSAGES.INVALID_DATE;
      return initiatedBookingExpiresAt;
    },
    fetchBookings(callback?: () => void) {
      this.$store.dispatch("fetchTourBookings", {
        payload: this.fetchBookingParams,
        callback,
      });
    },
    formattedDate(date: Date) {
      return getFormattedDateTime(date, FORMAT_DD_MMM_YYYY);
    },
    formattedTime(date: Date) {
      return getFormattedDateTime(date, FORMAT_HH_SS);
    },
  },
  data(): {
    ELEMENT_TYPES: IObject;
    headers: Array<{
      title: string;
      value: string;
      key: string;
      sortable: boolean;
    }>;
    NOTIFICATION_MESSAGES: IObject;
    fetchBookingParams: FetchBookingsParamsRequestModel;
    itemsPerPage: number;
  } {
    return {
      itemsPerPage: 0,
      fetchBookingParams: {
        search: "",
        start: null,
        end: null,
        page: 1,
        booking_type: BOOKING_TYPES.TOUR,
      },
      NOTIFICATION_MESSAGES,
      ELEMENT_TYPES,
      headers: [
        {
          title: "Booking ID",
          value: "bookingId",
          key: "bookingId",
          sortable: false,
        },
        {
          title: "Title",
          value: "title",
          key: "title",
          sortable: false,
        },
        {
          title: "Booking Time",
          value: "bookingTime",
          key: "bookingTime",
          sortable: false,
        },
        {
          title: "Start Time",
          value: "startDate",
          key: "startDate",
          sortable: false,
        },
        {
          title: "End Date",
          value: "endDate",
          key: "endDate",
          sortable: false,
        },
        {
          title: "Passengers",
          value: "travellers",
          key: "travellers",
          sortable: false,
        },
        {
          title: "Price",
          value: "price",
          key: "price",
          sortable: false,
        },
        {
          title: "Status",
          value: "status",
          key: "status",
          sortable: false,
        },
        {
          title: "Tour Type",
          value: "tour_type",
          key: "tour_type",
          sortable: false,
        },
        {
          title: "Agent",
          value: "agent",
          key: "agent",
          sortable: false,
        },
        {
          title: "Guests",
          value: "number_of_guest",
          key: "number_of_guest",
          sortable: false,
        },
      ],
    };
  },
});
</script>
