<template>
  <ag-card>
    <ag-row class="d-flex align-items-center">
      <ag-column sm="6" md="4" lg="2" cols="12">
        <ag-heading variant="label" title="Booking Id"></ag-heading>
        <a-g-text-field
          :testId="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'myBookings-bookingId')"
          v-model="bookingId"
          :value="bookingId"
        />
      </ag-column>
      <ag-column sm="6" md="4" lg="2" cols="12">
        <ag-heading variant="label" title="Email"></ag-heading>
        <a-g-text-field
          :testId="genTestId(ELEMENT_TYPES.TEXT_FIELD, 'myBookings-email')"
          v-model="email"
          :value="email"
        />
      </ag-column>
      <ag-column sm="6" md="4" lg="2" cols="12">
        <ag-heading variant="label" title="Status"></ag-heading>
        <ag-select
          :value="statusField"
          :testId="
            genTestId(ELEMENT_TYPES.SELECT_FIELD, 'myBookings-statusField')
          "
          variant="outlined"
          v-model="statusField"
          :items="bookingStatuses"
        />
      </ag-column>
      <ag-column sm="6" md="5" lg="3" cols="12" class="d-flex">
        <a-g-button
          :testId="genTestId(ELEMENT_TYPES.BUTTON, 'myBookings-search')"
          @click="fetchBookings"
          class="margin_right_10 align_multiple_btn"
          >Search
        </a-g-button>
        <a-g-button
          @click="clearSearchFilters"
          :testId="genTestId(ELEMENT_TYPES.BUTTON, 'myBookings-clearAll')"
          class="align_multiple_btn"
          >Clear All
        </a-g-button>
      </ag-column>
    </ag-row>
  </ag-card>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import {
  genTestId,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { BOOKING_STATUS } from "@/ag-portal-common/enums/BOOKING_STATUS";
import { FetchBookingsParamsRequestModel } from "@/modules/MyBookings/models/fetchBookingsParams.request";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";

export default defineComponent({
  name: "MyBookingsFiltersView",
  data(): {
    ELEMENT_TYPES: IObject;
    bookingStatuses: Array<BOOKING_STATUS>;
    bookingId: string;
    statusField: string;
    bookingFromField: Date | null;
    bookingToField: Date | null;
    email: string;
  } {
    return {
      ELEMENT_TYPES,
      bookingStatuses: Object.values(BOOKING_STATUS),
      statusField: "",
      bookingFromField: null,
      bookingToField: null,
      bookingId: "",
      email: "",
    };
  },
  methods: {
    genTestId,
    changeBookingFromField(date: Date) {
      this.bookingFromField = date;
    },
    changeBookingToField(date: Date) {
      this.bookingToField = date;
    },
    clearSearchFilters() {
      this.statusField = "";
      this.bookingToField = null;
      this.bookingFromField = null;
      this.bookingId = "";
      this.email = "";
      this.fetchBookings();
    },
    fetchBookings() {
      let payload: FetchBookingsParamsRequestModel = {
        ...(this.bookingId && { booking_id: this.bookingId }),
        ...(this.statusField && { status: this.statusField?.toUpperCase() }),
        ...(this.email && { creator_email: this.email }),
      };

      this.$emit("fetchBookings", payload);
    },
  },
});
</script>
