<template>
  <ag-modal
    persistent
    :is-open="isOpen"
    modal-width="40%"
    @close="$emit('handleReject')"
  >
    <template #body>
      <AgHeading title="Delete Whatsapp Member" variant="h3" test-id="" />
      <AgDiv>Are you sure to delete whatsapp member?</AgDiv>
      <AgDiv style="display: flex; float: right">
        <AGButton
          style="margin-right: 10px"
          test-id=""
          :disabled="isLoading"
          @click="$emit('handleReject')"
          variant="danger"
          type="button"
          >No
        </AGButton>
        <AGButton
          test-id=""
          @click="$emit('handleAccept')"
          :is-loading="isLoading"
          :disabled="isLoading"
          variant="primary"
          type="button"
          >Yes
        </AGButton>
      </AgDiv>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DeleteWhatsappMemberConfirmationModel",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
});
</script>
