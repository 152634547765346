<template>
  <span>
    <DurationSegment
      v-if="getDuration().days"
      title="day"
      :duration="getDuration().days"
      :boldNums="boldNums"
    />
    <DurationSegment
      v-if="getDuration().hours"
      title="hr"
      :duration="getDuration().hours"
      :boldNums="boldNums"
    />
    <DurationSegment
      v-if="getDuration().minutes"
      title="min"
      :duration="getDuration().minutes"
      :boldNums="boldNums"
    />
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import DurationSegment from "@/ag-portal-common/components/DurationSegment.vue";

export default defineComponent({
  name: "AgDuration",
  components: { DurationSegment },
  props: {
    from: {
      type: Date,
      required: false,
    },
    to: {
      type: Date,
      required: false,
    },
    boldNums: { type: Boolean, default: false },
    durationInMinutes: { type: Number, required: false },
  },

  methods: {
    getDuration(): {
      days: number;
      minutes: number;
      hours: number;
    } {
      let _from = this.from || new Date(Date.now());
      let _to = this.to || new Date(Date.now());
      const minutesDiff = differenceInMinutes(_to, _from);
      const hoursDiff = differenceInHours(_to, _from);
      const daysDiff = differenceInDays(_to, _from);
      const remainingMinutes = minutesDiff % 60;
      const remainingHours = hoursDiff % 24;

      return {
        days: daysDiff,
        minutes: remainingMinutes,
        hours: remainingHours,
      };
    },
  },
});
</script>
