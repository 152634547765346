import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { SECTOR_API_PATH } from "@/modules/Sector/constants";
import { SectorProductivityParamsRequestModel } from "../models/FetchSectorProductivityParams.request";
import { formatString } from "@/ag-portal-common/utils/helpers";

class SectorService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public getAll(): Promise<IAGResponse<any>> {
    return this.restClient.get(SECTOR_API_PATH.GET_ALL);
  }

  public getSectorStats(sectorId: string): Promise<IAGResponse<any>> {
    return this.restClient.get(
      formatString(SECTOR_API_PATH.GET_SECTOR_USAGE_SNAPSHOT, sectorId)
    );
  }

  public getSectorProductivity(
    sectorId: string,
    payload: SectorProductivityParamsRequestModel
  ): Promise<IAGResponse<any>> {
    return this.restClient.get(
      formatString(SECTOR_API_PATH.GET_SECTOR_PRODUCTIVITY, sectorId),
      payload
    );
  }
}

export default SectorService;
