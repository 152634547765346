import * as yup from "yup";
import { PASSWORD_STRENGTH_REGEX } from "@/modules/Auth/constants";

export const validateResetPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required("New Password is required")
    .matches(
      PASSWORD_STRENGTH_REGEX,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, and One Number"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm new password is required"),
});
