import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { ActionContext, ActionTree } from "vuex";
import SABREService from "@/modules/SabreSegment/services/sabreSegment.service";
import {
  NOTIFICATION_EVENTS,
  notificationBus,
} from "@/ag-portal-common/eventBusses/notification";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { formatString } from "@/ag-portal-common/utils/helpers";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";

const actions: ActionTree<any, any> = {
  async defaultSabreSegmentList() {
    let response: IAGResponse<any> = {
      success: false,
    };
    const methodName = "actions.defaultSabreSegmentList";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const sabreSegmentService = new SABREService();
      response = await sabreSegmentService.getByDefaultSegmentList();
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        return response.data?.data.segment_list;
        //
      } else {
        throw response;
      }
    } catch (err) {
      loggerService.logError(`${methodName}:`, err);
    } finally {
      //
    }
  },
  async defaultSabreSegmentStats() {
    let response: IAGResponse<any> = {
      success: false,
    };
    const methodName = "actions.defaultSabreSegmentStats";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const sabreSegmentService = new SABREService();
      response = await sabreSegmentService.getByDefaultSegmentStats();
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        return response.data?.data;
        //
      } else {
        throw response;
      }
    } catch (err) {
      loggerService.logError(`${methodName}:`, err);
    } finally {
      //
    }
  },
};

export default actions;
