<template>
  <AgCard class="card-wrapper">
    <p class="heading">Hotel Bookings</p>
    <hr />
    <AgDiv>
      <p class="overview-heading">Overview</p>
      <AgCard>
        <ag-row class="items-container">
          <ag-column
            xs="12"
            sm="12"
            md="6"
            lg="3"
            v-for="(item, index) in hotelBookingsAggregates"
            :key="index"
          >
            <ag-agent-info-box
              :title="renderItemTitle(item)"
              icon="notInvoice"
              :value="renderItemValue(item)"
            />
          </ag-column>
        </ag-row>
      </AgCard>
    </AgDiv>
    <AgTable
      v-if="showDetails"
      :headers="bookingHeader"
      :items="hotelBookingsOrganizations"
      :items-per-page="10"
      :has-pagination="false"
      :simpleDataTable="true"
      :hasSearch="true"
      @onClickRow="handleOrganizationRowClick"
    ></AgTable>
    <AgNotFound v-else test-id="" heading="Organizations not found" />
  </AgCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "lodash";

import {
  ISectorProductivity,
  HotelBookingsAggregates,
} from "@/ag-portal-common/interfaces/sectorProductivity.interface";
import { IHeading } from "@/ag-portal-common/interfaces/header.interface";

export default defineComponent({
  name: "HotelBookingsTable",
  props: {
    handleOrganizationRowClick: {
      type: Function,
    },
  },
  data(): {
    startDate: Date;
    endDate: Date;
    selectedSector: string;
    bookingHeader: IHeading[];
  } {
    return {
      startDate: new Date(),
      endDate: new Date(),
      selectedSector: "",
      bookingHeader: [
        {
          title: "Organization Name",
          value: "organization_name",
          key: "organization_name",
          sortable: true,
        },
        {
          title: "Hotel Bookings",
          value: "hotel_bookings",
          key: "hotel_bookings",
          sortable: true,
        },
        {
          title: "Hotel Issued",
          value: "hotel_issued",
          key: "hotel_issued",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    renderItemTitle(value: string) {
      return _.capitalize(value?.replaceAll("_", " "));
    },
    renderItemValue(value: keyof HotelBookingsAggregates) {
      return this.salesProductivity.hotel_bookings_data?.aggregates[value];
    },
  },
  computed: {
    salesProductivity(): ISectorProductivity {
      return this.$store.getters.salesProductivity;
    },
    showDetails(): boolean {
      const isLoading = this.$store.getters.isSalesLoading;
      const organizations =
        this.$store.getters.salesProductivity?.hotel_bookings_data
          ?.organizations || [];
      return !isLoading && organizations.length > 0;
    },
    hotelBookingsOrganizations() {
      const salesProductivity = this.$store.getters
        .salesProductivity as ISectorProductivity;
      const organizations =
        salesProductivity?.hotel_bookings_data?.organizations ?? [];

      return organizations;
    },
    hotelBookingsAggregates(): Array<keyof HotelBookingsAggregates> {
      const salesProductivity = this.$store.getters.salesProductivity as
        | ISectorProductivity
        | undefined;
      const aggregates = salesProductivity?.hotel_bookings_data?.aggregates;

      if (aggregates) {
        return Object.keys(aggregates) as Array<keyof HotelBookingsAggregates>;
      }

      return [];
    },
  },
});
</script>

<style lang="scss" scoped>
.card-wrapper {
  margin-block: 30px;
  border: solid 1px var(--green-color);
  border-top: solid 8px var(--green-color);
}

.heading {
  font-size: 30px;
  font-weight: bold;
}

.overview-heading {
  font-size: 19px;
  margin: 10px;
  font-weight: bold;
}

.items-container {
  border-left: solid 4px var(--green-color);
}

.empty-items-message {
  text-align: center;
  margin-block: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #828282;
}
</style>
