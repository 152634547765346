<template>
  <ag-modal :is-open="isOpen" @close="onCloseModal" modal-width="40%">
    <template #header>
      <v-card-title>Delete Agent</v-card-title>
      <a-g-button
        :testId="
          genTestId(
            ELEMENT_TYPES.BUTTON,
            'agent-delete-confirmation-modal-close'
          )
        "
        type="button"
        class="modal_close_icon"
        variant="link"
        @click="onCloseModal"
        >Close
      </a-g-button>
    </template>
    <template #body>
      <p>
        Are you sure you want to delete
        <b>{{ agent?.user.email }}</b
        >?
      </p>

      <div>
        <a-g-button
          :is-loading="$store.getters.isDeletingAgentFromOrganization"
          :testId="
            genTestId(
              ELEMENT_TYPES.BUTTON,
              'agent-delete-confirmation-modal-yes'
            )
          "
          @click="onDelete"
          >Yes
        </a-g-button>
        <span>&nbsp;&nbsp;</span>
        <a-g-button
          @click="onCloseModal"
          :testId="
            genTestId(
              ELEMENT_TYPES.BUTTON,
              'agent-delete-confirmation-modal-no'
            )
          "
          >No
        </a-g-button>
      </div>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IAgentV2 } from "@/ag-portal-common/interfaces/agent.interface";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";

export default defineComponent({
  name: "AgentDeleteConfirmationModal",
  events: ["onCloseModal", "onDeleteAgent"],
  props: {
    agent: { type: Object as PropType<IAgentV2>, required: true },
    isOpen: { type: Boolean, required: true },
  },
  data(): {
    ELEMENT_TYPES: IObject;
  } {
    return {
      ELEMENT_TYPES,
    };
  },
  methods: {
    genTestId,
    onCloseModal() {
      this.$emit("onCloseModal");
    },
    onDelete() {
      this.$emit("onDeleteAgent");
    },
  },
});
</script>
