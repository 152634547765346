<template>
  <my-bookings-filters-view
    v-show="showFilters"
    @fetchBookings="onUpdateFilter"
  />
  <ag-card>
    <ag-loader v-if="$store.getters.isFetchingHotelBookings" />
    <ag-table
      v-else-if="
        !$store.getters.isFetchingHotelBookings &&
        $store.getters.hotelBookings.bookings.length
      "
      :page="fetchBookingParams.page"
      :headers="headers"
      :items="$store.getters.hotelBookings.bookings"
      :total-page-count="totalPageCount"
      :has-pagination="true"
      :items-per-page="itemsPerPage"
      @onPageChange="onPageChange"
      :has-search="false"
    >
      <template #col-bookingId="{ item }">
        <router-link
          :data-test-id="
            genTestId(ELEMENT_TYPES.LINK, `hotel-booking-${item.raw.id}`)
          "
          :to="
            formatStringToRoutePath(PATH.HOTEL_BOOKING_DETAILS, {
              id: item.raw.id,
            })
          "
          >{{ item.raw.bookingId }}
        </router-link>
      </template>

      <template #col-guests="{ item }">
        <AGButtonDropDown
          text="Test"
          class="table_dropdown_wrap"
          :items="item.raw.guests"
        >
          <template #label>
            <i>{{ item.raw.guests[0] }}</i>
          </template>
          <AGDropDownButtonItem
            v-for="(item, index) in item.raw.guests"
            :key="index"
            :name="item"
          />
        </AGButtonDropDown>
      </template>
      <template #col-bookingTime="{ item }">
        <ag-div>{{ formattedDate(item.raw.bookingTime) }}</ag-div>
        <ag-div class="text-color-green"
          >{{ formattedTime(item.raw.bookingTime) }}
        </ag-div>
      </template>
      <template #col-status="{ item }">
        <ag-div class="d-flex align-items-center">
          <ag-div class="flight_table_status color_chip green"
            >{{ item.raw.status }}
          </ag-div>
          &nbsp;

          <ag-div
            v-if="
              item.raw.confirmedBookingExpiresAt === null &&
              item.raw.status !== isIssued
            "
          >
            PNR Expiry will be available soon
          </ag-div>
          <ag-div
            v-else-if="
              isDateExpired(
                getConfirmOrInitiateDate(
                  item.raw.status,
                  item.raw.initiatedBookingExpiresAt,
                  item.raw.confirmedBookingExpiresAt
                )
              ) && item.raw.status !== isIssued
            "
            class="color-red"
          >
            Expired
          </ag-div>
          <ag-div v-else>
            <ag-div v-if="item.raw.status !== isIssued">
              Expires in <br />
              <AgDuration
                :to="
                  getConfirmOrInitiateDate(
                    item.raw.status,
                    item.raw.initiatedBookingExpiresAt,
                    item.raw.confirmedBookingExpiresAt
                  )
                "
              />
            </ag-div>
          </ag-div>
        </ag-div>
      </template>
      <template #col-checkIn="{ item }">
        <ag-div>{{ formattedDate(item.raw.checkIn) }}</ag-div>
      </template>
      <template #col-checkOut="{ item }">
        <ag-div>{{ formattedDate(item.raw.checkOut) }}</ag-div>
      </template>
    </ag-table>

    <ag-not-found v-else :heading="NOTIFICATION_MESSAGES.NO_RESULTS_FOUND" />
  </ag-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  genTestId,
  getFormattedDateTime,
  formatStringToRoutePath,
  isDateExpired,
} from "@/ag-portal-common/utils/helpers";
import {
  FORMAT_DD_MMM_YYYY,
  FORMAT_HH_SS,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { PATH } from "@/ag-portal-common/constants/path";
import { BOOKING_STATUS } from "@/ag-portal-common/enums/BOOKING_STATUS";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { FetchBookingsParamsRequestModel } from "@/modules/MyBookings/models/fetchBookingsParams.request";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import AgDuration from "@/ag-portal-common/components/AgDuration.vue";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { BOOKING_TYPES } from "@/ag-portal-common/enums/BOOKING_TYPES";
import MyBookingsFiltersView from "@/modules/MyBookings/components/MyHotelBookingsFilters.vue";

export default defineComponent({
  name: "HotelBookings",
  components: { AgDuration, MyBookingsFiltersView },
  beforeMount() {
    this.fetchBookings(this.setItemsPerPage);
  },
  computed: {
    showFilters() {
      return this.isDashboardView === false;
    },
    PATH() {
      return PATH;
    },
    totalPageCount() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      let itemsPerPage = this.itemsPerPage;
      let totalCount = this.$store.getters.hotelBookings.count;

      return Math.ceil(totalCount / itemsPerPage);
    },
    isIssued() {
      return (
        BOOKING_STATUS.ISSUED.toUpperCase() ||
        BOOKING_STATUS.ISSUED_BUT_NOT_PAID.toUpperCase()
      );
    },
  },
  props: {
    isDashboardView: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    genTestId,
    formatStringToRoutePath,
    isDateExpired,
    onUpdateFilter(filters: FetchBookingsParamsRequestModel) {
      this.fetchBookingParams = {
        ...filters,
        booking_type: this.fetchBookingParams.booking_type,
        page: 1,
      };
      this.fetchBookings();
    },
    onPageChange(num: number) {
      let nextPage = this.$store.getters.hotelBookings.next;
      let prevPage = this.$store.getters.hotelBookings.previous;
      let currentPage: number = this.fetchBookingParams.page || 1;
      if ((num > currentPage && nextPage) || (currentPage > num && prevPage)) {
        this.fetchBookingParams.page = num;
        this.fetchBookings();
      }
    },
    setItemsPerPage() {
      let bookingsLength = this.$store.getters.hotelBookings.bookings.length;
      this.itemsPerPage = bookingsLength;
    },
    getConfirmOrInitiateDate(
      status: BOOKING_STATUS,
      initiatedBookingExpiresAt: Date,
      confirmedBookingExpiresAt: null | Date
    ) {
      if (status === BOOKING_STATUS.CONFIRMED.toUpperCase())
        return confirmedBookingExpiresAt || NOTIFICATION_MESSAGES.INVALID_DATE;
      return initiatedBookingExpiresAt;
    },
    fetchBookings(callback?: () => void) {
      this.$store.dispatch("fetchHotelBookings", {
        payload: this.fetchBookingParams,
        callback,
      });
    },
    formattedDate(date: Date) {
      return getFormattedDateTime(date, FORMAT_DD_MMM_YYYY);
    },
    formattedTime(date: Date) {
      return getFormattedDateTime(date, FORMAT_HH_SS);
    },
  },
  data(): {
    ELEMENT_TYPES: IObject;
    headers: Array<{
      title: string;
      value: string;
      key: string;
      sortable: boolean;
    }>;
    NOTIFICATION_MESSAGES: IObject;
    fetchBookingParams: FetchBookingsParamsRequestModel;
    itemsPerPage: number;
  } {
    return {
      itemsPerPage: 0,
      fetchBookingParams: {
        search: "",
        start: null,
        end: null,
        page: 1,
        booking_type: BOOKING_TYPES.HOTEL,
      },
      NOTIFICATION_MESSAGES,
      ELEMENT_TYPES,
      headers: [
        {
          title: "Booking ID",
          value: "bookingId",
          key: "bookingId",
          sortable: false,
        },
        {
          title: "Guests",
          value: "guests",
          key: "guests",
          sortable: false,
        },
        {
          title: "Booking Time",
          value: "bookingTime",
          key: "bookingTime",
          sortable: false,
        },
        {
          title: "Status",
          value: "status",
          key: "status",
          sortable: false,
        },
        {
          title: "Check In",
          value: "checkIn",
          key: "checkIn",
          sortable: false,
        },
        {
          title: "Check Out",
          value: "checkOut",
          key: "checkOut",
          sortable: false,
        },
      ],
    };
  },
});
</script>
