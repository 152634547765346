<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "changeProfile",

  methods: {
    async changePassword() {
      try {
        this.passwordError = ""; // pehle sa khaali kara do baar baar
        const strongRegex =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
        const myCondition =
          this.newPass === this.confirmNewPass &&
          this.newPass.trim() !== "" &&
          this.confirmNewPass.trim() !== "" &&
          strongRegex.test(this.newPass) &&
          strongRegex.test(this.confirmNewPass);

        if (myCondition) {
          const body = {
            new_password: this.newPass,
            old_password: this.currentPass,
          };

          const payload = {
            body,
            successCallback: () => {
              this.passwordError = "";
              this.confirmNewPass = "";
              this.newPass = "";
              this.currentPass = "";
            },
            failureCallback: () => {
              this.passwordError = "";
              this.confirmNewPass = "";
              this.newPass = "";
              this.currentPass = "";
            },
          };

          this.$store.dispatch("changePassword", payload);
        } else {
          this.passwordError =
            "1)Both passwords must match 2)Password should contain at least 8 characters including 1 lowercase, 1 uppercase, 1 numeric, and 1 special character.";
        }
      } catch (error) {
        //   Error
      }
    },
  },

  data() {
    return {
      currentPass: "",
      newPass: "",
      confirmNewPass: "",
      passwordError: "",
    };
  },

  onMounted() {
    const userObj = this.$store.getters.user;
  },
});
</script>

<template>
  <div class="container">
    <div class="Password">
      <form @submit.prevent>
        <label>Current Password</label>

        <div class="input-container">
          <div class="input-field">
            <input
              type="password"
              placeholder="Current Password"
              v-model="currentPass"
            />
          </div>
        </div>

        <label>New Password</label>
        <div class="input-container">
          <div class="input-field">
            <input
              type="password"
              placeholder="New Password"
              v-model="newPass"
            />
          </div>
        </div>

        <label>Confirm New Password</label>
        <div class="input-container">
          <div class="input-field">
            <input
              type="password"
              placeholder="Confirm New Password"
              v-model="confirmNewPass"
            />
          </div>
        </div>

        <div v-if="passwordError" class="error-message">
          {{ passwordError }}
          <br />
        </div>

        <AGButton
          class="ChangePasswordButton"
          style="min-width: 150px"
          @click="changePassword"
          >change password
        </AGButton>
      </form>
    </div>
  </div>
</template>

<style scoped>
label,
input,
button {
  font-size: 14px;
  font-weight: 400;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

input {
  border-radius: 5px;
}

button {
  padding-left: 6px;
  padding-right: 12px;
  margin-top: 10px;
  min-width: 120px;
  background-color: #2eb85c;
  color: aliceblue;
  width: 3cm;
  border-radius: 5px;
  align-items: center;
  border-color: white;
}

input {
  border: 1px solid #3fc79a;
  min-width: 3.5in;
  max-width: 4in;
  min-height: 1cm;
  max-height: 1cm;
  padding-left: 2%;
}

.input-container {
  display: flex;
  align-items: center;
  padding-bottom: 0.25%;
}

.input-field {
  flex: 1;
  background-color: white;
  min-width: 350px;
  max-width: 350px;
}

.input-field input {
  width: 100%;
  box-sizing: border-box;
}

input:disabled {
  background-color: #d8dbe0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.error-message {
  color: red;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1; /* Add this line */
  margin-top: 4px; /* Add margin to create space below the error message */
}
</style>
