import AgentService from "@/modules/Agent/services/agent.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { ActionContext, ActionTree } from "vuex";
import { IAgentState } from "@/interfaces/agentState.interface";
import OrganizationService from "@/modules/Organization/services/organization.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { AgentPermissionsChangeVerificationRequestModel } from "@/modules/Agent/models/agentPermissionsChangeVerification.request";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import analyticsService from "@/analytic.service";
import { AGENT_ANALYTICS_EVENTS } from "@/modules/Agent/constants/analyticsEvents";
import { IAgentPricing } from "@/interfaces/agentPricing.interface";

const actions: ActionTree<IAgentState, IAgentState> = {
  async getOrganizationAgents(
    context: ActionContext<IAgentState, IAgentState>,
    organizationId: string
  ) {
    const methodName = "actions.getOrganizationAgents";
    try {
      context.commit("fetchingOrganizationAgents", true);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const organizationService = new OrganizationService();
      const response: IAGResponse<any> =
        await organizationService.getOrganizationById(organizationId);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("fetchingOrganizationAgents", false);
        context.commit("saveOrganizationAgents", response.data.agents);
      } else {
        context.commit("fetchingOrganizationAgents", false);
        throw response;
      }
    } catch (err) {
      context.commit("fetchingOrganizationAgents", false);
      loggerService.logError(`${methodName}:`, err);
    }
  },
  async fetchAgentById(
    context: ActionContext<IAgentState, IAgentState>,
    payload: { agentId: string; organizationId: string }
  ) {
    const methodName = "actions.fetchAgentById";
    try {
      context.commit("fetchingAgent", true);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const organizationService = new OrganizationService();
      const response: IAGResponse<any> =
        await organizationService.getOrganizationAgentById(
          payload.organizationId,
          payload.agentId
        );
      context.commit("fetchAgentSuccess", response.data);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
    } catch (err) {
      context.commit("fetchingAgent", false);
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
  },
  async updateAgentPermissions(
    context: ActionContext<IAgentState, IAgentState>,
    payload: {
      agentId: string;
      callback: (referenceId: string) => void;
      permissions: { [key: string]: boolean };
    }
  ) {
    let response: IAGResponse<any> = {
      success: false,
    };

    const methodName = "actions.updateAgentPermissions";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("updatingAgentPermissions", true);
      const agentService = new AgentService();
      response = await agentService.updateAgentPermissions(
        context.rootGetters.organizationId,
        payload.agentId,
        payload.permissions
      );
      if (response.success) {
        context.commit("updateAgentPermissionsSuccess", response.data);
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("updatingAgentPermissions", false);
        analyticsService.logActionEvent(
          AGENT_ANALYTICS_EVENTS.UPDATE_AGENT_PERMISSIONS,
          {
            agentId: payload.agentId,
            permissions: payload.permissions,
            organizationId: context.rootGetters.organizationId,
          }
        );
        payload.callback(response.data?.reference || "");
      } else {
        throw response;
      }
    } catch (err) {
      context.commit("updatingAgentPermissions", false);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = response.error || "";
      notificationService.triggerNotification();
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
  },

  async verificationOnPermissionsChange(
    context: ActionContext<IAgentState, IAgentState>,
    payload: {
      body: AgentPermissionsChangeVerificationRequestModel;
      onSuccessCallback: () => void;
      callback: () => boolean;
    }
  ) {
    let response: IAGResponse<any> = {
      success: false,
    };

    const methodName = "actions.verificationOnPermissionsChange";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const agentService = new AgentService();
      response = await agentService.agentPermissionsChangeVerification(
        payload.body
      );
      if (response.success) {
        context.commit("updateAgentPermissionsSuccess", response.data);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          NOTIFICATION_MESSAGES.UPDATE_PERMISSIONS_SUCCESS;
        payload.onSuccessCallback();
        analyticsService.logActionEvent(
          AGENT_ANALYTICS_EVENTS.UPDATE_AGENT_PERMISSIONS_OTP_VERIFICATION,
          {
            reference: payload.body.reference,
          }
        );
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
      } else {
        throw response;
      }
    } catch (err) {
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        response.error || NOTIFICATION_MESSAGES.DEFAULT;
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    payload.callback();
    notificationService.triggerNotification();
  },

  async fetchAgentPricing(
    context: ActionContext<IAgentState, IAgentState>,
    customerCode: string
  ) {
    let response: IAgentPricing[] = [];
    const methodName = "actions.fetchAgentPricing";
    context.commit("enableIsFetchAgentPricing");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    const agentService = new AgentService();
    response = await agentService.fetchAgentPricing(customerCode);
    context.commit("saveAgentPricing", response);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
  },
};

export default actions;
