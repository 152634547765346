import { AgentPricingResponseModel } from "@/modules/Agent/models/agentPricing.response";
import { IAgentPricing } from "@/interfaces/agentPricing.interface";

export class AgentPricingMapper {
  private agentPricingList: IAgentPricing[] = [];

  public responseMapper(data: AgentPricingResponseModel[]): IAgentPricing[] {
    this.agentPricingList = data.map((item) => ({
      region: item.REGION,
      airline: item.AIRLINE,
      remarks: item.REMARKS,
      aeroPro: item.aeroPRO,
      salesChannel: item["SALES\n CHANNEL"],
    }));

    return this.agentPricingList;
  }
}
