import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_ag_radio_item = _resolveComponent("ag-radio-item")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_AgTable = _resolveComponent("AgTable")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_ag_radio = _resolveComponent("ag-radio")!
  const _component_a_g_text_field = _resolveComponent("a-g-text-field")!
  const _component_financial_profile_dropdown = _resolveComponent("financial-profile-dropdown")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_row = _resolveComponent("ag-row")!

  return (_openBlock(), _createBlock(_component_ag_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ag_heading, {
        title: "Create New Payment",
        variant: "h3"
      }),
      _createVNode(_component_ag_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ag_column, {
            xs: "12",
            sm: "12",
            md: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_div, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_heading, {
                    title: "Select Payment Method",
                    variant: "label"
                  }),
                  _createVNode(_component_ag_radio, {
                    modelValue: _ctx.method,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.method) = $event)),
                    testId: 
              _ctx.genTestId(_ctx.ELEMENT_TYPES.RADIO_BUTTON, 'create-payment-method')
            
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createVNode(_component_ag_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_radio_item, {
                            testId: 
                  _ctx.genTestId(_ctx.ELEMENT_TYPES.RADIO_BUTTON, 'create-payment-IBFT')
                ,
                            value: _ctx.PAYMENT_METHODS_TYPES.IBFT,
                            name: "method",
                            label: "IBFT - Bank Transfer"
                          }, null, 8, ["testId", "value"]),
                          (_ctx.bankDetails?.bank_account_no !== null && _ctx.isBank)
                            ? (_openBlock(), _createBlock(_component_AgTable, {
                                key: 0,
                                headers: _ctx.bankTable,
                                items: [_ctx.bankDetails],
                                "has-pagination": false,
                                "items-per-page": 1,
                                "has-search": false
                              }, {
                                "col-bank_name": _withCtx(({ item }) => [
                                  _createVNode(_component_ag_div, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.raw.bank_name), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                "col-bank_iban": _withCtx(({ item }) => [
                                  _createVNode(_component_ag_div, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.raw.bank_iban), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                "col-bank_title": _withCtx(({ item }) => [
                                  _createVNode(_component_ag_div, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.raw.bank_title), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                "col-bank_account_no": _withCtx(({ item }) => [
                                  _createVNode(_component_ag_div, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.raw.bank_account_no), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 1
                              }, 8, ["headers", "items"]))
                            : _createCommentVNode("", true),
                          (_ctx.isBank)
                            ? (_openBlock(), _createBlock(_component_ag_div, {
                                key: 1,
                                class: "margin_top_20"
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.bankDetails?.bank_account_no !== null)
                                    ? (_openBlock(), _createBlock(_component_ag_heading, {
                                        key: 0,
                                        title: `Note: ${_ctx.noteForAmount}`,
                                        variant: "b"
                                      }, null, 8, ["title"]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.bankDetails?.bank_account_no === null && _ctx.isBank)
                            ? (_openBlock(), _createBlock(_component_AgNotFound, {
                                key: 2,
                                "test-id": "",
                                heading: "Please contact billing team to have your IBFT details updated",
                                description: ""
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 512), [
                        [_vShow, !_ctx.isSectorDubai]
                      ]),
                      _withDirectives(_createVNode(_component_ag_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_radio_item, {
                            testId: 
                  _ctx.genTestId(
                    _ctx.ELEMENT_TYPES.RADIO_BUTTON,
                    'create-payment-ONE_BILL'
                  )
                ,
                            value: _ctx.PAYMENT_METHODS_TYPES.ONE_BILL,
                            name: "method",
                            label: "One Bill"
                          }, null, 8, ["testId", "value"])
                        ]),
                        _: 1
                      }, 512), [
                        [_vShow, !_ctx.isSectorDubai]
                      ]),
                      _createVNode(_component_ag_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_radio_item, {
                            testId: 
                  _ctx.genTestId(
                    _ctx.ELEMENT_TYPES.RADIO_BUTTON,
                    'create-payment-CREDIT_CARD'
                  )
                ,
                            value: _ctx.PAYMENT_METHODS_TYPES.CREDIT_CARD,
                            name: "method",
                            label: "Debit/Credit Card"
                          }, null, 8, ["testId", "value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue", "testId"]),
                  (_ctx.errors?.method)
                    ? (_openBlock(), _createBlock(_component_ag_heading, {
                        key: 0,
                        class: "color-red",
                        title: _ctx.errors?.method,
                        variant: "p"
                      }, null, 8, ["title"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              (!_ctx.isBank)
                ? (_openBlock(), _createElementBlock("form", {
                    key: 0,
                    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
                  }, [
                    (!_ctx.isBank)
                      ? (_openBlock(), _createBlock(_component_ag_heading, {
                          key: 0,
                          title: "Amount",
                          variant: "label"
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_a_g_text_field, {
                      testId: 
              _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT_FIELD, 'create-payment-amount')
            ,
                      error: _ctx.errors?.amount,
                      modelValue: _ctx.amount,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.amount) = $event)),
                      value: _ctx.amount
                    }, null, 8, ["testId", "error", "modelValue", "value"]),
                    _createVNode(_component_financial_profile_dropdown, {
                      label: "Financial Profile",
                      error: _ctx.errors['financial_profile_public_id'],
                      onOnUpdateFinancialProfile: _ctx.updateFinancialProfilePublicId
                    }, null, 8, ["error", "onOnUpdateFinancialProfile"]),
                    _createVNode(_component_ag_heading, {
                      title: "Description",
                      variant: "label"
                    }),
                    _createVNode(_component_a_g_text_field, {
                      testId: 
              _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT_FIELD, 'create-payment-description')
            ,
                      modelValue: _ctx.description,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.description) = $event)),
                      value: _ctx.description
                    }, null, 8, ["testId", "modelValue", "value"]),
                    _createVNode(_component_ag_div, { class: "d-flex justify-content-end" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_g_button, {
                          class: "margin_right_20",
                          onClick: _ctx.routeToPaymentList
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Cancel ")
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_a_g_button, {
                          type: "submit",
                          testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'create-payment-submit'),
                          "is-loading": _ctx.$store.getters.isCreatingPayment,
                          disabled: _ctx.$store.getters.isCreatingPayment
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Create Payment ")
                          ]),
                          _: 1
                        }, 8, ["testId", "is-loading", "disabled"])
                      ]),
                      _: 1
                    })
                  ], 32))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (!_ctx.isBank)
        ? (_openBlock(), _createBlock(_component_ag_div, {
            key: 0,
            class: "margin_top_20"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_heading, {
                title: `Note: ${_ctx.noteForAmount}`,
                variant: "b"
              }, null, 8, ["title"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}