import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import storageService from "@/ag-portal-common/services/storage.service";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";
import { PATH } from "@/ag-portal-common/constants/path";
import { PERMISSIONS } from "@/ag-portal-common/enums/PERMISSIONS";
import {
  isRoleAllowed,
  isUserPermitted,
  parseLocalStorageData,
} from "@/ag-portal-common/utils/helpers";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";

import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import notificationService from "@/ag-portal-common/services/notification.service";

export function AuthMiddleware(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const accessToken = storageService.getItem(STORAGE_KEYS.ACCESS_TOKEN);
  const refreshToken = storageService.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  if (to.meta.isProtected && (!accessToken || !refreshToken)) {
    next(PATH.ROOT);
  } else if (to.meta.isProtected === false && accessToken && refreshToken) {
    next(PATH.DASHBOARD);
  } else {
    next();
  }
}

export function CheckRoleMiddleware(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const user: IUserV2 | null = parseLocalStorageData(STORAGE_KEYS.USER);
  const allowedRoles = (to.meta?.roles as Array<USER_ROLES>) || [];
  const userRole = user?.role_unique_id as USER_ROLES;
  if (isRoleAllowed(userRole, allowedRoles)) {
    next();
  } else {
    next(PATH.DASHBOARD);
  }
}

export function CheckPermissionsMiddleware(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const user: IUserV2 | null = parseLocalStorageData(STORAGE_KEYS.USER);
  const userPermissions: Array<PERMISSIONS> =
    parseLocalStorageData(STORAGE_KEYS.PERMISSIONS) || [];

  const allowedPermissions = (to.meta?.permissions as Array<PERMISSIONS>) || [];

  const isAgentUser = user?.role_unique_id === USER_ROLES.AGENT;
  const canAllowPermissions = isAgentUser
    ? isUserPermitted(allowedPermissions, userPermissions)
    : true;

  if (canAllowPermissions) {
    next();
  } else {
    // next(PATH.DASHBOARD); TAHA BHAI ASKED TO CHANGE THIS
    notificationService.type = NOTIFICATION_TYPES.ERROR;
    notificationService.description = "user not allowed to perform this action";
    notificationService.triggerNotification();
    setTimeout(() => {
      next(PATH.DASHBOARD);
    }, 5000); // after 5 secs routed to dashboard
  }
}
