import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AuthRoutes from "@/modules/Auth/route";
import HomeLayout from "@/ag-portal-common/Layout/HomeLayout.vue";
import HomeRoutes from "@/router/homeRoutes";
import { PATH } from "@/ag-portal-common/constants/path";
import {
  AuthMiddleware,
  CheckPermissionsMiddleware,
  CheckRoleMiddleware,
} from "@/router/middleware";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.ROOT,
    name: "auth",
    meta: {
      isProtected: false,
    },
    children: AuthRoutes,
  },
  {
    path: PATH.ROOT,
    name: "homeRoutes",
    component: HomeLayout,
    meta: {
      isProtected: true,
    },
    props: {
      title: "",
    },
    children: HomeRoutes,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(AuthMiddleware);
router.beforeEach(CheckRoleMiddleware);
router.beforeEach(CheckPermissionsMiddleware);

export default router;
