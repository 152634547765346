import { MutationTree } from "vuex";

import { ISalesState } from "@/ag-portal-common/interfaces/salesState.interface";
import {
  ISalesSector,
  ISalesSnapshot,
} from "@/ag-portal-common/interfaces/salesProductivity.interface";
import { ISectorProductivity } from "@/ag-portal-common/interfaces/sectorProductivity.interface";

const mutations: MutationTree<ISalesState> = {
  setSalesLoading(state, payload: boolean) {
    state.isLoading = payload;
  },

  setSalesSectors(state, payload: ISalesSector[]) {
    state.sectors = payload;
  },

  setSalesSnapshot(state, payload: ISalesSnapshot) {
    state.salesSnapshot = payload;
  },

  setSalesProductivity(state, payload: ISectorProductivity) {
    state.salesProductivity = payload;
  },
};

export default mutations;
