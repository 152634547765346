import { RouteRecordRaw } from "vue-router";
import AgentList from "@/modules/Agent/views/AgentList.vue";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import AgentManagment from "@/modules/Agent/views/AgentManagment.vue";
import { PATH } from "@/ag-portal-common/constants/path";
import AgentPermissions from "@/modules/Agent/views/AgentView.vue";
import AgentPricing from "@/modules/Agent/views/AgentPricing.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "agentList",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.AGENT_LIST.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.AGENT_LIST.PERMISSIONS,
    },
    component: AgentList,
  },
  {
    path: PATH.AGENT_MANAGEMENT,
    name: "agentManagement",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.AGENT_MANAGEMENT.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.AGENT_MANAGEMENT.PERMISSIONS,
    },
    component: AgentManagment,
  },
  {
    path: `${PATH.AGENT_PERMISSIONS}/:id`,
    name: "agentPermissions",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.AGENT_MANAGEMENT.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.AGENT_MANAGEMENT.PERMISSIONS,
    },
    component: AgentPermissions,
  },
  // {
  //   path: PATH.AGENT_PRICING,
  //   name: "agentPricing",
  //   meta: {
  //     roles: ROLES_AND_PERMISSION_ON_ROUTES.AGENT_PRICING.ROLES,
  //     permissions: ROLES_AND_PERMISSION_ON_ROUTES.AGENT_PRICING.PERMISSIONS,
  //   },
  //   component: AgentPricing,
  // },
];

export default routes;
