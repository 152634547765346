<template>
  <AgTabs test-id="test">
    <template #TabHeading>
      <v-tab value="one">
        <ag-icon test-id="flightIcon"></ag-icon>
        <span>Analytics</span>
      </v-tab>
      <v-tab value="two" :disabled="productivityTabDisabled">
        <ag-icon test-id="hotelIcon"></ag-icon>
        <span>Productivity</span></v-tab
      >
    </template>
    <template #TabDetail>
      <v-window-item value="one" transition="fade" reverse-transition="fade">
        <AnalyticsTab
      /></v-window-item>
      <v-window-item value="two" transition="fade" reverse-transition="fade">
        <ProductivityTab />
      </v-window-item>
    </template>
  </AgTabs>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AnalyticsTab from "@/modules/Sales/components/SalesSnapshotTabs/analyticsTab.vue";
import ProductivityTab from "@/modules/Sales/components/SalesSnapshotTabs/productivityTab.vue";

export default defineComponent({
  name: "SalesSnapshot",
  components: {
    AnalyticsTab,
    ProductivityTab,
  },
  computed: {
    productivityTabDisabled(): boolean {
      const { salesmanId } = this.$route.query;
      return !salesmanId;
    },
  },
  mounted() {
    this.$store.dispatch("fetchSalesSectors");
  },
  beforeUnmount() {
    this.$store.commit("setSalesSnapshot", null);
    this.$store.commit("setSalesProductivity", null);
  },
});
</script>
