<template>
  <ag-agent-list-organization-and-agents v-if="organization">
    <template #headingOrganization>
      <ag-heading title="Organization" variant="h2"></ag-heading>
    </template>
    <template #organizationItems>
      <ag-agent-info-box
        title="Status"
        icon="profileCard"
        :value="organization.status"
      >
      </ag-agent-info-box>
      <ag-agent-info-box
        title="Name"
        icon="profileCard"
        :value="organization.name"
      ></ag-agent-info-box>
      <ag-agent-info-box
        title="Sector Name"
        icon="mapLocation"
        :value="organization.sector"
      ></ag-agent-info-box>
      <ag-agent-info-box
        title="Currency"
        icon="currentBalance"
        :value="organization.currency"
      ></ag-agent-info-box>
      <ag-agent-info-box
        title="Default Financial Profile"
        icon="profileCardLight"
        :value="
          organization?.financial_profiles[0]
            ? `${organization?.financial_profiles[0]?.public_id} (${organization?.financial_profiles[0]?.platform_id})`
            : ''
        "
      ></ag-agent-info-box>
      <ag-agent-info-box
        title="PIA Provider"
        icon="provider"
        :value="organization.pia_provider || ''"
      ></ag-agent-info-box>
      <ag-agent-info-box
        title="Sales POC"
        icon="profileCard"
        :value="organization.sales_poc || ''"
      ></ag-agent-info-box>
      <ag-agent-info-box
        title="Phone"
        icon="phone"
        :value="organization.contact_person_phone || ''"
      ></ag-agent-info-box>
      <ag-agent-info-box
        title="Email"
        icon="mail"
        :value="organization.contact_person_email || ''"
      ></ag-agent-info-box>

      <a-g-button
        v-if="isShowUpdateStatusButton"
        :testId="genTestId(ELEMENT_TYPES.BUTTON, 'organization-update-status')"
        @click="
          onStatusUpdate(
            organization.public_id,
            organization.status === ORGANIZATION_STATUSES.ACTIVE
              ? ORGANIZATION_STATUSES.INACTIVE
              : ORGANIZATION_STATUSES.ACTIVE
          )
        "
        :disabled="$store.getters.isUpdatingOrganization"
        :is-loading="$store.getters.isUpdatingOrganization"
      >
        {{ organizationStatus }} Account
      </a-g-button>
    </template>
    <template #headingAgents>
      <ag-heading title="Agents" variant="h2"></ag-heading>
    </template>
    <template v-if="enableActions" #addNewAgentButton>
      <a-g-button
        type="button"
        @click="$emit('onAddAgent')"
        :testId="genTestId(ELEMENT_TYPES.BUTTON, 'organization-add-new-agent')"
        >Add New Agent
      </a-g-button>
    </template>
    <template #agentItems>
      <ag-column
        class="custom-agent-list"
        v-for="agent in organizationAgents"
        v-bind:key="agent.agent_id"
        icon="mail"
      >
        <div class="agent_type_wrap">
          <ag-row>
            <ag-column sm="12" md="8">
              <ag-agent-info-box
                icon="mail"
                title="Email"
                :value="agent.user.email"
              >
              </ag-agent-info-box>
            </ag-column>

            <ag-column sm="12" md="4">
              <ag-agent-info-box
                icon="persons"
                title="Agent Sub Type"
                :value="agent.user.role_sub_type"
                class="user_type_btn"
              >
              </ag-agent-info-box>
            </ag-column>
          </ag-row>
          <ag-column
            v-if="agent.user.permissions.length"
            class="organization_permission_wrap"
          >
            <ag-heading variant="h4">Permissions</ag-heading>
            <ag-column class="permission_inner">
              <ag-heading
                variant="span"
                class="inner_row"
                v-bind:key="index"
                v-for="(permission, index) in agent.user.permissions"
              >
                {{ formatPermissionString(permission) }}
              </ag-heading>
            </ag-column>
          </ag-column>

          <ag-column>
            <a-g-button
              v-if="isEnableUserDeletion(agent.user.role_sub_type)"
              :testId="
                genTestId(ELEMENT_TYPES.BUTTON, 'organization-delete-agent')
              "
              @click="onDeleteAgentRequest(agent)"
              >Delete
            </a-g-button>
          </ag-column>
        </div>
      </ag-column>
    </template>
  </ag-agent-list-organization-and-agents>

  <agent-delete-confirmation-modal
    v-if="selectedAgent"
    :is-open="isOpenAgentDeletionModal"
    :agent="selectedAgent"
    @onCloseModal="closeAgentDeletionModal"
    @onDeleteAgent="deleteAgent"
  />
</template>

<script lang="ts">
import { defineComponent, inject, PropType } from "vue";
import { IOrganizationDetail } from "@/ag-portal-common/interfaces/organizationDetail.interface";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { IAgentV2 } from "@/ag-portal-common/interfaces/agent.interface";
import { AGENT_SUB_ROLES } from "@/ag-portal-common/enums/AGENT_SUB_ROLES";
import AgentDeleteConfirmationModal from "@/modules/Agent/components/AgentDeleteConfirmationModal.vue";
import analyticsService from "@/analytic.service";
import { AGENT_ANALYTICS_EVENTS } from "@/modules/Agent/constants/analyticsEvents";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";

export default defineComponent({
  name: "OrganizationAndAgentList",
  components: { AgentDeleteConfirmationModal },
  events: ["onAddAgent", "updateOrganizationStatus"],
  props: {
    organization: {
      type: Object as PropType<IOrganizationDetail> | null,
    },
    email: {
      type: String || null,
      required: false,
      default: null,
    },
    enableActions: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    isOpenAgentDeletionModal: boolean;
    selectedAgent: IAgentV2 | null;
    ORGANIZATION_STATUSES: any;
    ELEMENT_TYPES: IObject;
  } {
    return {
      ELEMENT_TYPES,
      isOpenAgentDeletionModal: false,
      ORGANIZATION_STATUSES,
      selectedAgent: null,
    };
  },
  methods: {
    genTestId,
    deleteAgent() {
      this.$store.dispatch("deleteAgentFromOrganization", {
        agentId: this.selectedAgent?.agent_id,
        callback: this.agentDeleteSuccessCallback,
      });
    },
    agentDeleteSuccessCallback() {
      analyticsService.logActionEvent(AGENT_ANALYTICS_EVENTS.AGENT_DELETED, {
        agentId: this.selectedAgent?.agent_id,
      });
      this.closeAgentDeletionModal();
    },
    onDeleteAgentRequest(agent: IAgentV2) {
      this.selectedAgent = agent;
      this.isOpenAgentDeletionModal = true;
      analyticsService.logActionEvent(
        AGENT_ANALYTICS_EVENTS.INITIATE_AGENT_DELETE_REQUEST,
        {
          agentId: this.selectedAgent?.agent_id,
        }
      );
    },
    closeAgentDeletionModal() {
      this.isOpenAgentDeletionModal = false;
    },
    onStatusUpdate(id: string, status: ORGANIZATION_STATUSES) {
      this.$emit("updateOrganizationStatus", {
        status,
        public_id: id,
      });
    },
    formatPermissionString(value: string) {
      return (
        value.charAt(0).toUpperCase() +
        value.slice(1).replaceAll("_", " ").toLowerCase()
      );
    },
    isEnableUserDeletion(agentSubRole: AGENT_SUB_ROLES) {
      if (!this.enableActions) {
        return false;
      }
      if (
        this.user()?.role_unique_id === USER_ROLES.AG_SUPER_USER &&
        agentSubRole === AGENT_SUB_ROLES.OWNER
      ) {
        return true;
      }
      if (
        this.user()?.role_unique_id === USER_ROLES.AGENT &&
        agentSubRole !== AGENT_SUB_ROLES.OWNER
      ) {
        return true;
      }
      return false;
    },
  },
  computed: {
    isShowUpdateStatusButton() {
      return (
        this.user()?.role_unique_id === USER_ROLES.AG_SUPER_USER &&
        this.enableActions
      );
    },

    organizationStatus() {
      return this.organization?.status === ORGANIZATION_STATUSES.ACTIVE
        ? "Deactivate"
        : "Activate";
    },

    organizationAgents() {
      const agents = this.organization?.agents || [];
      return [...agents].sort((a, b) => {
        if (a.user.email === this.email) return -1;
        else if (b.user.email === this.email) return 1;
        else return a.user.email.localeCompare(b.user.email);
      });
    },
  },
  setup() {
    const user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2 | null;
    return {
      user,
    };
  },
});
</script>
