import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgAutoComplete = _resolveComponent("AgAutoComplete")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgTable = _resolveComponent("AgTable")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ag_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ag_heading, {
          variant: "h2",
          title: "Select Pricing"
        }),
        _createVNode(_component_ag_row, { class: "soa_main_wrapper" }, {
          default: _withCtx(() => [
            _createVNode(_component_ag_column, {
              xs: "12",
              md: "7",
              lg: "9",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AgAutoComplete, {
                  testId: "",
                  modelValue: _ctx.financialProfileId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.financialProfileId) = $event)),
                  label: "Financial Profiles",
                  items: _ctx.filteredProfiles,
                  clearable: true,
                  isLoading: _ctx.isFetchingFinancialProfiles,
                  class: "user-input",
                  isdisabled: _ctx.isFetchingFinancialProfiles || _ctx.isLoading,
                  onKeyup: _ctx.onChangeFPInputHandler
                }, {
                  dropdownItem: _withCtx(({ itemProps, itemData }) => [
                    _createElementVNode("div", _mergeProps({ class: "item" }, itemProps), _toDisplayString(itemData.label), 17)
                  ]),
                  _: 1
                }, 8, ["modelValue", "items", "isLoading", "isdisabled", "onKeyup"])
              ]),
              _: 1
            }),
            _createVNode(_component_ag_column, {
              xs: "12",
              md: "5",
              lg: "3",
              cols: "12",
              class: "d-flex align-items-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AGButton, {
                  onClick: _ctx.onFetchAgPricing,
                  disabled: _ctx.disableButtons,
                  testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'get-AgPricing'),
                  isdisabled: _ctx.isFetchingFinancialProfiles || !_ctx.canFetch() || _ctx.isLoading,
                  class: "sb-btn user-input"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isFetchingFinancialProfiles ? "Loading" : "Fetch Pricing"), 1)
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled", "testId", "isdisabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.pricings.length)
      ? (_openBlock(), _createBlock(_component_ag_card, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_AgTable, {
              headers: _ctx.tableHeaders,
              items: _ctx.pricings,
              "has-pagination": true,
              "items-per-page": 100,
              "has-search": true,
              simpleDataTable: true
            }, {
              "col-sales_channel": _withCtx(({ item }) => [
                _createVNode(_component_AgDiv, { class: "table-column-width" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.sales_channel), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-airline": _withCtx(({ item }) => [
                _createVNode(_component_AgDiv, { class: "table-column-width" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.airline), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-apply_on_gross_fare": _withCtx(({ item }) => [
                _createVNode(_component_AgDiv, { class: "table-column-width" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.apply_on_gross_fare ? "✅" : "❌"), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-sale_on_and_after": _withCtx(({ item }) => [
                _createVNode(_component_AgDiv, { class: "table-column-width" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.sale_on_and_after ? item.raw.sale_on_and_after : "-"), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-sale_on_and_before": _withCtx(({ item }) => [
                _createVNode(_component_AgDiv, { class: "table-column-width" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.sale_on_and_before ? item.raw.sale_on_and_before : "-"), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-travel_on_and_after": _withCtx(({ item }) => [
                _createVNode(_component_AgDiv, { class: "table-column-width" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.travel_on_and_after ? item.raw.travel_on_and_after : "-"), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-travel_on_and_before": _withCtx(({ item }) => [
                _createVNode(_component_AgDiv, { class: "table-column-width" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.travel_on_and_before ? item.raw.travel_on_and_before : "-"), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-remarks": _withCtx(({ item }) => [
                _createVNode(_component_AgDiv, { class: "table-column-width" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.raw.remarks ? item.raw.remarks : "-"), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "col-rbds": _withCtx(({ item }) => [
                _createVNode(_component_AgDiv, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.displayRbds(item.raw.rbds)), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["headers", "items"])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_AgNotFound, {
          key: 1,
          "test-id": "",
          heading: "No Ag Pricing Found",
          description: ""
        }))
  ], 64))
}