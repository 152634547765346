import { RouteRecordRaw } from "vue-router";
import OrganizationList from "@/modules/Organization/views/OrganizationList.vue";
import OrganizationPreference from "@/modules/Organization/views/OrganizationPreference.vue";
import CreateOrganization from "@/modules/Organization/views/CreateOrganization.vue";
import UpdateOrganization from "@/modules/Organization/views/UpdateOrganization.vue";
import OrganizationDetail from "@/modules/Organization/views/OrganizationDetail.vue";
import OrganizationSnapshot from "@/modules/Organization/views/OrganiztionSnapshot.vue";
import SectorSnapshot from "@/modules/Organization/views/SectorSnapshot.vue";
import CurrencyConversion from "@/modules/Organization/views/CurrencyConversion.vue";
import WebsiteManagement from "@/modules/Organization/views/WebsiteMangement.vue";
import WhatsappManagement from "@/modules/Organization/views/WhatsappManagement.vue";
import PassengerManagement from "@/modules/Organization/views/PassengerManagement.vue";
import SalesMyTeam from "@/modules/Organization/views/SalesMyTeam.vue";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import { PATH } from "@/ag-portal-common/constants/path";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "organizationList",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION.PERMISSIONS,
    },
    component: OrganizationList,
  },
  {
    path: ":id",
    name: "organizationDetail",
    meta: {
      roles: [
        ...ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION.ROLES,
        ...ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION_DETAIL.ROLES,
      ],
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION.PERMISSIONS,
    },
    component: OrganizationDetail,
  },
  {
    path: PATH.PREFERENCE,
    name: "preference",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.PREFERENCE.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.PREFERENCE.PERMISSIONS,
    },
    component: OrganizationPreference,
  },
  {
    path: PATH.CREATE_ORGANIZATION,
    name: "createOrganization",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION.PERMISSIONS,
    },
    component: CreateOrganization,
  },
  {
    path: PATH.UPDATE_ORGANIZATION,
    name: "updateOrganization",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION.PERMISSIONS,
    },
    component: UpdateOrganization,
  },
  {
    path: PATH.WEBSITE_MANAGEMENT,
    name: "websiteManagement",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.WEBSITE_MANAGEMENT.ROLES,
      permissions:
        ROLES_AND_PERMISSION_ON_ROUTES.WEBSITE_MANAGEMENT.PERMISSIONS,
    },
    component: WebsiteManagement,
  },
  {
    path: PATH.WHATSAPP_MANAGEMENT,
    name: "whatsappManagement",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.WHATSAPP_MANAGEMENT.ROLES,
      permissions:
        ROLES_AND_PERMISSION_ON_ROUTES.WHATSAPP_MANAGEMENT.PERMISSIONS,
    },
    component: WhatsappManagement,
  },
  {
    path: PATH.PASSENGER_MANAGEMENT,
    name: "passengerManagement",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.PASSENGER_MANAGEMENT.ROLES,
      permissions:
        ROLES_AND_PERMISSION_ON_ROUTES.PASSENGER_MANAGEMENT.PERMISSIONS,
    },
    component: PassengerManagement,
  },
  {
    path: PATH.ORGANIZATION_SNAPSHOT,
    name: "organizationSnapshot",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION_SNAPSHOT.ROLES,
      permissions:
        ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION_SNAPSHOT.PERMISSIONS,
    },
    component: OrganizationSnapshot,
  },
  {
    path: PATH.SECTOR_SNAPSHOT,
    name: "sectorSnapshot",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.SECTOR_SNAPSHOT.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.SECTOR_SNAPSHOT.PERMISSIONS,
    },
    component: SectorSnapshot,
  },
  {
    path: PATH.CURRENCY_CONVERSION,

    name: "currencyConversion",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.CURRENCY_CONVERSION.ROLES,
      permissions:
        ROLES_AND_PERMISSION_ON_ROUTES.CURRENCY_CONVERSION.PERMISSIONS,
    },
    component: CurrencyConversion,
  },
  {
    path: PATH.MY_TEAM,
    name: "salesMyTeam",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.SALES_MY_TEAM.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.SALES_MY_TEAM.PERMISSIONS,
    },
    component: SalesMyTeam,
  },
];

export default routes;
