import { ISalesState } from "@/ag-portal-common/interfaces/salesState.interface";

const salesState: ISalesState = {
  isLoading: true,
  sectors: null,
  salesSnapshot: null,
  salesProductivity: null,
};

export default salesState;
