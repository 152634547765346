import * as yup from "yup";

export const createPaymentValidationschema = yup.object().shape({
  amount: yup
    .number()
    .required("Amount is required.")
    .min(1, "Amount must be greater than 0."),
  method: yup.string().required("Payment method is required"),
  financial_profile_public_id: yup
    .string()
    .required("Financial profile is required"),
});
