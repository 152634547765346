import { RouteRecordRaw } from "vue-router";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import CreditLimitRequests from "@/modules/CreditLimitManagement/views/CreditLimitRequests.vue";
import { PATH } from "@/ag-portal-common/constants/path";
import CreateCreditLimitRequest from "@/modules/CreditLimitManagement/views/CreateCreditLimitRequest.vue";
import CreditLimitRequestDetail from "@/modules/CreditLimitManagement/views/CreditLimitRequestDetail.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "creditLimitManagement",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.CREDIT_LIMIT_MANAGEMENT.ROLES,
      permissions:
        ROLES_AND_PERMISSION_ON_ROUTES.CREDIT_LIMIT_MANAGEMENT.PERMISSIONS,
    },
    component: CreditLimitRequests,
  },
  {
    path: PATH.CREATE_CREDIT_LIMIT_REQUEST,
    name: "createCreditLimitRequest",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.CREATE_CREDIT_LIMIT_REQUEST.ROLES,
      permissions:
        ROLES_AND_PERMISSION_ON_ROUTES.CREATE_CREDIT_LIMIT_REQUEST.PERMISSIONS,
    },
    component: CreateCreditLimitRequest,
  },
  {
    path: PATH.CREDIT_LIMIT_REQUEST_DETAIL,
    name: "creditLimitRequestDetail",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.CREDIT_LIMIT_REQUEST_DETAIL.ROLES,
      permissions:
        ROLES_AND_PERMISSION_ON_ROUTES.CREDIT_LIMIT_REQUEST_DETAIL.PERMISSIONS,
    },
    component: CreditLimitRequestDetail,
  },
];

export default routes;
