<template>
  <ag-card class="agent-filter-form">
    <ag-heading variant="h2" title="Select Information"></ag-heading>
    <ag-row class="v-center">
      <ag-column sm="12" md="4" cols="12">
        <AgentAutoComplete @onChange="onChangeAgentSearchField" />
      </ag-column>
      <ag-column sm="12" md="4" cols="12">
        <AGTextField
          v-model="emailFieldValue"
          :value="emailFieldValue"
          type="text"
          label="Search Email"
        />
      </ag-column>
      <ag-column sm="12" md="4" class="align-agent-btn">
        <AGButton variant="link" @click="clearSearch">Clear</AGButton>
        <AGButton
          variant="primary"
          :disabled="
            (!emailFieldValue && !agentSearchFieldValue) ||
            $store.getters.isFetchingOrganization
          "
          :isLoading="$store.getters.isFetchingOrganization"
          @click="onClickPricingDetails"
          >Get Price Details
        </AGButton>
      </ag-column>
    </ag-row>
  </ag-card>
</template>

<script>
import AgentAutoComplete from "@/components/AgentAutoComplete.vue";

export default {
  name: "AgAgentListInfoFilterForm",
  components: { AgentAutoComplete },
  events: ["fetchOrganization"],
  data() {
    return {
      emailFieldValue: "",
      agentSearchFieldValue: null,
      filteredAgents: [],
    };
  },
  methods: {
    onClickPricingDetails() {
      this.$emit(
        "fetchOrganization",
        this.agentSearchFieldValue,
        this.emailFieldValue
      );
    },
    clearSearch() {
      this.agentSearchFieldValue = "";
      this.emailFieldValue = "";
    },
    onChangeAgentSearchField(value) {
      this.agentSearchFieldValue = value;
    },
  },
};
</script>
